import { useDispatch, useSelector } from 'react-redux'
import { InfoModal } from '@wadiz-frontend/waffle-modified'
import { CLOSE_WAFFLE_MODAL } from '../../reducers/'

export const WaffleInfoModal = () => {
  const dispatch = useDispatch()
  const { isOpen, title, message, confirmLabel, buttonColor } = useSelector((store) => store.waffleModal)

  return (
    isOpen && (
      <InfoModal
        title={title}
        message={message}
        buttonLabel={confirmLabel || '확인'}
        onClose={() =>
          dispatch({
            type: CLOSE_WAFFLE_MODAL,
          })
        }
        size={'sm'}
        buttonProps={{ color: buttonColor || 'primary', size: 'lg' }}
      />
    )
  )
}
