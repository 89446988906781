// eslint-disable-next-line
import React, { FC } from 'react'
import classNames from 'classnames/bind'
import style from './GuideSection.module.scss'
import TitleSection from '../TitleSection'
const cx = classNames.bind(style)

const GuideSection: FC = () => (
  <section className={cx('container')}>
    <TitleSection section="GuideSection" className={cx('title')} />

    <div className={cx('buttonSection')}>
      <a className={cx('guidBtn')} href={process.env.REACT_APP_WADIZ_AD_GOODS} type="text/html" target="_blank" rel="noopener noreferrer">
        <span>상품 소개서</span>
        <i className={cx('downloadIcon')}></i>
      </a>
      <a className={cx('guidBtn')} href={process.env.REACT_APP_WADIZ_AD_MANUAL} type="text/html" target="_blank" rel="noopener noreferrer">
        <span>이용 가이드</span>
        <i className={cx('downloadIcon')}></i>
      </a>
      <a
        className={cx('guidBtn')}
        href={process.env.REACT_APP_WADIZ_AD_CREATE_MANUAL}
        type="text/html"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>제작 가이드</span>
        <i className={cx('downloadIcon')}></i>
      </a>
    </div>
  </section>
)

export default GuideSection
