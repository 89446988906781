import { api } from '@wad/api'
import type { FunctionKeywordValidation, KeywordValidationReturnType } from '@wad/api'

export const fetchGetKeywordValidation: FunctionKeywordValidation = ({
  keyword,
  start_date,
  end_date,
  validateType,
  creative_id,
}) =>
  api<KeywordValidationReturnType>(
    'GET',
    `/creative/keyword/validation?keyword=${keyword}&start_date=${start_date}&end_date=${end_date}${
      !validateType ? '' : '&type=' + validateType
    }${!creative_id ? '' : '&creative_id=' + creative_id}`
  )
