import React, { PureComponent } from 'react'

import RefundAccount from '../../components/SettingContent/contents/RefundAccount'

import { connect } from 'react-redux'
import { openPopup } from '../../reducers/dialogReducer'
import { network } from '../../network'
import { toast } from 'react-toastify'

class SettingPage extends PureComponent {
  state = {
    // settingType: 0, // 0: 세금계산서, 1: 사업자 정보, 2: 환불 계좌, 3: 간편 계좌
    makerInfo: {},
    bankList: [],

    loadingBarFlag: false,

    openGuide: { idx: -1, type: '' }, // 설정 가이드
  }

  componentDidMount() {
    const promiseList = []
    let makerInfo = {}
    let bankList = []

    this.setState({ loadingBarFlag: true }, () => {
      promiseList.push(
        new Promise((resolve, reject) => {
          return network.callApi({
            url: `maker/info?maker_id=${this.props.user.maker_id}`,
            method: 'get',
            successFunc: (jsonData) => {
              makerInfo = jsonData.result
              resolve(jsonData.result)
            },
            failFunc: (error) => {
              reject(error)
            },
          })
        })
      )

      promiseList.push(
        new Promise((resolve, reject) => {
          return network.callApi({
            url: 'bank-info/list',
            method: 'get',
            successFunc: (jsonData) => {
              bankList = jsonData.result
              resolve(jsonData.result)
            },
            failFunc: (error) => {
              reject(error)
            },
          })
        })
      )

      Promise.all(promiseList).then(() => {
        this.setState({ makerInfo, bankList, loadingBarFlag: false })
      })
    })
  }

  // 가이드 flag change function
  handleOpenGuideType = (idx, type) => {
    this.setState((prevState) => ({
      openGuide: { idx, type: prevState.openGuide.type === type && prevState.openGuide.idx === idx ? '' : type },
    }))
  }

  handleSelectBank = (e) => {
    const { makerInfo, bankList } = this.state
    const newMakerInfo = { ...makerInfo }
    newMakerInfo.bankCode = e.target.value
    newMakerInfo.bankName = bankList.find((ex) => ex.bankCode === e.target.value).bankName
    this.setState({ makerInfo: newMakerInfo })
  }

  handelInputChange = (e) => {
    const { target } = e
    const { name } = target
    const { makerInfo } = this.state
    const newMakerInfo = { ...makerInfo }

    const val = target.value
    if (name === 'bankAccount') {
      const bankAccountValidate = /^[\n]|(^[0-9])*/gi.test(val)
      if (!bankAccountValidate || val.length > 16) {
        return
      }
    }
    newMakerInfo[name] = target.value
    this.setState({ makerInfo: newMakerInfo })
  }

  handleUpdateRefundAccount = () => {
    const { makerInfo } = this.state
    if (!makerInfo.bankCode || makerInfo.bankCode === '' || !makerInfo.bankAccount || makerInfo.bankAccount === '') {
      toast('환불계좌 입력정보를 확인하세요.')
      return
    }
    network.callApi({
      url: 'maker/update',
      method: 'POST',
      body: {
        id: makerInfo.id,
        bankName: makerInfo.bankName,
        bankCode: makerInfo.bankCode,
        bankAccount: makerInfo.bankAccount,
      },
      successFunc: (jsonData) => {
        if (jsonData.result === 'success') {
          toast('환불 계좌가 등록되었습니다.')
        }
      },
      failFunc: () => {
        console.error(`fail- maker/update`)
      },
    })
  }

  render() {
    const { state, handleOpenGuideType, handelInputChange, handleSelectBank, handleUpdateRefundAccount } = this

    const { makerInfo, bankList, openGuide, loadingBarFlag } = state

    return (
      <RefundAccount
        makerInfo={makerInfo}
        bankList={bankList}
        openGuide={openGuide}
        loadingBarFlag={loadingBarFlag}
        onChangeOpenGuideType={handleOpenGuideType}
        inputChange={handelInputChange}
        selectBank={handleSelectBank}
        updateRefundAccount={handleUpdateRefundAccount}
      />
    )
  }
}

export default connect(({ user, project }) => ({ user, project }), { openPopup })(SettingPage)
