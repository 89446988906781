import React from 'react'

export default function useIsSmallScreen(size = 768) {
  const [width, setWidth] = React.useState(10000);

  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width < size;
}
