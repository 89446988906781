import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// css module
import styles from './Balloon.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

class Ballon extends Component {
  componentDidMount() {
    // add event listener for clicks
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    // make sure you remove the listener when the component is destroyed
    document.removeEventListener('click', this.handleClick, false);
  }

  handleClick = e => {
    if (!ReactDOM.findDOMNode(this).contains(e.target)) {
      this.props.hideDropdown();
    }
  }

  render() {
    const { title, text } = this.props;
    return (
      <div className={cx('wz message', 'guide')}>
        <dl>
          <dt>
            {title}
          </dt>
          <dd>
            {text}
          </dd>
        </dl>
      </div>
    );
  }
}

export default Ballon;
