import React, { useRef, useEffect, useCallback, useState, useMemo, useLayoutEffect } from 'react'
import cx from 'classnames'
import ReactModal from 'react-modal'

import { sendAppMessage2 } from '@wadiz-frontend/utils-modified' // '@wadiz-frontend/utils-modified/action';
import { isIos, isWadizIosApp, isWadizApp } from '@wadiz-frontend/utils-modified' // '@wadiz-frontend/utils-modified/browser';

import { CloseIcon } from '@wadiz-frontend/waffle-icons-modified'
import Button, { ButtonProps } from '../Button'

import styles from './ConfirmModal.module.scss'

export enum ModalSize {
  'mobile',
  'sm',
  'md',
  'lg',
  'xl',
  'full',
  'sheet',
  'sheetMD',
}

export enum ButtonsDirection {
  'horizontal',
  'vertical',
}

export interface ConfirmModalProps extends Partial<Pick<ReactModal.Props, 'onAfterOpen'>> {
  /** 타이틀 */
  title: string | React.ReactNode | null

  /** 메시지 콘텐츠 */
  message?: string

  /** 확인 버튼 라벨 */
  positiveButtonLabel?: string | null

  /** 취소 버튼 라벨 */
  negativeButtonLabel?: string | null

  /** 확인 버튼 속성 */
  positiveButtonProps?: ButtonProps

  /** 취소 버튼 속성 */
  negativeButtonProps?: ButtonProps

  /**
   * 하단 버튼 배치 방향
   * @default horizontal
   **/
  buttonsDirection?: keyof typeof ButtonsDirection

  /**
   * 모달 크기
   * @default 'md'
   **/
  size?: keyof typeof ModalSize | Object

  /** 오버레이 투명 딤드 시 사용 */
  overlayTransparent?: boolean

  /**
   * 레이어 순서
   * @description 서드파티 라이브러리 요소에 의해 레이어가 겹치는 경우 사용하세요.
   **/
  zIndex?: number

  /**
   * 버튼 클릭 시 자동 닫기 여부
   * @default true
   **/
  autoClose?: boolean

  /**
   * 오버레이 클릭 시 자동 닫기 여부
   * @default true
   **/
  closeClickOutside?: boolean

  /**
   * 본문 스크롤 비활성화
   * @default false
   **/
  preventBodyScroll?: boolean

  /**
   * 버튼 영역 노출 여부
   * @default true
   **/
  showButtons?: boolean

  /**
   * 닫기(X) 아이콘 노출 여부
   * @default true
   **/
  showCloseButton?: boolean

  /**
   * 페이지 전환 시 실행하는 함수
   * @description 모달을 메서드 방식으로 사용하는 경우 페이지 전환 시 닫기 용도로 사용하세요.
   **/
  cleanUp?: Function

  /* close 되어 contentRef가 null이 된 경우 호출, animation 종료 시점으로 사용 */
  onAfterClose?: Function

  /** 닫기(X) 아이콘 클릭 시 실행하는 함수 */
  onClose?: Function

  /* 모달이 사라진 경우에 호출(데이터 수집 용도) */
  onContentHide?: Function

  /* 모달이 실제로 보여진 경우에 호출(데이터 수집 용도) */
  onContentShow?: Function

  /** 확인 버튼 클릭 시 실행하는 함수 */
  onPositiveButtonClick?: Function

  /** 취소 버튼 클릭 시 실행하는 함수 */
  onNegativeButtonClick?: Function

  /** ReactModal의 overlayRef prop, (element or null) 값이 전달된다 */
  overlayRef?: Function

  /** 오버레이 클래스명 */
  overlayClassName?: string

  /** content를 full-size로 고정하고 싶은 경우에 설정 (height: 100%로 설정됨) */
  fullHeight?: boolean

  /** header 영역 커스텀 시 사용 */
  headerClassName?: string

  /** content 영역 커스텀 시 사용 */
  contentClassName?: string

  /** footer 영역 커스텀 시 사용 */
  footerClassName?: string

  /** modal의 show/hide를 제어 인스턴스가 유지되기 때문에 애니메이션을 적용할 수 있음
   * onClose() -> isOpen(false) -> animation 적용 -> onAfterClose() -> 모달 instance 제거
   */
  isOpen?: boolean

  /** 콘텐츠 영역의 좌우 padding */
  paddingX?: number

  /** 콘텐츠 영역의 하단 padding */
  paddingBottom?: number

  /** 콘텐츠 영역의 탑 padding */
  paddingTop?: number

  className?: string

  children?: React.ReactNode

  // 닫기 버튼 플로팅 - 타이틀이 없고 콘텐츠에 이미지가 있거나 타이틀 영역이 잡히면 안 되는 경우
  isFloatingCloseButton?: boolean

  // 헤더 영역 하단에 보더있는 타입
  isHeaderBorderType?: boolean
}

type localState = {
  overlayElement: null | HTMLElement
}

const breakpoints = [
  ['lg', '1096'],
  ['md', '769'],
]
const OVERLAY_CLASS_NAME = 'waffle__modal__overlay'
const overlayBackgroundColor = 'rgba(0, 0, 0, 0.2)'

// css 파일에 있는 padding을 style에서 설정하도록 수정
const PADDING_MAP_FROM_SIZE = {
  mobile: 24,
  sm: 24,
  md: 24,
  lg: 40,
  xl: 40,
  full: 16,
  sheet: 24,
  sheetMD: 24,
}

export const ConfirmModal = ({
  title,
  message,
  positiveButtonLabel = '확인',
  negativeButtonLabel = '취소',
  positiveButtonProps = {},
  negativeButtonProps = {},
  buttonsDirection = 'horizontal',
  size = 'md',
  overlayClassName: overlayClassNameProp,
  overlayTransparent = false,
  zIndex,
  autoClose = true,
  closeClickOutside = true,
  preventBodyScroll = true,
  showButtons = true,
  showCloseButton = true,
  cleanUp,
  onClose,
  onAfterClose,
  onContentShow,
  onContentHide,
  onPositiveButtonClick,
  onNegativeButtonClick,
  overlayRef,
  fullHeight,
  className,
  headerClassName,
  contentClassName,
  footerClassName,
  children,
  isOpen = true,
  paddingX,
  paddingBottom,
  paddingTop,
  isFloatingCloseButton = false,
  isHeaderBorderType = false,
  ...props
}: ConfirmModalProps) => {
  const localStateRef = useRef<localState>({ overlayElement: null })
  const localState = localStateRef.current

  const getBreakPoint = () => {
    for (let i = 0; i < breakpoints.length; i++) {
      const [name, width] = breakpoints[i]
      if (window.matchMedia(`(min-width: ${width}px)`).matches) {
        return name
      }
    }
    return 'sm'
  }

  const targetSize = useMemo(() => {
    let targetSize
    const currentBreakPoint = getBreakPoint()
    if (size.constructor.name === 'Object') {
      targetSize = size[currentBreakPoint as keyof typeof size]
    } else {
      targetSize = size
    }
    if (!targetSize) {
      targetSize = 'sm' // 값을 입력하지 않은 경우 sm 적용
    }

    return targetSize
  }, [size]) as keyof typeof PADDING_MAP_FROM_SIZE

  const getOverlayElements = () => {
    return Array.from(document.getElementsByClassName(OVERLAY_CLASS_NAME) as HTMLCollectionOf<HTMLElement>)
  }

  const handleClose = useCallback(() => {
    onClose && onClose()
  }, [onClose])

  const handlePositiveButtonClick = useCallback(() => {
    autoClose && handleClose()
    onPositiveButtonClick && onPositiveButtonClick()
  }, [onPositiveButtonClick])

  const handleNegativeButtonClick = useCallback(() => {
    autoClose && handleClose()
    onNegativeButtonClick && onNegativeButtonClick()
  }, [onNegativeButtonClick])

  const getRootStyle = () => {
    return cx(
      styles.confirmModal,
      typeof className === 'string' ? cx(styles.desktopContent, className) : styles.desktopContent,
      {
        [styles.sizeMobile]: targetSize === 'mobile',
        [styles.sizeSm]: targetSize === 'sm',
        [styles.sizeMd]: targetSize === 'md',
        [styles.sizeLg]: targetSize === 'lg',
        [styles.sizeXl]: targetSize === 'xl',
        [styles.sizeFull]: targetSize === 'full',
        [styles.sizeSheet]: targetSize === 'sheet',
        [styles.sizeSheetMD]: targetSize === 'sheetMD',
        [styles.overlayTransparent]: overlayTransparent,
      }
    )
  }

  const previousBodyStyleRef = useRef({ touchAction: '', position: '', width: '', scrollX: -1, scrollY: -1 })
  const setOverlay = (overlayElement: HTMLElement) => {
    if (overlayRef) {
      overlayRef(overlayElement)
    }
    if (overlayElement && preventBodyScroll) {
      // 모달이 오픈되는 시점에 overflow hidden 처리
      if (isIos && !isWadizApp && document.body.style.position !== 'fixed') {
        // iOS 모웹에서, body scroll 되는 현상 대응
        previousBodyStyleRef.current.touchAction = document.body.style.touchAction
        previousBodyStyleRef.current.position = document.body.style.position
        previousBodyStyleRef.current.width = document.body.style.width
        previousBodyStyleRef.current.scrollX = window.scrollX
        previousBodyStyleRef.current.scrollY = window.scrollY

        document.body.style.top = `-${window.scrollY}px`
        document.body.style.left = `-${window.scrollX}px`
        document.body.style.width = '100%'
        document.body.style.position = 'fixed'
      }
      document.body.style.overflow = 'hidden'
    }

    if (!overlayElement) {
      if (isIos && !isWadizApp && previousBodyStyleRef.current.scrollX !== -1) {
        //모달이 닫힐 때, iOS 모웹에서, body scroll 되는 현상을 대응하기 위해 설정했던 style 값을 원복한다.
        document.body.style.touchAction = previousBodyStyleRef.current.touchAction
        document.body.style.width = previousBodyStyleRef.current.width
        if (document.body.style.position === 'fixed') {
          document.body.style.position = previousBodyStyleRef.current.position
          window.scrollTo(previousBodyStyleRef.current.scrollX, previousBodyStyleRef.current.scrollY)
        }

        document.body.style.top = ''
        document.body.style.left = ''
        document.body.style.width = ''
        document.body.style.position = ''
      }

      document.body.style.overflow = 'auto' // 모달이 닫히는 시점에 overflow auto 처리, 중복으로 여러 모달이 열려있는 경우는 고려하지 않음 > 모달을 중복으로 생성하는 구조를 지양하기로 디자이너팀과 협의
    }

    if (zIndex && overlayElement) {
      overlayElement.style.zIndex = '' + zIndex
    }

    // 외부 영역 클릭 시 overlayElement 요소인 경우에만 닫기
    // 외부 영역의 모든 요소를 대상으로 할 경우 모달 내부에서 <Portal /> 형태로 사용한 컴포넌트 클릭 시 사라지는 사이드 이펙트 발생
    if (closeClickOutside && overlayElement) {
      overlayElement.addEventListener('click', (event) => {
        if (event.target === overlayElement && onClose) {
          onClose()
        }
      })
    }

    // 여러개의 모달이 오픈되는 경우 최종 모달만 dimmed 적용, react18 에서 동시성 랜더링을 지원하기 때문에 이전 overlayElement를 저장하고 해당 element를 제외하는 방식으로 실제 랜더링된 element를 구분하도록 변경
    const renderedModalElements = getOverlayElements()
    const modalElements = renderedModalElements.filter((overlay) => overlay !== localState.overlayElement)
    modalElements.forEach((element: HTMLElement, index) => {
      if (index === modalElements.length - 1) {
        element.style.backgroundColor = overlayBackgroundColor
      } else {
        element.style.backgroundColor = 'transparent'
      }
    })

    if (overlayElement && overlayTransparent) {
      overlayElement.style.backgroundColor = 'transparent'
    }

    localState.overlayElement = overlayElement
  }

  const stylesMap = useMemo(() => {
    const padding = `${PADDING_MAP_FROM_SIZE[targetSize]}px`
    const contentPadding = paddingX === undefined ? padding : `${paddingX}px`
    return {
      root:
        paddingBottom !== undefined
          ? {
              paddingBottom: `calc(${paddingBottom}px + env(safe-area-inset-bottom))`,
            }
          : {},
      header: {
        paddingLeft: padding,
        paddingRight: padding,
        paddingTop: paddingTop ? `${paddingTop}px` : undefined,
      },
      content: {
        paddingLeft: contentPadding,
        paddingRight: contentPadding,
      },
      footer: {
        paddingLeft: contentPadding,
        paddingRight: contentPadding,
      },
    }
  }, [targetSize, paddingX, paddingBottom, paddingTop])

  return (
    <ReactModal
      style={{ content: stylesMap.root }}
      className={
        typeof className === 'object'
          ? className
          : {
              base: getRootStyle(),
              afterOpen: styles.contentAfterOpen,
              beforeClose: styles.contentBeforeClose,
            }
      }
      overlayClassName={{
        base: cx(styles.overlay, OVERLAY_CLASS_NAME, overlayClassNameProp),
        afterOpen: '',
        beforeClose: '',
      }}
      closeTimeoutMS={300}
      appElement={window.document && window.document.body}
      overlayRef={setOverlay}
      contentRef={(node) => {
        if (node) {
          if (isWadizApp) {
            sendAppMessage2('modal.opened')
            // TODO: 모달 내부에서 다른 Link로 이동시 스크롤이 블럭되는 현상 수정 - 이후 앱에서 Page 전환(로드)시 스크롤 블럭 초기화 처리 필요
            node.addEventListener('click', (e) => {
              const aTag = (e.target as Element)?.closest('a')
              if (aTag) {
                sendAppMessage2('modal.closed')
              }
            })
          }
          onContentShow?.()
        } else {
          if (isWadizApp) {
            // FIXME: 다른 모달이 오픈되어 있는 경우 스크롤이 되는 현상 방지(더 명확한 방법이 있으면 좋겠다...)
            const overlayElements = getOverlayElements()
            if (overlayElements.length <= 1) {
              sendAppMessage2('modal.closed')
            }
          }
          onContentHide?.()
          if (!isOpen) {
            onAfterClose?.()
          }
        }
      }}
      isOpen={isOpen}
      {...props}
    >
      <div
        className={cx(styles.header, headerClassName, {
          [styles.emptyTitle]: !title,
          [styles.border]: isHeaderBorderType,
        })}
        style={stylesMap.header}
      >
        {showCloseButton && (
          <div className={cx(styles.closeIconRoot, { [styles.floating]: isFloatingCloseButton })}>
            <button className={styles.closeIconWrapper} aria-label="모달 닫기" onClick={() => handleClose()}>
              <CloseIcon size={24} />
            </button>
          </div>
        )}
        {title && <div className={styles.title}>{title}</div>}
      </div>
      <div
        style={stylesMap.content}
        className={cx(styles.content, { [styles.hasTitle]: title, [styles.fullHeight]: fullHeight }, contentClassName)}
      >
        {children && children}
        {!children && <div className={styles.message}>{message}</div>}
      </div>
      {showButtons && (
        <div style={stylesMap.footer} className={cx(styles.footer, footerClassName)}>
          <div className={cx(styles.buttonGroup, { [styles.buttonGroupVertical]: buttonsDirection === 'vertical' })}>
            {negativeButtonLabel !== null && (
              <Button
                size="lg"
                className={styles.negativeButton}
                onClick={handleNegativeButtonClick}
                {...negativeButtonProps}
              >
                {negativeButtonLabel}
              </Button>
            )}
            {positiveButtonLabel !== null && (
              <Button
                variant="contained"
                color="primary"
                size="lg"
                className={styles.positiveButton}
                onClick={handlePositiveButtonClick}
                {...positiveButtonProps}
              >
                {positiveButtonLabel}
              </Button>
            )}
          </div>
        </div>
      )}
    </ReactModal>
  )
}

export default React.memo(ConfirmModal)
