// eslint-disable-next-line
import React, { FC, useEffect, useState, useCallback, useMemo } from 'react'
import Cookies from 'universal-cookie'
import { Button, Checkbox, ConfirmModal } from '@wadiz-frontend/waffle-modified'

import styles from './ExpiredNotificationModal.module.scss'

type parametersType = {
  handleKey: string
  title: string
}
const cookie = new Cookies()
export const ExpiredNotificationModal: FC<parametersType> = ({ handleKey, title, children }) => {
  const [showModal, setShowModal] = useState(false)
  const [isChecked, setChecked] = useState(false)
  const cookieKey = 'notShowAdNotification'

  useEffect(() => {
    const condition = sessionStorage.getItem(handleKey)
    if ((!condition || condition !== 'false') && cookie.get(cookieKey) !== 'true') {
      setShowModal(true)
    }
  }, [handleKey])

  const handleChange = useCallback(({ currentTarget }) => {
    setChecked(!!currentTarget?.checked)
  }, [])
  const handleClose = useCallback(() => {
    sessionStorage.setItem(handleKey, 'false')
    setShowModal(false)

    if (isChecked) {
      cookie.set(cookieKey, 'true', { maxAge: 60 * 60 * 24 })
    }
  }, [handleKey, isChecked])

  return (
    showModal && (
      <ConfirmModal title={title} showButtons={false}>
        <div>
          <div>{children}</div>
          <div className={styles.buttonContainer}>
            <Checkbox text={'하루 동안 보지 않기'} size="sm" onChange={handleChange} />
            <Button variant="contained" color="primary" onClick={handleClose}>
              확인
            </Button>
          </div>
        </div>
      </ConfirmModal>
    )
  )
}
