import { api } from './api'

export type NotificationContent = {
  id: number // 공지사항 id
  title: string // 공지사항 제목(관리자)
  content: string // 공지사항 내용
}
type ReturnType = {
  message: string | null
  data: NotificationContent[]
}
type FunctionType = () => Promise<ReturnType>
export const fetchNotification: FunctionType = () => api('GET', 'notifications')
