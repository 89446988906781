import React, { Component } from 'react';
import Toast from './Toast';
import styles from './ToastContainer.module.scss';

export interface ToastContainerProps {
  /** didMount시점에 호출하는 callback*/
  onDidMount: Function,
}

export interface ToastContainerState {
  items: ToastItem[],
}

export interface ToastItem {
  id: number,
  contents: any,
  requestDestroy: boolean,
  props: object,
}

class ToastContainer extends Component<ToastContainerProps, ToastContainerState> {
  currentId: number;

  constructor(props: ToastContainerProps) {
    super(props);
    this.state = {
      items: [],
    };
    this.currentId = 0;
  }

  addToast = (contents: React.ReactElement, toastProps: any) => {
    this.currentId += 1;
    const newItems = this.state.items.map((item) => {
      const nextItem = {...item, requestDestroy: true };
      return nextItem;
    });
    newItems.push({ id: this.currentId, contents, requestDestroy: false, props: toastProps });
    this.setState({items: newItems});
  }

  removeToast = (id: any) => {
    const newItems = this.state.items.filter(item => {
      return item.id !== id
    });
    this.setState({items: newItems});
  }

  showToast = (content: React.ReactElement, toastProps = {}) => {
    this.addToast(content, toastProps);
  }

  componentDidMount() {
    this.props.onDidMount(this);
  };

  render() {
    return (
    <div className={styles.container}>
      {this.state.items.map(item => {
        return (
          <Toast id={item.id} key={item.id} requestDestroy={item.requestDestroy} onClose={this.removeToast} {...item.props}>{item.contents}</Toast>
        );
      })}
    </div>
    )
  }
};

export default ToastContainer;
