import { FC, useCallback } from 'react'
import { toast } from 'react-toastify'
import { notification } from 'antd'
import { PersonIcon } from '@wadiz-frontend/waffle-icons-modified'
import classNames from 'classnames/bind'
import { checkAreaDisabled, AreaType } from '@legacy/definitions'
import style from './AreaItem.module.scss'
const cx = classNames.bind(style)

export type tAreaInfo = {
  area: string
  available: number
  createdAt: string
  id: number
  imgUrl: string
  manualUrl: string
  packagedId: number | null
  isPackaged: boolean | undefined
  updatedAt: string
}

type Props = {
  isStoreProject: boolean
  projectType: number
  projectStartDate: string //-- YYYY-MM-DDTHH:mm:ss date string
  comingsoon: number
  area: tAreaInfo
  selected: boolean
  onSelectArea: () => void
}

type tPackageTitleProps = {
  children: string
}
const PackageTitle = ({ children }: tPackageTitleProps) => {
  const dashContentList = children.split('-')
  if (!children || dashContentList.length < 2) {
    return <span className={cx('areaTitle')}>{children}</span>
  }
  return (
    <>
      <span className={cx('areaPackageTitle')}>{dashContentList[0].trim()}</span> <br />
      <span className={cx('areaTitle')} style={{ lineHeight: '0' }}>
        {dashContentList[1].trim()}
      </span>
    </>
  )
}

const AreaItem: FC<Props> = (props) => {
  const [api, contextHolder] = notification.useNotification()
  const { selected, isStoreProject, projectType /* projectStartDate, comingsoon */ } = props
  const { area, imgUrl, available, id } = props.area
  const isNotAvailableArea = available === 1
  const disabled = isNotAvailableArea || checkAreaDisabled({ isStoreProject, isPreOrder: projectType === 4, id })

  const openNotification = useCallback(() => {
    notification.destroy()
    api.open({
      message: '확인해주세요',
      description: '구버전의 와디즈 앱을 사용하는 일부 사용자에게는 프리오더 패키지 광고가 노출되지 않을 수 있습니다.',
      icon: <PersonIcon size={20} />,
    })
  }, [api])
  const doClick = useCallback(() => {
    if (disabled) {
      // const { projectType } = props
      const { id } = props.area
      if (id === AreaType.messagePush) {
        toast('광고 상품 운영을 준비 중입니다')
        // } else if ((projectType == 2 || projectType === 4) && (AreaType.comingSoonHome === id || AreaType.comingSoonList === id)) {
        // toast('오픈 예정인 프로젝트만 가능한 광고 입니다.')
      } else {
        toast('해당 광고 영역은 선택할 수 없습니다.')
      }
    } else {
      props.onSelectArea()
      if (!selected && (id === AreaType.preOrderBanner || id === AreaType.preOrderList)) {
        openNotification()
      }
    }
  }, [disabled, props, selected, id, openNotification])

  const styleAdImage = {
    backgroundImage: `url(${imgUrl})`,
  }

  return (
    <div className={cx('wrapper')}>
      {contextHolder}
      <div className={cx('container')} onClick={doClick}>
        <div className={cx('areaImage', { selected }, { disabled })} style={styleAdImage}></div>
        <PackageTitle>{area}</PackageTitle>
      </div>
    </div>
  )
}

export default AreaItem
