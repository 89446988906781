import React from 'react'
import { NotificationModal } from '@center/ui'
import Lnb from '../Lnb'
import NavBar from '../NavBar'
import AdListCard from '../AdListCard'
import style from './AdListContent.module.scss'
import className from 'classnames/bind'
import LoadingBar from '../LoadingBar/LoadingBar'
const cx = className.bind(style)

const AdListContent = ({
  navList,
  makerName,
  isWadizAdmin,
  projectId,
  adId,
  adName,
  projectName,
  adAccountList,
  LnbData,
  projectType,
  lossText,
  searchPopupIsShow,
  searchResult,
  searchKeyword,
  selectedFilter,
  sortedAdAccountList,
  mobileMenuIsOpen,
  toggleMoreLessButton,
  handleMoveToModifyAd,
  handleCopyDetailModalOpen,
  handleDeleteAdAccountModalOpen,
  handleCancelRequestForReviewAdAccountModalOpen,
  handleCancelScheduledAdAccountModalOpen,
  handlePreviewModalOpen,
  handleMoveToCreateAd,
  handleCancelRejectReasonModalOpen,
  checkDeletableAdvertisement,
  handleDeleteAdvertisement,
  handleSearch,
  getSearchResult,
  closeSearchPopup,
  handleSearchAdClick,
  refreshAdAccountList,
  handleChangeSortOption,
  handleMoveToResultReport,
  handleMoveToCostReport,
  handleClickDisabledLnbButton,
  handleMobileMenuOpen,
  loadingBarFlag,
  optionList,
  handleToAdCreate,
  isSuperUser,
}) => {
  return loadingBarFlag ? (
    <LoadingBar />
  ) : (
    <main className={cx('main_container', 'ad')}>
      <Lnb
        type="adlist"
        mobileMenuIsOpen={mobileMenuIsOpen}
        LnbData={LnbData}
        projectId={projectId}
        adId={adId}
        toggleMoreLessButton={toggleMoreLessButton}
        handleSearch={handleSearch}
        searchPopupIsShow={searchPopupIsShow}
        getSearchResult={getSearchResult}
        closeSearchPopup={closeSearchPopup}
        searchResult={searchResult}
        searchKeyword={searchKeyword}
        handleSearchAdClick={handleSearchAdClick}
        refreshAdAccountList={refreshAdAccountList}
        handleMoveToResultReport={handleMoveToResultReport}
        handleMoveToCostReport={handleMoveToCostReport}
        handleClickDisabledLnbButton={handleClickDisabledLnbButton}
      />
      <div className={cx('ad_list', 'content_wrap')}>
        {LnbData.length === 0 ? (
          <div className={cx('report_empty')}>
            <NavBar list={navList} />
            <div className={cx('empty_container')}>
              <h2>안녕하세요. {makerName} 메이커님.</h2>
              <p>
                등록된 광고가 없어 광고 리스트를 확인할 수 없습니다.<br></br>광고를 만들면 광고 리스트를 확인할 수
                있습니다.
              </p>
              <button
                type="button"
                className={cx('wz button primary', 'button_320')}
                disabled={!isSuperUser}
                onClick={() => handleToAdCreate()}
              >
                <i className={cx('icon add')} />
                광고 만들기
              </button>

              <div className={cx('guideBtn_wrap')}>
                <a
                  href={process.env.REACT_APP_WADIZ_AD_MANUAL}
                  type="text/html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className={cx('wz large button', 'guideBtn')}>
                    <div>
                      광고 필수 가이드 먼저 보기
                      <div className={cx('guide_download_icon', 'ad_guide')}></div>
                    </div>
                  </button>
                </a>
              </div>
            </div>
          </div>
        ) : (
          <>
            <NavBar list={navList} handleMobileMenuOpen={handleMobileMenuOpen} />
            <div className={cx('navbar')}>
              <ul className={cx('navbar_list')}>
                {navList &&
                  navList.map((val, idx) => (
                    <li key={idx} className={cx('navbar_item')}>
                      <i className={cx('icon chevron-left')}></i>
                      <button onClick={() => handleMobileMenuOpen(true)}>{val.name}</button>
                    </li>
                  ))}
              </ul>
            </div>
            {/* 컨텐츠 시작 */}
            <hgroup>
              <h2>{adName}</h2>
              <p>{projectName}</p>
            </hgroup>
            <div className={cx('ad_list_manage')}>
              {!(adAccountList.length > 0) && (
                <button
                  type="button"
                  className={cx('wz button less', 'button_historyBack')}
                  onClick={() => handleDeleteAdvertisement(checkDeletableAdvertisement(adAccountList))}
                >
                  <i className={cx('icon delete-o')}></i>
                  <span>광고삭제</span>
                </button>
              )}
            </div>
            {adAccountList.length > 0 && (
              <div className={cx('ad_list_loop')}>
                {sortedAdAccountList.length === 0 && selectedFilter === 'all'
                  ? adAccountList.map((val, idx) => (
                      <AdListCard
                        key={idx}
                        isWadizAdmin={isWadizAdmin}
                        data={val}
                        projectType={projectType}
                        lossText={lossText}
                        handleCopyDetailModalOpen={handleCopyDetailModalOpen}
                        handleDeleteAdAccountModalOpen={handleDeleteAdAccountModalOpen}
                        handleCancelRequestForReviewAdAccountModalOpen={handleCancelRequestForReviewAdAccountModalOpen}
                        handleCancelScheduledAdAccountModalOpen={handleCancelScheduledAdAccountModalOpen}
                        handlePreviewModalOpen={handlePreviewModalOpen}
                        handleCancelRejectReasonModalOpen={handleCancelRejectReasonModalOpen}
                        handleMoveToModifyAd={handleMoveToModifyAd}
                      />
                    ))
                  : sortedAdAccountList.map((val, idx) => (
                      <AdListCard
                        key={idx}
                        isWadizAdmin={isWadizAdmin}
                        data={val}
                        projectType={projectType}
                        lossText={lossText}
                        handleCopyDetailModalOpen={handleCopyDetailModalOpen}
                        handleDeleteAdAccountModalOpen={handleDeleteAdAccountModalOpen}
                        handleCancelRequestForReviewAdAccountModalOpen={handleCancelRequestForReviewAdAccountModalOpen}
                        handleCancelScheduledAdAccountModalOpen={handleCancelScheduledAdAccountModalOpen}
                        handlePreviewModalOpen={handlePreviewModalOpen}
                        handleCancelRejectReasonModalOpen={handleCancelRejectReasonModalOpen}
                        handleMoveToModifyAd={handleMoveToModifyAd}
                      />
                    ))}
                {/* 광고 리스트 추가 버튼 추후 추가 예정 */}
                {adAccountList.length === 1 && selectedFilter === 'all' && <AdListCard isAddBtn />}
              </div>
            )}
            {/* 광고리스트가 없을때 */}
            {(adAccountList.length === 0 || (sortedAdAccountList.length === 0 && selectedFilter !== 'all')) && (
              <div className={cx('ad_listNull')}>
                <i className={cx('icon error-o')}></i>
                <p>아래 버튼을 클릭하여 광고 등록을 시작해 주세요.</p>
                <button
                  type="button"
                  className={cx('wz button primary', 'button_320')}
                  onClick={handleMoveToCreateAd}
                  disabled={!isSuperUser}
                >
                  <i className={cx('icon add')}></i>광고 등록
                </button>
              </div>
            )}
          </>
        )}
        {/* 컨텐츠 끝 */}
      </div>
      <NotificationModal />
    </main>
  )
}

export default AdListContent
