import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openPopup, closePopup } from '../../reducers/dialogReducer';
import CommonDialog from './CommonDialog/CommonDialog';
import className from 'classnames/bind';
import style from './Dialog.module.scss';
const cx = className.bind(style);

class ConfirmDialog extends Component {
  initState = {
    isShow: false,
  };

  state = this.initState;

  static getDerivedStateFromProps = nextProps => {
    if (nextProps.dialog.dialogType === 'confirmDialog') {
      return { isShow: true };
    }

    return { ...(new ConfirmDialog()).initState };
  };

  handleClose = () => {
    this.props.closePopup();
  };

  handleConfirm = () => {
    // confirm function
    const { dialogData } = this.props.dialog;
    dialogData.confirmFunc();
    // close when success
    this.handleClose();
  }

  render() {
    const { isShow } = this.state;
    const { dialogData } = this.props.dialog;
    return isShow && (
      <CommonDialog title={dialogData.title} type={dialogData.type} closeText={dialogData.closeText} onClickClose={this.handleClose} confirmText={dialogData.confirmText} onClickConfirm={this.handleConfirm}>
        {/* Content Area */}
        <main className={cx('dialog_content') }>
          {dialogData.content}
        </main>
      </CommonDialog>
    );
  }
}

export default connect(({ dialog }) => ({ dialog }), ({ openPopup, closePopup }))(ConfirmDialog);
