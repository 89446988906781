import React from 'react'

// Components
import Tooltip from '../../Tooltip'

// CSS module
import className from 'classnames/bind'
import style from '../SettingContent.module.scss'
import Lnb from '../../Lnb'
import NavBar from '../../NavBar'
import LoadingBar from '../../LoadingBar/LoadingBar'
const cx = className.bind(style)

const navList = [
  { name: '설정', link: '/main/ad/settings/' },
  { name: '사업자 정보 확인', link: '/main/ad/settings/businessInfo' },
]

const BusinessInformation = ({ businessInfo, openGuide, loadingBarFlag, onChangeOpenGuideType }) => {
  const { rewardList, investList } = businessInfo
  return (
    <main className={cx('main_container', 'settings')}>
      <Lnb type="settings" />
      <div className={cx('content_wrap')}>
        <NavBar list={navList} />
        {loadingBarFlag ? (
          <LoadingBar />
        ) : (
          <>
            {/* 컨텐츠 시작 */}
            {rewardList.length === 0 && investList.length === 0 ? (
              // {/* 사업자 정보가 없을때 */}
              <div className={cx('report_empty')}>
                <h2>메이커님의 사업자 정보가 없습니다.</h2>
                <p>
                  메이커님의 사업자 정보를 먼저 확인 부탁드립니다.<br></br>
                  {/* 사업자 정보는 <a href="#none" className={cx('point_link_blue')}>이곳</a>에서 변경할 수 있습니다.<br></br> */}
                  사업자 정보 관련 문의 사항은{' '}
                  <a
                    href={process.env.REACT_APP_WADIZ_HELP_CENTER}
                    target="_blank"
                    className={cx('point_link_blue')}
                    rel="noreferrer"
                  >
                    광고 문의하기
                  </a>{' '}
                  로 문의 부탁드립니다.
                </p>
              </div>
            ) : (
              // {/* 사업자 정보가 있을때 */}
              <div className={cx('settings_registInfo')}>
                <hgroup>
                  <h2>사업자 정보 확인</h2>
                  <p>
                    회원님의 사업자 정보입니다.<br></br>
                    세금계산서 관련 문의 사항은{' '}
                    <a href="mailto:ad@wadiz.kr" className={cx('point_link_blue')}>
                      ad@wadiz.kr
                    </a>
                    로 [광고-사업자 정보] 제목으로 메일을 보내주세요.
                  </p>
                </hgroup>
                <section>
                  {investList.length > 0 &&
                    investList.map((val, idx) => (
                      <div key={idx} style={{ paddingBottom: '30px' }}>
                        <div
                          className={cx('guide_wrap', {
                            active: openGuide.idx === idx && openGuide.type === 'investinfo',
                          })}
                          onClick={() => onChangeOpenGuideType(idx, 'investinfo')}
                        >
                          <h3>투자 - 기업 정보</h3>
                          <Tooltip
                            title="투자 - 기업 정보"
                            text={
                              '와디즈의 투자에 등록된 기업 정보입니다. 기업 정보 수정은 메이커 페이지에서 수정하시기 바랍니다.'
                            }
                          />
                        </div>
                        <table className={cx('wz table', 'theadLeft')}>
                          <caption>회원님의 사업자정보</caption>
                          <tbody>
                            <tr>
                              <th scope="row">사업자 구분</th>
                              <td>{val.businessType === 1 ? '개인사업자' : '법인사업자'}</td>
                            </tr>
                            {val.registrationNumber && (
                              <tr>
                                <th scope="row">사업자 번호</th>
                                <td>{val.registrationNumber}</td>
                              </tr>
                            )}
                            <tr>
                              <th scope="row">대표자 / 이메일</th>
                              <td>
                                {val.ceoName}{' '}
                                <a href={`mailto:${val.ceoEmail}`} className={cx('point_link_blue')}>
                                  {val.ceoEmail}
                                </a>
                              </td>
                            </tr>
                            {val.coCeo && (
                              <>
                                {val.coCeo.length > 0 && (
                                  <tr>
                                    <th rowSpan={val.coCeo.length} scope="row">
                                      공동대표자 / 이메일
                                    </th>
                                    {
                                      <td>
                                        {val.coCeo[0].coCeoName}{' '}
                                        <a href={`mailto:${val.coCeo[0].coCeoEmail}`} className={cx('point_link_blue')}>
                                          {val.coCeo[0].coCeoEmail}
                                        </a>
                                      </td>
                                    }
                                  </tr>
                                )}
                                {val.coCeo.slice(1).length > 0 && (
                                  <tr>
                                    {val.coCeo.slice(1).map((coCeo, index) => (
                                      <td key={index}>
                                        {coCeo.coCeoName}{' '}
                                        <a href={`mailto:${coCeo.coCeoEmail}`} className={cx('point_link_blue')}>
                                          {coCeo.coCeoEmail}
                                        </a>
                                      </td>
                                    ))}
                                  </tr>
                                )}
                              </>
                            )}
                            {/* {
                                      val.licenseUrl && (
                                        <tr>
                                          <th scope="row">사업자 등록증</th>
                                          <td>
                                            <a href="#none" download={val.licenseUrl}>
                                              <button type="button" className={cx('wz button dense')}>
                                                <i className={cx('icon download')}></i>
                                                다운로드
                                              </button>
                                            </a>
                                          </td>
                                        </tr>
                                      )
                                    }
                                    {
                                      val.bankbookUrl && (
                                        <tr>
                                          <th scope="row">통장사본</th>
                                          <td>
                                            <a href="#none" download={val.bankbookUrl}>
                                              <button type="button" className={cx('wz button dense')}>
                                                <i className={cx('icon download')}></i>
                                                다운로드
                                              </button>
                                            </a>
                                          </td>
                                        </tr>
                                      )
                                    } */}
                          </tbody>
                        </table>
                      </div>
                    ))}
                  {rewardList.length > 0 &&
                    rewardList.map((val, idx) => (
                      <div key={idx} style={{ paddingBottom: '30px' }}>
                        <div
                          className={cx('guide_wrap', {
                            active: openGuide.idx === idx && openGuide.type === 'rewardinfo',
                          })}
                          onClick={() => onChangeOpenGuideType(idx, 'rewardinfo')}
                        >
                          <h3>펀딩 - 사업자 정보</h3>
                          <Tooltip
                            title="펀딩 - 사업자정보"
                            text={
                              '와디즈의 펀딩에 등록된 사업자 정보입니다. 펀딩에 등록된 사업자 정보가 여러 개일 경우 모두 노출됩니다. 사업자 정보 수정은 메이커 페이지에서 수정하시기 바랍니다.'
                            }
                          />
                        </div>
                        <table className={cx('wz table', 'theadLeft')}>
                          <caption>회원님의 사업자정보</caption>
                          <tbody>
                            <tr>
                              <th scope="row">사업자 구분</th>
                              <td>{val.businessType === 1 ? '개인사업자' : '법인사업자'}</td>
                            </tr>
                            {val.registrationNumber && (
                              <tr>
                                <th scope="row">사업자 번호</th>
                                <td>{val.registrationNumber}</td>
                              </tr>
                            )}
                            <tr>
                              <th scope="row">대표자 / 이메일</th>
                              <td>
                                {val.ceoName}{' '}
                                <a href={`mailto:${val.ceoEmail}`} className={cx('point_link_blue')}>
                                  {val.ceoEmail}
                                </a>
                              </td>
                            </tr>
                            {val.coCeo && (
                              <>
                                {val.coCeo.length > 0 && (
                                  <tr>
                                    <th rowSpan={val.coCeo.length} scope="row">
                                      공동대표자 / 이메일
                                    </th>
                                    {
                                      <td>
                                        {val.coCeo[0].coCeoName}{' '}
                                        <a href={`mailto:${val.coCeo[0].coCeoEmail}`} className={cx('point_link_blue')}>
                                          {val.coCeo[0].coCeoEmail}
                                        </a>
                                      </td>
                                    }
                                  </tr>
                                )}
                                {val.coCeo.slice(1).length > 0 && (
                                  <tr>
                                    {val.coCeo.slice(1).map((coCeo, index) => (
                                      <td key={index}>
                                        {coCeo.coCeoName}{' '}
                                        <a href={`mailto:${coCeo.coCeoEmail}`} className={cx('point_link_blue')}>
                                          {coCeo.coCeoEmail}
                                        </a>
                                      </td>
                                    ))}
                                  </tr>
                                )}
                              </>
                            )}
                            {/* {
                                      val.licenseUrl && (
                                        <tr>
                                          <th scope="row">사업자 등록증</th>
                                          <td>
                                            <a href="#none" download={val.licenseUrl}>
                                              <button type="button" className={cx('wz button dense')}>
                                                <i className={cx('icon download')}></i>
                                                다운로드
                                              </button>
                                            </a>
                                          </td>
                                        </tr>
                                      )
                                    }
                                    {
                                      val.bankbookUrl && (
                                        <tr>
                                          <th scope="row">통장사본</th>
                                          <td>
                                            <a href="#none" download={val.bankbookUrl}>
                                              <button type="button" className={cx('wz button dense')}>
                                                <i className={cx('icon download')}></i>
                                                다운로드
                                              </button>
                                            </a>
                                          </td>
                                        </tr>
                                      )
                                    } */}
                          </tbody>
                        </table>
                      </div>
                    ))}
                </section>
              </div>
            )}
          </>
        )}
      </div>
    </main>
  )
}

export default BusinessInformation
