import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { closePopup } from '../../reducers/dialogReducer';
import { network } from '../../network';

import className from 'classnames/bind';
import style from './Dialog.module.scss';

const cx = className.bind(style);

class ReportFavoritAlert extends PureComponent {
  initState = {
    adCenterAdvertisementId: '',
    adCenterMakerId: '',
    reportName: '',
    reportType: '',
    defaultReportName: '',
    listData: [],
    reservYear: new Date().getFullYear(),
    reservMonth: new Date().getMonth() + 1,
    isShow: false,
  };

  state = this.initState;

  static getDerivedStateFromProps = (nextProps, nextState) => {
    if (nextProps.dialog.dialogType === 'reportFavorit') {
      if (!nextState.isShow) {
        const {
          adCenterAdvertisementId, adCenterMakerId, reportName, reportType,
        } = nextProps.dialog.dialogData;
        const defaultReportName = `${reportType}보고서-${reportName}`;
        return {
          isShow: true, adCenterAdvertisementId, adCenterMakerId, reportName, reportType, defaultReportName,
        };
      }

      return { ...nextState };
    }

    return { ...(new ReportFavoritAlert()).initState };
  };

  handleReportName = (e) => {
    this.setState({ defaultReportName: e.target.value });
  }

  handleClose = () => {
    this.props.closePopup();
    this.setState({ ...this.initState });
  };

  handleConfirm = () => {
    // confirm function
    const {
      adCenterAdvertisementId, reportType,
    } = this.state;

    network.callApi({
      url: `report/favorites/duplicate?advertisement_id=${adCenterAdvertisementId}&report_type=${reportType}`,
      method: 'GET',
      header: { 'Content-Type': 'application/json;charset=UTF-8' },
      successFunc: (jsonData) => {
        const { duplicate } = jsonData.result;
        if (duplicate) {
          toast('이미 등록된 즐겨찾기 입니다.');
        } else {
          this.sendPOSTAddBookMark();
        }
      },
    });

    this.handleClose();
  }

  sendPOSTAddBookMark = () => {
    const {
      adCenterAdvertisementId, adCenterMakerId, reportType, defaultReportName,
    } = this.state;
    network.callApi({
      url: 'report/favorites/save',
      method: 'POST',
      header: { 'Content-Type': 'application/json;charset=UTF-8' },
      body: {
        adCenterAdvertisementId,
        adCenterMakerId,
        reportName: defaultReportName,
        reportType,
      },
      successFunc: (jsonData) => {
        if (jsonData.result === 'success') {
          toast('보고서 즐겨찾기 등록 완료');
        } else {
          toast('보고서 즐겨찾기 등록 실패');
        }
      },
      failFunc: () => {
        toast('보고서 즐겨찾기 등록 실패');
      },
    });
  }

  render() {
    const {
      handleClose, handleConfirm,
    } = this;
    const {
      isShow,
    } = this.state;

    return isShow && (
      <>
        <div className={cx('dialog_shadow')} onClick={handleClose}></div>
        <div id={cx('reportFavorit')} className={cx('dialog_wrap', 'typePopup')}>
          <header>
            <div className={cx('dialog_close')}>
              {/* Close Button Area */}
              <button className={cx('wz button less')} onClick={handleClose}>
                <i className={cx('icon close')} />
              </button>
            </div>
            <div className={cx('dialog_title')}>
              {/* Title Area */}
              <h3>보고서 즐겨찾기</h3>
            </div>
          </header>
          <main className={cx('dialog_content')}>
            {/* Content Area */}
            <p>해당 보고서를 즐겨찾기하면 <em className={cx('point_primary')}>보고서 즐겨찾기</em>에서 확인 할 수 있습니다.</p>
            <div className={cx('wz input')}>
              <textarea onChange={(e) => this.handleReportName(e)} value={this.state.defaultReportName}></textarea>
            </div>
          </main>
          <footer className={cx('dialog_button')}>
            {/* Button Area */}
            <button className={cx('wz button dense')} onClick={handleClose}>취소</button>
            <button className={cx('wz button primary dense')} onClick={handleConfirm}>저장</button>
          </footer>
        </div>
      </>
    );
  }
}

export default connect(({ dialog }) => ({ dialog }), ({ closePopup }))(ReportFavoritAlert);
