export type AdStateTypeEnum =
  | 'Creating'
  | 'Recreating'
  | 'Submitted'
  | 'Accepted'
  | 'Scheduled'
  | 'In_Progress'
  | 'Completed'
  | 'Feedback'
  | 'Feedback_in_Contr'
  | 'Payment_Failed'
  | 'Feedback_in_Sched'
  | 'Feedback_in_Prog'
  | 'Modified_in_Contr'
  | 'Modified_in_Failed'
  | 'Modified_in_Sched'
  | 'Modified_in_Prog'
  | 'Canceled'
  | 'Canceled_C'
  | 'Canceled_F'
  | 'Canceled_S'
  | 'Canceled_P'
  | 'Canceled_in_Sched'
  | 'Canceled_in_Prog'
  | 'Refused_C'
  | 'Refused_F'
  | 'Refused_S'
  | 'Refused_P'
  | 'Rejected'
  | 'Rejected_in_Contr'
  | 'Deleted'
  | 'None'
  | 'Unknown'

export enum AdStateEnum {
  Creating = 100,
  Recreating = 600,
  Submitted = 200,
  Accepted = 260,
  Scheduled = 310,
  In_Progress = 320,
  Completed = 340,
  Feedback = 209,
  Feedback_in_Contr = 269,
  Payment_Failed = 277,
  Feedback_in_Sched = 319,
  Feedback_in_Prog = 329,
  Modified_in_Contr = 265,
  Modified_in_Failed = 275,
  Modified_in_Sched = 315,
  Modified_in_Prog = 325,
  Canceled = 103,
  Canceled_C = 263,
  Canceled_F = 273,
  Canceled_S = 313,
  Canceled_P = 323,
  Canceled_in_Sched = 332,
  Canceled_in_Prog = 331,
  Refused_C = 266,
  Refused_F = 276,
  Refused_S = 316,
  Refused_P = 326,
  Rejected = 406,
  Rejected_in_Contr = 466,
  Deleted = 550,
  None = 0,
  Unknown = 999,
}

export const AdStateString = {
  Creating: '작성중',
  Recreating: '수정중',
  Submitted: '요청',
  Accepted: '계약중',
  Scheduled: '예정',
  In_Progress: '진행중',
  Completed: '완료',
  Feedback: '피드백',
  Feedback_in_Contr: '(계약중)피드백',
  Payment_Failed: '결제실패',
  Feedback_in_Sched: '(예정)피드백',
  Feedback_in_Prog: '(진행중)피드백',
  Modified_in_Contr: '(계약중)수정요청',
  Modified_in_Failed: '(결제실패)수정요청',
  Modified_in_Sched: '(예정)수정요청',
  Modified_in_Prog: '(진행중)수정요청',
  Canceled: '(요청)취소',
  Canceled_C: '(계약중/수정요청)취소',
  Canceled_F: '(결제실패/수정요청)취소',
  Canceled_S: '(예약/수정요청)취소',
  Canceled_P: '(진행중/수정요청)취소',
  Canceled_in_Sched: '(예정)취소',
  Canceled_in_Prog: '(진행중)취소',
  Refused_C: '(계약중/수정요청)거절',
  Refused_F: '(결제실패/수정요청)거절',
  Refused_S: '(예약/수정요청)거절',
  Refused_P: '(진행중/수정요청)거절',
  Rejected: '반려',
  Rejected_in_Contr: '(계약중)반려',
  Deleted: '삭제',
  None: 'None',
  Unknown: 'Unknown:',
}

export const AdStateColor = {
  Creating: '#00C4C4',
  Recreating: '#FF6666',
  Submitted: '#00C4C4',
  Accepted: '#00C4C4',
  Scheduled: '#00C4C4',
  In_Progress: '#00C4C4',
  Completed: '#FF6666',
  Feedback: '#FF6666',
  Feedback_in_Contr: '#FF6666',
  Payment_Failed: '#FF6666',
  Feedback_in_Sched: '#FF6666',
  Feedback_in_Prog: '#FF6666',
  Modified_in_Contr: '#00C4C4',
  Modified_in_Failed: '#00C4C4',
  Modified_in_Sched: '#00C4C4',
  Modified_in_Prog: '#00C4C4',
  Canceled: '#868E96',
  Canceled_C: '#868E96',
  Canceled_F: '#868E96',
  Canceled_S: '#868E96',
  Canceled_P: '#868E96',
  Canceled_in_Sched: '#868E96',
  Canceled_in_Prog: '#868E96',
  Refused_C: '#868E96',
  Refused_F: '#868E96',
  Refused_S: '#868E96',
  Refused_P: '#868E96',
  Rejected: '#868E96',
  Rejected_in_Contr: '#868E96',
  Deleted: '#868E96',
  None: '#868E96',
  Unknown: '#868E96',
}
