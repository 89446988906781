// eslint-disable-next-line
import React, { useEffect, useState } from 'react'
import { useDetectAdBlock } from 'adblock-detect-react'
import useIsSmallScreen from './useIsSmallScreen'
import classNames from 'classnames/bind'
import style from './HeadNotify.module.scss'
const cx = classNames.bind(style)

const SmallScreenNotify = () => <p>광고 등록 및 관리는 PC에서 해주세요.</p>
const AdBlockHeader = () => (
  <p>
    광고 차단 기능이 켜져 있어요! <em>광고 차단 기능을 끄고</em> 페이지를 새로 고침 해 주세요.
    <br />
    <a href={'https://makercenter.wadiz.kr/board/basic/217?rownum=10&search_type=title_contents&search_text=&category1_idx=38&category2_idx=&page=2'}>방법 알아보기</a>
  </p>
)

const IEBlockHeader = () => (
  <p>
    인터넷 익스프롤러 브라우저는 지원하지 않습니다. <br />
    <em>구글 크롬 브라우저</em> 나 <em>파이어폭스 브라우저</em> 를 <br />
    사용해주세요.
  </p>
)

const isIE = () => {
  // https://www.whatismybrowser.com/guides/the-latest-user-agent/internet-explorer
  const ua = window.navigator.userAgent
  const msie = ua.indexOf('MSIE ') //
  const trident = ua.indexOf('Trident/') // IE
  return msie > 0 || trident > 0
}

const HeadNotify = () => {
  const [isShow, setShow] = useState(false)
  const isAdBlockInstalled = useDetectAdBlock()
  const isSmall = useIsSmallScreen()

  useEffect(() => {
    setShow(isSmall || isAdBlockInstalled || isIE())
  }, [isAdBlockInstalled, isSmall])

  return (
    <section className={cx('container', isShow && 'show')}>
      {isSmall ? <SmallScreenNotify /> : isAdBlockInstalled ? <AdBlockHeader /> : isIE() && <IEBlockHeader />}
    </section>
  )
}

export default HeadNotify
