import React from 'react';
import classNames from 'classnames/bind';
import TitleSection from '../TitleSection';
import style from './DisplayAdSection.module.scss';
const cx = classNames.bind(style);

const DisplayAdSection = () => {
  return (
    <section className={cx('container')}>
      <TitleSection section="DisplayAdSection" className={cx('title')} />
      <div className={cx('visual')} aria-label="와디즈 메인 이미지" />
    </section>
  )
};

export default DisplayAdSection;
