import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom-v5'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'

// Components
import { openPopup, openAlertPopup, setMenuStatus, openWaffleModal } from '../../reducers'
import { TermsModalContainer } from '../../components/TermsModalContainer'

import AdModifyContent from '../../components/AdModifyContent/AdModifyContent'
import { network } from '../../network'
// import className from 'classnames/bind'
import { centerGetCreativesStatusWithCount, centerGetPubAreaAvailability } from '@wad/api'
import { fetchBusinessDays, fetchBgColorList } from '@center/api'
import { getTermsAgreements, getWadizOnlyCondition } from '../_api'
import { sortByArea, AreaType, checkMakerLandingURL } from '@legacy/definitions'
import addPackagedTag from './utils/addPackagedTag'
import {
  validatorAllField,
  promiseAdvertisementUpdate,
  makeRequestBody,
  promiseCreativeCreate,
  promiseCreativeUpdate,
  promiseDiscountUpdate,
  promiseCreativeSyncCreate,
  promiseCreativeSyncUpdate,
} from './utils'

// import style from '../../components/AdModifyContent/AdModifyContent.module.scss'
// const cx = className.bind(style)
class AdModifyPage extends PureComponent {
  selectedAdAccountCardRef = React.createRef()

  state = {
    isModify: true,
    modifyStep: 1,
    isComplete: false,
    makerId: 0,
    projectId: 0,
    projectName: '',
    adId: 0,
    adName: '',
    LnbData: [],
    adAccountList: [],
    projectList: [],
    // bgColorList: [],
    openGuide: { idx: -1, type: '' },

    adsPrice: 0, // 전체 광고 금액
    adsVat: 0, // 전체 부가세
    totalPrice: 0, // 총 금액

    startTime: '',

    modifiedAdAccountList: [],
    landingUrl: '',
    selectProjectErrMsg: '',
    changeAdNameErrMsg: '',

    selectedAdAccountCardId: 0,
    openDate: '',

    projectType: 0,
    lossText: '',

    loadingBarFlag: false,

    usingCouponList: [],

    // 약관 동의 모달
    isOpenAgreementModal: false,

    holidays: [],
    shared: null,
  }

  componentDidMount() {
    this.getAdModifyData()
    if (this.props.user.isWadizAdmin) this.setState({ isComplete: true })
  }

  static getDerivedStateFromProps = (nextProps, nextState) => {
    const { pathname } = window.location
    const step = pathname.split('/')[5]
    switch (step) {
      case 'step1':
        return { ...nextState, modifyStep: 1 }
      case 'step2':
        return { ...nextState, modifyStep: 2 }
      default:
        return { ...nextState, modifyStep: 1 }
    }
  }

  async getHolidays() {
    const { result } = await fetchBusinessDays({ startDate: dayjs().format('YYYY-MM-DD'), withinDays: 60 })
    this.setState({
      // o 제외가 휴일
      holidays: result.filter((item) => item.type !== 'o'),
    })
  }

  countHolidays(adAccount) {
    // 휴일 단가
    let normalDays = 0,
      holidays = 0
    // 휴일, 평일 체크
    const dateRange = new Array(adAccount.impMinPeriod)
      .fill('')
      .map((_, idx) => dayjs(adAccount.startAt).add(idx, 'day').format('YYYY-MM-DD'))
    dateRange.forEach((date) => {
      if (this.state.holidays.some((i) => date === i.eventDate)) {
        holidays += 1
      } else {
        normalDays += 1
      }
    })
    return {
      normalDays,
      holidays,
    }
  }

  getMakerInfo = () => {
    const { projectId, adAccountList } = this.props.history.location.state
    // 메이커 정보 가져오기 - LandingURL
    if (checkMakerLandingURL(adAccountList[0]?.adCenterPubAreaId)) {
      new Promise((resolve, reject) =>
        network.callApi({
          url: `maker-page/info?project_id=${projectId}&creative_id=${adAccountList[0].id}`,
          method: 'get',
          successFunc: (jsonData) => {
            this.setState({
              makerInfo: jsonData.result,
            })
            resolve(jsonData.result)
          },
          failFunc: (error) => reject(error),
        })
      )
    }
  }

  async getAdModifyData() {
    await this.getHolidays()
    this.getMakerInfo()

    this.setState({ loadingBarFlag: true }, () => {
      let projectList = [...this.state.projectList]
      // let bgColorList = [...this.state.bgColorList]
      let adAccountList = [...this.state.adAccountList]
      const usingCouponList = [...this.state.usingCouponList]
      let modifiedAdAccountList = [...this.state.modifiedAdAccountList]
      const promiseList = []
      let { landingUrl, comingsoon } = this.state

      const { campaignId, projectId, projectName, LnbData, adId, adName, openDate, projectType, lossText } =
        this.props.history.location.state

      let addedAccountList = []
      const addedPromiseList = []
      const addedPromise = new Promise((addedResolve, addedReject) => {
        if (this.props.history.location.addedAccountList) {
          addedAccountList = [...this.props.history.location.addedAccountList]
          addedAccountList.forEach((adAccount) => {
            const tempAdAccount = adAccount
            const adCenterPubAccountId = tempAdAccount.id
            addedPromiseList.push(
              new Promise((resolve, reject) => {
                return network.callApi({
                  url: `pub-account/info?pub_account_id=${adCenterPubAccountId}`,
                  method: 'get',
                  successFunc: (jsonData) => {
                    tempAdAccount.info = jsonData.result
                    resolve(jsonData.result)
                  },
                  failFunc: (error) => {
                    reject(error)
                    addedReject(error)
                  },
                })
              })
            )
            addedPromiseList.push(
              new Promise((resolve, reject) => {
                const url =
                  tempAdAccount.usedDiscount === 1
                    ? `discount/list?campaign_id=${campaignId}&project_type=${projectType}&pub_account_id=${adCenterPubAccountId}&is_used=1`
                    : `discount/list?campaign_id=${campaignId}&project_type=${projectType}&pub_account_id=${adCenterPubAccountId}`
                return network.callApi({
                  url,
                  method: 'get',
                  successFunc: (discountCouponData) => {
                    if (discountCouponData.result.list && discountCouponData.result.list.length > 0) {
                      const discountCoupon = [...discountCouponData.result.list]
                      if (tempAdAccount.usedDiscount === 2) {
                        for (let i = 0; i < discountCoupon.length; i += 1) {
                          if (
                            discountCoupon[i].isUsed === 2 &&
                            tempAdAccount.adCenterDiscountId &&
                            tempAdAccount.adCenterDiscountId !== discountCoupon[i].id
                          ) {
                            discountCoupon.splice(i, 1)
                          }
                        }
                      }
                      tempAdAccount.discountCoupon = discountCoupon
                    } else {
                      tempAdAccount.discountCoupon = []
                    }
                    resolve()
                  },
                  failFunc: (error) => {
                    reject(error)
                    addedReject(error)
                  },
                })
              })
            )
          })
          Promise.all(addedPromiseList).then((res) => {
            addedAccountList.forEach((adAccount) => {
              const tempAdAccount = adAccount
              tempAdAccount.adCenterAdvertisementId = this.state.adId
              tempAdAccount.adCenterPubAreaId = tempAdAccount.info.adCenterPubAreaId
              tempAdAccount.adCenterPubAccountId = tempAdAccount.id
              tempAdAccount.area = tempAdAccount.positionName
              tempAdAccount.status = 1
              tempAdAccount.adProcessStatus = 0
              tempAdAccount.adPayStatus = 0
              tempAdAccount.callApiType = 'create'
              tempAdAccount.landingUrl = landingUrl
              tempAdAccount.mainCopy = ''
              tempAdAccount.keyword = ''
              tempAdAccount.subCopy = ''
              if (tempAdAccount.discountCoupon.length > 0) {
                if (adAccount.usedDiscount === 2) {
                  usingCouponList.push(tempAdAccount.adCenterDiscountId)
                  tempAdAccount.discountIdx = tempAdAccount.discountCoupon.findIndex(
                    (coupon) => coupon.id === tempAdAccount.adCenterDiscountId
                  )
                  tempAdAccount.discountPrice =
                    tempAdAccount.price - (tempAdAccount.price * tempAdAccount.discountCoupon[0].discountRate) / 100
                } else {
                  tempAdAccount.discountIdx = ''
                }
              }
              addedResolve()
            })
          })
        } else {
          addedResolve()
        }
      })
      addedPromise.then(
        () => {
          let { selectedAdAccountCardId } = { ...this.props.history.location }

          selectedAdAccountCardId = selectedAdAccountCardId || 0

          network.callApi({
            url: `creative/list?advertisement_id=${adId}`,
            method: 'get',
            successFunc: (jsonData) => {
              const result = jsonData.result.sort((a, b) => {
                return Number(b.main) - Number(a.main) // 메인 영역인 값을 index 0으로 만들기
              })
              adAccountList = sortByArea(addPackagedTag([...addedAccountList, ...result]), this.props.user.isSuperUser)
              modifiedAdAccountList = sortByArea(
                addPackagedTag([...addedAccountList, ...modifiedAdAccountList]),
                this.props.user.isSuperUser
              )

              this.setState({
                shared: result[0].shared,
              })
              const getLandingurl = new Promise((resolve, reject) => {
                return network.callApi({
                  url: `landing-url/info?project_id=${projectId}`,
                  method: 'get',
                  successFunc: (landingUrlData) => {
                    landingUrl = Object.prototype.hasOwnProperty.call(landingUrlData.result, 'landingUrl')
                      ? landingUrlData.result.landingUrl
                      : ''
                    comingsoon = Object.prototype.hasOwnProperty.call(landingUrlData.result, 'comingsoon')
                      ? landingUrlData.result.comingsoon
                      : ''
                    resolve(landingUrl)
                  },
                  failFunc: (landingUrlError) => {
                    reject(landingUrlError)
                  },
                })
              })

              promiseList.push(getLandingurl)

              const getProjectList = new Promise((resolve, reject) => {
                return network.callApi({
                  url: `projects/list?maker_id=${this.props.user.maker_id}`,
                  method: 'get',
                  header: { 'Content-Type': 'application/json;charset=UTF-8' },
                  successFunc: (projectListData) => {
                    projectList = projectListData.result
                    resolve(projectListData.result)
                  },
                  failFunc: (error) => {
                    reject(error)
                  },
                })
              })

              promiseList.push(getProjectList)

              adAccountList.forEach((adAccount) => {
                const tempAdAccount = adAccount
                promiseList.push(
                  new Promise((resolve, reject) => {
                    let selectType = tempAdAccount.packagedId ? 2 : 0

                    // 슈퍼유저일 경우 다중지면도 패키지가 아닌 단일로 해야함 type: 0 - 일반 / 2 - 패키지
                    // if (this.props.user.isSuperUser) selectType = 0
                    return network.callApi({
                      url: `goods/list?pub_account_id=${tempAdAccount.adCenterPubAccountId}&advertiser_type=${
                        this.props.user.isWadizAdmin ? 1 : 0
                      }&type=${selectType}&${
                        projectType === 3 ? 'wdz_store_project_id' : 'wdz_campaign_id'
                      }=${campaignId}`,
                      method: 'get',
                      successFunc: (goodsListData) => {
                        tempAdAccount.goodsList = goodsListData.result
                        for (let j = 0; j < goodsListData.result.length; j += 1) {
                          if (goodsListData.result[j].id === tempAdAccount.adCenterPubGoodsId) {
                            tempAdAccount.selectedGoods = goodsListData.result[j]
                            break
                          }
                        }
                        resolve(goodsListData.result)
                      },
                      failFunc: (error) => {
                        reject(error)
                      },
                    })
                  })
                )
                promiseList.push(
                  new Promise((resolve, reject) => {
                    return network.callApi({
                      url: `pub-account/info?pub_account_id=${tempAdAccount.adCenterPubAccountId}`,
                      method: 'get',
                      successFunc: (accountInfoData) => {
                        tempAdAccount.info = accountInfoData.result
                        resolve(accountInfoData.result)
                      },
                      failFunc: (error) => {
                        reject(error)
                      },
                    })
                  })
                )

                promiseList.push(
                  new Promise((resolve, reject) => {
                    const url =
                      tempAdAccount.usedDiscount === 1
                        ? `discount/list?campaign_id=${campaignId}&project_type=${projectType}&pub_account_id=${tempAdAccount.adCenterPubAccountId}&is_used=1`
                        : `discount/list?campaign_id=${campaignId}&project_type=${projectType}&pub_account_id=${tempAdAccount.adCenterPubAccountId}`
                    return network.callApi({
                      url,
                      method: 'get',
                      successFunc: (discountCouponData) => {
                        if (discountCouponData.result.list && discountCouponData.result.list.length > 0) {
                          const discountCoupon = [...discountCouponData.result.list]
                          if (tempAdAccount.usedDiscount === 2) {
                            for (let i = 0; i < discountCoupon.length; i += 1) {
                              if (
                                discountCoupon[i].isUsed === 2 &&
                                tempAdAccount.adCenterDiscountId &&
                                tempAdAccount.adCenterDiscountId !== discountCoupon[i].id
                              ) {
                                discountCoupon.splice(i, 1)
                              }
                            }
                          }
                          tempAdAccount.discountCoupon = discountCoupon
                        } else {
                          tempAdAccount.discountCoupon = []
                        }
                        resolve()
                      },
                      failFunc: (error) => {
                        reject(error)
                      },
                    })
                  })
                )
                promiseList.push(
                  centerGetCreativesStatusWithCount(adAccount.id, 1).then(({ data }) => {
                    if (data.length < 1) {
                      return
                    }
                    const { adState, description, detail } = data[0]
                    const { feedbackTypes, reason } = detail || {}
                    tempAdAccount.responseAdmin = {
                      adState,
                      description,
                      feedbackTypes,
                      reason,
                    }
                  })
                )
              })

              const checkMigration = (tempAdAccount) => {
                return new Promise((resolve, reject) => {
                  return network.callApi({
                    url: `goods/list?pub_account_id=${tempAdAccount.adCenterPubAccountId}&advertiser_type=${
                      this.props.user.isWadizAdmin ? 1 : 0
                    }&type=0&${projectType === 3 ? 'wdz_store_project_id' : 'wdz_campaign_id'}=${campaignId}`,
                    method: 'get',
                    successFunc: (res) => {
                      tempAdAccount.goodsList = res.result === 'fail' ? [] : res.result
                      for (let j = 0; j < res.result.length; j += 1) {
                        if (res.result[j].id === tempAdAccount.adCenterPubGoodsId) {
                          tempAdAccount.selectedGoods = res.result[j]
                          break
                        }
                      }
                      resolve(tempAdAccount)
                    },
                  })
                })
              }

              let adsPrice = 0
              let adsVat = 0
              let totalPrice = 0

              Promise.all(promiseList).then(async () => {
                adAccountList.forEach((adAccount) => {
                  const tempAdAccount = adAccount
                  tempAdAccount.selectedGoods = tempAdAccount.selectedGoods || {
                    impPeriod: 0,
                    impNum: 0,
                    price: 0,
                    holidayPrice: 0,
                  }

                  tempAdAccount.startAt = tempAdAccount.startAt ? tempAdAccount.startAt : ''
                  tempAdAccount.endAt = tempAdAccount.endAt ? tempAdAccount.endAt : ''
                  tempAdAccount.startTime = tempAdAccount.startAt.substring(11, 16)
                    ? tempAdAccount.startAt.substring(11, 16)
                    : this.state.startTime
                  tempAdAccount.impNum = tempAdAccount.selectedGoods.impNum
                  tempAdAccount.pricingType = tempAdAccount.selectedGoods.pricingType
                  tempAdAccount.impPeriod = tempAdAccount.selectedGoods.impPeriod
                  tempAdAccount.impMinPeriod = tempAdAccount.selectedGoods.impMinPeriod

                  tempAdAccount.positionName = tempAdAccount.area
                  tempAdAccount.adCenterPubAreaId = tempAdAccount.info.adCenterPubAreaId
                  tempAdAccount.landingUrl = tempAdAccount.landingUrl ? tempAdAccount.landingUrl : landingUrl
                  tempAdAccount.goodsIdx = tempAdAccount.goodsList.findIndex(
                    (goods) => goods.id === tempAdAccount.selectedGoods.id
                  )
                  tempAdAccount.pricingType = tempAdAccount.info.pricingType

                  // find discount coupon list index
                  if (tempAdAccount.discountCoupon.length > 0) {
                    if (tempAdAccount.usedDiscount === 2) {
                      usingCouponList.push(tempAdAccount.adCenterDiscountId)
                      tempAdAccount.discountIdx = tempAdAccount.discountCoupon.findIndex(
                        (coupon) => coupon.id === tempAdAccount.adCenterDiscountId
                      )
                      tempAdAccount.discountIdx = tempAdAccount.discountIdx < 0 ? '' : tempAdAccount.discountIdx
                      tempAdAccount.discountPrice = tempAdAccount.price
                      tempAdAccount.price = tempAdAccount.selectedGoods.price
                      tempAdAccount.vat = Math.floor(tempAdAccount.discountPrice * 0.1)
                    } else {
                      tempAdAccount.discountIdx = ''
                      tempAdAccount.vat = Math.floor(tempAdAccount.price * 0.1)
                    }
                  } else {
                    tempAdAccount.discountPrice = null
                  }
                  // TODO : 상태에 따라서 전이 할 수 있도록 관리 작업이 필요함 .
                  if (
                    this.props.user.isWadizAdmin ||
                    (tempAdAccount.status === 1 && tempAdAccount.adProcessStatus !== 3) ||
                    tempAdAccount.status === 4 ||
                    tempAdAccount.adState === 'Feedback' ||
                    tempAdAccount.adState === 'Recreating'
                  ) {
                    if (!tempAdAccount.callApiType) {
                      tempAdAccount.callApiType = 'update'
                    }
                    modifiedAdAccountList.push(tempAdAccount)
                  }
                })

                adAccountList = await Promise.all(
                  adAccountList.map(async (adAccount) => {
                    // 마이그레이션 시 나오는 문제 방어코드
                    if (adAccount.goodsIdx === -1 && adAccount.packagedId) {
                      const newData = await checkMigration(adAccount)
                      adAccount = {
                        ...adAccount,
                        ...newData,
                      }
                      adAccount.goodsIdx = adAccount.goodsList?.findIndex(
                        (goods) => goods.id === adAccount.selectedGoods.id
                      )
                    }

                    // bgColor를 사용할 때 API 호출
                    if (adAccount.bgColor) {
                      const { result } = await fetchBgColorList(adAccount.info.adCenterPubAreaId)
                      adAccount.bgColorList = result
                      adAccount.adCenterBgColorId =
                        result[result.findIndex((bg) => bg.bgColor === adAccount.bgColor)].adCenterPubAreaId
                    }
                    const { normalDays, holidays } = this.countHolidays(adAccount)

                    const price =
                      adAccount.selectedGoods.price * normalDays + adAccount.selectedGoods.holidayPrice * holidays
                    adsPrice += price
                    adsVat += Math.floor(price * 0.1)
                    adAccount.normalDays = normalDays
                    adAccount.holidays = holidays
                    return adAccount
                  })
                )

                totalPrice = adsPrice + adsVat

                const tempAdAccountList = []
                const tempIndexArray = []
                for (let i = 0; i < adAccountList.length; i += 1) {
                  if (adAccountList[i].status === 1) {
                    tempAdAccountList.push(adAccountList[i])
                    tempIndexArray.push(i)
                  }
                }

                for (let i = 0; i < adAccountList.length; i += 1) {
                  if (tempIndexArray.indexOf(i) < 0) {
                    tempAdAccountList.push(adAccountList[i])
                  }
                }
                adAccountList = tempAdAccountList

                this.setState(
                  {
                    campaignId,
                    projectId,
                    projectName,
                    LnbData,
                    adId,
                    adName,
                    adAccountList,
                    projectList,
                    // bgColorList,
                    adsPrice,
                    adsVat,
                    totalPrice,
                    modifiedAdAccountList,
                    selectedAdAccountCardId,
                    startTime: adAccountList[0] ? adAccountList[0].startTime : '00:00',
                    landingUrl,
                    comingsoon,
                    openDate,
                    lossText,
                    projectType,
                    loadingBarFlag: false,
                    usingCouponList,
                  },
                  () => {
                    this.scrollToAdAccountCardTop()
                  }
                )
              })
            },
            failFunc: () => {
              this.setState({ loadingBarFlag: false })
            },
          })
        },
        () => {
          this.setState({ loadingBarFlag: false })
        }
      )
    })
  }

  handleOpenGuideType = (idx, type) => {
    this.setState((prevState) => ({ openGuide: { idx, type: prevState.openGuide.type === type ? '' : type } }))
  }

  handleChangeProject = (event) => {
    const prjectList = [...this.state.projectList]
    const projectId = Number(event.target.value)
    const { projectName } = prjectList[prjectList.findIndex((project) => project.id === projectId)]
    this.setState({ projectId, projectName, selectProjectErrMsg: '' })
  }

  handleChangeAdName = (event) => {
    this.setState({ adName: event.target.value, changeAdNameErrMsg: '' })
  }

  handleAdAccountModalOpen = () => {
    const getCheckedNotBlocked = (makerId) =>
      new Promise((resolve, reject) =>
        network.callApi({
          url: `maker/isBlocked?maker_id=${makerId}`,
          method: 'get',
          successFunc: (jsonData) => resolve(jsonData ? jsonData.result : false),
          failFunc: () => reject(),
        })
      )

    const { openDate, campaignId, projectType, comingsoon, landingUrl } = this.state

    const getGoodListData = (id, isWadizAdmin, isPackaged) => {
      let selectType = isPackaged ? 2 : 0
      // 슈퍼유저일 경우 다중지면도 패키지가 아닌 단일로 해야함 type: 0 - 일반 / 2 - 패키지
      // if (this.props.user.isSuperUser) selectType = 0
      return new Promise((resolve, reject) =>
        network.callApi({
          url: `goods/list?pub_account_id=${id}&advertiser_type=${isWadizAdmin ? '1' : '0'}&type=${selectType}&${
            projectType === 3 ? 'wdz_store_project_id' : 'wdz_campaign_id'
          }=${campaignId}`,
          method: 'get',
          successFunc: (jsonData) => resolve(jsonData.result),
          failFunc: (error) => reject(error),
        })
      )
    }
    const getPubAccountInfoData = (id) =>
      new Promise((resolve, reject) =>
        network.callApi({
          url: `pub-account/info?pub_account_id=${id}`,
          method: 'get',
          successFunc: (jsonData) => {
            resolve(jsonData.result)
          },
          failFunc: (error) => {
            reject(error)
          },
        })
      )

    const getDiscountCouponCode = (id, usedDiscount, adCenterDiscountId) =>
      new Promise((resolve, reject) =>
        network.callApi({
          url:
            usedDiscount === 1
              ? `discount/list?campaign_id=${campaignId}&project_type=${projectType}&pub_account_id=${id}&is_used=1`
              : `discount/list?campaign_id=${campaignId}&project_type=${projectType}&pub_account_id=${id}`,
          method: 'get',
          successFunc: (jsonData) => {
            resolve(
              !jsonData.result.list
                ? []
                : usedDiscount !== 2
                ? jsonData.result.list
                : jsonData.result.list.filter(
                    (item) => item.isUsed !== 2 || !adCenterDiscountId || adCenterDiscountId === item.id
                  )
            )
          },
          failFunc: (error) => {
            reject(error)
          },
        })
      )

    const confirmFunc = (data) => {
      const aList = data.returnState.accountList.reduce((acc, item) => {
        if (!item.packagedInfo) {
          return [...acc, item]
        }

        const {
          discount,
          endAt,
          goodsIdx,
          goodsList,
          goodsNum,
          isAdded,
          startAt,
          packagedInfo: { accountItem, goodsItem, positionName, manualUrl },
        } = item

        return [
          ...acc,
          {
            ...item,
            price: goodsList[goodsIdx].price,
            packaged: 'master',
          },
          {
            ...accountItem,
            price: goodsItem[goodsIdx].price,
            discount,
            endAt,
            goodsIdx,
            goodsList,
            goodsNum,
            goodsid: goodsItem[goodsIdx].id,
            isAdded,
            manualUrl,
            positionName,
            selectedGoods: goodsItem[goodsIdx],
            startAt,
            packaged: 'slave',
          },
        ]
      }, [])

      const makeAccountItem = (base, goodsList, info, discountCoupon) => {
        const newAccountItem = {
          ...base,
          goodsList,
          openDate: dayjs(data.returnState.startDate).format('YYYY-MM-DD'),
          mainCopy: '',
          subCopy: '',
          landingUrl,
          info,
          adCenterPubAccountId: base.id,
          vat: Math.floor(base.price * 0.1),
          discountCoupon,
          adStartDateId: `adStartDate_${base.id}`,
          adEndDateId: `adEndDate_${base.id}`,
          adCenterAdvertisementId: this.state.adId,
          adCenterPubAreaId: info.adCenterPubAreaId,
          area: base.positionName,
          status: 1,
          adProcessStatus: 0,
          adPayStatus: 0,
          callApiType: 'create',
          pricingType: info.pricingType,
        }

        if (discountCoupon.length > 0) {
          newAccountItem.discountIdx = 0
          newAccountItem.discountId = newAccountItem.discountCoupon[newAccountItem.discountIdx].id
          newAccountItem.discountPrice =
            newAccountItem.price - newAccountItem.price * newAccountItem.discountCoupon[0].discountRate
        } else {
          newAccountItem.discountPrice = null
        }
        return newAccountItem
      }
      Promise.all(
        aList.reduce(
          (acc, item) => [
            ...acc,
            getGoodListData(item.id, this.props.user.isWadizAdmin, item.packaged),
            getPubAccountInfoData(item.id),
            getDiscountCouponCode(item.id, item.usedDiscount, item.adCenterDiscountId),
          ],
          []
        )
      ).then((response) => {
        const len = response.length / 3
        const tmpList = []
        for (let i = 0; i < len; i += 1) {
          const baseIdx = i * 3
          tmpList.push(makeAccountItem(aList[i], response[baseIdx], response[baseIdx + 1], response[baseIdx + 2]))
        }

        const { adAccountList: preAccountList, modifiedAdAccountList: preModified } = this.state
        const adAccountList = sortByArea([...tmpList, ...preAccountList])
        const modifiedAdAccountList = sortByArea([...tmpList, ...preModified])
        const prices = adAccountList.reduce(
          (a, adAccount) => {
            const price =
              adAccount.price * (adAccount.normalPeriod || 0) + adAccount.holidayPrice * (adAccount.holidayPeriod || 0)
            return {
              adsPrice: a.adsPrice + price,
              adsVat: a.adsVat + Math.floor(price * 0.1),
            }
          },
          { adsPrice: 0, adsVat: 0 }
        )

        this.setState(
          {
            adAccountList,
            modifiedAdAccountList,
            totalPrice: prices.adsPrice + prices.adsVat,
            ...prices,
          },
          () => {
            this.scrollToAdAccountCardTop()
          }
        )
      })
    }

    getCheckedNotBlocked(this.props.user.userData.makerId)
      .then((isNotBlocked) => {
        if (isNotBlocked) {
          return Promise.resolve(true)
        }
        this.props.openAlertPopup({
          alertData: {
            content: (
              <p>
                메이커님은 관리자에 의해 차단되었습니다. 관리자에게 문의 바랍니다.
                <br />
                ad@wadiz.kr로 메일 주시기 바랍니다.
              </p>
            ),
            closeText: '닫기',
          },
        })
        return Promise.reject()
      })
      .then(() => {
        this.props.openPopup({
          dialogType: 'adAccount',
          dialogData: {
            isStoreProject: this.isStoreProject(),
            campaignId,
            openDate,
            projectType,
            comingsoon,
            accountList: [...this.state.adAccountList],
            confirmFunc,
          },
        })
      })
  }

  isWadizOnlyGoodsSelected = () => {
    const { adAccountList: accountList } = this.state
    // accountList 는 상품 정보를 가지고 있는 배열이다.
    // FE레거시 코드가 이부분을 굉장히 복잡하게 관리하고 있는데,
    // 어쨌든 store에 경우 상품을 하나씩만 선택가능하므로 wadiz Only를 확인하는 대상은 0번 index일 수 밖에없다.
    if (!accountList[0]) {
      return false
    }
    const { selectedGoods } = accountList[0]
    return selectedGoods?.badgeType === 'WadizOnly'
  }

  isStoreProject = () => {
    const { projectList, campaignId } = this.state
    return projectList?.find((p) => `${p.campaignId}` === `${campaignId}`)?.projectType === 3
  }

  adAccountListDel = (idx) => {
    this.props.openPopup({
      dialogType: 'adAccountConfirmDialog',
      dialogData: {
        type: 'delete',
        title: '만들고 있던 광고 상품을 삭제 하시겠습니까?',
        confirmText: '삭제',
        // confirmFunc: this.deleteAdAccount,
        confirmFunc: () => {
          const adAccountList = [...this.state.adAccountList]
          const modifiedAdAccountList = [...this.state.modifiedAdAccountList]

          network.callApi({
            url: 'creative/delete',
            method: 'POST',
            body: {
              id: adAccountList[idx].id,
            },
            successFunc: (jsonData) => {
              if (jsonData.result) {
                const index = modifiedAdAccountList.findIndex((adAccount) => adAccount.id === adAccountList[idx].id)
                if (
                  modifiedAdAccountList[index].adCenterDiscountId &&
                  modifiedAdAccountList[index].adCenterDiscountId !== 0
                ) {
                  const url = 'discount/update'
                  network.callApi({
                    url,
                    method: 'POST',
                    body: { id: modifiedAdAccountList[index].adCenterDiscountId, isUsed: 1 },
                    successFunc: () => {
                      console.info(`success- ${url}`)
                    },
                    failFunc: () => {
                      console.error(`fail- ${url}`)
                    },
                  })
                }

                const { packagedId: modifiedPackagedId } = modifiedAdAccountList[index]
                modifiedAdAccountList.splice(index, 1)
                if (modifiedPackagedId) {
                  const i = modifiedAdAccountList.findIndex(
                    (adAccount) => adAccount.adCenterPubAreaId === modifiedPackagedId
                  )
                  if (i > -1) {
                    modifiedAdAccountList.splice(i, 1)
                  }
                }

                const { packagedId } = adAccountList[idx]
                adAccountList.splice(idx, 1)
                if (packagedId) {
                  const i = adAccountList.findIndex((adAccount) => adAccount.adCenterPubAreaId === packagedId)
                  if (i > -1) {
                    adAccountList.splice(i, 1)
                  }
                }

                let adsPrice = 0
                let adsVat = 0
                let totalPrice = 0

                adAccountList.forEach((adAccount) => {
                  if ((adAccount.status === 1 && adAccount.adProcessStatus !== 3) || adAccount.status === 4) {
                    if (adAccount.discountPrice || adAccount.discountPrice === 0) {
                      adsPrice += adAccount.discountPrice
                      adsVat += Math.floor(adAccount.discountPrice * 0.1)
                    } else {
                      adsPrice += adAccount.price
                      adsVat += Math.floor(adAccount.price * 0.1)
                    }
                  }
                })

                totalPrice = adsPrice + adsVat
                this.setState({
                  adAccountList,
                  adsPrice,
                  adsVat,
                  totalPrice,
                  modifiedAdAccountList,
                })
              }
            },
            failFunc: () => {
              console.error(`fail- ${'creative/delete'}`)
            },
          })
        },
        // confirmData: { adAccountId },
      },
    })
  }

  adAccountListDelModalConfirm = (idx) => {
    const adAccountList = [...this.state.adAccountList]
    const modifiedAdAccountList = [...this.state.modifiedAdAccountList]

    network.callApi({
      url: 'creative/delete',
      method: 'POST',
      body: {
        id: this.state.modifiedAdAccountList[idx].id,
      },
      successFunc: (jsonData) => {
        if (jsonData.result) {
          const index = adAccountList.findIndex((adAccount) => adAccount.id === modifiedAdAccountList[idx].id)
          const selectedAreaId = modifiedAdAccountList[idx].packagedId || modifiedAdAccountList[idx].adCenterPubAreaId
          if (adAccountList[index].adCenterDiscountId) {
            const url = 'discount/update'
            network.callApi({
              url,
              method: 'POST',
              body: { id: adAccountList[index].adCenterDiscountId, isUsed: 1 },
              successFunc: () => {
                console.info(`success- ${url}`)
              },
              failFunc: () => {
                console.error(`fail- ${url}`)
              },
            })
          }

          const removeFilter = (a) => a.packagedId !== selectedAreaId && a.adCenterPubAreaId !== selectedAreaId

          const filteredAccountList = adAccountList.filter(removeFilter)
          const filteredModifiedAccountList = modifiedAdAccountList.filter(removeFilter)
          // adAccountList.splice(index, 1);
          // modifiedAdAccountList.splice(idx, 1);

          let adsPrice = 0
          let adsVat = 0
          let totalPrice = 0

          filteredAccountList.forEach((adAccount) => {
            if (adAccount.discountPrice || adAccount.discountPrice === 0) {
              adsPrice += adAccount.discountPrice
              adsVat += Math.floor(adAccount.discountPrice * 0.1)
            } else {
              adsPrice += adAccount.price
              adsVat += Math.floor(adAccount.price * 0.1)
            }
          })

          totalPrice = adsPrice + adsVat

          this.setState({
            adAccountList: filteredAccountList,
            adsPrice,
            adsVat,
            totalPrice,
            modifiedAdAccountList: filteredModifiedAccountList,
          })
        }
      },
      failFunc: () => {
        console.error(`fail- ${'creative/delete'}`)
      },
    })
  }

  handleAdAccountListDelModalOpen = (idx) => {
    this.props.openPopup({
      dialogType: 'adAccountConfirmDialog',
      dialogData: {
        type: 'delete',
        title: '만들고 있던 광고 상품을 삭제 하시겠습니까?',
        confirmText: '삭제',
        // confirmFunc: this.deleteAdAccount,
        confirmFunc: () => {
          this.adAccountListDelModalConfirm(idx)
        },
        // confirmData: { adAccountId },
      },
    })
  }

  onSelectGoods = (val, idx) => {
    const adAccountList = [...this.state.adAccountList]
    if (!val) {
      adAccountList[idx].impNum = 0
      adAccountList[idx].goodsid = 0
      adAccountList[idx].adCenterPubAccountId = 0
      adAccountList[idx].impPeriod = 0
      adAccountList[idx].price = 0
      adAccountList[idx].vat = 0

      delete adAccountList[idx].info
      delete adAccountList[idx].startAt
      delete adAccountList[idx].endAt

      this.setState({
        adAccountList,
      })
      return
    }

    const { impNum, adCenterPubAccountId, impPeriod, price } = adAccountList[idx].goodsList[val]

    adAccountList[idx].selectedGoods = adAccountList[idx].goodsList[val]
    // 패키지라면 서브 패키지 상품도 수정
    if (adAccountList[idx].packagedId) {
      const pId = adAccountList[idx].packagedId
      const otherIdx = idx === 0 ? 1 : 0
      if (pId === adAccountList[otherIdx].adCenterPubAreaId) {
        adAccountList[otherIdx].selectedGoods = adAccountList[otherIdx].goodsList[val]
      }
    }

    adAccountList[idx].impNum = impNum
    adAccountList[idx].adCenterPubAccountId = adCenterPubAccountId
    // adAccountList[idx].adCenterPubGoodsId = id
    adAccountList.forEach((v, index) => (v.adCenterPubGoodsId = adAccountList[index].goodsList[val].id))
    adAccountList[idx].price = price
    adAccountList[idx].impPeriod = impPeriod
    adAccountList[idx].vat = Math.floor(price * 0.1)
    adAccountList[idx].nameErrMsg = ''
    adAccountList[idx].goodsIdx = val
    adAccountList[idx].costPrice = adAccountList[idx].selectedGoods.price

    adAccountList[idx].endAt = dayjs(adAccountList[idx].startAt)
      .add(impPeriod - 1, 'day')
      .format('YYYY-MM-DD')

    if (adAccountList[idx].discountCoupon.length > 0) {
      if (adAccountList[idx].usedDiscount === 2) {
        if (adAccountList[idx].discountIdx >= 0) {
          adAccountList[idx].discountPrice =
            adAccountList[idx].price -
            (adAccountList[idx].price *
              adAccountList[idx].discountCoupon[adAccountList[idx].discountIdx].discountRate) /
              100
          adAccountList[idx].vat = Math.floor(adAccountList[idx].discountPrice * 0.1)
        }
      } else {
        adAccountList[idx].discountIdx = ''
        delete adAccountList[idx].discountPrice
      }
      adAccountList[idx].price = adAccountList[idx].selectedGoods.price
    } else {
      adAccountList[idx].discountPrice = null
    }

    let adsPrice = 0
    let adsVat = 0
    let totalPrice = 0

    adAccountList.forEach((adAccount) => {
      /* 광고 운영 상태
        0	요청
        1	예정
        2	진행중 (피드백 중..)
        3	취소
        4	완료
        5	삭제
        6	수정중 (요청 후, 요청 취소 상태)
        7	결재 실패
      */

      // if ((adAccount.status === 1 && adAccount.adProcessStatus !== 3) || adAccount.status === 4) {
      // [2023/1/11] 광고 요청 취소 후 상품 변경 시 & 광고 피드백 후 상품 변경시 결제 상품이 0원으로 나오는 문제 수정 (2, 6 추가)
      if (
        (adAccount.status === 1 && adAccount.adProcessStatus !== 3) ||
        adAccount.status === 2 ||
        adAccount.status === 4 ||
        adAccount.status === 6
      ) {
        if (adAccount.discountPrice || adAccount.discountPrice === 0) {
          adsPrice += adAccount.discountPrice
          adsVat += Math.floor(adAccount.discountPrice * 0.1)
        } else {
          // adsPrice += adAccount.price
          // adsVat += Math.floor(adAccount.price * 0.1)
        }
      }
      const price =
        adAccount.selectedGoods.price * adAccount.normalDays + adAccount.selectedGoods.holidayPrice * adAccount.holidays
      adsPrice += price
      adsVat += Math.floor(price * 0.1)
    })
    totalPrice = adsPrice + adsVat

    // adAccountList[idx].impNum = adAccountList[idx].goodsList[val].impNum;
    // adAccountList[idx].goodsid = adAccountList[idx].goodsList[val].id;
    // adAccountList[idx].impPeriod = adAccountList[idx].goodsList[val].impPeriod;

    this.setState({
      adAccountList,
      adsPrice,
      adsVat,
      totalPrice,
    })
  }

  onSelectDiscountCoupon = (val, idx) => {
    const { adAccountList } = this.state
    const newAccountList = [...adAccountList]

    const account = newAccountList[idx]

    if (val === '' || !val) {
      account.adCenterDiscountId = ''
      account.discountIdx = ''
      account.discountPrice = account.price
    } else {
      account.discountIdx = val
      account.adCenterDiscountId = account.discountCoupon[val].id
      account.discountPrice = account.price - (account.price * account.discountCoupon[val].discountRate) / 100
    }

    let adsPrice = 0
    let adsVat = 0
    let totalPrice = 0

    adAccountList.forEach((adAccount) => {
      if ((adAccount.status === 1 && adAccount.adProcessStatus !== 3) || adAccount.status === 4) {
        if (adAccount.discountPrice || adAccount.discountPrice === 0) {
          adsPrice += adAccount.discountPrice
          adsVat += Math.floor(adAccount.discountPrice * 0.1)
        } else {
          adsPrice += adAccount.price
          adsVat += Math.floor(adAccount.price * 0.1)
        }
      }
    })

    totalPrice = adsPrice + adsVat

    this.setState({
      adAccountList: newAccountList,
      adsPrice,
      adsVat,
      totalPrice,
    })
  }

  doCheckEndDate = (startAt, endAt, id) => {
    const { projectType, campaignId } = this.state
    return centerGetPubAreaAvailability({ startAt, endAt, pubAreaId: id, projectType, campaignId }).then((res) => {
      // Available:가능, Imminent:마감 임박, Closed:마감, Unavailable: 이전 광고와 연계하여 광고 할 수 없음.
      const {
        data: { availability },
      } = res
      if (availability === 'Closed') {
        toast('설정하신 기간의 예약이 마감되었습니다. 다른 기간을 선택해주세요.')
      }
      return availability
    })
  }

  onChangeDateRange = (startAt, endAt, idx, checkHolidays) => {
    const adAccountList = [...this.state.adAccountList]
    const newAccountList = [...adAccountList]

    const areaId = newAccountList[idx].adCenterPubAreaId
    this.doCheckEndDate(startAt, endAt, areaId).then((availability) => {
      // Available:가능, Imminent:마감 임박, Closed:마감, Unavailable: 이전 광고와 연계하여 광고 할 수 없음.
      if (availability === 'Closed') {
        return
      }

      const currentAccount = newAccountList[idx]
      currentAccount.startAt = startAt
      currentAccount.endAt = endAt
      currentAccount.adStartDateFocus = false
      currentAccount.adEndDateFocus = false

      currentAccount.holidayPeriod = checkHolidays.holidays
      currentAccount.normalPeriod = checkHolidays.normalDays

      currentAccount.holidays = checkHolidays.holidays
      currentAccount.normalDays = checkHolidays.normalDays

      const price =
        currentAccount.selectedGoods.price * checkHolidays.normalDays +
        currentAccount.selectedGoods.holidayPrice * checkHolidays.holidays
      currentAccount.vat = Math.floor(price * 0.1)

      const adsPrice = price
      const adsVat = Math.floor(price * 0.1)
      const totalPrice = price + currentAccount.vat

      if (currentAccount.packagedId) {
        const pIdx = newAccountList.findIndex((acc) => acc.adCenterPubAreaId === currentAccount.packagedId)
        if (pIdx > -1) {
          const slaveAccount = newAccountList[pIdx]
          slaveAccount.startAt = currentAccount.startAt
          slaveAccount.endAt = currentAccount.endAt
        }
      }

      this.setState({
        adAccountList: newAccountList,
        totalPrice,
        adsPrice,
        adsVat,
      })
    })
  }

  onFocusChange = (name, value, idx) => {
    const adAccountList = [...this.state.adAccountList]
    const newAccountList = [...adAccountList]

    if (name.indexOf('adStartDateFocus_') >= 0) {
      newAccountList[idx].adStartDateFocus = value
    } else {
      newAccountList[idx].adEndDateFocus = value
    }

    newAccountList[idx].dateErrMsg = ''
    this.setState({
      adAccountList: newAccountList,
    })
  }

  handleClickDatepicker = (data, idx) => {
    this.props.openPopup({
      dialogType: 'adDate',
      dialogData: {
        data,
        openDate: this.state.openDate,
        confirmFunc: (startDate, endDate, startTime) => this.handleSetAdDate(idx, startDate, endDate, startTime),
      },
    })
  }

  handleSetAdDate = (idx, startDate, endDate, startTime) => {
    const adAccountList = [...this.state.adAccountList]

    const splitedStartAt = startDate.split('-')
    const splitedEndAt = endDate.split('-')

    const sendDateFlag = adAccountList[idx].startAt
      ? adAccountList[idx].startAt.substring(0, 10) !==
        `${splitedStartAt[0]}-${splitedStartAt[1][1] ? splitedStartAt[1] : `0${splitedStartAt[1]}`}-${
          splitedStartAt[2][1] ? splitedStartAt[2] : `0${splitedStartAt[2]}`
        }`
      : true

    adAccountList[idx].startAt = `${splitedStartAt[0]}-${
      splitedStartAt[1][1] ? splitedStartAt[1] : `0${splitedStartAt[1]}`
    }-${splitedStartAt[2][1] ? splitedStartAt[2] : `0${splitedStartAt[2]}`}`
    adAccountList[idx].endAt = `${splitedEndAt[0]}-${splitedEndAt[1][1] ? splitedEndAt[1] : `0${splitedEndAt[1]}`}-${
      splitedEndAt[2][1] ? splitedEndAt[2] : `0${splitedEndAt[2]}`
    }`
    adAccountList[idx].startTime = startTime
    adAccountList[idx].sendDateFlag = sendDateFlag
    adAccountList[idx].dateErrMsg = ''
    this.setState({ adAccountList, startTime })
  }

  handlePreviewModalOpen = (title, imgUrl, mainCopy, subCopy, bgColor, pubAreaId) => {
    this.props.openPopup({
      dialogType: 'previewDialog',
      dialogData: {
        title,
        confirmText: '닫기',
        imgUrl,
        mainCopy,
        subCopy,
        bgColor,
        pubAreaId,
      },
    })
  }

  imgFileSel = (e, idx, iwidth, iheight, isize) => {
    const { target } = e
    const tempfile = target.files[0]
    const fr = new FileReader()
    const image = new Image()
    if (!tempfile) return
    fr.readAsDataURL(tempfile)
    fr.onload = (file) => {
      image.src = file.target.result
      image.onload = () => {
        const { height, width } = image
        if (width !== iwidth || height !== iheight) {
          toast('이미지 사이즈를 확인하세요.')
          target.value = null
          return
        }
        if (tempfile.size > isize * 1024 * 1024) {
          toast('이미지 용량을 확인하세요.')
          target.value = null
          return
        }

        const data = new FormData()
        data.append('img_file', tempfile)

        const { adAccountList } = this.state
        const { adCenterPubAreaId } = adAccountList[idx]
        const { goodsIdx, goodsList, selectedGoods } = adAccountList[0]
        const { badgeType } = goodsList[goodsIdx] || selectedGoods
        data.append(
          'img_type',
          badgeType === 'Popularity' && adCenterPubAreaId === AreaType.comingSoonList ? 'COMBINATION' : 'NORMAL'
        )

        network.callApi({
          url: 'creative/image/upload',
          method: 'post',
          body: data,
          isFormData: true,
          successFunc: ({ result, response }) => {
            if (!result) {
              toast('이미지 업로드 실패')
              return
            }
            this.setImage(response.imgUrl, idx)
          },
          failFunc: () => {
            console.error(`fail- ${'creative/image/upload'}`)
          },
        })
      }
    }
  }
  setImage = (imgUrl, idx) => {
    const { adAccountList } = this.state
    const newAccountList = [...adAccountList]
    newAccountList[idx].imgUrl = imgUrl
    newAccountList[idx].imageErrMsg = ''
    this.setState({ adAccountList: newAccountList }, () => {
      const imgElm = document.getElementById(`fileImg${idx}`)
      if (imgElm) {
        imgElm.src = imgUrl
      }
    })
  }

  imgDel = (idx) => {
    const { adAccountList } = this.state
    const newAccountList = [...adAccountList]
    delete newAccountList[idx].imgUrl

    this.setState({ adAccountList: newAccountList })
  }

  selectColor = (selColor, idx) => {
    const { adAccountList } = this.state
    const newAccountList = [...adAccountList]

    newAccountList[idx].bgColor = selColor.bgColor
    newAccountList[idx].adCenterBgColorId = selColor.id
    newAccountList[idx].backgroundColorErrMsg = ''
    this.setState({ adAccountList: newAccountList })
  }

  handleChangeMainCopy = (value, idx) => {
    const adAccountList = [...this.state.adAccountList]
    adAccountList[idx].mainCopy = value
    adAccountList[idx].mainCopyErrMsg = ''
    this.setState({ adAccountList })
  }

  handleChangeKeyword = (value, idx) => {
    const adAccountList = [...this.state.adAccountList]
    adAccountList[idx].keyword = value
    this.setState({ adAccountList })
  }

  handleChangeSubCopy = (value, idx) => {
    const adAccountList = [...this.state.adAccountList]
    adAccountList[idx].subCopy = value
    adAccountList[idx].subCopyErrMsg = ''
    this.setState({ adAccountList })
  }

  handleChangeLandingUrl = (event, idx) => {
    const adAccountList = [...this.state.adAccountList]
    adAccountList[idx].landingUrl = event.target.value
    this.setState({ adAccountList })
  }

  handleClickPrevButton = (step) => {
    if (step === 0) this.handleClickGoToAdList()
    else
      this.props.history.push({
        pathname: `/main/ad/modify/${this.state.projectId}/step${step}`,
        state: { ...this.state },
      })

    window.scrollTo(0, 0)
  }

  handleCheckValidation = () => {
    const { flag, toastMsg, adAccountList, selectProjectErrMsg, changeAdNameErrMsg } = validatorAllField(this.state)

    if (flag || toastMsg !== '') {
      this.setState({ adAccountList, selectProjectErrMsg, changeAdNameErrMsg })
      if (toastMsg !== '') {
        toast(toastMsg)
      } else {
        toast('입력 정보를 확인하세요.')
      }
    }
    return flag
  }

  doSubmit = () => {
    const { projectId, adId, modifyStep, campaignId, projectType, usingCouponList, startTime } = this.state
    const { isWadizAdmin } = this.props.user
    let adAccountList = [...this.state.adAccountList]
    let modifiedAdAccountList = []
    let { selectProjectErrMsg, changeAdNameErrMsg } = this.state
    let createRequestBodyList = []
    let updateRequestBodyList = []
    const promiseList = []

    // 휴일 정보가 없을 경우
    if (!adAccountList[0]?.normalDays) {
      adAccountList = adAccountList.map((adAccount) => {
        const { normalDays, holidays } = this.countHolidays(adAccount)
        return {
          ...adAccount,
          normalDays,
          holidays,
        }
      })
    }
    // 광고 수정을 하지 않았는데, step1 에서 다음을 누르면 광고가 수정되어버린다.
    // 그래서 뒤로가기를 하면 수정된것이 보인다.
    this.setState({ loadingBarFlag: true }, () => {
      if (modifyStep !== 1) {
        return
      }

      promiseList.push(promiseAdvertisementUpdate(this.state))

      const {
        createList,
        updateList,
        modifiedList,
        couponList: tmpCouponList,
      } = makeRequestBody(isWadizAdmin, adAccountList, adId, startTime)
      createRequestBodyList = createRequestBodyList.concat(createList)
      updateRequestBodyList = updateRequestBodyList.concat(updateList)
      modifiedAdAccountList = modifiedAdAccountList.concat(modifiedList)
      if (createRequestBodyList.length > 0) {
        if (createRequestBodyList[0].shared === 1) {
          // 다중 지면 패키지
          promiseList.push(promiseCreativeSyncCreate(createRequestBodyList[0]))
        } else {
          promiseList.push(promiseCreativeCreate(createRequestBodyList))
        }
      }

      if (updateRequestBodyList.length > 0) {
        if (updateRequestBodyList[0].shared === 1) {
          // 다중 지면 패키지
          promiseList.push(promiseCreativeSyncUpdate(updateRequestBodyList[0]))
        } else {
          promiseList.push(promiseCreativeUpdate(updateRequestBodyList))
        }
      }

      if (usingCouponList.length > 0) {
        usingCouponList.forEach((coupon) => {
          promiseList.push(promiseDiscountUpdate(coupon, 1))
        })
      }

      if (tmpCouponList.length > 0) {
        tmpCouponList.forEach((coupon) => {
          promiseList.push(promiseDiscountUpdate(coupon, 2))
        })
      }
      const { adId: stateAdId, startTime: stateStartTime } = this.state
      Promise.all(promiseList).then(() => {
        if (isWadizAdmin) {
          this.handleClickGoToAdList()
          toast('광고가 수정되었습니다.')
        } else {
          //TODO : 광고(advertisement) ID로 작성중인 creative list 를 조회하는  API 로 파악됩니다.
          // "no data"는 등록된 광고소재(creative)가 없다는 메시지입니다.
          network.callApi({
            url: `creative/reserved/list?advertisement_id=${stateAdId}`,
            method: 'get',
            successFunc: (data) => {
              if (data.result.length <= 0) {
                // TODO : 작성중에서 요청중으로 넘어갈 때만 이 리스트가 올라온다.
                // 이 외에도 수정중에 저장한다면, step2로 넘길 필요가 있다.따라서,
                // 수정중에 해당하는 3가지 상태에서 여기를 통과하면, step2로 넘어가는 코드를 넣는다.
                // 추후에 작업할 때는 전체적으로 손봐야 한다.
                const isModifyStateLessOne = adAccountList.reduce(
                  (isModifyState, account) =>
                    isModifyState ||
                    account.adState === 'Recreating' ||
                    account.adState === 'Accepted' ||
                    account.adState === 'Feedback' ||
                    account.adState === 'Payment_Failed' ||
                    account.adState === 'Modified_in_Contr' ||
                    account.adState === 'Modified_in_Failed',
                  false
                )
                if (isModifyStateLessOne) {
                  this.setState(
                    {
                      adAccountList,
                      modifiedAdAccountList,
                      selectProjectErrMsg,
                      changeAdNameErrMsg,
                      loadingBarFlag: false,
                      usingCouponList: tmpCouponList,
                    },
                    () =>
                      this.props.history.push({
                        pathname: `/main/ad/modify/${projectId}/step2`,
                        state: { ...this.state },
                      })
                  )
                } else {
                  this.handleClickGoToAdList()
                  toast('광고가 수정되었습니다.')
                }
                return
              }
              data.result.forEach((adAccount) => {
                const tempAdAccount = adAccount
                tempAdAccount.startTime = stateStartTime
              })
              network.callApi({
                url: `creative/list?advertisement_id=${stateAdId}`,
                method: 'get',
                successFunc: (jsonData) => {
                  adAccountList = []
                  adAccountList = jsonData.result
                  this.setState({
                    shared: jsonData.result[0].shared,
                  })
                  // 다중 지면 일 경우 main이 1인것만 확인하면 됨
                  if (adAccountList[0].shared === 1) adAccountList = adAccountList.filter((i) => i.main === 1)

                  const packagedIdList = adAccountList.reduce(
                    (a, item) => [...a, ...(!item.packagedId ? [] : [item.packagedId])],
                    []
                  )
                  packagedIdList.forEach((packagedId) => {
                    const slavePackage = adAccountList.find((n) => n.adCenterPubAreaId === packagedId)
                    if (!slavePackage) {
                      return
                    }
                    slavePackage.isSlavePackage = true
                  })
                  adAccountList = adAccountList
                    .sort((a, b) => Boolean(b.isSlavePackage) - Boolean(a.isSlavePackage))
                    .reverse()
                  const newPromiseList = []

                  adAccountList.forEach(async (adAccount) => {
                    const tempAdAccount = adAccount
                    newPromiseList.push(
                      new Promise((resolve, reject) => {
                        let selectType =
                          (tempAdAccount.packagedId &&
                            adAccountList.findIndex(
                              (account) => account.adCenterPubAreaId === tempAdAccount.packagedId
                            ) > -1) ||
                          tempAdAccount.isSlavePackage
                            ? 2
                            : 0

                        // null = 패키지 아님, 1=주영역, 0=보조영역
                        if (adAccountList[0].main !== null) selectType = 2

                        // 슈퍼유저일 경우 다중지면도 패키지가 아닌 단일(일반)로 해야함
                        if (this.props.user.isSuperUser) selectType = 0

                        // type: 0 - 일반 / 2 - 패키지
                        return network.callApi({
                          url: `goods/list?pub_account_id=${tempAdAccount.adCenterPubAccountId}&advertiser_type=${
                            isWadizAdmin ? 1 : 0
                          }&type=${selectType}&${
                            projectType === 3 ? 'wdz_store_project_id' : 'wdz_campaign_id'
                          }=${campaignId}`,
                          method: 'get',
                          successFunc: (goodsListData) => {
                            tempAdAccount.goodsList = goodsListData.result
                            for (let j = 0; j < goodsListData.result.length; j += 1) {
                              if (goodsListData.result[j].id === tempAdAccount.adCenterPubGoodsId) {
                                tempAdAccount.selectedGoods = goodsListData.result[j]
                                break
                              }
                              adAccount.selectedGoods = adAccount.selectedGoods || jsonData.result[0]
                            }
                            resolve(jsonData.result)
                          },
                          failFunc: (error) => {
                            reject(error)
                          },
                        })
                      })
                    )
                    newPromiseList.push(
                      new Promise((resolve, reject) => {
                        return network.callApi({
                          url: `pub-account/info?pub_account_id=${tempAdAccount.adCenterPubAccountId}`,
                          method: 'get',
                          successFunc: (accountInfoData) => {
                            tempAdAccount.info = accountInfoData.result
                            resolve(accountInfoData.result)
                          },
                          failFunc: (error) => {
                            reject(error)
                          },
                        })
                      })
                    )
                    newPromiseList.push(
                      new Promise((resolve, reject) => {
                        const url =
                          tempAdAccount.adCenterDiscountId === 0
                            ? `discount/list?campaign_id=${campaignId}&project_type=${projectType}&pub_account_id=${adAccount.adCenterPubAccountId}&is_used=1`
                            : `discount/list?campaign_id=${campaignId}&project_type=${projectType}&pub_account_id=${adAccount.adCenterPubAccountId}`
                        return network.callApi({
                          url,
                          method: 'get',
                          successFunc: (discountCouponData) => {
                            if (discountCouponData.result.list && discountCouponData.result.list.length > 0) {
                              const discountCoupon = [...discountCouponData.result.list]
                              if (tempAdAccount.adCenterDiscountId !== 0) {
                                for (let i = 0; i < discountCoupon.length; i += 1) {
                                  if (
                                    discountCoupon[i].isUsed === 2 &&
                                    tempAdAccount.adCenterDiscountId &&
                                    tempAdAccount.adCenterDiscountId !== discountCoupon[i].id
                                  ) {
                                    discountCoupon.splice(i, 1)
                                  }
                                }
                              }
                              tempAdAccount.discountCoupon = discountCoupon
                            } else {
                              tempAdAccount.discountCoupon = []
                            }
                            resolve()
                          },
                          failFunc: (error) => {
                            reject(error)
                          },
                        })
                      })
                    )
                  })
                  Promise.all(newPromiseList).then(() => {
                    adAccountList.forEach((adAccount) => {
                      const tempAdAccount = adAccount
                      tempAdAccount.positionName = tempAdAccount.area
                      tempAdAccount.impPeriod = tempAdAccount.selectedGoods?.impPeriod
                      tempAdAccount.adCenterPubAreaId = tempAdAccount.info.adCenterPubAreaId
                      tempAdAccount.impNum = tempAdAccount.selectedGoods.impNum
                      tempAdAccount.pricingType = tempAdAccount.info.pricingType
                      tempAdAccount.impMinPeriod = tempAdAccount.selectedGoods.impMinPeriod
                      tempAdAccount.goodsIdx = tempAdAccount.goodsList.findIndex(
                        (goods) => goods.id === tempAdAccount.selectedGoods.id
                      )

                      if (tempAdAccount.discountCoupon.length > 0) {
                        tempAdAccount.discountIdx = tempAdAccount.discountCoupon.findIndex(
                          (coupon) => tempAdAccount.adCenterDiscountId === coupon.id
                        )
                        tempAdAccount.discountIdx = tempAdAccount.discountIdx < 0 ? '' : tempAdAccount.discountIdx
                        tempAdAccount.discountPrice = tempAdAccount.price
                        tempAdAccount.price = tempAdAccount.selectedGoods.price
                        tempAdAccount.vat = Math.floor(tempAdAccount.discountPrice * 0.1)
                      } else {
                        tempAdAccount.discountPrice = null
                        tempAdAccount.vat = Math.floor(tempAdAccount.price * 0.1)
                      }

                      // TODO : 이 부분은 다시 확인이 필요하다.
                      if (
                        isWadizAdmin ||
                        (tempAdAccount.status !== 2 &&
                          tempAdAccount.adProcessStatus !== 1 &&
                          !(tempAdAccount.adProcessStatus === 3 && tempAdAccount.status !== 1) &&
                          tempAdAccount.adProcessStatus !== 2 &&
                          tempAdAccount.adProcessStatus !== 4)
                      ) {
                        tempAdAccount.callApiType = 'update'
                      }
                    })

                    const tempAdAccountList = []
                    const tempIndexArray = []
                    for (let i = 0; i < adAccountList.length; i += 1) {
                      if (adAccountList[i].status === 1) {
                        tempAdAccountList.push(adAccountList[i])
                        tempIndexArray.push(i)
                      }
                    }

                    for (let i = 0; i < adAccountList.length; i += 1) {
                      if (tempIndexArray.indexOf(i) < 0) {
                        tempAdAccountList.push(adAccountList[i])
                      }
                    }

                    // 광고가 step1에서 넘어갈 때 수정되어버려서 step2에서 뒤로가기 할 때 데이터가 날라감 그래서 중복코드가 생김
                    adAccountList = Promise.all(
                      adAccountList.map(async (adAccount) => {
                        // bgColor를 사용할 때 API 호출
                        if (adAccount.bgColor) {
                          const { result } = await fetchBgColorList(adAccount.info.adCenterPubAreaId)
                          adAccount.bgColorList = result
                          adAccount.adCenterBgColorId =
                            result[result.findIndex((bg) => bg.bgColor === adAccount.bgColor)].adCenterPubAreaId
                        }
                        return adAccount
                      })
                    )

                    adAccountList = tempAdAccountList
                    this.setState(
                      {
                        adAccountList,
                        modifiedAdAccountList: tempAdAccountList,
                        selectProjectErrMsg,
                        changeAdNameErrMsg,
                        loadingBarFlag: false,
                        usingCouponList: tmpCouponList,
                      },
                      () =>
                        this.props.history.push({
                          pathname: `/main/ad/modify/${projectId}/step2`,
                          state: { ...this.state },
                        })
                    )
                  })
                },
                failFunc: () => {
                  console.error(`fail- promise all`)
                },
              })
            },
            failFunc: () => {
              console.error(`fail- promise all`)
            },
          })
        }
      })
    })

    window.scrollTo(0, 0)
  }

  doValidationCheckForEndDate = () => {
    const { adAccountList: accountList, projectType, campaignId } = this.state
    // const makerId = this.props.user.maker_id // this.props.user.userData.makerId
    return Promise.all(
      accountList.map((account) =>
        centerGetPubAreaAvailability({
          startAt: dayjs(account.startAt).format('YYYY-MM-DD'),
          endAt: dayjs(account.endAt).format('YYYY-MM-DD'),
          pubAreaId: account.adCenterPubAreaId,
          projectType,
          campaignId,
        })
      )
    ).then((res) => {
      const newAccountList = accountList.map((account, index) => ({
        ...account,
        dateErrMsg:
          res[index]?.data?.availability !== 'Closed'
            ? ''
            : '설정하신 기간의 예약이 마감되었습니다. 다른 기간을 선택해주세요.',
      }))

      const availability = this.estimationPubAreaAvailability(res)
      return new Promise((resolve, reject) => {
        this.setState({ adAccountList: newAccountList }, () => {
          // Available:가능, Imminent:마감 임박, Closed:마감, Unavailable: 이전 광고와 연계하여 광고 할 수 없음.
          if (availability === 'Closed') {
            toast('설정하신 기간의 예약이 마감되었습니다. 다른 기간을 선택해주세요')
          }
          if (availability === 'Unavailable') {
            toast('다른 기간을 선택해 주세요. 같은 기간에 이미 진행중인 광고가 있습니다')
            return
          }
          resolve(availability)
        })
      })
    })
  }

  submit = () => {
    if (this.handleCheckValidation()) {
      return
    }
    this.doValidationCheckForEndDate().then((availability) => {
      // Available:가능, Imminent:마감 임박, Closed:마감, Unavailable: 이전 광고와 연계하여 광고 할 수 없음.
      if (availability === 'Closed') {
        return
      }
      this.doSubmit()
    })
  }

  validateAccountListOfPackage = (aList) => {
    const packageIdList = aList.reduce((idList, n) => (!n.packagedId ? idList : [...idList, n.packagedId]), [])
    const subPackageList = packageIdList
      .map((n) => aList.find((acc) => acc.adCenterPubAreaId === n))
      .filter((item) => item !== undefined)
    const isNotValidate =
      subPackageList.reduce((failureList, sub) => {
        if (sub.costPrice !== 0) {
          return [...failureList, sub]
        }
        return failureList
      }, []).length > 0

    return !isNotValidate
  }

  estimationPubAreaAvailability = (list) => {
    // Available:가능, Imminent:마감 임박, Closed:마감, Unavailable: 이전 광고와 연계하여 광고 할 수 없음.
    if (list.findIndex((r) => r?.data?.availability === 'Closed') > -1) {
      return 'Closed'
    }
    if (list.findIndex((r) => r?.data?.availability === 'Imminent') > -1) {
      return 'Imminent'
    }
    return 'Available'
  }

  doAgreementConfirm = (isConfirmed) => {
    this.setState({ isOpenAgreementModal: false }, () => {
      if (isConfirmed) {
        this.handleOpenReservationPaymentDialogImp()
      }
    })
  }

  isFakeWadizOnlyCondition = async () => {
    if (!this.isWadizOnlyGoodsSelected() || !this.isStoreProject() || this.props.user.isSuperUser) {
      return false
    }
    const wadizOnlyCondition = await getWadizOnlyCondition({
      adMakerId: this.props.user?.maker_id,
      wdzStoreProjectId: this.state.campaignId,
    })
    return !wadizOnlyCondition
  }

  handleOpenReservationPaymentDialog = async () => {
    const isFakeWadizOnly = await this.isFakeWadizOnlyCondition()

    if (isFakeWadizOnly) {
      this.props.openWaffleModal({
        title: '광고 상품을 다시 선택해 주세요',
        message: '해당 프로젝트는 와디즈 Only 프로젝트가 아니므로 할인 적용 대상이 아닙니다.',
        buttonColor: 'secondary',
      })
      return
    }

    const isAgreements = getTermsAgreements({ adMakerId: this.props.user?.maker_id })
    if (isAgreements) {
      this.handleOpenReservationPaymentDialogImp()
    } else {
      this.setState({ isOpenAgreementModal: true })
    }
  }

  handleOpenReservationPaymentDialogImp = () => {
    if (!this.validateAccountListOfPackage(this.state.modifiedAdAccountList)) {
      toast('패키지 설정을 확인해 주세요. ')
      return
    }

    const { projectType, campaignId } = this.state
    const accountList = [...this.state.modifiedAdAccountList]
    Promise.all(
      accountList.map((account) =>
        centerGetPubAreaAvailability({
          startAt: dayjs(account.startAt).format('YYYY-MM-DD'),
          endAt: dayjs(account.endAt).format('YYYY-MM-DD'),
          pubAreaId: account.adCenterPubAreaId,
          projectType,
          campaignId,
        })
      )
    ).then((res) => {
      // Available:가능, Imminent:마감 임박, Closed:마감, Unavailable: 이전 광고와 연계하여 광고 할 수 없음.
      const availability = this.estimationPubAreaAvailability(res)
      if (availability === 'Unavailable') {
        this.props.openWaffleModal({
          title: '다른 기간을 선택해 주세요.',
          message: '같은 기간에 이미 진행중인 광고가 있습니다',
        })
        return
      }

      this.props.openPopup({
        dialogType: 'creditCardDetailDialog',
        dialogData: {
          title: '카드 결제 정보',
          price: this.state.totalPrice,
          advertisementId: this.state.adId,
          accountList,
          confirmFunc: (isComplete) => this.handleClickReservationPayment(isComplete),
          closeText: '닫기',
          areaAvailable: availability,
        },
      })
    })
  }

  handleClickReservationPayment = (isComplete) => {
    this.setState({ isComplete }, () => window.scrollTo(0, 0))
  }

  handleClickGoToAdList = () => {
    this.props.setMenuStatus(false)
    this.props.history.push('/main/ad/list')
  }

  comma = (val) => {
    if (typeof val === 'string') return val.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if (typeof val === 'number') return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    return val
  }

  scrollToAdAccountCardTop = () => {
    if (this.selectedAdAccountCardRef.current && this.selectedAdAccountCardRef.current.offsetParent) {
      window.scrollTo(0, this.selectedAdAccountCardRef.current.offsetParent.offsetTop)
      this.setState({ selectedAdAccountCardId: 0 })
    }
  }

  onChangeLandingURL = (isProjectURL) => {
    const { adAccountList, makerInfo, landingUrl } = this.state
    const newAccountList = adAccountList.map((account) => ({
      ...account,
      landingUrl: isProjectURL ? landingUrl : makerInfo.makerPageLandingUrl,
      makerPageUrlSelected: !isProjectURL,
    }))
    this.setState({
      adAccountList: newAccountList,
    })
  }

  render() {
    const {
      handleOpenGuideType,
      handleChangeProject,
      handleChangeAdName,
      handleAdAccountModalOpen,
      adAccountListDel,
      handleAdAccountListDelModalOpen,
      onSelectGoods,
      handleClickDatepicker,
      handlePreviewModalOpen,
      imgFileSel,
      imgDel,
      selectColor,
      handleChangeMainCopy,
      handleChangeKeyword,
      handleChangeSubCopy,
      handleChangeLandingUrl,
      handleClickPrevButton,
      submit,
      handleOpenReservationPaymentDialog,
      handleClickGoToAdList,
      comma,
      selectedAdAccountCardRef,
      onSelectDiscountCoupon,
      onChangeDateRange,
      onFocusChange,
      doAgreementConfirm,
      onChangeLandingURL,
      setImage,
    } = this
    const { isOpenAgreementModal } = this.state
    const sortedAdAccountList = sortByArea(this.state.adAccountList, this.props.user.isSuperUser)

    return (
      <>
        <AdModifyContent
          state={this.state}
          adAccountList={sortedAdAccountList}
          isWadizAdmin={this.props.user.isWadizAdmin}
          isSuperUser={this.props.user.isSuperUser}
          handleOpenGuideType={handleOpenGuideType}
          handleChangeAdName={handleChangeAdName}
          handleChangeProject={handleChangeProject}
          handleAdAccountModalOpen={handleAdAccountModalOpen}
          adAccountListDel={adAccountListDel}
          handleAdAccountListDelModalOpen={handleAdAccountListDelModalOpen}
          onSelectGoods={onSelectGoods}
          onSelectDiscountCoupon={onSelectDiscountCoupon}
          handleClickDatepicker={handleClickDatepicker}
          handlePreviewModalOpen={handlePreviewModalOpen}
          imgFileSel={imgFileSel}
          imgDel={imgDel}
          selectColor={selectColor}
          handleChangeMainCopy={handleChangeMainCopy}
          handleChangeKeyword={handleChangeKeyword}
          handleChangeSubCopy={handleChangeSubCopy}
          handleChangeLandingUrl={handleChangeLandingUrl}
          handleClickPrevButton={handleClickPrevButton}
          submit={submit}
          handleOpenReservationPaymentDialog={handleOpenReservationPaymentDialog}
          handleClickGoToAdList={handleClickGoToAdList}
          comma={comma}
          onChangeDateRange={onChangeDateRange}
          onFocusChange={onFocusChange}
          selectedAdAccountCardRef={selectedAdAccountCardRef}
          onChangeLandingURL={onChangeLandingURL}
          setImage={setImage}
        />
        <TermsModalContainer isOpen={isOpenAgreementModal} onAgreementConfirm={doAgreementConfirm} />
      </>
    )
  }
}

export default connect(({ user, dialog }) => ({ user, dialog }), {
  openPopup,
  openAlertPopup,
  setMenuStatus,
  openWaffleModal,
})(withRouter(AdModifyPage))
