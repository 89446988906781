// eslint-disable-next-line
import React, { FC } from 'react'
import styles from './PopularlyPopper.module.scss'

type PropsType = {
  goodsList: Array<{ badgeType: string }>
}
export const PopularlyPopper: FC<PropsType> = ({ children, goodsList }) => {
  const isVisible =
    !goodsList || goodsList.length === 0
      ? false
      : goodsList.reduce((isPopular, goods) => goods.badgeType === 'Popularity' || isPopular, false)

  return !isVisible ? null : (
    <div className={styles.container}>
      <div role="tooltip" className={styles.popper}>
        {children}
        <div className={styles.arrows} />
      </div>
    </div>
  )
}
