import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
// import * as Sentry from '@sentry/browser'
import ErrorContent from '../../components/ErrorContent'

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log(error)
    // eslint-disable-next-line no-debugger
    // debugger;
    // Sentry.captureException(error)
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    // eslint-disable-next-line no-debugger
    // debugger;
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorContent />
    }

    return this.props.children
  }
}

export default connect(null)(ErrorBoundary)
