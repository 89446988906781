import Cookies from 'universal-cookie';

// https://kapeli.com/cheat_sheets/Axios.docset/Contents/Resources/Documents/index
export default function axiosConfig() {
  const cookies = new Cookies();
  const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    token: cookies.get('token'),
  };
  return {
    baseURL: `${process.env.REACT_APP_WADIZ_API_HOST}api/v1/ad/`,
    timeout: 3000,
    headers,
  };
}
