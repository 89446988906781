import React from 'react';

// Plugin
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
  // TimelineMarkers,
  // TodayMarker,
} from 'react-calendar-timeline/lib';
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container';
import './Timeline.scss';
import moment from 'moment';
import { Spring } from 'react-spring/renderprops';

// CSS module
import style from './Timeline.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(style);

const TimelineContent = (props) => {
  return (
    <div className={cx('timeline_box')}>
      <div className={cx('timeline_controller')}>
        <button
          className={cx('control_btn')}
          onClick={props.onPrevClick}
        >
          <i className='icon chevron-left'></i>
        </button>
        <span className={cx('current_date')}>{props.currentMonthText}</span>
        <button
          className={cx('control_btn')}
          onClick={props.onNextClick}
        >
          <i className='icon chevron-right'></i>
        </button>
      </div>
      <ul className={cx('legend_box')}>
        { props.legend.map(({ name, color }, idx) => (
          <li key={idx} className={cx('legend_item')}>
            <i className={cx('legend_ico')} style={{ backgroundColor: color }}></i>{name}
          </li>
        )) }
      </ul>
      <Spring
        config={{ duration: 250 }}
        to={{
          visibleTimeStart: props.visibleTimeStart,
          visibleTimeEnd: props.visibleTImeEnd,
        }}
        immediate={props.scrolling}
      >
        {() => (
          <Timeline
            resizeDetector={containerResizeDetector}
            {...props}
          >
            <TimelineHeaders className="sticky">
              <SidebarHeader>
                {({ getRootProps }) => {
                  return (
                    <div className={'adDate-left-title'} {...getRootProps()}>광고 영역/구좌</div>
                  );
                }}
              </SidebarHeader>
              <DateHeader
                unit='primaryHeader'
                labelFormat='YYYY.MM'
                intervalRenderer={({ getIntervalProps, intervalContext }) => {
                  const dateHeaderOpt = getIntervalProps();
                  delete dateHeaderOpt.onClick;
                  return (
                    <div className={'rct-dateHeader rct-dateHeader-primary'} {...dateHeaderOpt}>
                      <span>{intervalContext.intervalText}</span>
                    </div>
                  );
                }}
              />
              <DateHeader
                unit='day'
                labelFormat='YYYY/MM/DD'
                intervalRenderer={({ getIntervalProps, intervalContext }) => {
                  const dateHeaderOpt = getIntervalProps();
                  delete dateHeaderOpt.onClick;
                  const targetDate = intervalContext.intervalText;
                  let classItem = '';
                  if (moment().format('YYYY/MM/DD') === targetDate) {
                    classItem += 'today ';
                  }
                  if (moment(targetDate).day() === 6) {
                    classItem += 'saturday ';
                  }
                  if (moment(targetDate).day() === 0) {
                    classItem += 'sunday ';
                  }
                  if (moment(targetDate).format('DD') === '01') {
                    classItem += 'border-item ';
                  }
                  return (
                    <div className={`rct-dateHeader ${classItem}`} {...dateHeaderOpt}>
                      {targetDate.split('/')[2]}
                    </div>
                  );
                }}
              />
            </TimelineHeaders>
            {/* <TimelineMarkers>
              <TodayMarker>
                {({ styles }) => (
                  <div className='today-marker' style={styles} />
                )}
              </TodayMarker>
            </TimelineMarkers> */}
          </Timeline>
        )}
      </Spring>
    </div>
  );
};

export default TimelineContent;
