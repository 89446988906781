import { api, ResultType } from './api'

export interface DateRange {
  startDate: string // 2023-06-01
  withinDays: number // startDate로 부터 + 몇일 까지 받을지
}

export interface BusinessDayItem {
  eventDate: string // 대상 일자
  name: string // 요일
  type: 'o' | 'h' | 'i' | 't' // 일반 = o, 공휴일 = h, 대체공휴일 = i, 임시공휴일 = t
}
export const fetchBusinessDays = ({ startDate, withinDays }: DateRange): Promise<ResultType<BusinessDayItem[]>> => {
  return api('GET', `business-days?start_date=${startDate}&within_days=${withinDays}`)
}
