import { useState } from 'react'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { nanoid } from 'nanoid'
import type { PerformanceType } from '@center/api'
import { type PerformanceReportParams, usePerformanceReportQuery } from './_queries/usePerformanceReportQuery'
import styles from './PerformanceReportTable.module.scss'

const ProjectNameCell: React.FC<{ id: string | number; name: string }> = ({ id, name }) => <div>{`${id}_${name}`}</div>
const cutDate = (date: string) => /^([\d-]*)/.exec(date)?.[1]
const Period: React.FC<{ start: string; end: string }> = ({ start, end }) => {
  const startDate = cutDate(start)
  const endDate = cutDate(end)
  return startDate === endDate ? (
    <span>{startDate}</span>
  ) : (
    <span>
      {startDate}
      <br />
      {'~'}
      {endDate}
    </span>
  )
}
const separateString = (price: number | string) => price.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
const CurrencyCell: React.FC<{ value: string }> = ({ value }) => (
  <div className={styles.currencyCell}>
    <div>{`${separateString(value || 0)}원`}</div>
  </div>
)
const NumericCell: React.FC<{ num: string }> = ({ num }) => (
  <div className={styles.numericCell}>{`${separateString(num || 0)}`}</div>
)
const PercentCell: React.FC<{ num: string }> = ({ num }) => (
  <div className={styles.numericCell}>{`${separateString(num || 0)}%`}</div>
)
const columns: ColumnsType<PerformanceType> = [
  {
    title: '프로젝트',
    dataIndex: 'projectName',
    width: 200,
    sorter: true,
    align: 'center',
    className: styles.projectCell,
    render: (projectName, data) => <ProjectNameCell id={data.projectId} name={projectName} />,
  },
  {
    title: '광고 영역명',
    dataIndex: 'area',
    width: 140,
    sorter: true,
    align: 'center',
    className: styles.areaCell,
  },
  {
    title: '기간',
    dataIndex: 'startAt',
    width: 120,
    align: 'center',
    className: styles.periodCell,
    render: (startAt, data) => <Period start={startAt} end={data.endAt} />,
  },
  {
    title: '광고비',
    dataIndex: 'adPrice',
    width: 120,
    align: 'center',
    className: styles.verticalMiddleCell,
    render: (text) => <CurrencyCell value={text} />,
  },
  {
    title: '노출수',
    dataIndex: 'numImp',
    className: styles.verticalMiddleCell,
    render: (num) => <NumericCell num={num} />,
  },
  {
    title: '클릭수',
    dataIndex: 'numClick',
    className: styles.verticalMiddleCell,
    render: (num) => <NumericCell num={num} />,
  },
  {
    title: '클릭률',
    dataIndex: 'ctr',
    className: styles.verticalMiddleCell,
    render: (num) => <PercentCell num={num} />,
  },
  {
    title: '오픈알림수',
    dataIndex: 'numOpenAlarm',
    className: styles.verticalMiddleCell,
    render: (num) => <NumericCell num={num} />,
  },
  {
    title: '전환수',
    dataIndex: 'numFunding',
    className: styles.verticalMiddleCell,
    render: (num) => <NumericCell num={num} />,
  },
  {
    title: '전환금액',
    dataIndex: 'numFundingPrice',
    className: styles.verticalMiddleCell,
    render: (text) => <CurrencyCell value={text} />,
  },
]
export const PerformanceReportTable: React.FC = () => {
  const [dataParam, setDataParam] = useState<PerformanceReportParams>({
    page: 1,
    pageSize: 10,
  })

  const { isInitialLoading, data } = usePerformanceReportQuery(dataParam)
  const { totalCount, list } = data || { totalCount: 0, list: [] }

  return (
    <Table
      loading={isInitialLoading}
      columns={columns}
      dataSource={list}
      rowKey={() => nanoid()}
      pagination={{
        position: ['bottomLeft'],
        total: totalCount,
        pageSizeOptions: [5, 10, 15, 20, 25, 30],
      }}
      onChange={(pagination, _, sorter) => {
        const { field, order } = sorter as { field: 'projectName' | 'area'; order: 'ascend' | 'descend' }
        setDataParam({
          projectName: field === 'projectName' ? order : undefined,
          area: field === 'area' ? order : undefined,
          page: pagination.current || 1,
          pageSize: pagination.pageSize || 10,
        })
      }}
    />
  )
}
