import React from 'react'
import Lnb from '../../../components/Lnb'
import NavBar from '../../../components/NavBar'
import { PerformanceReportTable } from '@center/ui'
import styles from './ResultReportPage.module.scss'

export const ResultReportPage = () => (
  <main className={styles.container}>
    <Lnb type="report" />
    <div className={styles.contentWrapper}>
      <NavBar
        list={[
          { name: '보고서', link: '/main/ad/report/' },
          { name: '성과 보고서', link: '/main/ad/report/result' },
        ]}
      />
      <hgroup>
        <h2>성과 보고서</h2>
        <p>
          * 본 리포트는 BETA 버전이며 2023-06-29 이후 집행된 광고에 한하여 표기되고 있습니다. (문의사항은{' '}
          <a
            href={process.env.REACT_APP_WADIZ_HELP_CENTER}
            target="_blank"
            className={styles.point_link_blue}
            rel="noreferrer"
          >
            광고 문의하기
          </a>
          로 문의 부탁드립니다)
          <br />
          * 와디즈 광고센터를 통해 신청하신 광고의 데이터입니다. (앱 메인 팝업, 앱 푸시, 카카오톡 푸시 등의 광고
          데이터는 표기되지 않습니다)
          <br />
          * 광고비는 부가세 별도 금액으로 표기되었습니다. 광고센터에 등록된 카드로 결제 및 승인된 건에 한하여 광고금액이
          표기되오며 기타 결제수단으로 진행된 광고의 경우 0원으로 보여질 수 있습니다. 최초 결제 금액 기준으로 광고비가
          표기되며 부분환불, 환불 금액이 반영되지 않을 수 있습니다.
          <br />* 광고 상품(패키지)별 데이터 집계 기준이 상이할 수 있으며 오차가 발생될 수 있으므로 참고 데이터로 활용
          권장드립니다. 노출수, 클릭수는 와디즈 자체 태그 기반으로 집계된 데이터 입니다.{' '}
          <strong style={{ display: 'contents' }}>
            전환값(오픈알림수, 전환수, 전환금액)은 광고가 집행될 일자에 발생된 모든 전환을 표기하고 있으며
          </strong>{' '}
          광고 집행 후 다음날 오전 시점 기준 집계된 값으로 메이커 스튜디오 데이터와 차이가 있을 수 있습니다.
          <br />
          <strong style={{ display: 'contents' }}>
            * 향후 집행한 광고에 대한 전환 성과 데이터만 별도로 확인할 수 있도록 개선 예정입니다.
          </strong>
          <br />
          * 메인보드 광고 상품 집행 시 프로젝트 랜딩이 아닌 “메이커 페이지” 랜딩으로 옵션을 선택한 경우 전환값이
          집계되지 않으며 노출수, 클릭수, 클릭률까지의 데이터만 표기됩니다.
          <br />
          <br />
          {'※ '}
          <a
            className={styles.point_link_blue}
            href={
              'https://makercenter.wadiz.kr/board/basic/1018?rownum=1&search_type=&search_text=&category1_idx=&category2_idx=&board_id=8'
            }
          >
            광고 기본 용어
          </a>
          에 대해 알고 싶으신가요? 링크를 클릭하시면 성과 보고서에 적힌 광고 용어의 뜻을 살펴보실 수 있습니다.
          <br />
          <br />
        </p>
      </hgroup>
      <PerformanceReportTable />
    </div>
  </main>
)
