import { PreviewBox } from '../PreviewBox'
import { PreviewModal } from '../PreviewModal'
import { PreviewItem } from './PreviewItem'
import styles from './CardKeyVisualPreview.module.scss'

type CardKeyVisualPreviewFunction = {
  mainCopy: string | undefined
  subCopy: string | undefined
  imageUrl: string
  onCloseImage?: () => void | undefined
}

export const CardKeyVisualPreview: React.FC<CardKeyVisualPreviewFunction> = ({
  mainCopy,
  subCopy,
  imageUrl,
  onCloseImage,
}) => {
  const modalWidth = 1080
  return (
    <div>
      <PreviewBox onCloseImage={onCloseImage}>
        <div className={styles.firstViewContainer}>
          <div className={styles.normalContainer}>
            <PreviewItem mainCopy={mainCopy} subCopy={subCopy} imageUrl={imageUrl} />
          </div>
        </div>
      </PreviewBox>
      <PreviewModal width={modalWidth}>
        <div className={styles.largeContainer}>
          <div className={styles.desktopContainer}>
            <PreviewItem mainCopy={mainCopy} subCopy={subCopy} imageUrl={imageUrl} paddingBottom={'40%'} />
            <div className={styles.tag}>데스크탑</div>
          </div>
          <div className={styles.mobileContainer}>
            <PreviewItem mainCopy={mainCopy} subCopy={subCopy} imageUrl={imageUrl} paddingBottom={'110%'} isMobile />
            <div className={styles.tag}>모바일</div>
          </div>
        </div>
      </PreviewModal>
    </div>
  )
}
