import { memo } from 'react'
import { Typography } from 'antd'

const { Text } = Typography

const MiddleNotify = () => (
  <div style={{ marginTop: 16 }}>
    <Text>
      ※ 메인콘텐츠, 메인보드 광고는 모바일에서만 노출되며, PC에서는 노출되지 않습니다.
      <br />※ 광고 집행일 기준 3영업일 이내 광고를 취소하는 경우 위약금이 발생할 수 있습니다.
    </Text>
  </div>
)

export default memo(MiddleNotify)
