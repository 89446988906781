import React from 'react';
import className from 'classnames/bind';
import style from './LoadingBar.module.scss';

const cx = className.bind(style);

const LoadingBar = () => {
  return (
    <div className={cx('loadingBar-wrapper')}>
      <div className={cx('loadingio-spinner-rolling-hcw45k9qjb')}>
        <div className={cx('ldio-yzt3xtxgned')}>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingBar;
