import React, { Fragment } from 'react'

// Components
import Lnb from '../Lnb'
import NavBar from '../NavBar'
import BarChart from '../Chart/BarChart/BarChart'
// import ExcelExportContainer from '../../containers/ExcelExportContainer/ExcelExportContainer'

// CSS module
import style from './AdResultReportContent.module.scss'
import className from 'classnames/bind'
const cx = className.bind(style)

const isNullText = (value) => {
  if (value === '' || value === null || value === undefined) {
    return '-'
  }
  return value
}

const AdResultDetailContent = ({
  isWadizAdmin,
  adName,
  projectName,
  tableType,
  tableData,
  fileName,
  isDownload,
  allItemList,
  typeItemList,
  chart,

  handleClickHistoryBack,
  handleClickType,
  handleClickFavorit,
  handleExportExcel,

  navList,
  statusType,
}) => {
  return (
    <main className={cx('main_container', 'report')}>
      <Lnb type="report" />
      <div className={cx('adList_detail', 'content_wrap')}>
        <NavBar list={navList} />
        <hgroup>
          <h2>{adName}</h2>
          <p>{projectName}</p>
        </hgroup>
        <div className={cx('wz container table', 'cancellation')}>
          <ul className={cx('date_range_control')}>
            <li className={cx({ active: tableType === 0 })} onClick={() => handleClickType(0)}>
              전체
            </li>
            <li
              className={cx({ active: tableType === 1 })}
              style={{ display: isWadizAdmin && 'none' }}
              onClick={() => handleClickType(1)}
            >
              일자별
            </li>
            <li className={cx({ active: tableType === 2 })} onClick={() => handleClickType(2)}>
              시간별
            </li>
            <li className={cx({ active: tableType === 3 })} onClick={() => handleClickType(3)}>
              요일별
            </li>
          </ul>
          <div className={cx('wz table track', 'ad_table_total', 'tableWrap')}>
            {tableType === 0 && (
              <table className={cx('wz structured table', 'ad_table', 'ad_table_detail_total')}>
                <caption>{/* 광고명 */}성과보고서</caption>
                <thead>
                  <tr>
                    <th style={{ width: '50px' }}>No.</th>
                    <th>광고 영역</th>
                    <th style={{ width: '70px' }}>광고구좌</th>
                    <th style={{ width: '150px' }}>광고기간</th>
                    <th style={{ width: '80px' }}>광고비</th>
                    <th style={{ width: '100px' }}>광고단계</th>
                    <th style={{ width: '90px' }} className={cx('dot_showCount')}>
                      노출수
                    </th>
                    <th style={{ width: '90px' }} className={cx('dot_clickCount')}>
                      클릭수
                    </th>
                    <th style={{ width: '90px' }} className={cx('dot_clickRate')}>
                      클릭률
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allItemList.length > 0 &&
                    allItemList.map((val, idx) => (
                      <Fragment key={idx}>
                        <tr>
                          <td>{allItemList.length - idx}</td>
                          <td style={{ fontWeight: '500' }}>{val.area}</td>
                          <td>{val.accountNum}</td>
                          <td>
                            {val.startAt.slice(2, 10)} ~<br></br>
                            {val.endAt.slice(2, 10)}
                          </td>
                          <td>{val.price.toLocaleString()}원</td>
                          <td>
                            {isNullText(
                              val.adProcessStatus !== 0
                                ? statusType.adProcessStatus[val.adProcessStatus]
                                : statusType.status[val.status]
                            )}
                          </td>
                          <td>{val.numImp.toLocaleString()}</td>
                          <td>{val.numClick.toLocaleString()}</td>
                          <td>{val.ctr}</td>
                        </tr>
                      </Fragment>
                    ))}
                  {/* 데이터가 없는 경우 */}
                  {allItemList.length === 0 && (
                    <tr>
                      <td colSpan="8">-</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
            {tableType === 1 && (
              <table className={cx('wz structured table', 'ad_table', 'ad_table_detail_date')}>
                <caption>{/* 광고명 */}성과보고서</caption>
                <thead>
                  <tr>
                    <th style={{ width: '130px' }}>일자</th>
                    <th>광고 영역</th>
                    <th style={{ width: '70px' }}>광고구좌</th>
                    <th style={{ width: '100px' }}>광고단계</th>
                    <th style={{ width: '90px' }} className={cx('dot_showCount')}>
                      노출수
                    </th>
                    <th style={{ width: '90px' }} className={cx('dot_clickCount')}>
                      클릭수
                    </th>
                    <th style={{ width: '90px' }} className={cx('dot_clickRate')}>
                      클릭률
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {typeItemList.length > 0 &&
                    typeItemList.map((val, idx) => (
                      <Fragment key={idx}>
                        <tr>
                          <td rowSpan={val.creativeList.length + 1} style={{ fontWeight: '500' }}>
                            {val.key}
                          </td>
                          <td>{val.creativeList[0].area}</td>
                          <td>{val.creativeList[0].accountNum}</td>
                          <td>
                            {isNullText(
                              val.creativeList[0].adProcessStatus !== 0
                                ? statusType.adProcessStatus[val.creativeList[0].adProcessStatus]
                                : statusType.status[val.creativeList[0].status]
                            )}
                          </td>
                          <td>{val.creativeList[0].numImp.toLocaleString()}</td>
                          <td>{val.creativeList[0].numClick.toLocaleString()}</td>
                          <td>{val.creativeList[0].ctr}</td>
                        </tr>
                        {val.creativeList.slice(1).map((val2, idx2) => (
                          <tr key={idx2}>
                            <td>{val2.area}</td>
                            <td>{val2.accountNum}</td>
                            <td>
                              {isNullText(
                                val2.adProcessStatus !== 0
                                  ? statusType.adProcessStatus[val2.adProcessStatus]
                                  : statusType.status[val2.status]
                              )}
                            </td>
                            <td>{val2.numImp.toLocaleString()}</td>
                            <td>{val2.numClick.toLocaleString()}</td>
                            <td>{val2.ctr}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan="3" className={cx('aligned left')}>
                            합계
                          </td>
                          <td>{val.totalNumImp}</td>
                          <td>{val.totalNumClick}</td>
                          <td>{val.totalCtr}</td>
                        </tr>
                      </Fragment>
                    ))}
                  {/* 데이터가 없는 경우 */}
                  {typeItemList.length === 0 && (
                    <tr>
                      <td colSpan="6">-</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
            {tableType === 2 && (
              <table className={cx('wz structured table', 'ad_table', 'ad_table_detail_date')}>
                <caption>{/* 광고명 */}성과보고서</caption>
                <thead>
                  <tr>
                    <th style={{ width: '130px' }}>일자</th>
                    <th>광고 영역</th>
                    <th style={{ width: '70px' }}>광고구좌</th>
                    <th style={{ width: '100px' }}>광고단계</th>
                    <th style={{ width: '90px' }} className={cx('dot_showCount')}>
                      노출수
                    </th>
                    <th style={{ width: '90px' }} className={cx('dot_clickCount')}>
                      클릭수
                    </th>
                    <th style={{ width: '90px' }} className={cx('dot_clickRate')}>
                      클릭률
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {typeItemList.length > 0 &&
                    typeItemList.map((val, idx) => (
                      <Fragment key={idx}>
                        <tr>
                          <td rowSpan={val.creativeList.length + 1} style={{ fontWeight: '500' }}>
                            {val.key}
                          </td>
                          <td>{val.creativeList[0].area}</td>
                          <td>{val.creativeList[0].accountNum}</td>
                          <td>
                            {isNullText(
                              val.creativeList[0].adProcessStatus !== 0
                                ? statusType.adProcessStatus[val.creativeList[0].adProcessStatus]
                                : statusType.status[val.creativeList[0].status]
                            )}
                          </td>
                          <td>{val.creativeList[0].numImp.toLocaleString()}</td>
                          <td>{val.creativeList[0].numClick.toLocaleString()}</td>
                          <td>{val.creativeList[0].ctr}</td>
                        </tr>
                        {val.creativeList.slice(1).map((val2, idx2) => (
                          <tr key={idx2}>
                            <td>{val2.area}</td>
                            <td>{val2.accountNum}</td>
                            <td>
                              {isNullText(
                                val2.adProcessStatus !== 0
                                  ? statusType.adProcessStatus[val2.adProcessStatus]
                                  : statusType.status[val2.status]
                              )}
                            </td>
                            <td>{val2.numImp.toLocaleString()}</td>
                            <td>{val2.numClick.toLocaleString()}</td>
                            <td>{val2.ctr}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan="3" className={cx('aligned left')}>
                            합계
                          </td>
                          <td>{val.totalNumImp}</td>
                          <td>{val.totalNumClick}</td>
                          <td>{val.totalCtr}</td>
                        </tr>
                      </Fragment>
                    ))}
                  {/* 데이터가 없는 경우 */}
                  {typeItemList.length === 0 && (
                    <tr>
                      <td colSpan="6">-</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
            {tableType === 3 && (
              <table className={cx('wz structured table', 'ad_table', 'ad_table_detail_date')}>
                <caption>{/* 광고명 */}성과보고서</caption>
                <thead>
                  <tr>
                    <th style={{ width: '130px' }}>일자</th>
                    <th>광고 영역</th>
                    <th style={{ width: '70px' }}>광고구좌</th>
                    <th style={{ width: '100px' }}>광고단계</th>
                    <th style={{ width: '90px' }} className={cx('dot_showCount')}>
                      노출수
                    </th>
                    <th style={{ width: '90px' }} className={cx('dot_clickCount')}>
                      클릭수
                    </th>
                    <th style={{ width: '90px' }} className={cx('dot_clickRate')}>
                      클릭률
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {typeItemList.length > 0 &&
                    typeItemList.map((val, idx) => (
                      <Fragment key={idx}>
                        <tr>
                          <td rowSpan={val.creativeList.length + 1} style={{ fontWeight: '500' }}>
                            {val.key}
                          </td>
                          <td>{val.creativeList[0].area}</td>
                          <td>{val.creativeList[0].accountNum}</td>
                          <td>
                            {isNullText(
                              val.creativeList[0].adProcessStatus !== 0
                                ? statusType.adProcessStatus[val.creativeList[0].adProcessStatus]
                                : statusType.status[val.creativeList[0].status]
                            )}
                          </td>
                          <td>{val.creativeList[0].numImp.toLocaleString()}</td>
                          <td>{val.creativeList[0].numClick.toLocaleString()}</td>
                          <td>{val.creativeList[0].ctr}</td>
                        </tr>
                        {val.creativeList.slice(1).map((val2, idx2) => (
                          <tr key={idx2}>
                            <td>{val2.area}</td>
                            <td>{val2.accountNum}</td>
                            <td>
                              {isNullText(
                                val2.adProcessStatus !== 0
                                  ? statusType.adProcessStatus[val2.adProcessStatus]
                                  : statusType.status[val2.status]
                              )}
                            </td>
                            <td>{val2.numImp.toLocaleString()}</td>
                            <td>{val2.numClick.toLocaleString()}</td>
                            <td>{val2.ctr}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan="3" className={cx('aligned left')}>
                            합계
                          </td>
                          <td>{val.totalNumImp}</td>
                          <td>{val.totalNumClick}</td>
                          <td>{val.totalCtr}</td>
                        </tr>
                      </Fragment>
                    ))}
                  {/* 데이터가 없는 경우 */}
                  {typeItemList.length === 0 && (
                    <tr>
                      <td colSpan="6">-</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
          {/* {tableData.length > 0 && (
            <div className={cx('ad_table_btnArea')}>
              <button onClick={() => handleExportExcel()} className={cx('wz button')}>
                <i className={cx('icon download')}></i>엑셀 파일로 정보 다운로드
                <ExcelExportContainer fileName={fileName} tableData={tableData} isDownload={isDownload} />
              </button>
            </div>
          )} */}
          <div className={cx('ad_list_graph')}>
            {chart.length !== 0 && (
              <BarChart idItem={'categoryBarChart'} data={chart} height={'500px'} width={'1074px'} />
            )}
          </div>
          <div className={cx('buttonArea')}>
            <button
              type="button"
              className={cx('wz button less', 'button_historyBack')}
              onClick={handleClickHistoryBack}
            >
              <i className={cx('icon chevron-left')} />
              이전
            </button>
            <button type="button" className={cx('wz button primary', 'button_320')} onClick={handleClickFavorit}>
              보고서 즐겨찾기
            </button>
          </div>
        </div>
      </div>
    </main>
  )
}

export default AdResultDetailContent
