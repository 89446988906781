import React from 'react';
import classNames from 'classnames/bind';
import TitleSection from '../TitleSection';
import style from './AdProcessSection.module.scss';
const cx = classNames.bind(style);

const contentList = [
  {
    list: ['광고 만들기 시작', '광고 등록', '승인 요청 및 결제 예약'],
    title: '광고 등록',
    desc: `
      프로젝트 정보와 광고가 연동되어 있어, 먼저 프로젝트를 만들어야 합니다. 프로젝트의 요건 확인(1차 심사) 승인 후, 광고 센터에서 광고를 만들 수 있습니다.`,
  },
  {
    list: ['승인 요청 및 결제 예약', '광고 승인/반려/피드백', '광고 진행'],
    title: '광고 심사 & 승인',
    desc: `
      광고 진행에 문제가 없을 경우, 담당자가 광고를 승인하며 광고비가 결제됩니다. 피드백/반려 시 광고비는 결제되지 않습니다.`,
  },
  {
    list: ['광고 승인', '광고 집행', '광고 리포트 확인'],
    title: '광고 진행 & 보고',
    desc: `
      광고는 게재일의 0시부터 24시까지 노출됩니다. 광고 집행 +1 영업일 경과 후, 광고센터에서 리포트를 확인할 수 있습니다.`,
  },
]

const ContentItem = ({
  jump,
  list,
  title,
  desc,
}) => (
  <section>
    <div className={cx('contentItem')}>
      <ol start={jump}>
        {list.map((n, index) => (
          <li key={index + jump}>{n}</li>
        ))}
      </ol>
    </div>
    <div className={cx('contentNotify')}>
      <h3>{title}</h3>
      <p>{desc}</p>
    </div>
  </section>
)


const AdProcessSection = () => {
  return (
    <section className={cx('container')}>
      <TitleSection section="AdProcessSection" className={cx('title')} />
      <div className={cx('steps')}>
        {contentList.map((item, index) => (
          <ContentItem
            key={`contentItem${index}`}
            jump={index * 3 + 1}
            list={item.list}
            title={`${1 + index}. ${item.title}`}
            desc={item.desc}
          />
        ))}
      </div>
    </section>
  )
};

export default AdProcessSection;
