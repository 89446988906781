import styles from './PreviewItem.module.scss'

type PreviewItemFunction = {
  mainCopy: string | undefined
  subCopy: string | undefined
  imageUrl: string
  paddingBottom?: string
  isMobile?: boolean
}

export const PreviewItem: React.FC<PreviewItemFunction> = ({
  mainCopy,
  subCopy,
  imageUrl,
  paddingBottom = '50.25%',
  isMobile = false,
}) => (
  <div className={styles.contents} style={{ paddingBottom: paddingBottom }}>
    <div className={styles.imageContainer}>
      <img id="fileImg" src={imageUrl} className={styles.image} alt="미리보기 이미지" />
      <div className={styles.adBadge}>AD</div>
    </div>
    <div className={styles.textWrapper}>
      <div className={styles.text}>
        <p className={styles.title}>
          {mainCopy?.split('\n').map((c) => (
            <div>{`${c}` || ''}</div>
          ))}
        </p>
        <p className={styles.description}>{subCopy || ''}</p>
      </div>
    </div>
    <div
      className={`${styles.navigationButtonWrapper} ${styles.left}`}
      style={{ display: isMobile ? 'none' : 'block' }}
    >
      <button className={styles.button}>
        <svg viewBox="0 0 24 24" fill="#fff" focusable="false" role="presentation" aria-hidden="true">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.076 2.576l.848.848L8.35 12l8.575 8.576-.848.848L6.65 12l9.425-9.424z"
          ></path>
        </svg>
      </button>
    </div>
    <div
      className={`${styles.navigationButtonWrapper} ${styles.right}`}
      style={{ display: isMobile ? 'none' : 'block' }}
    >
      <button className={styles.button}>
        <svg viewBox="0 0 24 24" fill="#fff" focusable="false" role="presentation" aria-hidden="true">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.924 2.576l-.848.848L15.65 12l-8.575 8.576.848.848L17.35 12 7.924 2.576z"
          ></path>
        </svg>
      </button>
    </div>
    <div className={styles.indicator} style={{ width: isMobile ? '90%' : '50%' }}>
      <div />
    </div>
  </div>
)
