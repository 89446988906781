import React from 'react'
import cx from 'classnames'

import { InfoIcon } from '@wadiz-frontend/waffle-icons-modified'
import styles from './MessageBox.module.scss'

export enum MessageBoxColor {
  /**
   * 일반적인 메세지 표시
   * */
  basic = 'basic',

  /**
   * 서비스 주요 동선에 밀접한 연관이 있는 메세지 표시
   * */
  primary = 'primary',

  /**
   * 주의를 끌어 메세지 인지시킬 필요가 있는 메세지 표시
   */
  info = 'info',

  /**
   * 경고성 주의사항 메세지 표시
   */
  danger = 'danger',
}

export interface MessageBoxProps {
  /** MessgaBox 색상 */
  color: keyof typeof MessageBoxColor

  /** MessageBox 제목 */
  title?: string

  /** MessageBox 설명 */
  description: string | React.ReactElement

  /** title / description 정렬 기준 */
  isVertical?: boolean

  /** InfoIcon 숨김용도 */
  hideInfoIcon?: boolean

  /** 클래스 명 */
  className?: string
}

export const MessageBox = ({
  className,
  color,
  title,
  description,
  isVertical,
  hideInfoIcon = false,
  ...props
}: MessageBoxProps) => {
  const classes = cx(
    styles.messageBox,
    color && {
      [styles.basic]: color === MessageBoxColor.basic,
      [styles.primary]: color === MessageBoxColor.primary,
      [styles.info]: color === MessageBoxColor.info,
      [styles.danger]: color === MessageBoxColor.danger,
    },
    isVertical && { [styles.vertical]: isVertical },
    className
  )

  return (
    <div {...props} color={color} className={classes}>
      {!hideInfoIcon && <InfoIcon className={cx(styles.icon, { [styles.withoutTitle]: !title })} />}
      <div className={styles.content}>
        {title && <p className={styles.title}>{title}</p>}
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  )
}

export default React.memo(MessageBox)
