import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { closePopup } from '../../reducers/dialogReducer';

import className from 'classnames/bind';
import style from './Dialog.module.scss';
import CommonDialog from './CommonDialog/CommonDialog';
const cx = className.bind(style);

class CopyDetailDialog extends PureComponent {
  state = {
    isShow: false,
  };

  static getDerivedStateFromProps = nextProps => {
    if (nextProps.dialog.dialogType === 'copyDetailDialog') {
      return { isShow: true };
    } return { isShow: false };
  };

  handleClose = () => {
    this.props.closePopup();
    this.setState({
      isShow: false,
    });
  };

  handleConfirm = () => {
    // confirm function

    // close when success
    this.handleClose();
  }

  render() {
    const {
      handleClose,
    } = this;

    const { dialogData } = this.props.dialog;
    const {
      isShow,
    } = this.state;

    return isShow && (
      <CommonDialog title={dialogData.title} closeText={dialogData.closeText} onClickClose={handleClose}>
        {/* Content Area */}
        <main className={cx('dialog_content', 'adList_previewCopy')}>
          <p>
            <strong>메인카피</strong>
            <em>{dialogData.mainCopy}</em>
          </p>
          {dialogData.subCopy && (
            <p>
              <strong>서브카피</strong>
              <em>{dialogData.subCopy}</em>
            </p>
          )}
          {/* <p>
            <strong>ImgUrl</strong>
            <em>{dialogData.imgUrl}</em>
          </p> */}
        </main>
      </CommonDialog>
    );
  }
}

export default connect(({ dialog }) => ({ dialog }), ({ closePopup }))(CopyDetailDialog);
