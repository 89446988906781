import { createAction, handleActions } from 'redux-actions'

const OPEN_WAFFLE_MODAL = 'dialog/OPEN_WAFFLE_MODAL'
export const CLOSE_WAFFLE_MODAL = 'dialog/CLOSE_WAFFLE_MODAL'

export const openWaffleModal = createAction(OPEN_WAFFLE_MODAL)
export const closeWaffleModal = createAction(CLOSE_WAFFLE_MODAL)

const initState = {
  isOpen: false,
  modal: '',
  title: '',
  message: '',
  confirmLabel: '',
  buttonColor: 'primary',
  handleClose: null,
}

export default handleActions(
  {
    [OPEN_WAFFLE_MODAL]: (state, action) => ({
      ...state,
      isOpen: true,
      ...action.payload,
    }),
    [CLOSE_WAFFLE_MODAL]: (state) => {
      if (state.handleClose) {
        state.handleClose()
      }
      return initState
    },
  },
  initState
)
