import React, { PureComponent } from 'react'

// Plugin
import { withRouter } from 'react-router-dom-v5'
import { connect } from 'react-redux'

import { getProjectList } from './_api'
import { openPopup } from '../reducers/dialogReducer'
import { setMenuStatus } from '../reducers/projectMenuReducer'
import { setActivatedAdInfo, resetActivatedAdInfo } from '../reducers/adListReducer'

// Component
import AdListContent from '../components/AdListContent'
import { network } from '../network'
import { toast } from 'react-toastify'

import { sortByArea } from '@legacy/definitions'

import { centerPostCreativesCancel, centerGetCreativesStatusWithCount, centerGetCreativesStatus } from '@wad/api'

class AdListPage extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      makerId: 0,
      projectId: 0,
      projectName: '',
      campaignId: 0,
      adId: 0,
      adName: '',
      LnbData: [],
      adAccountList: [],
      searchPopupIsShow: false,
      searchKeyword: '',
      searchResult: [],
      selectedFilter: 'all',
      sortedAdAccountList: [],
      optionList: [],
      openDate: '',
      lossText: '',
      projectType: 0,

      loadingBarFlag: false,
    }
  }

  navList = [
    { name: 'MY 광고관리', link: '/main/ad/list' },
    { name: '', link: '' },
  ]

  componentDidMount() {
    this.getAdListPageData()
  }

  async getAdListPageData() {
    const makerId = this.props.user.maker_id
    let LnbData = [...this.state.LnbData]
    let { projectId, projectName, adId, adName, campaignId, openDate, lossText, projectType, landingUrl, comingsoon } =
      this.state
    let adAccountList = [...this.state.adAccountList]

    this.setState({ loadingBarFlag: true }, () => {
      getProjectList({ makerId }).then((res) => {
        LnbData = res
        const { activatedProjectId, activatedAdId, activatedCampaignId } = this.props.adList
        if (LnbData.length > 0) {
          const { id: tmpId, projectName: tmpName, campaignId: tmpCampaignId, projectType: tmpType } = LnbData[0]
          projectId = tmpId
          projectName = tmpName
          campaignId = tmpCampaignId
          projectType = tmpType
          if (LnbData[0].advertisementList.length > 0) {
            if (activatedProjectId === 0 || activatedAdId === 0 || activatedCampaignId === 0) {
              const { adCenterAdvertisementId: tmpAdId, adName: tmpAdName } = LnbData[0].advertisementList[0]
              adId = tmpAdId
              adName = tmpAdName
              this.props.setActivatedAdInfo({ projectId, adId, campaignId })
              for (let i = 0; i < LnbData.length; i += 1) {
                LnbData[i].isOpen = i === 0
              }
            } else {
              const activatedProjectIndex = LnbData.findIndex((lnbData) => lnbData.id === activatedProjectId)
              projectId = activatedProjectId
              const {
                projectName: tmp2Name,
                campaignId: tmp2CampaignId,
                projectType: tmp2Type,
              } = LnbData[activatedProjectIndex]
              projectName = tmp2Name
              campaignId = tmp2CampaignId
              projectType = tmp2Type
              const activatedAdIndex = LnbData[activatedProjectIndex].advertisementList.findIndex(
                (adData) => adData.adCenterAdvertisementId === activatedAdId
              )
              adId = activatedAdId
              const { adName: tmp2AdName } = LnbData[activatedProjectIndex].advertisementList[activatedAdIndex]
              adName = tmp2AdName

              LnbData[activatedProjectIndex].isOpen = true
            }
          }

          const getOpenDate = new Promise((resolve, reject) => {
            return network.callApi({
              url: `project/openDate?project_id=${campaignId}&project_type=${projectType}`,
              method: 'get',
              successFunc: (openDateJsonData) => {
                const { openDate: tmpDate } = openDateJsonData.result
                openDate = tmpDate
                resolve(openDateJsonData)
              },
              failFunc: (error) => {
                reject(error)
              },
            })
          })

          const getLossText = new Promise((resolve, reject) => {
            return network.callApi({
              url: 'getLossText',
              method: 'get',
              successFunc: (jsonData) => {
                const { lossText: tmpLossText } = jsonData.result
                lossText = tmpLossText
                resolve(jsonData)
              },
              failFunc: (error) => {
                reject(error)
              },
            })
          })

          const getLandingurl = new Promise((resolve, reject) => {
            return network.callApi({
              url: `landing-url/info?project_id=${projectId}`,
              method: 'get',
              successFunc: (jsonData) => {
                landingUrl = Object.prototype.hasOwnProperty.call(jsonData.result, 'landingUrl')
                  ? jsonData.result.landingUrl
                  : ''
                comingsoon = Object.prototype.hasOwnProperty.call(jsonData.result, 'comingsoon')
                  ? jsonData.result.comingsoon
                  : ''
                resolve(jsonData)
              },
              failFunc: (error) => {
                reject(error)
              },
            })
          })

          const getAdvertisementData = new Promise((resolve, reject) => {
            return network.callApi({
              url: `creative/list?advertisement_id=${adId}`,
              method: 'get',
              successFunc: (jsonData) => {
                adAccountList = jsonData.result.sort((a, b) => {
                  return Number(b.main) - Number(a.main) // 메인 영역인 값을 index 0으로 만들기
                })

                for (let i = 0; i < adAccountList.length; i += 1) {
                  if (!adAccountList[i].startAt) adAccountList[i].startAt = ''
                  if (!adAccountList[i].endAt) adAccountList[i].endAt = ''
                  if (adAccountList[i].reportList.length > 0) {
                    const tmpReportList = adAccountList[i].reportList.map((val) => {
                      return { ...val, reportTime: val.reportTime ? val.reportTime.slice(2, -9) : '' }
                    })
                    adAccountList[i].reportList = tmpReportList
                  }
                }
                console.log('cretive/list', adAccountList);
                resolve(jsonData)
              },
              failFunc: (error) => {
                reject(error)
              },
            })
          })
          Promise.all([getOpenDate, getAdvertisementData, getLossText, getLandingurl]).then(() => {
            this.setState(
              {
                makerId,
                LnbData,
                projectId,
                campaignId,
                projectName,
                adId,
                adName,
                adAccountList,
                openDate,
                projectType,
                lossText,
                landingUrl,
                comingsoon,
              },
              () => {
                this.setOptionList()
                this.setState({ loadingBarFlag: false })
              }
            )
          })
        } else {
          this.setState({ LnbData, makerId, loadingBarFlag: false })
        }
      })
    })
  }

  handleCopyDetailModalOpen = (title, mainCopy, subCopy, imgUrl) => {
    this.props.openPopup({
      dialogType: 'copyDetailDialog',
      dialogData: {
        title,
        mainCopy,
        subCopy,
        imgUrl,
        closeText: '닫기',
      },
    })
  }

  handleToAdCreate = () => {
    // 광고 생성 페이지로 이동
    const { projectId, adId, campaignId } = this.state
    setActivatedAdInfo({ projectId, adId, campaignId })
    this.props.history.push('/main/ad/create/step1')
  }

  toggleMoreLessButton = (projectId, isOpen) => {
    const LnbData = [...this.state.LnbData]
    for (let i = 0; i < LnbData.length; i += 1) {
      LnbData[i].isOpen = LnbData[i].id === projectId ? isOpen : false
    }

    this.setState({ LnbData })
  }

  handleDeleteAdAccountModalOpen = (adAccount) => {
    this.props.openPopup({
      dialogType: 'adAccountConfirmDialog',
      dialogData: {
        type: 'delete',
        title: '만들고 있던 광고 상품을 삭제 하시겠습니까?',
        confirmText: '삭제',
        confirmFunc: this.deleteAdAccount,
        confirmData: adAccount,
      },
    })
  }

  deleteAdAccount = (data) => {
    network.callApi({
      url: 'creative/delete',
      method: 'POST',
      body: { id: data.id },
      successFunc: (jsonData) => {
        if (jsonData.result === 'success') {
          if (data.adCenterDiscountId) {
            network.callApi({
              url: 'discount/update',
              method: 'POST',
              body: { id: data.adCenterDiscountId, isUsed: 1 },
              successFunc: () => {
                // 할인쿠폰 업데이트 성공
              },
              failFunc: () => {
                // 할인쿠폰 업데이트 실패
                console.error(`fail- creative/delete`)
              },
            })
          }
          this.refreshAdAccountList()
          toast('삭제되었습니다.')
        } else {
          toast('삭제에 실패하였습니다.')
        }
      },
      failFunc: () => {
        toast('삭제에 실패하였습니다.')
      },
    })
  }

  handleCancelRequestForReviewAdAccountModalOpen = (adAccountId) => {
    this.props.openPopup({
      dialogType: 'adAccountConfirmDialog',
      dialogData: {
        type: 'cancelRequestForReview',
        title: '심사요청을 취소하시겠습니까?',
        confirmText: '확인 취소',
        confirmFunc: this.cancelAdAccount,
        confirmData: { adAccountId, isScheduled: false },
      },
    })
  }

  handleCancelScheduledAdAccountModalOpen = (adAccountId, selector) => {
    const openModal = (type, title) => {
      this.props.openPopup({
        dialogType: 'adAccountConfirmDialog',
        dialogData: {
          type,
          title,
          confirmText: '확인',
          cancelText: '취소',
          confirmFunc: this.cancelScheduledAdAccount,
          confirmData: adAccountId,
        },
      })
    }

    const selectorType = selector || 'cancelScheduledAd'
    if (selector !== 'Submitted:Cancel') {
      openModal(
        selectorType,
        selector === 'Modified:Cancel' ? '수정요청을 취소하시겠습니까?' : '광고를 취소하시겠습니까?'
      )
      return
    }

    centerGetCreativesStatus(adAccountId).then(({ data: historyList }) => {
      const isSubmittedCancelAfterRecreating = historyList.reduce(
        (isAfterCreating, history) => isAfterCreating || history.adState === 'Recreating',
        false
      )

      openModal(
        !isSubmittedCancelAfterRecreating ? 'SubmittedAfterCreating:Cancel' : 'Submitted:Cancel',
        '심사요청을 취소하시겠습니까?'
      )
    })
  }

  handlePreviewModalOpen = (title, imgUrl, mainCopy, subCopy, bgColor, pubAreaId) => {
    this.props.openPopup({
      dialogType: 'previewDialog',
      dialogData: {
        title,
        confirmText: '닫기',
        imgUrl,
        mainCopy,
        subCopy,
        bgColor,
        pubAreaId,
      },
    })
  }

  handleCancelRejectReasonModalOpen = (cancelReason, reasonType) => {
    this.props.openPopup({
      dialogType: 'adAccountConfirmDialog',
      dialogData: {
        type: 'cancelReason',
        title:
          reasonType === 'reject' ? '메이커님, 안타깝게도 광고가 반려되었습니다.' : '메이커님, 광고가 취소되었습니다.',
        cancelReason,
        // confirmText: '닫기',
      },
    })
  }

  cancelAdAccount = (data) => {
    // 심사요청 상태일 경우
    // status: 작성중(1)로 변경
    // adProcessStatus :0 으로 변경
    // adPayStatus : 결제 예약취소로 변경(1)
    network.callApi({
      url: 'creative/update',
      method: 'POST',
      body: [
        {
          id: data.adAccountId,
          adCenterAdvertisementId: this.state.adId,
          status: 1,
          adProcessStatus: 0,
          adPayStatus: 1,
          cancelReason: '취소한 광고는 다시 진행할 수 없습니다.\n 광고만들기를 통해 광고를 다시 등록해 주세요.',
          cancelStatus: 3,
        },
      ],
      successFunc: (jsonData) => {
        if (jsonData.result === 'success') {
          this.refreshAdAccountList()
        } else {
          toast('광고를 취소할 수 없습니다.\n광고 담당자에게 별도 문의를 해주세요.')
        }
      },
      failFunc: () => {
        toast('광고를 취소할 수 없습니다.\n광고 담당자에게 별도 문의를 해주세요.')
      },
    })
  }

  cancelScheduledAdAccount = (adAccountId) => {
    centerPostCreativesCancel(adAccountId)
      .then(
        ({ data: { description } }) => {
          toast(!description ? '수정되었습니다.' : `${description}으로 변경 되었습니다.`)
          this.refreshAdAccountList()
        },
        () => {
          toast('광고를 취소할 수 없습니다.\n광고 담당자에게 별도 문의를 해주세요.')
        }
      )
      .catch(() => {
        toast('광고를 취소할 수 없습니다.\n광고 담당자에게 별도 문의를 해주세요.')
      })
  }

  refreshAdAccountList = (prjId, cmpId, prjName, advId, advName, prjType) => {
    const projectId = prjId || this.state.projectId
    const campaignId = cmpId || this.state.campaignId
    const projectName = prjName || this.state.projectName
    const adId = advId || this.state.adId
    const adName = advName || this.state.adName
    const projectType = prjType || this.state.projectType

    let adAccountList = [...this.state.adAccountList]
    let sortedAdAccountList = [...this.state.sortedAdAccountList]
    sortedAdAccountList = []
    let { openDate, landingUrl, comingsoon } = this.state

    this.setState({ loadingBarFlag: true }, () => {
      const getOpenDate = new Promise((resolve, reject) => {
        return network.callApi({
          url: `project/openDate?project_id=${campaignId}&project_type=${projectType}`,
          method: 'get',
          successFunc: (openDateJsonData) => {
            const { openDate: tmpOpenDate } = openDateJsonData.result
            openDate = tmpOpenDate
            resolve(openDateJsonData)
          },
          failFunc: (error) => {
            reject(error)
          },
        })
      })

      const getAdvertisementData = new Promise((resolve, reject) => {
        return network.callApi({
          url: `creative/list?advertisement_id=${adId}`,
          method: 'get',
          successFunc: (jsonData) => {
            adAccountList = jsonData.result.sort((a, b) => {
              return Number(b.main) - Number(a.main) // 메인 영역인 값을 index 0으로 만들기
            })
            this.props.setActivatedAdInfo({ projectId, adId, campaignId })
            for (let i = 0; i < adAccountList.length; i += 1) {
              if (!adAccountList[i].startAt) adAccountList[i].startAt = ''
              if (!adAccountList[i].endAt) adAccountList[i].endAt = ''
              if (adAccountList[i].reportList.length > 0) {
                const tmpReportList = adAccountList[i].reportList.map((val) => {
                  return { ...val, reportTime: val.reportTime ? val.reportTime.slice(2, -9) : '' }
                })
                adAccountList[i].reportList = tmpReportList
              }
            }
            resolve(jsonData)
          },
          failFunc: (error) => {
            reject(error)
          },
        })
      })

      const getLandingurl = new Promise((resolve, reject) => {
        return network.callApi({
          url: `landing-url/info?project_id=${projectId}`,
          method: 'get',
          successFunc: (jsonData) => {
            landingUrl = Object.prototype.hasOwnProperty.call(jsonData.result, 'landingUrl')
              ? jsonData.result.landingUrl
              : ''
            comingsoon = Object.prototype.hasOwnProperty.call(jsonData.result, 'comingsoon')
              ? jsonData.result.comingsoon
              : ''
            resolve(jsonData)
          },
          failFunc: (error) => {
            reject(error)
          },
        })
      })

      const getStatusData = centerGetCreativesStatusWithCount(adId, 1)

      Promise.all([getOpenDate, getAdvertisementData, getLandingurl, getStatusData]).then((res) => {
        this.setState(
          {
            adAccountList,
            projectId,
            campaignId,
            projectName,
            adId,
            adName,
            selectedFilter: 'all',
            sortedAdAccountList,
            projectType,
            openDate,
            landingUrl,
            loadingBarFlag: false,
            comingsoon,
          },
          () => {
            this.props.setMenuStatus(false)
            this.setOptionList()
          }
        )
      })
    })
  }

  handleMobileMenuOpen = (flag) => {
    this.props.setMenuStatus(flag)
  }

  handleMoveToCreateAd = () => {
    const { projectId, adId, campaignId } = this.state
    setActivatedAdInfo({ projectId, adId, campaignId })
    this.props.history.push({
      pathname: '/main/ad/create/step2',
      state: { ...this.state },
    })
  }

  handleMoveToModifyAd = (selectedAdAccountCardId, pageSelector) => {
    const { projectId, adId, campaignId } = this.state
    setActivatedAdInfo({ projectId, adId, campaignId })
    this.props.history.push({
      pathname: `/main/ad/modify${pageSelector}/${this.state.projectId}/step1`,
      state: { ...this.state },
      selectedAdAccountCardId,
    })
  }

  handleDeleteAdvertisement = (deletableFlag) => {
    const id = this.state.adId
    if (deletableFlag) {
      this.props.openPopup({
        dialogType: 'adAccountConfirmDialog',
        dialogData: {
          type: 'onlyText',
          title: '광고 삭제',
          deleteText1: '광고를 삭제하면, 더 이상 해당 광고에 대해 확인할 수 없습니다.',
          confirmText: '삭제',
          confirmFunc: () => {
            network.callApi({
              url: `advertisement/delete?id=${this.state.adId}`,
              method: 'POST',
              body: { id },
              successFunc: (jsonData) => {
                if (jsonData.result === 'success') {
                  this.props.resetActivatedAdInfo()
                  this.getAdListPageData()
                } else if (jsonData.result === 'non delete') {
                  this.props.openPopup({
                    dialogType: 'adAccountConfirmDialog',
                    dialogData: {
                      type: 'deleteAdAlert',
                      title: '광고 삭제 불가',
                    },
                  })
                } else {
                  toast('삭제를 실패하였습니다.')
                }
              },
              failFunc: () => {
                toast('삭제를 실패하였습니다.')
              },
            })
          },
        },
      })
    } else {
      this.props.openPopup({
        dialogType: 'adAccountConfirmDialog',
        dialogData: {
          type: 'deleteAdAlert',
          title: '광고 삭제 불가',
        },
      })
    }
  }

  checkDeletableAdvertisement = (adAccountList) => {
    let resultFlag = true
    for (let i = 0; i < adAccountList.length; i += 1) {
      if (
        !(adAccountList[i].status === 1 && adAccountList[i].adProcessStatus === 0) &&
        !(adAccountList[i].status === 4 && adAccountList[i].adProcessStatus === 0)
      ) {
        resultFlag = false
        break
      }
    }
    return resultFlag
  }

  handleSearch = (event) => {
    this.setState({ searchKeyword: event.target.value })
  }

  getSearchResult = () => {
    const url = `advertisement/search?maker_id=${this.state.makerId}&ad_name=${this.state.searchKeyword}`
    network.callApi({
      url,
      method: 'get',
      successFunc: (jsonData) => {
        let searchResult = [...this.state.searchResult]
        searchResult = jsonData.result
        this.setState({ searchResult, searchPopupIsShow: true })
      },
      failFunc: () => {
        console.error(`fail- ${url}`)
      },
    })
  }

  handleSearchAdClick = (projectId, campaignId, projectName, adId, adName, prjType) => {
    this.refreshAdAccountList(projectId, campaignId, projectName, adId, adName, prjType)
  }

  closeSearchPopup = () => {
    let searchResult = [...this.state.searchResult]
    searchResult = []
    this.setState({ searchPopupIsShow: false, searchResult })
  }

  handleChangeSortOption = (event) => {
    const { value } = event.target
    const adAccountList = [...this.state.adAccountList]
    let sortedAdAccountList = [...this.state.sortedAdAccountList]
    let { selectedFilter } = this.state
    sortedAdAccountList = []
    switch (value) {
      case 'advertising':
        for (let i = 0; i < adAccountList.length; i += 1) {
          if (adAccountList[i].adProcessStatus === 2) {
            sortedAdAccountList.push(adAccountList[i])
          }
        }
        selectedFilter = 'advertising'
        break
      case 'creating':
        for (let i = 0; i < adAccountList.length; i += 1) {
          if (adAccountList[i].status === 1 && adAccountList[i].adProcessStatus !== 3) {
            sortedAdAccountList.push(adAccountList[i])
          }
        }
        selectedFilter = 'creating'
        break
      case 'scheduled':
        for (let i = 0; i < adAccountList.length; i += 1) {
          if (adAccountList[i].adProcessStatus === 1) {
            sortedAdAccountList.push(adAccountList[i])
          }
        }
        selectedFilter = 'scheduled'
        break
      case 'reqeustForReview':
        for (let i = 0; i < adAccountList.length; i += 1) {
          if (adAccountList[i].status === 2) {
            sortedAdAccountList.push(adAccountList[i])
          }
        }
        selectedFilter = 'reqeustForReview'
        break
      case 'complete':
        for (let i = 0; i < adAccountList.length; i += 1) {
          if (adAccountList[i].adProcessStatus === 4) {
            sortedAdAccountList.push(adAccountList[i])
          }
        }
        selectedFilter = 'complete'
        break
      case 'reject':
        for (let i = 0; i < adAccountList.length; i += 1) {
          if (adAccountList[i].status === 4) {
            sortedAdAccountList.push(adAccountList[i])
          }
        }
        selectedFilter = 'reject'
        break
      case 'cancel':
        for (let i = 0; i < adAccountList.length; i += 1) {
          if (adAccountList[i].adProcessStatus === 3) {
            sortedAdAccountList.push(adAccountList[i])
          }
        }
        selectedFilter = 'cancel'
        break
      case 'all ':
        sortedAdAccountList = []
        selectedFilter = 'all'
        break
      default:
        sortedAdAccountList = []
        selectedFilter = 'all'
        break
    }
    this.setState({ sortedAdAccountList, selectedFilter })
  }

  handleMoveToResultReport = (adId) => {
    const { projectId, campaignId } = this.state
    setActivatedAdInfo({ projectId, adId, campaignId })
    this.props.history.push(`/main/ad/report/result/detail/${adId}`)
  }

  handleMoveToCostReport = (adId) => {
    const { projectId, campaignId } = this.state
    setActivatedAdInfo({ projectId, adId, campaignId })
    this.props.history.push(`/main/ad/report/cost/detail/${adId}`)
  }

  handleClickDisabledLnbButton = () => {
    this.props.openPopup({
      dialogType: 'adAccountConfirmDialog',
      dialogData: {
        type: 'onlyText',
        title: '광고 영역 및 구좌 등록 안내',
        deleteText1: '광고 영역 및 구좌가 등록 후에, 확인할 수 있습니다.',
        // confirmText: '닫기',
      },
    })
  }

  setOptionList = () => {
    const adAccountList = [...this.state.adAccountList]
    let optionList = []
    optionList.push({
      optionValue: 'all',
      optionText: '전체',
    })

    for (let i = 0; i < adAccountList.length; i += 1) {
      if (adAccountList[i].adProcessStatus === 2) {
        optionList.push({
          optionText: '진행중',
          optionValue: 'advertising',
        })
      } else if (adAccountList[i].status === 1 && adAccountList[i].adProcessStatus !== 3) {
        optionList.push({
          optionText: '작성중',
          optionValue: 'creating',
        })
      } else if (adAccountList[i].adProcessStatus === 1) {
        optionList.push({
          optionText: '예정',
          optionValue: 'scheduled',
        })
      } else if (adAccountList[i].status === 2) {
        optionList.push({
          optionText: '승인요청',
          optionValue: 'reqeustForReview',
        })
      } else if (adAccountList[i].adProcessStatus === 4) {
        optionList.push({
          optionText: '완료',
          optionValue: 'complete',
        })
      } else if (adAccountList[i].status === 4) {
        optionList.push({
          optionText: '반려',
          optionValue: 'reject',
        })
      } else if (adAccountList[i].adProcessStatus === 3) {
        optionList.push({
          optionText: '취소',
          optionValue: 'cancel',
        })
      }
    }
    optionList = [...new Set(optionList.map((obj) => JSON.stringify(obj)))].map((str) => JSON.parse(str))
    this.setState({ optionList })
  }

  // handleAddAccount = () => {
  //   const { openDate, projectType, adAccountList, comingsoon, campaignId } = this.state
  //   this.props.openPopup({
  //     dialogType: 'adAccount',
  //     dialogData: {
  //       campaignId,
  //       openDate,
  //       projectType,
  //       accountList: adAccountList,
  //       comingsoon,
  //       confirmFunc: (data) => {
  //         const { adId, projectId, landingUrl } = this.state
  //         const { accountList } = data.returnState
  //         const { isWadizAdmin } = this.props.user
  //         const createRequestBodyList = []
  //         accountList.forEach((account) => {
  //           const tmpAccount = account
  //           const tempList = {}

  //           tempList.adCenterAdvertisementId = adId
  //           tempList.adCenterBgColorId = tmpAccount.adCenterBgColorId
  //           tempList.adCenterPubAccountId = tmpAccount.id
  //           tempList.adCenterPubAreaId = tmpAccount.adCenterPubAreaId
  //           tempList.adCenterPubGoodsId = tmpAccount.goodsid
  //           tempList.adCenterStatusId = 1 // 추후 수정될 가능성 있음
  //           if (tmpAccount.startAt && tmpAccount.startAt !== '')
  //             tempList.startAt = `${tmpAccount.startAt}${tmpAccount.startAt.length > 11 ? '' : ' 00:00:00'}`
  //           if (tmpAccount.endAt && tmpAccount.endAt !== '')
  //             tempList.endAt = `${tmpAccount.endAt}${tmpAccount.endAt.length > 11 ? '' : ' 00:00:00'}`
  //           tempList.status = isWadizAdmin ? 3 : 1
  //           tempList.adProcessStatus = isWadizAdmin ? 1 : 0
  //           tempList.advertiserDivision = isWadizAdmin ? 1 : 0
  //           tempList.bgColor = tmpAccount.selColor
  //           // tempList.imgFile = val.imgFile;
  //           if (Object.prototype.hasOwnProperty.call(tmpAccount, 'imgUrl')) tempList.imgUrl = tmpAccount.imgUrl
  //           tempList.mainCopy = tmpAccount.mainCopy
  //           tempList.subCopy = tmpAccount.subCopy
  //           tempList.landingUrl = landingUrl
  //           tempList.price = tmpAccount.price
  //           tempList.vat = tmpAccount.vat

  //           createRequestBodyList.push(tempList)
  //         })
  //         const { campaignId } = this.state
  //         setActivatedAdInfo({ projectId, adId, campaignId })
  //         this.props.history.push({
  //           pathname: `/main/ad/modify/${projectId}/step1`,
  //           state: { ...this.state },
  //           addedAccountList: accountList,
  //         })
  //       },
  //     },
  //   })
  // }

  render() {
    const { navList, state } = this
    const {
      toggleMoreLessButton,
      handleCopyDetailModalOpen,
      handleDeleteAdAccountModalOpen,
      handleCancelRequestForReviewAdAccountModalOpen,
      handleCancelScheduledAdAccountModalOpen,
      handlePreviewModalOpen,
      handleMoveToCreateAd,
      handleMoveToModifyAd,
      handleCancelRejectReasonModalOpen,
      checkDeletableAdvertisement,
      handleDeleteAdvertisement,
      handleSearch,
      getSearchResult,
      closeSearchPopup,
      handleSearchAdClick,
      refreshAdAccountList,
      handleChangeSortOption,
      handleMoveToResultReport,
      handleMoveToCostReport,
      handleClickDisabledLnbButton,
      handleMobileMenuOpen,
      handleToAdCreate,
    } = this
    const sortedAdAccountList = sortByArea(state.adAccountList, this.props.user.isSuperUser)
    const { status: mobileMenuIsOpen } = this.props.projectMenu
    // eslint-disable-next-line no-debugger
    return (
      <AdListContent
        {...state}
        adAccountList={sortedAdAccountList}
        makerName={this.props.user.userData.noMaskingName}
        isSuperUser={this.props.user.isSuperUser}
        isWadizAdmin={this.props.user.isWadizAdmin}
        mobileMenuIsOpen={mobileMenuIsOpen}
        navList={navList}
        toggleMoreLessButton={toggleMoreLessButton}
        handleCopyDetailModalOpen={handleCopyDetailModalOpen}
        handleDeleteAdAccountModalOpen={handleDeleteAdAccountModalOpen}
        handleCancelRequestForReviewAdAccountModalOpen={handleCancelRequestForReviewAdAccountModalOpen}
        handleCancelScheduledAdAccountModalOpen={handleCancelScheduledAdAccountModalOpen}
        handlePreviewModalOpen={handlePreviewModalOpen}
        handleMoveToCreateAd={handleMoveToCreateAd}
        handleCancelRejectReasonModalOpen={handleCancelRejectReasonModalOpen}
        checkDeletableAdvertisement={checkDeletableAdvertisement}
        handleDeleteAdvertisement={handleDeleteAdvertisement}
        handleSearch={handleSearch}
        getSearchResult={getSearchResult}
        closeSearchPopup={closeSearchPopup}
        handleSearchAdClick={handleSearchAdClick}
        refreshAdAccountList={refreshAdAccountList}
        handleMoveToModifyAd={handleMoveToModifyAd}
        handleChangeSortOption={handleChangeSortOption}
        handleMoveToResultReport={handleMoveToResultReport}
        handleMoveToCostReport={handleMoveToCostReport}
        handleMobileMenuOpen={handleMobileMenuOpen}
        handleClickDisabledLnbButton={handleClickDisabledLnbButton}
        handleToAdCreate={handleToAdCreate}
      />
    )
  }
}

export default connect(
  ({ user, adList, loadingBar, projectMenu }) => ({
    user,
    adList,
    loadingBar,
    projectMenu,
  }),
  {
    openPopup,
    setActivatedAdInfo,
    resetActivatedAdInfo,
    setMenuStatus,
  }
)(withRouter(AdListPage))
