import React, { useState, useCallback, FC } from 'react'
import type {
  KeywordListType,
  FunctionPopularKeyword,
  FunctionKeywordValidation,
  KeywordValidationParam,
  KeywordValidationReturnType,
} from '@wad/api'
import { KeywordTable } from '../KeywordTable'
import { usePopularKeyword, useValidationKeyword } from '../queries'
import { useUnavailableKeywordNotification } from '../hooks'
import styles from './PopularSelectKeyword.module.scss'

type KeywordSelectPopupType = {
  fetchGetKeywordValidation: FunctionKeywordValidation
  fetchGetPopularKeyword: FunctionPopularKeyword
  validationData: KeywordValidationParam
  onSelectKeyword: (keyword: string) => void
}

export const PopularSelectKeyword: FC<KeywordSelectPopupType> = ({
  fetchGetKeywordValidation,
  fetchGetPopularKeyword,
  validationData,
  onSelectKeyword,
}) => {
  const [selectKeyword, setSelectKeyword] = useState<string>('')
  const [unavailableList, setUnavailableList] = useState<Array<string>>([])
  const { isInitialLoading: isLoading, data: popularData } = usePopularKeyword({
    fetchGetPopularKeyword,
    enabled: true,
  })

  const { contextHolder, noti } = useUnavailableKeywordNotification()
  const doSettled = useCallback(
    (validateResult: KeywordValidationReturnType) => {
      if (selectKeyword === '') {
        return
      }
      if (validateResult?.result === 'success') {
        onSelectKeyword(selectKeyword)
      } else {
        setUnavailableList([...unavailableList, selectKeyword])
        noti()
      }
      setSelectKeyword('')
    },
    [noti, onSelectKeyword, selectKeyword, unavailableList]
  ) as (validateResult?: KeywordValidationReturnType, error?: unknown) => void
  const { isInitialLoading: isValidateIn } = useValidationKeyword({
    fetchGetKeywordValidation,
    validationData: { ...validationData, keyword: selectKeyword } as KeywordValidationParam,
    enabled: selectKeyword !== '',
    onSettled: doSettled,
  })
  const data: KeywordListType = popularData || ({ count: 0, list: [] } as KeywordListType)
  return (
    <>
      <div className={styles.fieldSpace} />
      <KeywordTable
        isLoading={isLoading}
        isHold={isValidateIn}
        data={data}
        onSelectKeyword={setSelectKeyword}
        unavailableList={unavailableList}
      />
      {contextHolder}
    </>
  )
}
