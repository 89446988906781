import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.scss'
import Root from './Root'
import * as serviceWorker from './serviceWorker'
// import * as Sentry from '@sentry/browser'
// import { Integrations } from '@sentry/tracing'

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://56fb5c2ba0b44f39b3f7fc5557b33744@o194600.ingest.sentry.io/6078650',
//     integrations: [new Integrations.BrowserTracing()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//     whitelistUrls: [/^https:\/\/(.+)\.wadiz\.kr/i],
//   })
// }

const root = createRoot(document.getElementById('root'))
root.render(<Root />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
