import { useQuery, UseQueryResult, useQueryClient } from '@tanstack/react-query'
import { fetchPubAreaList, PubAreaItem, PubAreaParams } from '@center/api'
import keys from './keys'

interface PackageAccount extends PubAreaItem {
  // 삭제되야함 이 인터페이스는
  subArea?: string[]
  subImgUrl?: string[]
  packagedIds?: number[]
}

const usePubAreaList = (params: PubAreaParams): UseQueryResult<PubAreaItem[]> => {
  return useQuery(keys.accountList, () => fetchPubAreaList(params), {
    staleTime: Infinity,
    select: (data) => {
      let { result } = data
      result = result.map((pubArea) => {
        if (!pubArea.isPackage) return pubArea
        return {
          ...pubArea,
          areas: pubArea.areas.sort((a, b) => {
            return Number(b.main) - Number(a.main) // 메인 영역인 값을 index 0으로 만들기
          }),
          subName: `(${pubArea.areas.map((i) => i.area).join(', ')})`,
        }
      })
      return result
    },
  })
}

const useCachedAdAccountList = () => {
  const queryClient = useQueryClient()
  return queryClient.getQueryData<PackageAccount[]>(keys.accountList)
}

export { usePubAreaList, PackageAccount, useCachedAdAccountList }
