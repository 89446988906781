import React from 'react'

// Components
import Tooltip from '../../Tooltip'

// CSS module
import className from 'classnames/bind'
import style from '../SettingContent.module.scss'
import Lnb from '../../Lnb'
import NavBar from '../../NavBar'
import LoadingBar from '../../LoadingBar/LoadingBar'
const cx = className.bind(style)

const navList = [
  { name: '설정', link: '/main/ad/settings/' },
  { name: '간편 결제 관리', link: '/main/ad/settings/simpleAccount' },
]

const SimpleAccount = ({
  openGuide,
  simplePaymentCardArray,
  loadingBarFlag,
  onChangeOpenGuideType,
  handleRegisterSimplePaymentModalOpen,
  handleDeleteSimplePaymentModalOpen,
  handleDeleteAllSimplePaymentModalOpen,
}) => (
  <main className={cx('main_container', 'settings')}>
    <Lnb type="settings" />
    <div className={cx('content_wrap')}>
      <NavBar list={navList} />
      {loadingBarFlag ? (
        <LoadingBar />
      ) : (
        <>
          {/* 컨텐츠 시작 */}
          <div className={cx('setting_simpleAccount')}>
            <hgroup>
              <h2>간편 결제 관리</h2>
              <p>
                회원님의 간편결제 현황입니다.<br></br>
                간편결제를 사용하려면 아래에서 간편결제 신용카드를 등록하세요<br></br>
                간편결제를 사용하지 않으려면, 신용카드 정보를 모두 삭제하세요.<br></br>
                간편결제 관련 문의 사항은{' '}
                <a
                  href={process.env.REACT_APP_WADIZ_HELP_CENTER}
                  target="_blank"
                  className={cx('point_link_blue')}
                  rel="noreferrer"
                >
                  광고 문의하기
                </a>{' '}
                로 문의 부탁드립니다.
              </p>
            </hgroup>
            <section>
              <div
                className={cx('guide_wrap', { active: openGuide.type === 'creditcard' })}
                onClick={() => onChangeOpenGuideType(0, 'creditcard')}
              >
                <h3>신용카드 관리</h3>
                <Tooltip
                  title="신용카드 관리"
                  text={
                    '간편계좌로 등록하면 추후 광고 등록시에 결제가 편리하며 아래에서 간편 계좌를 추가/삭제할 수 있습니다.'
                  }
                />
              </div>
              <ul className={cx('manageCard')}>
                <li className={cx('manageCard_regist')} onClick={() => handleRegisterSimplePaymentModalOpen()}>
                  <button type="button" className={cx('wz button less')}>
                    <i className={cx('icon add')}></i>
                  </button>
                </li>
                {simplePaymentCardArray.length > 0 && (
                  <>
                    {simplePaymentCardArray.map((val, idx) => (
                      <li key={idx} className={cx('manageCard_registed')}>
                        <small>{val.cardName}</small>
                        **** - **** - **** - {val.cardNumber}
                        <button
                          type="button"
                          className={cx('wz button less')}
                          onClick={() => handleDeleteSimplePaymentModalOpen(val.id, val.cardNumber)}
                        >
                          <i className={cx('icon delete-o')}></i>
                          <span>삭제하기</span>
                        </button>
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </section>
            <div className={cx('buttonArea')}>
              <button
                type="button"
                className={cx('wz button gray', 'button_320')}
                onClick={() => handleDeleteAllSimplePaymentModalOpen()}
              >
                카드정보 모두 삭제
              </button>
            </div>
            {/* 컨텐츠 끝 */}
          </div>
        </>
      )}
    </div>
  </main>
)

export default SimpleAccount
