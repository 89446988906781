import React, { PureComponent } from 'react';

// Plugin
import { connect } from 'react-redux';
import AdAccount from './AdAccount/AdAccount';
import AdDate from './AdDate';
import ReportFavorit from './ReportFavoritAlert';
import ReportCostDetail from './ReportCostDetail';
import CopyDetailDialog from './CopyDetailDialog';
import AlertDialog from './AlertDialog';
import ConfirmDialog from './ConfirmDialog';
import TaxBillDetailDialog from './TaxBillDetailDialog';
import SimplePaymentRegisterDialog from './SimplePaymentRegisterDialog';
import CreditCardDetailDialog from './CreditCardDetailDialog';
import CreditCardDetailDialog2 from './CreditCardDetailDialog2';
import AdAccountConfirmDialog from './AdAccountConfirmDialog';
import PreviewDialog from './PreviewDialog';
import { WaffleInfoModal } from './WaffleInfoModal';

class RootDialog extends PureComponent {
  render() {
    const { dialogType } = this.props.dialog;

    let targetDialog = null;
    if (dialogType === 'adAccount') {
      targetDialog = <AdAccount />;
    } else if (dialogType === 'adDate') {
      targetDialog = <AdDate />;
    } else if (dialogType === 'reportFavorit') {
      targetDialog = <ReportFavorit />;
    } else if (dialogType === 'reportCostDetail') {
      targetDialog = <ReportCostDetail />;
    } else if (dialogType === 'copyDetailDialog') {
      targetDialog = <CopyDetailDialog />;
    } else if (dialogType === 'confirmDialog') {
      targetDialog = <ConfirmDialog />;
    } else if (dialogType === 'taxBillDetailDialog') {
      targetDialog = <TaxBillDetailDialog />;
    } else if (dialogType === 'simplePaymentRegisterDialog') {
      targetDialog = <SimplePaymentRegisterDialog />;
    } else if (dialogType === 'creditCardDetailDialog') {
      targetDialog = <CreditCardDetailDialog />;
    } else if (dialogType === 'creditCardDetailDialog2') {
      targetDialog = <CreditCardDetailDialog2 />;
    } else if (dialogType === 'adAccountConfirmDialog') {
      targetDialog = <AdAccountConfirmDialog />;
    } else if (dialogType === 'previewDialog') {
      targetDialog = <PreviewDialog />;
    }
    return (
      <>
        {targetDialog}
        <AlertDialog />
        <WaffleInfoModal />
      </>
    );
  }
}

export default connect(({ dialog, alert }) => ({ dialog, alert }), null)(RootDialog);
