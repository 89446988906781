import { useCallback } from 'react'
import { Button } from 'antd'

interface AdGuideProps {
  url: string
}

export const AdGuide: React.FC<AdGuideProps> = ({ url }) => {
  const doClick = useCallback(() => {
    window.open(url)
  }, [url])

  return (
    <div>
      <Button onClick={doClick} disabled={!url}>
        <i className={'icon download'}></i>
        영역별 광고 소재 제작 가이드 확인하기
      </Button>
    </div>
  )
}
