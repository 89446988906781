import { useQueries } from '@tanstack/react-query'
import { fetchAccountDetailList, type PubAreaItemArea } from '@center/api'
import keys from './keys'

const adAccountQuery = (pubAreaId: number, isWadizAdmin: boolean) => {
  const query = {
    queryKey: keys.accountDetailList(pubAreaId),
    queryFn: () => fetchAccountDetailList({ isWadizAdmin, pubAreaId }),
    staleTime: Infinity,
    enabled: Boolean(pubAreaId),
  }
  return query
}

const useAdAccountDetailList = (areas: PubAreaItemArea[], isWadizAdmin: boolean) => {
  const queries = areas.map((i) => adAccountQuery(i.id, isWadizAdmin))
  const data = useQueries({
    queries,
  })
  // accountNum이 0 인걸 보여줘야함
  return data.map((i) => i.data?.result.find((account) => account.accountNum === 0))
}

export { useAdAccountDetailList }
