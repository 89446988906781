// eslint-disable-next-line
import React, { FC } from 'react'
import styles from './FeedbackSection.module.scss'
const FeedbackContainer: FC = ({ children }) => <div className={styles.container}>{children}</div>

type PropsFeedback = {
  feedback: string
}
export const FeedbackSection: FC<PropsFeedback> = ({ feedback }) => (
  <FeedbackContainer>
    <div className={styles.notation}>
      <i className={'icon error-o'}></i> 피드백을 확인하세요.
    </div>
    {feedback !== '' && <pre className={styles.info}>* {feedback} </pre>}
  </FeedbackContainer>
)
