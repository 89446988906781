import storeObj from './store/configureStore'
import { setError } from './reducers/errorReducer'
import Cookies from 'universal-cookie'
// import * as Sentry from '@sentry/browser'

export const network = (() => {
  const callApi = ({ url, method, header, body, successFunc, failFunc, isFormData }) => {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
    }

    if (header) {
      const headerKeys = Object.keys(header)
      for (let i = 0; i < headerKeys.length; i += 1) {
        headers[headerKeys[i]] = header[headerKeys[i]]
      }
    }

    const cookies = new Cookies()
    headers.token = cookies.get('token')

    if (isFormData) delete headers['Content-Type']

    const reqOpt = {
      method,
      headers,
    }

    if (body) {
      reqOpt.body = isFormData ? body : JSON.stringify(body)
    }
    fetch(encodeURI(`${process.env.REACT_APP_WADIZ_API_HOST}api/v1/ad/${url}`), reqOpt)
      .then((response) => response.json())
      .then((jsonData) => {
        const { status } = jsonData
        if (status === 401) {
          if (window.location.pathname !== '/login') {
            window.location.assign('/login')
          }
          // Sentry.captureException(jsonData);
          failFunc()
        } else if (status === 404 || status === 500) {
          const { store } = storeObj
          // Sentry.captureException(jsonData);
          store.dispatch(setError(true))
        } else {
          if (jsonData?.error?.code === 401) {
            // Sentry.captureException('로그인 에러');
          }
          successFunc(jsonData)
        }
      })
      .catch((error) => {
        const { store } = storeObj
        store.dispatch(setError(true))
        failFunc(error)
      })
  }
  return {
    callApi,
  }
})()
