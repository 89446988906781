import React from 'react'
export const Terms = () => (
  <article>
    <h2 id="제1조-목적-">제1조 (목적)</h2>
    <p>
      본 광고 서비스 이용약관(이하 “본 약관”이라 합니다)은 리워드형 크라우드펀딩 프로젝트 또는 온라인소액투자중개
      프로젝트의 개설자와 스토어 서비스를 통하여 상품을 등록하고 판매하는 자(이하 “메이커”라 합니다)가 본 서비스를
      제공하는 와디즈 주식회사(이하 “회사”)가 운영 및 제공하는 인터넷 사이트(
      <a href="https://www.wadiz.kr" target="_blank" without rel="noopener noreferrer">
        https://www.wadiz.kr
      </a>
      , 이하 “사이트”라 합니다), 모바일 애플리케이션 또는 모바일 웹에서 광고/마케팅 상품을 매수하여 이를 시행함에 있어
      회사와 메이커 간의 권리, 의무 등 필요한 제반 사항을 규정함을 목적으로 합니다.
    </p>
    <h2 id="제2조-정의-">제2조 (정의)</h2>
    <p style={{ marginBottom: '12px' }}>
      본 약관에서 사용하는 용어의 정의는 다음과 같습니다. 본 약관에서 정의되지 않은 용어는 일반 거래 관행에 따라 정의된
      의미를 가집니다.
    </p>
    <ol style={{ marginLeft: '1.4em' }}>
      <li>
        “광고 서비스”란 메이커가 사이트에 개설한 프로젝트의 활성화를 도모할 수 있도록 회사가 제공하는 각종 마케팅
        서비스를 말하며, 메이커는 회사가 운영하는 광고 센터 및 기타 회사에서 정한 방법에 따라 선택적으로 광고 서비스를
        신청할 수 있습니다.
      </li>
      <li>
        “광고 영역” 이란 메이커가 회사의 승인을 받아 사이트 또는 앱(푸쉬 포함)을 통해 프로젝트 광고물을 게재하는 영역을
        의미하며, 개별 광고 영역은 광고 구좌로 구성됩니다.
      </li>
      <li>
        “광고물”이란 회사가 운영하는 광고 서비스를 이용하기 위하여 메이커가 회사에 제공하는 이미지, 텍스트, 동영상,
        오디오 등 다양한 형태의 광고 소재를 의미합니다.
      </li>
      <li>“광고 상품”이란 회사가 광고 구좌별로 제공하는 광고 서비스 단위를 의미합니다.</li>
      <li>“광고 센터”란 광고물을 사이트 이용자에게 마케팅하기 위해 구축된 회사의 소프트웨어 시스템을 의미합니다.</li>
      <li>
        “광고가이드”란 본 약관에 따른 광고물의 등록, 검수, 게재, 제한 등과 관련된 운영 정책, 광고소재, 광고비 단가 등과
        관련된 내용을 규정한 이용가이드, 제작가이드, 심의가이드, 집행, 단가표, 위약금 규정 등으로서 본 약관의 일부를
        구성합니다.
      </li>
      <li>“광고 대금”이란 본 계약에 따른 광고 집행에 대한 대가로 메이커가 회사에게 지불하는 금원을 의미합니다.</li>
    </ol>
    <h2 id="제3조-약관의효력-">제3조 (약관의 효력)</h2>
    <ol style={{ marginLeft: '1.4em' }}>
      <li>
        회사는 본 약관에 규정되지 않은 세부적인 내용에 대해 회사가 수시로 개정할 수 있는 광고가이드 및 개별 정책 등(이하
        "정책"이라 합니다)을 제정하여 운영할 수 있으며, 해당 내용을 사이트를 통하여 게시합니다. 정책은 이 약관과 더불어
        광고 서비스 이용계약(이하 "이용계약"이라 합니다)의 일부를 구성합니다. 메이커가 본 약관 및 정책에 동의하는 것은
        양 당사자의 인감을 날인하여 이용계약을 체결하는 것과 동일한 효력을 가지며, 동의 시점부터 이용계약의 효력이
        발생합니다.
      </li>
      <li>
        회사는 광고 서비스 중 특정 사항에 관한 약관(이하 "개별약관"이라 합니다)을 별도로 제정할 수 있으며, 메이커가
        개별약관에 동의한 경우 개별약관은 이용계약의 일부를 구성하고 개별약관에 이 약관과 상충하는 내용이 있을 경우 개별
        약관이 우선적으로 적용됩니다.
      </li>
      <li>
        메이커가 본 약관에 동의한 후 메이커의 계정을 통하여 광고센터에서 이루어지는 행위는 모두 메이커의 행위로
        인정됩니다.
      </li>
    </ol>

    <h2 id="제4조-약관의게시및개정-">제4조 (약관의 게시 및 개정)</h2>
    <ol style={{ marginLeft: '1.4em' }}>
      <li>회사는 이 약관의 내용을 메이커가 쉽게 알 수 있도록 사이트 또는 광고센터를 통하여 게시합니다.</li>
      <li>
        회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 이 약관 및 정책을 개정할 수 있으며, 이 경우 개정내용과
        적용일자를 명시하여 사이트를 통해 그 적용일자 7일 전부터 적용일자 전일까지 게시합니다. 다만, 변경 내용이
        메이커에게 불리한 변경의 경우에는 개정약관 및 정책의 적용일자 30일 전부터 적용일자까지 게시합니다.
      </li>
      <li>
        메이커가 개정약관 및 정책에 동의하지 않는 경우에는 개정 약관 및 정책의 적용일 이전에 거부 의사를 표시하고 본
        약관 및 정책에 의한 이용계약을 해지할 수 있습니다.
      </li>
      <li>
        회사가 본 조 제2항에 따라 개정약관 및 정책을 게시 또는 통지하면서 메이커에게 적용일 전까지 의사표시를 하지
        않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 메이커가 명시적으로 거부의사를
        표명하지 아니한 경우 개정약관 및 정책에 동의한 것으로 봅니다.
      </li>
    </ol>

    <h2 id="제5조-이용계약의성립-">제5조 (이용계약의 성립)</h2>
    <ol style={{ marginLeft: '1.4em' }}>
      <li>
        이용계약은 서비스를 이용하고자 하는 자(이하 “이용신청자”라 함)가 본 약관 및 정책에 동의하고 회사가 정한 절차에
        따라 광고 게재를 신청하며, 이에 대해 회사가 심사를 거쳐 승낙함으로써 성립합니다. 회사는 이용승낙의 의사표시를
        광고센터의 해당 서비스 화면에 게시하거나 email 또는 기타 방법으로 할 수 있습니다.
      </li>
      <li>
        이용신청자는 회사가 별도로 요청하는 증빙서류가 있는 경우 신속히 제출하여야 하며, 회사는 해당 서류를 징구하는
        시점까지 신청에 대한 승낙을 보류하거나 거부할 수 있습니다.
      </li>
      <li>
        회사는 제1항에 따라 광고 게재를 신청한 자 중에서 아래 각호에 해당되는 경우에는 승인을 거부할 수 있으며, 등록이
        된 이후에도 아래 각호의 사유가 확인된 경우에는 승낙을 취소할 수 있습니다.
        <ol style={{ margin: '6px 0px', listStyleType: 'lower-alpha' }}>
          <li>회사의 다른 서비스와 관련하여 이전에 회원 자격을 상실한 적이 있는 경우</li>
          <li>타인의 정보 등(예)주민등록번호, 사업자번호, I-PIN 등)를 이용한 경우</li>
          <li>허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우</li>
          <li>
            회사로부터 자격 정지 조치 등을 받은 메이커가 그 조치기간 중에 이용계약을 임의 해지하고 재이용신청을 하는
            경우
          </li>
          <li>기술적 지장 등으로 이용신청에 대한 승낙이 어려운 경우</li>
          <li>
            등록을 신청한 자의 귀책사유로 인하여 승인이 불가능하거나 필수 서류 또는 회사가 요청하는 서류를 제출하지 않는
            등 기타 제반 사항을 위반하여 신청하는 경우
          </li>
          <li>
            기타 본 약관에 위배되거나 위법 또는 부당한 이용신청 등 회사가 합리적인 판단에 의하여 필요하다고 인정하는
            경우
          </li>
          <li>
            메이커는 광고 게재 신청시에 기입한 정보와 관련하여 연락처 등의 변경이 있을 경우 즉시 회사에 이를 통지하여야
            하며, 회사는 통지의 지연으로 인하여 발생한 손해에 대하여 책임을 지지 않습니다.
          </li>
        </ol>
      </li>
    </ol>

    <h2 id="제6조-메이커의권리의무-">제6조 (메이커의 권리/의무)</h2>
    <ol style={{ marginLeft: '1.4em' }}>
      <li>
        메이커는 관련 법령, 본 약관, 정책, 가이드라인 및 "회사"가 안내 및 공지하거나 통지한 사항 등을 준수하여야 하며,
        기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
      </li>
      <li>
        메이커는 회사가 광고 센터 또는 사이트 내 공지사항을 통해 게시 및 안내하는 정책 및 공지사항, 광고가이드를
        확인하고 이를 숙지하여야 합니다.
      </li>
      <li>
        메이커는 회사와 협의를 거쳐 메이커가 광고를 진행하고자 하는 프로젝트에 대한 광고 일정, 방식, 규모 등 전반적인
        광고 집행 계획을 수립하여야 합니다.
      </li>
      <li>메이커는 광고의 종류와 광고 대금을 정하여 회사가 정한 절차에 따라 광고 게재를 신청하여야 합니다.</li>
      <li>
        메이커는 회사에서 진행하는 광고 내용이 회사와 협의된 내용과 다를 경우, 회사에 시정을 요청할 수 있습니다. 이
        경우, 회사는 즉시 메이커의 요청 사항을 반영하여 광고 내용을 정정하되, 즉시 정정이 어려운 경우 메이커와 협의를
        통해 가능한 조치를 취하여야 합니다.
      </li>
      <li>
        메이커는 원활한 광고 집행을 위해 회사와 사전 합의된 일정에 따라 카드 결제 또는 회사가 별도 고지하는 계좌로
        이체하는 방식으로 광고 대금을 지급하여야 합니다.
      </li>
    </ol>

    <h2 id="제7조-회사의권리의무-">제7조 (회사의 권리/의무)</h2>
    <ol style={{ marginLeft: '1.4em' }}>
      <li>
        회사는 관련법령과 본 약관이 금지하는 행위를 하지 않으며, 본 약관에 따라 안정적으로 광고상품을 제공하는데 최선을
        다하여야 합니다.
      </li>
      <li>
        회사는 광고상품의 종류 및 세부유형, 정책, 가이드라인 등의 내용을 회사의 사이트 또는 광고센터에 공지하여야
        합니다.
      </li>
      <li>
        회사는 메이커가 광고물을 제작, 전달할 수 있도록 제작가이드를 제공하고, 사전 준비가 필요한 각 준비사항을 안내해야
        합니다.
      </li>
      <li>
        회사는 효과적으로 차질없이 광고를 진행하도록 하기 위해 광고 센터에서 메이커의 광고 운영, 시스템 제공 및
        모니터링을 진행하여야 합니다.
      </li>
      <li>
        메이커가 본 약관 제6조 제6항에 따라 시정을 요청한 경우, 회사는 즉시 메이커의 요청 사항을 반영하여 광고 내용을
        정정하되, 즉시 정정이 어려운 경우 메이커와 협의를 통해 가능한 조치를 취하여야 합니다.
      </li>
      <li>
        회사는 광고 집행을 한 후 서비스 모니터링 및 광고 결과 확인을 위한 보고자료를 작성하고, 광고 종료 후 5 영업일
        이내에 메이커에게 광고 결과에 대한 사항을 제공하여야 합니다.
      </li>
      <li>
        회사는 메이커로부터 지급 받아야 할 광고 대금 채권과, 타 계약(계약의 명칭 및 종류를 불문한다)에 따른 메이커의
        회사에 대한 채권을 대등액에서 상계할 수 있습니다. 단, 상계 방식 이외의 결제 방식으로 정산이 필요한 경우에는 상호
        서면 합의 하에 결제 방식을 결정할 수 있습니다.
      </li>
    </ol>

    <h2 id="제8조-광고대금-">제8조 (광고대금)</h2>
    <ol style={{ marginLeft: '1.4em' }}>
      <li>메이커는 광고 서비스를 제공받는 대가로 별도 고지되는 광고 대금을 회사에게 지급하여야 합니다.</li>
      <li>
        메이커는 원활한 광고 집행을 위해 회사와 사전 합의된 일정에 따라 카드 결제 또는 회사가 별도 고지하는 계좌로
        이체하는 방식으로 광고 대금을 지급하여야 합니다.
      </li>
    </ol>

    <h2 id="제9조-광고물의제작-">제9조 (광고물의 제작)</h2>
    <ol style={{ marginLeft: '1.4em' }}>
      <li>
        회사와 메이커는 광고 상품에 활용할 광고물을 별도로 제작할 경우, 상호 협의하여 해당 광고물을 제작하는 당사자를
        정할 수 있습니다.
      </li>
      <li>
        메이커가 제1항의 광고물을 제작하는 경우, 해당 광고물이 지식재산권 등 제3자의 권리를 침해하지 않고 범죄행위를
        구성하지 않으며 표시·광고의 공정화에 관한 법률, 청소년보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등
        관련법령을 위반하지 않도록 하여야 하며, 회사의 사이트, 광고센터에 공지된 회사의 광고 가이드라인을 준수하여야
        합니다.
      </li>
      <li>
        메이커가 제1항의 광고물을 제작하는 경우, 회사에 광고물을 제출하여 회사가 해당 광고물을 검수할 수 있도록 하여야
        합니다.
      </li>
      <li>
        회사는 제3항에 따라 광고물을 검수하는 과정에서 수정 및 보완이 필요한 사항이 있으면 이를 즉시 메이커에게
        통지하고, 메이커는 회사와 협의를 통해 검수의견을 반영하여 수정 및 보완하여야 합니다.
      </li>
      <li>회사와 메이커는 제1항 내지 제3항의 과정을 거쳐 최종 광고물을 확정합니다.</li>
      <li>
        회사는 본 조에 따른 광고물을 확정 후, 이를 변경할 필요가 있을 때에는 메이커에게 사전 통지 후 변경할 수 있다.
      </li>
    </ol>

    <h2 id="제10조-할인코드-">제10조 (할인코드)</h2>
    <ol style={{ marginLeft: '1.4em' }}>
      <li>
        회사는 관련법령과 본 약관이 금지하는 행위를 하지 않으며, 본 약관에 따라 안정적으로 광고상품을 제공하는데 최선을
        다하여야 합니다.
      </li>
      <li>
        회사는 광고 서비스를 이용하는 메이커에게 광고 서비스 신청 시 일정비율을 할인 받을 수 있는 할인코드를 발급할 수
        있으며, 할인코드별 유효기간, 할인금액, 사용 조건 등은 개별 할인 코드마다 달리하여 정할 수 있습니다.
      </li>
      <li>
        할인 코드는 메이커 본인의 광고 서비스 이용에만 사용할 수 있으며, 어떠한 경우에도 타인에게 매매하거나 양도할 수
        없습니다.
      </li>
      <li>
        메이커가 할인 코드의 적용을 위해 필요한 정보의 입력과정에서 발생한 오류에 따른 모든 불이익에 대해서 회사는
        책임을 지지 않습니다.
      </li>
      <li>
        할인 코드는 일부 품목이나 금액에 따라 사용이 제한될 수 있으며 유효기간이 지난 후에는 사용할 수 없고, 광고 대금
        결제 이후에는 광고 대금 결제가 취소되지 않는 한 재사용할 수 없으며, 할인금액은 결제가 진행될 때 이용료에서 즉시
        차감됩니다.
      </li>
      <li>
        메이커에게 제공된 할인 코드는 광고 서비스 이용을 위한 적용 시에 중복으로 사용할 수 없습니다. 단, 쿠폰의 종류와
        내용에 따라 회사, 발행자, 메이커의 중복사용 허용 등 사용범위를 달리 정한 경우에는 그러하지 않습니다.
      </li>
      <li>할인 코드에 표시된 유효기간이 만료되거나 이용계약이 종료되면 할인 코드는 소멸합니다.</li>
      <li>
        회사는 메이커가 부정한 목적과 방법으로 할인 코드 등을 획득하거나 사용하는 사실이 확인될 경우, 해당 메이커에 대한
        할인 코드를 회수 또는 소멸시키거나 이용계약을 해지할 수 있습니다.
      </li>
    </ol>
    <h2 id="제11조-게재의제한-">제11조 (게재의 제한)</h2>
    <ol style={{ marginLeft: '1.4em' }}>
      <li>
        아래 각 호의 사유가 발생하는 경우 회사는 메이커에 대한 사전 통지를 거쳐 광고물의 게재를 거부하거나 기 게재되고
        있는 광고의 집행을 정지 또는 중단할 수 있습니다. 단, 회사는 부득이한 경우 사후에 통지할 수 있습니다.
        <ol style={{ margin: '6px 0px', listStyleType: 'lower-alpha' }}>
          <li>메이커가 위탁하는 광고가 이용계약 또는 회사의 광고가이드에 반하는 경우</li>
          <li>
            메이커가 위탁하는 광고가 관련 법령을 위반하여 메이커 또는 회사가 행정기관 기타 정부기관으로부터 경고, 고발
            등의 조치를 당하거나 당할 위험이 있는 경우
          </li>
          <li>
            광고물의 내용이 제3자의 권리를 침해하여 메이커 또는 회사와 제3자 사이에 분쟁이 발생하거나 발생할 위험이 있는
            경우
          </li>
          <li>메이커가 진행하고 있거나 진행할 예정인 프로젝트가 중단 또는 취소된 경우</li>
          <li>회사가 광고물의 검수 후 광고물의 수정을 요청하였음에도 메이커가 수정하지 않은 경우</li>
          <li>광고상품 서비스용 설비의 점검, 보수 또는 공사 등으로 광고상품 서비스의 원활한 제공이 어려운 경우</li>
          <li>전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지했을 경우</li>
          <li>
            국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 광고상품 서비스 이용에 지장이 있는 경우
          </li>
          <li>기타 중대한 사유로 인하여 회사가 광고상품 서비스를 제공하기 어려운 경우</li>
        </ol>
      </li>
      <li>
        회사는 고의 또는 과실이 없는 한 제1항에 따른 광고상품 서비스 이용제한 내지 중지로 인해 발생한 이용자의 손해에
        대하여 책임을 부담하지 아니합니다.
      </li>
    </ol>
    <h2 id="제12조-비밀유지-">제12조 (비밀유지)</h2>
    <ol style={{ marginLeft: '1.4em' }}>
      <li>
        회사와 메이커는 이용계약의 체결 및 이행으로 취득한 상대방의 경영정보, 영업비밀, 고객정보 등 제반 비밀사항에
        대해서는 거래기간 중 및 거래 종료 이후에도 그 사항을 상대방의 사전 서면 승인 없이는 제3자에게 제공, 공개,
        누설하거나 본 약관상의 목적 이외의 다른 목적으로 이용하여서는 안됩니다.
      </li>
      <li>전항의 의무는 이용계약의 종료 후에도 존속합니다.</li>
    </ol>
    <h2 id="제13조-이용계약의해지-">제13조 (이용계약의 해지)</h2>
    <ol style={{ marginLeft: '1.4em' }}>
      <li>
        메이커의 광고물에 대한 심사가 반려되는 등의 사유로 이용계약을 체결한 날로부터 180일이 되는 날까지 광고집행이
        이루어지지 아니할 경우 이용계약이 해지된 것으로 봅니다.
      </li>
      <li>
        메이커와 회사는 다음 각 호 중 하나의 상황이 발생한 경우에는 상대방에게 서면으로 통지하여 본 계약을 해지할 수
        있습니다.
        <ol style={{ margin: '6px 0px', listStyleType: 'lower-alpha' }}>
          <li>계약당사자 또는 사실상 경영권을 행사하는 자가 금융기관으로부터 부도처분 기타 거래 정지를 당한 경우</li>
          <li>
            영업의 폐지, 변경(영업변경의 경우 이 계약에 중대한 부정적인 영향을 미치는 변경) 또는 해산의 결의 등을 하였을
            때
          </li>
          <li>회생절차개시의 신청, 파산신청 등이 있는 경우</li>
          <li>
            압류, 가압류, 가처분, 경매신청, 조세처분 등 강제집행을 받아 정상적인 영업이 어려워지거나 특히 일방의
            상대방에 대한 채권이 압류, 가압류되는 등 강제집행을 받는 경우
          </li>
          <li>제10조에 따른 게재제한 사유가 발생하여 회사가 시정을 요구하였음에도 시정되지 않은 경우</li>
          <li>제11조에 따른 비밀유지의무를 위반한 경우</li>
          <li>
            본 계약상 의무사항을 위반하고 이의 시정요구일로부터 10일 이내에 이러한 위반 사항이 치유되지 아니한 경우
          </li>
        </ol>
      </li>
      <li>
        메이커가 본 약관에 의한 이용계약을 해지하고자 하는 경우, 해지사유를 기재한 서면(전자우편 또는 광고센터를 통한
        신청을 포함)으로 회원 탈퇴를 요청하여야 합니다. 만약 현재 진행 중인 거래, 문의, 또는 민원이 있는 경우에는 탈퇴
        신청이 불가능하며, 메이커가 해당 사항을 처리 완료한 후 본 약관 및 정책에서 정하는 바에 따라 탈퇴 및 이용계약
        해지가 가능합니다.
      </li>
      <li>
        본 조에 의한 이용계약의 해지는 기발생한 양 당사자의 권리관계 및 손해배상 청구권에 영향을 미치지 아니합니다.
      </li>
    </ol>
    <h2 id="제14조-손해배상-">제14조 (손해배상)</h2>
    <ol style={{ marginLeft: '1.4em' }}>
      <li>
        회사 또는 메이커의 명백한 귀책 사유로 본 약관을 위반하여 상대방 또는 다른 회원을 포함한 제3자에게 손해를
        발생하게 한 때에는 귀책 당사자는 이를 배상할 책임을 부담합니다.
      </li>
      <li>
        본 약관 및 정책에 따른 메이커의 의무와 관련하여 회사가 회사의 귀책 없이 제3자로부터 손해배상청구 등 소송 또는
        기타 방법으로 이의제기를 받는 경우, 회사는 위 사실을 메이커에게 통지할 수 있고 메이커는 회사를 방어할 수 있는
        모든 자료 및 정보를 제공함으로써 문제의 해결에 적극 협조함으로써 면책시켜야 하며, 이로 인해 회사에 발생한 손해를
        배상하여야 합니다.
      </li>
      <li>
        회사의 고의 또는 과실로 인한 광고의 중단 및 불충분한 집행으로 인하여 메이커에게 손해가 발생할 경우, 메이커는
        정책에 따라 회사에게 손해배상을 청구하거나, 손해배상 청구에 갈음하여 합리적인 조속한 시간 내에 유사한 위치에
        광고를 집행할 것을 청구할 수 있습니다.
      </li>
      <li>
        화재, 홍수, 전염병 등의 천재지변이나 사고 또는 정부기관의 조치 기타 사회통념상 부득이한 경우에 당사자가 최선의
        노력을 하였음에도 불구하고 본 약관상의 의무를 이행하지 못한 경우, 해당 당사자는 해당 사유로 인한 손해배상 기타
        책임을 부담하지 아니하나, 그러한 경우에도 당사자들은 본 약관의 목적 달성을 위한 방안을 수립하기 위해 최대한
        노력하여야 합니다.
      </li>
    </ol>
    <h2 id="제15조-지식재산권-">제15조 (지식재산권)</h2>
    <ol style={{ marginLeft: '1.4em' }}>
      <li>
        본 계약의 이행과 관련하여 메이커가 회사에게 제공한 광고물을 비롯한 자료 및 정보에 대한 소유권 및 저작권 등
        지식재산권은 메이커에게 귀속되며, 회사는 이를 이용계약의 목적에 한하여 사용할 수 있습니다.
      </li>
      <li>
        이용계약의 이행과 관련하여 회사가 작성하고 메이커에게 제공하는 통계자료를 비롯한 모든 자료 및 정보에 대한 소유권
        및 지식재산권은 회사에게 귀속됩니다.
      </li>
      <li>
        메이커와 회사는 상대방의 상표, 로고, 서비스 표, 이미지, 문자, 부호 등 모든 지식재산을 상대방의 이용계약에 명시된
        의무를 이행하는 데 필요한 범위를 넘어 상대방이 사전 서면 동의 없이 사용할 수 없습니다.
      </li>
    </ol>
    <h2 id="제16조-개인정보보호-">제16조 (개인정보 보호)</h2>
    <p>
      회사와 메이커는 개인정보보호법, 정보통신망 이용 촉진 및 정보보호등에 관한 법률을 비롯한 관계법령의 규정을
      준수하여야 합니다.
    </p>
    <h2 id="제17조-준거법등-">제17조 (준거법 등)</h2>
    <ol style={{ marginLeft: '1.4em' }}>
      <li>이용계약의 준거법은 대한민국 법률로 합니다.</li>
      <li>이용계약과 관련하여 회사와 메이커 간에 발생하는 모든 분쟁/소송은 민사소송법 상의 관할법원에서 해결합니다.</li>
    </ol>
    <h2 id="부칙">부칙</h2>
    <p>본 약관은 2021년 9월 28일부터 시행됩니다.</p>
  </article>
)
