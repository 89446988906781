import React from 'react';
import cx from 'classnames';

import styles from './withIcon.module.scss';

export type WithIconWrapperProps = {
  /** 접근성을 위한 아이콘 라벨 */
  label?: string;
  /** 양 옆으로 뒤집음 */
  flip?: boolean;
  /** 상/하로 뒤집음 */
  flipVertical?: boolean;
  /** Size of the icon. */
  size?: 'small' | 'large' | number | string;
  /** Color the icon using a CSS hexcode. */
  color?: string;
  /** Render as inline instead of block. */
  inline?: boolean;
  className?: string;
};

export type Props = {
  focusable: 'false';
  role: 'presentation' | 'img';
  className?: string;
  style?: React.CSSProperties;
};

export default function withIcon(
  name: string,
): (WrappedComponent: React.ComponentType<Props>) => React.ComponentType<WithIconWrapperProps> {
  return WrappedComponent => {
    function Icon({
      label,
      color,
      size,
      flip,
      flipVertical,
      inline,
      className,
    }: WithIconWrapperProps) {
      const props: Props & { 'aria-hidden'?: boolean; 'aria-label'?: string } = {
        focusable: 'false',
        role: label ? 'img' : 'presentation',
        className: cx(
          styles['icon'],
          {
            [styles['inline']]: inline,
            [styles['flip']]: flip,
            [styles['flipVertical']]: flipVertical,
          },
          className
        ),
        style: {
          width: size,
          height: size,
          color,
        },
      }

      if (label) {
        props['aria-label'] = label;
      } else {
        props['aria-hidden'] = true;
      }

      return <WrappedComponent {...props} />;
    }

    Icon.displayName = name;
    Icon.WrappedComponent = WrappedComponent;

    return Icon;
  };
}
