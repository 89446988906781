import React from 'react';
import Footer from '../../components/Footer';
import {
  KeyVisual,
  AdServiceSection,
  GuideSection,
  AskSection,
  FootVisual,
  DisplayAdSection,
  RecommandAdSection,
  TargetPushAdSection,
  AdProcessSection,
} from './components';
import classNames from 'classnames/bind';
import style from './IntroPage.module.scss';
const cx = classNames.bind(style);

const IntroPageContent = () => (
  <main id='main' className={cx('AdServiceSection')}>
    <KeyVisual />
    <AdServiceSection />
    <RecommandAdSection />
    <DisplayAdSection />
    <TargetPushAdSection />
    <AdProcessSection />
    <GuideSection/>
    <AskSection />
    <FootVisual />
  </main>
);

export const IntroPage = () => (
  <>
    <IntroPageContent />
    <Footer />
  </>
);
export default IntroPage;
