import React from 'react'
import { Link, NavLink } from 'react-router-dom-v5'

import className from 'classnames/bind'
import style from './Gnb.module.scss'
const cx = className.bind(style)

const Gnb = ({ onChangeMenuIsOpen, onClickLogout, currentPath, token, ...state }) => {
  const { isOpen, menuList } = state

  if (
    currentPath.indexOf('/main/intro') > -1 ||
    currentPath.indexOf('/login') > -1 ||
    currentPath === '/main' ||
    !token
  )
    return null

  return (
    <div className={cx('header_gnb')} style={{ width: isOpen && '100%' }}>
      <button className={cx('isMo', 'toggle_menu')} onClick={() => onChangeMenuIsOpen()}>
        <i className="icon menu"></i>
        <span>메뉴</span>
      </button>
      <nav className={cx('header_gnbList')} style={{ display: isOpen && 'block' }}>
        <button className={cx('isMo', 'btn_close')} onClick={() => onChangeMenuIsOpen()}>
          <i className="icon close"></i>
          <span>닫기</span>
        </button>
        <ul className={cx('header_gnbList_main')}>
          {menuList.map((val, idx) => (
            <li
              key={idx}
              className={cx({
                active:
                  window.location.pathname.indexOf(val.id) !== -1 ||
                  (val.link === '/main/ad/list' && window.location.pathname.indexOf('/main/ad/modify') >= 0),
              })}
            >
              <NavLink
                to={val.link}
                onClick={() => {
                  onChangeMenuIsOpen(val.id === 'list')
                }}
              >
                {val.name}
              </NavLink>
              <ul className={cx('header_gnbList_sub')}>
                {val?.children &&
                  val.children.map((val2, idx2) => (
                    <li key={idx2} className={cx({ active: window.location.pathname === val2.link && 'active' })}>
                      <NavLink to={`${val2.link}`} onClick={() => onChangeMenuIsOpen()}>
                        {val2.name}
                      </NavLink>
                    </li>
                  ))}
              </ul>
            </li>
          ))}
        </ul>
      </nav>
      <div className={cx('header_authMenu', 'isMo')} style={{ display: isOpen && 'block' }}>
        <a
          href="https://makercenter.wadiz.kr/board/basic/918?rownum=197&search_type=title_contents&search_text=&category1_idx=&category2_idx=&page=1"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => onChangeMenuIsOpen()}
        >
          <button className={cx('wz button circular dense primary')}>도움말</button>
        </a>
        {/* <Link to="/main/ad/user/help" onClick={() => onChangeMenuIsOpen()}><button className={cx('wz button circular dense primary')}>도움말</button></Link> */}
        <Link to="/main/ad/user/accountManage" onClick={() => onChangeMenuIsOpen()}>
          <button className={cx('wz button circular dense blue')}>계정정보</button>
        </Link>
        {/* <button type='button' className={cx('wz button dense circular')} onClick={onClickLogout}>로그아웃</button> */}
      </div>
    </div>
  )
}

export default Gnb
