import React, { useCallback } from 'react'
import { notification } from 'antd'
import { ErrorIcon } from '@wadiz-frontend/waffle-icons-modified'

type Params = {
  contextHolder: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  noti: (description?: string) => void
}
type FunctionType = () => Params
export const useUnavailableKeywordNotification: FunctionType = () => {
  const [api, contextHolder] = notification.useNotification()
  const noti = useCallback(
    (description?: string) => {
      notification.destroy()
      api.open({
        message: '확인해주세요',
        description: description || '해당 기간에 키워드는 이미 광고가 등록되었습니다.',
        icon: <ErrorIcon size={20} />,
      })
    },
    [api]
  )
  return {
    contextHolder,
    noti,
  }
}
