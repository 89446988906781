import type { ThemeConfig } from 'antd'
export const antdTheme: ThemeConfig = {
  token: {
    fontFamily: 'Pretendard',
    // colorBgElevated: '#000000',
    colorBorder: '#cdd3d8', // $color-gray-400
    colorPrimary: '#00c4c4', // $color-mint-600
    colorPrimaryActive: '#00b2b2', // $color-mint-700
    colorPrimaryHover: '#00b2b2', //$color-mint-700

    colorErrorHover: '#f66', // $color-danger-600

    controlHeight: 40,
    controlHeightLG: 48,
    borderRadius: 4,
  },
  components: {
    Breadcrumb: {
      lastItemColor: '#212529',
      itemColor: '#868E96',
      separatorColor: '#ADB5BD',
    },
    Table: {
      borderColor: '#F2F4F6',
      headerColor: '#212529', //$color-gray-900
      headerBg: '#F2F4F6', //Gray/G100
      headerSplitColor: undefined, //'#F2F4F6', //Gray/G300
      headerBorderRadius: 0,
      cellPaddingBlock: 8,
    },
    Carousel: {
      dotWidth: 4,
      dotHeight: 4,
      dotActiveWidth: 4,
    },
    Badge: {
      colorError: '#FF6666', // $color-danger-600
    },
    Menu: {
      horizontalItemHoverColor: '#FF0000',
      itemColor: '#495057',
      iconSize: 12,
      itemHoverBg: '#F2F4F6',
      itemSelectedBg: '#F2F4F6',
      colorPrimary: '#495057',
      itemMarginBlock: 0,
      subMenuItemBg: '#F9FAFB',
    },
  },
}
