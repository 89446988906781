import React from 'react';
import axios from 'axios';
import makeDefaultConfig from './axiosConfig';

export const makeFetch = (method, url, opt) => {
  const config = {
    ...makeDefaultConfig(),
    ...(opt || {}),
    method,
    url,
  };
  const instance = axios.create(config);

  instance.interceptors.request.use(
    requestConfig => requestConfig,
    error => Promise.reject(error),
  );
  instance.interceptors.response.use(response => {
    return response;
  });

  return instance.request();
};

const useFetch = (method, url, opt) => {
  const [data, setData] = React.useState({ result: [] });
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  React.useEffect(() => {
    makeFetch(method, url, opt)
      .then(response => {
        if (!response) {
          return Promise.reject(new Error('응답이 없습니다.'));
        }

        if (response instanceof Error) {
          return Promise.reject(response);
        }

        setData(response.data);
        return response;
      })
      .catch(err => {
        setHasError(true);
        setErrorMessage(err);
        return err;
      })
      .finally(() => {
        setIsLoading(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data, isLoading, hasError, errorMessage,
  };
};

export default useFetch;
