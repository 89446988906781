import React, { FC } from 'react'
import { RightOutlined } from '@ant-design/icons'
import styles from './Preview.module.scss'

type Props = {
  mainCopy: string
  imageUrl: string
}
export const Preview: FC<Props> = ({ mainCopy, imageUrl }) => (
  <section className={styles.Container}>
    <h6 className={styles.Title}>{mainCopy}</h6>
    <div className={styles.ThumbnailWrapper}>
      <img id="fileImg" src={imageUrl} className={styles.Thumbnail} alt="미리보기 이미지" />
      <div className={styles.badge}>
        바로가기
        <RightOutlined />
      </div>
    </div>
  </section>
)
