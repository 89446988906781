import React from 'react';

// Components
import Lnb from '../Lnb';
import NavBar from '../NavBar';

// CSS module
import style from './AccountManageContent.module.scss';
import className from 'classnames/bind';
const cx = className.bind(style);

const AccountManageContent = ({
  makerEmail,
  makerName,
  phoneNumber,
  navList,
}) => {
  return (
    <main className={cx('main_container', 'user')}>
      <Lnb type="user_account" />
      <div className={cx('account_manage', 'content_wrap')}>
        <NavBar list={navList} />
        {/* 컨텐츠 시작 */}
        <div className={cx('account_manage_info')}>
          <hgroup>
            <h2>계정정보</h2>
            <p>
              회원님의 개인정보입니다.<br></br>
              {/* 개인정보를 수정하기 위해서는 <a href="https://www.wadiz.kr/web/waccount/wAccountUpdateBasicInfoIntro" className={cx('point_link_blue')}>이곳</a>에서 수정하세요<br></br> */}
              개인정보 관련 문의 사항은{' '}
              <a
                href={process.env.REACT_APP_WADIZ_HELP_CENTER}
                target="_blank"
                className={cx('point_link_blue')}
                rel="noreferrer"
              >
                광고 문의하기
              </a>
              로 문의 부탁드립니다.
            </p>
          </hgroup>
          <h3>회원님의 개인정보</h3>
          <table className={cx('wz table', 'theadLeft')}>
            <caption>회원님의 개인정보</caption>
            <tbody>
              <tr>
                <th scope="row">아이디</th>
                <td>{makerEmail}</td>
              </tr>
              <tr>
                <th scope="row">회원이름</th>
                <td>{makerName}</td>
              </tr>
              <tr>
                <th scope="row">전화번호</th>
                <td>{phoneNumber}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* 컨텐츠 끝 */}
      </div>
    </main>
  )
};
export default AccountManageContent;
