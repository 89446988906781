import { Typography } from 'antd'
import { AdGoodsFormField, type AdGoodsSelectorFunction } from './AdGoodsFormField'
import styles from './AdGoodsSelector.module.scss'

const { Title, Text } = Typography
export const AdGoodsSelector: React.FC<AdGoodsSelectorFunction> = (props) => (
  <div className={styles.goodsArea}>
    <div style={{ marginBottom: 16 }}>
      <div style={{ marginBottom: 8 }}>
        <Title level={5}>광고 상품 선택 & 기간 설정</Title>
        <Text style={{ marginBottom: 40, display: 'block' }}>
          각 상품별 평일과 휴일(주말, 공휴일)의 광고 금액이 다르며, 아래 상품 금액은 부가세 별도이니 광고 집행 계획에
          참고해주세요.
        </Text>
        <Text>원하시는 상품을 선택해 주세요.</Text>
      </div>
      <AdGoodsFormField {...props} />
    </div>
  </div>
)
