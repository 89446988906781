import { api } from '@wad/api'

type AdvertisementType = {
  adCenterAdvertisementId: number
  adCenterProjectId: number
  adName: string
  created_at: string
  creativeCount: number
  projectName: string
}
type ProjectInfoType = {
  adCenterMakerId: number
  advertisementList: Array<AdvertisementType>
  campaignId: number
  createdAt: string
  id: number
  lossText: string
  projectName: string
  projectType: number
  updatedAt: string
}
type ProjectListReturnType = {
  message: string | null
  result: Array<ProjectInfoType>
}

type ProjectListParameter = {
  makerId: number
}

type ProjectListFunctionType = (param: ProjectListParameter) => Promise<ProjectListReturnType>
export const getProjectList: ProjectListFunctionType = async ({ makerId }) =>
  api<ProjectListReturnType>('GET', `advertisement/search?maker_id=${makerId}`).then((res) => {
    const { result } = res as ProjectListReturnType
    if (result) {
      return Promise.resolve(result)
    }
    return Promise.reject(res)
  })
