import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { openPopup } from '../reducers/dialogReducer'

import AccountManageContent from '../components/AccountManageContent/AccountManageContent'

class AccountManagePage extends PureComponent {
  state = {}

  navList = [{ name: '계정정보', link: '/main/ad/user/accountManage' }]

  render() {
    const { navList } = this
    const { makerEmail, makerName, phoneNumber } = this.props.user.userData
    return (
      <AccountManageContent makerEmail={makerEmail} makerName={makerName} phoneNumber={phoneNumber} navList={navList} />
    )
  }
}

export default connect(({ user }) => ({ user }), { openPopup })(AccountManagePage)
