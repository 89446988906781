import { createAction, handleActions } from 'redux-actions'
import { isSuperUser } from '@legacy/definitions'
import { network } from '../network'

const FETCH_LOGIN = 'user/FETCH_LOGIN'
const CLEAR_USER_INFO = 'user/CLEAR_USER_INFO'
const LOGOUT = 'user/LOGOUT'
const LOGIN = 'user/LOGIN'

export const logout = createAction(LOGOUT)
export const login = createAction(LOGIN)
export const clearUserInfo = createAction(CLEAR_USER_INFO)

const fetchLogin = createAction(FETCH_LOGIN)
export const fetchLoginApi =
  ({ token }) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      const url = 'maker/info'
      network.callApi({
        url,
        method: 'GET',
        successFunc: (jsonData) => {
          if (jsonData.result) {
            window.wadiz = window.wadiz || {}
            window.wadiz.globals = window.wadiz.globals || {}
            window.wadiz.globals.hashedUserName = jsonData.result.hashedMakerEmail
            resolve(jsonData.result)
            dispatch(fetchLogin({ ...jsonData.result, token }))
          } else {
            reject(jsonData)
            dispatch(logout())
          }
        },
        failFunc: (error) => {
          reject(error)
          dispatch(logout())
        },
      })
    })
  }

const initState = {
  maker_id: 10000001,
  isAuthed: false,
  isWadizAdmin: false,
  isSuperUser: false,
  token: null,
}

export default handleActions(
  {
    [FETCH_LOGIN]: (state, action) => {
      const tempUserData = action.payload
      const { token, id, advertiserType } = tempUserData
      return {
        ...state,
        userData: tempUserData,
        token,
        maker_id: id,
        isAuthed: true,
        isWadizAdmin: advertiserType === 1,
        isSuperUser: isSuperUser(tempUserData.noMaskingMakerEmail),
      }
    },
    [LOGOUT]: () => {
      // sessionStorage.clear();
      return initState
    },
    [LOGIN]: (state, action) => {
      return {
        ...state,
        token: action.payload,
      }
    },
    [CLEAR_USER_INFO]: () => initState,
  },
  initState
)
