import { useQuery } from '@tanstack/react-query'
import { fetchBusinessDays } from '@center/api'

const LIMITE_DAY = 60

interface BusinessDaysParams {
  startDate: string // 2023-06-01
  withinDays?: number // startDate로 부터 + 몇일 까지 받을지 기본이 LIMITE_DAY
}

export const useBusinessDays = ({ startDate, withinDays = LIMITE_DAY }: BusinessDaysParams) => {
  return useQuery(['businessDay'], () => fetchBusinessDays({ startDate, withinDays }), {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  })
}
