import React, { Fragment } from 'react'

// Components
import Lnb from '../Lnb'
import NavBar from '../NavBar'
import PaginatorContainer from '../../containers/PaginatorContainer/PaginatorContainer'
// import ExcelExportContainer from '../../containers/ExcelExportContainer/ExcelExportContainer'

// CSS module
import style from './AdCostReportContent.module.scss'
import className from 'classnames/bind'
import LoadingBar from '../LoadingBar/LoadingBar'
const cx = className.bind(style)

const navList = [
  { name: '보고서', link: '/main/ad/report/' },
  { name: '광고비 보고서', link: '/main/ad/report/cost' },
]

const viewSizeList = [5, 10, 15, 20, 25, 30]

const AdCostReportContent = ({
  state,
  makerName,
  handleProjectNameSelect,
  handleAdNameSelect,
  handleOptionSelectAndGetResult,
  handlePageNumber,
  handleProjectNameSort,
  handleAdNameSort,
  handleViewSizeSelect,
  handleGoToADDetail,
  handleClickDetailPopUp,
  handleExportExcel,
  handleToAdCreate,
  localeOrNull,
  statusType,
  tableData,
  fileName,
  isDownload,
  isInit,
  selectedOption,
  totalCount,
}) => {
  const isNull = (value) => {
    if (value === '' || value === null || value === undefined) {
      return true
    }
    return false
  }
  const isNullText = (value) => {
    if (value === '' || value === null || value === undefined) {
      return '-'
    }
    return value
  }

  return (
    <main className={cx('main_container', 'report')}>
      <Lnb type="report" />
      <div className={cx('report_cost', 'content_wrap')}>
        <NavBar list={navList} />
        {state.loadingBarFlag ? (
          <LoadingBar />
        ) : state.allItemTotalCount === 0 ? (
          <div className={cx('report_empty')}>
            <h2>안녕하세요. {makerName} 메이커님.</h2>
            <p>
              등록된 광고가 없어 광고비 보고서를 확인할 수 없습니다.<br></br>광고를 만들면 광고비 보고서를 확인할 수
              있습니다.
            </p>
            <button type="button" className={cx('wz button primary', 'button_320')} onClick={() => handleToAdCreate()}>
              <i className={cx('icon add')} />
              광고 만들기
            </button>
          </div>
        ) : (
          <>
            <h2>광고비 보고서</h2>
            {/* 광고비 보고서 메인화면 */}
            <div className={cx('result_select')}>
              <label className={cx('wz outline dropdown', 'rpDropdown')}>
                <select defaultValue={state.selectedOption.projectId} onChange={handleProjectNameSelect}>
                  <option value="">전체 프로젝트</option>
                  {state.projectNameOption.map((val) => (
                    <option key={val[0]} value={val[0]}>
                      {val[1]}
                    </option>
                  ))}
                </select>
              </label>
              <label className={cx('wz outline dropdown', 'rpDropdown')}>
                <select defaultValue={state.selectedOption.adId} onChange={handleAdNameSelect}>
                  <option value="">전체 광고명</option>
                  {state.adNameOption.map((val) => (
                    <option key={val[0]} value={val[0]}>
                      {val[1]}
                    </option>
                  ))}
                </select>
              </label>
              <button onClick={handleOptionSelectAndGetResult} className={cx('wz button')}>
                이동
              </button>
              {document.body.offsetWidth > 768 && (
                <label className={cx('wz dropdown outline', 'rpDropdown', 'tablePageLength')}>
                  <select defaultValue={state.selectedOption.size} onChange={handleViewSizeSelect}>
                    {viewSizeList.map((val, idx) => (
                      <option key={idx} value={val}>
                        {val}개씩 보기
                      </option>
                    ))}
                  </select>
                </label>
              )}
            </div>
            <div className={cx('wz container table', 'cancellation')}>
              <div className={cx('wz table track', 'tableWrap')}>
                <table className={cx('ad_table', 'ad_table_total')}>
                  <thead>
                    <tr>
                      <th style={{ width: '50px' }}>No.</th>
                      <th style={{ width: '150px' }}>
                        <button className={cx('wz button less mini')} onClick={handleProjectNameSort}>
                          프로젝트
                          <i
                            className={cx(state.selectedOption.projectSort === 0 ? 'icon angle-down' : 'icon angle-up')}
                          ></i>
                          <span>정렬하기</span>
                        </button>
                      </th>
                      <th style={{ width: '150px' }}>
                        <button className={cx('wz button less mini')} onClick={handleAdNameSort}>
                          광고명
                          <i
                            className={cx(state.selectedOption.adSort === 0 ? 'icon angle-down' : 'icon angle-up')}
                          ></i>
                          <span>정렬하기</span>
                        </button>
                      </th>
                      <th style={{ width: '174px' }}>광고 영역</th>
                      <th style={{ width: '50px' }}>
                        광고<br></br>구좌
                      </th>
                      <th style={{ width: '120px' }}>광고기간</th>
                      <th style={{ width: '90px' }}>광고비</th>
                      <th style={{ width: '70px' }}>
                        광고<br></br>단계
                      </th>
                      {/* <th style={{ width: '90px' }}>광고<br></br>잔금</th> */}
                      {/* <th style={{ width: '70px' }}>남은<br></br>광고일</th> */}
                      <th style={{ width: '70px' }}>
                        남은<br></br>광고시간
                      </th>
                      <th style={{ width: '55px' }}>
                        결제<br></br>상태
                      </th>
                    </tr>
                  </thead>
                  {/* 병합된 부분은 tbody안에 묶어주세요 */}
                  {state.itemList.length > 0 &&
                    state.itemList.map((val, idx) => (
                      <Fragment key={idx}>
                        <tbody className={cx('ad_table_group')}>
                          <tr key={idx}>
                            <td rowSpan={val.creativeList.length}>{val.idx}</td>
                            <td rowSpan={val.creativeList.length} className={cx('aligned left')}>
                              {val.projectName}
                            </td>
                            <td
                              rowSpan={val.creativeList.length}
                              className={cx(val.adCenterAdvertisementId && 'point_primary')}
                              onClick={() => handleGoToADDetail(val.adCenterAdvertisementId)}
                            >
                              {val.adName}
                            </td>
                            <td>{val.creativeList[0].area}</td>
                            <td>{val.creativeList[0].accountNum}</td>
                            {val.creativeList[0].startAt === '' ? (
                              <td>-</td>
                            ) : (
                              <td>
                                {val.creativeList[0].startAt.slice(2, 10)} ~<br></br>
                                {val.creativeList[0].endAt.slice(2, 10)}
                              </td>
                            )}
                            <td>
                              {localeOrNull(val.creativeList[0].price) !== '-'
                                ? `${localeOrNull(val.creativeList[0].price)}원`
                                : '-'}
                            </td>
                            <td>
                              {isNullText(
                                val.creativeList[0].adProcessStatus !== 0
                                  ? statusType.adProcessStatus[val.creativeList[0].adProcessStatus]
                                  : statusType.status[val.creativeList[0].status]
                              )}
                            </td>
                            {/* <td>{val.creativeList[0].status === 1 ? '-' : localeOrNull(val.creativeList[0].adRemainPrice) !== '-' ? `${localeOrNull(val.creativeList[0].adRemainPrice)}원` : '-'}</td> */}
                            {/* <td>{val.creativeList[0].status === 1 ? '-' : val.creativeList[0].adRemainDay}</td> */}
                            <td>{val.creativeList[0].status === 1 ? '-' : val.creativeList[0].adRemainHour}</td>
                            <td
                              className={cx(
                                !isNull(statusType.adPayStatus[val.creativeList[0].adPayStatus]) && 'point_primary',
                                'not_pointer'
                              )}
                              // onClick={() => !isNull(statusType.adPayStatus[val.creativeList[0].adPayStatus]) && handleClickDetailPopUp(val.creativeList[0].id)}
                            >
                              {isNullText(statusType.adPayStatus[val.creativeList[0].adPayStatus])}
                            </td>
                          </tr>
                          {val.creativeList.slice(1).map((val2, idx2) => (
                            <tr key={idx2}>
                              <td> {val2.area}</td>
                              <td> {val2.accountNum}</td>
                              <td>
                                {val2.startAt.slice(2, 10)} ~<br></br>
                                {val2.endAt.slice(2, 10)}
                              </td>
                              <td>{localeOrNull(val2.price) !== '-' ? `${localeOrNull(val2.price)}원` : '-'}</td>
                              <td>
                                {isNullText(
                                  val2.adProcessStatus !== 0
                                    ? statusType.adProcessStatus[val2.adProcessStatus]
                                    : statusType.status[val2.status]
                                )}
                              </td>
                              {/* <td>{val2.status === 1 ? '-' : localeOrNull(val2.adRemainPrice) !== '-' ? `${localeOrNull(val2.adRemainPrice)}원` : '-'}</td> */}
                              {/* <td>{val2.status === 1 ? '-' : val2.adRemainDay}</td> */}
                              <td>{val2.status === 1 ? '-' : val2.adRemainHour}</td>
                              <td
                                className={
                                  !isNull(statusType.adPayStatus[val2.adPayStatus]) &&
                                  cx('point_primary', 'not_pointer')
                                }
                                // onClick={() => !isNull(statusType.adPayStatus[val2.adPayStatus]) && handleClickDetailPopUp(val2.id)}
                              >
                                {isNullText(statusType.adPayStatus[val2.adPayStatus])}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Fragment>
                    ))}
                  {isInit && state.itemList.length === 0 && (
                    <tbody className={cx('ad_table_group')}>
                      <tr>
                        <td className={cx('error_table')} colSpan="12">
                          <div>
                            <i className={cx('icon error-o')}></i>광고비 보고서가 없습니다.
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
              {state.itemList.length !== 0 && (
                <>
                  <PaginatorContainer
                    onChange={handlePageNumber}
                    activePage={selectedOption.start}
                    totalCount={totalCount}
                    viewCount={selectedOption.size}
                  />
                  {/* <div className={cx('ad_table_btnArea')}>
                    <button onClick={handleExportExcel} className={cx('wz button')}>
                      <i className={cx('icon download')}></i>엑셀 파일로 정보 다운로드
                      <ExcelExportContainer fileName={fileName} tableData={tableData} isDownload={isDownload} />
                    </button>
                  </div> */}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </main>
  )
}

export default AdCostReportContent
