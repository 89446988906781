import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom-v5'
import { connect } from 'react-redux'

// Components
import { openPopup } from '../../reducers/dialogReducer'
import AdResultDetailContent from '../../components/AdResultReportContent/AdResultDetailContent'

import { dateToStringAdPeriod } from '../../datetostring'
import { network } from '../../network'

class AdResultDetailPage extends PureComponent {
  state = {
    tableType: 0,
    adName: '',
    projectName: '',
    allItemList: [],
    typeItemList: [],
    chart: [],
    tableInfo: {
      tableLabel: [],
      tableValue: [],
    },
    tableData: [],

    fileName: '',

    isWadizAdmin: false,
  }

  navList = [
    { name: '보고서', link: '/main/ad/report/' },
    { name: '성과 보고서', link: '/main/ad/report/result' },
    { name: '상세보기', link: window.location.pathname },
  ]

  fileNames = ['성과_보고서_상세_전체', '성과_보고서_상세_일자별', '성과_보고서_상세_시간별', '성과_보고서_상세_요일별']

  tableTypeValue = ['all', 'daily', 'hourly', 'daily-of-week']

  tableValues = {
    type: ['key', 'area', 'accountNum', 'numImp', 'numClick', 'ctr'],
    all: ['no', 'area', 'accountNum', 'period', 'price', 'numImp', 'numClick', 'ctr'],
  }

  tableLabels = {
    all: ['No.', '광고', '구좌', '기간', '광고비', '광고단계', '노출수', '클릭수', '클릭률'],
    daily: ['일자', '광고 영역', '구좌', '광고단계', '노출수', '클릭수', '클릭률'],
    hourly: ['시간', '광고 영역', '구좌', '광고단계', '노출수', '클릭수', '클릭률'],
    dailyOfWeek: ['요일', '광고 영역', '구좌', '광고단계', '노출수', '클릭수', '클릭률'],
  }

  statusType = {
    status: ['', '작성중', '승인요청', '승인완료', '반려'],
    adProcessStatus: ['', '예정', '진행중', '취소', '완료', '삭제'],
    adPayStatus: ['', '결제 예약취소', '결제 예약완료', '결제완료', '결제실패', '환불대상', '환불완료', '정산완료'],
    cancelStatus: [
      '',
      '관리자 광고 진행중 취소',
      '관리자 광고 예정중 취소',
      '메이커의 심사중 광고취소',
      '메이커의 예정중 광고 취소',
      '시스템 취소',
      '반려-심사중 관리자가 광고 반려',
      '결제실패',
    ],
  }

  componentDidMount() {
    this.setState({ isWadizAdmin: this.props.user.isWadizAdmin })
    this.getItem(0)
  }

  getItem = (tableTypeIdx) => {
    const adId = this.props.match.params.id
    const makerId = this.props.user.maker_id
    const type = this.tableTypeValue[tableTypeIdx]

    const url = `result-report/${type}/list?maker_id=${makerId}&advertisement_id=${adId}`
    network.callApi({
      url,
      method: 'get',
      header: { 'Content-Type': 'application/json;charset=UTF-8' },
      successFunc: (jsonData) => {
        const { result } = jsonData
        if (tableTypeIdx === 0) {
          if (result.length === 0) {
            // No Data Case
            this.setState({
              adName: '성과 보고서가 없거나 삭제되었습니다.',
              projectName: '성과 보고서가 없거나 삭제되었습니다.',
              allItemList: [],
              chart: [],
              tableType: tableTypeIdx,
            })
            return
          }
          const { adName, projectName, creativeList } = result[0]
          const chart = this.setAllDataToChart(creativeList)
          this.setState(
            {
              adName,
              projectName,
              allItemList: creativeList,
              chart,
              tableType: tableTypeIdx,
            },
            () => this.genExcelData()
          )
        } else {
          // 일자 요일 시간별 데이터,
          let typeItemList = result
          if (type === 'daily')
            typeItemList = typeItemList.map((val) => {
              return { ...val, key: val.key.slice(2) }
            })
          const chart = this.setTypeDataToChart(typeItemList)
          this.setState({ typeItemList, chart, tableType: tableTypeIdx }, () => this.genExcelData())
        }
      },
      failFunc: () => {
        console.error(`fail- ${url}`)
      },
    })
  }

  setAllDataToChart = (items) => {
    const chart = []
    for (let i = 0; i < items.length; i += 1) {
      const { area, numClick, numImp, ctr, accountNum } = items[i]
      // TEMP area + i
      chart.push({
        category: `${area}-${accountNum}`,
        impressionsCount: numImp,
        clickCount: numClick,
        clickRatio: ctr,
      })
    }
    return chart
  }

  setTypeDataToChart = (items) => {
    const chart = []
    for (let i = 0; i < items.length; i += 1) {
      const {
        // key, totalNumImp, totalNumClick, totalCtr,
        key,
        totalNumImp,
        totalNumClick,
        totalCtr,
      } = items[i]
      chart.push({
        category: key,
        impressionsCount: totalNumImp,
        clickCount: totalNumClick,
        clickRatio: totalCtr,
      })
    }
    return chart
  }

  handleClickHistoryBack = () => this.props.history.goBack()

  handleClickType = (tableType) => {
    this.getItem(tableType)
  }

  handleClickFavorit = () => {
    this.props.openPopup({
      dialogType: 'reportFavorit',
      dialogData: {
        reportType: '성과',
        reportName: this.state.adName,
        adCenterMakerId: this.props.user.maker_id,
        adCenterAdvertisementId: this.props.match.params.id,
      },
    })
  }

  handleExportExcel = () => {
    // this.genExcelData();
    this.setState({ isDownload: true, fileName: this.fileNames[this.state.tableType] }, () => {
      this.handleDownloaded()
    })
  }

  genRow = (tableInfo, item) => {
    const { tableLabel, tableValue } = tableInfo
    const d = {}
    for (let i = 0; i < tableLabel.length; i += 1) {
      d[tableLabel[i]] = item[tableValue[i]]
    }

    return d
  }

  genExcelData = () => {
    const { typeItemList, tableInfo, allItemList } = this.state
    const items = []
    if (this.state.tableType === 1) {
      tableInfo.tableValue = this.tableValues.type
      tableInfo.tableLabel = this.tableLabels.daily
    } else if (this.state.tableType === 2) {
      tableInfo.tableValue = this.tableValues.type
      tableInfo.tableLabel = this.tableLabels.hourly
    } else if (this.state.tableType === 3) {
      tableInfo.tableValue = this.tableValues.type
      tableInfo.tableLabel = this.tableLabels.dailyOfWeek
    } else if (this.state.tableType === 0) {
      tableInfo.tableValue = this.tableValues.all
      tableInfo.tableLabel = this.tableLabels.all
      allItemList.forEach((val, idx) => {
        const row = {
          no: allItemList.length - idx,
          area: val.area,
          accountNum: val.accountNum,
          period: `${dateToStringAdPeriod(val.startAt)}~${dateToStringAdPeriod(val.endAt)}`,
          price: val.price,
          numImp: val.numImp,
          numClick: val.numClick,
          ctr: val.ctr,
        }
        items.push(this.genRow(tableInfo, row))
      })
      // 단일 파일 [items]
      this.setState({ tableData: [items] })
      return
    }

    typeItemList.forEach((val) => {
      const row = {
        key: val.key,
        area: val.creativeList[0].area,
        accountNum: val.creativeList[0].accountNum,
        numClick: val.creativeList[0].numClick,
        numImp: val.creativeList[0].numImp,
        ctr: val.creativeList[0].ctr,
      }
      items.push(this.genRow(tableInfo, row))

      val.creativeList.slice(1).forEach((val2) => {
        const row2 = {
          area: val2.area,
          accountNum: val2.accountNum,
          numClick: val2.numClick,
          numImp: val2.numImp,
          ctr: val2.ctr,
        }
        items.push(this.genRow(tableInfo, row2))
      })
      const row3 = {
        area: '합계',
        numClick: val.totalNumClick,
        numImp: val.totalNumImp,
        ctr: val.totalCtr,
      }
      items.push(this.genRow(tableInfo, row3))
    })
    this.setState({ tableData: [items] })
  }

  handleDownloaded = () => {
    const isDownload = this.state.isDownload !== true
    this.setState({ isDownload })
  }

  render() {
    const { handleClickHistoryBack, handleClickType, handleClickFavorit, handleExportExcel, navList, statusType } = this
    const {
      isWadizAdmin,
      adName,
      projectName,
      tableType,
      allItemList,
      typeItemList,
      chart,
      tableData,
      fileName,
      isDownload,
    } = this.state

    return (
      <AdResultDetailContent
        isWadizAdmin={isWadizAdmin}
        adName={adName}
        projectName={projectName}
        tableType={tableType}
        allItemList={allItemList}
        typeItemList={typeItemList}
        chart={chart}
        tableData={tableData}
        fileName={fileName}
        isDownload={isDownload}
        handleClickHistoryBack={handleClickHistoryBack}
        handleClickType={handleClickType}
        handleClickFavorit={handleClickFavorit}
        handleExportExcel={handleExportExcel}
        navList={navList}
        statusType={statusType}
      />
    )
  }
}

export default connect(({ user }) => ({ user }), { openPopup })(withRouter(AdResultDetailPage))
