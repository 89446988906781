import React from 'react'
import { Typography } from 'antd'
import { BgColorResult } from '@center/api'
import { BgColorList } from '@center/ui'

interface AdBgColorListProps {
  defaultColor?: string
  bgColorDataList: BgColorResult[]
  handleBgColor: (bgColorData: BgColorResult) => void
  errorMsg?: string
}

const { Text } = Typography
export const AdBgColorList: React.FC<AdBgColorListProps> = ({
  defaultColor,
  bgColorDataList,
  handleBgColor,
  errorMsg,
}) => {
  return (
    <article>
      <BgColorList defaultColor={defaultColor} bgColorDataList={bgColorDataList} onClick={handleBgColor} />
      {errorMsg && <Text type="danger">{errorMsg}</Text>}
    </article>
  )
}
