import withIcon, { Props } from './utils/withIcon'

function HelpIcon(props: Props) {
  return (
    <svg viewBox="0 0 40 40" {...props}>
      <path fill="none" d="M0 0h40v40H0z" />
      <path d="M20 1a19 19 0 1 0 19 19A19.06 19.06 0 0 0 20 1zm0 29.5a1.8 1.8 0 1 1 1.8-1.79 1.8 1.8 0 0 1-1.8 1.75zm6-16.13a6.28 6.28 0 0 1-2.7 5.2l-.6.5c-1.7 1.7-1.7 2.4-1.7 4.3h-2a6.51 6.51 0 0 1 2.3-5.7L22 18a4.29 4.29 0 0 0 2-3.7 3.7 3.7 0 0 0-4-4 3.7 3.7 0 0 0-4 4h-2a5.7 5.7 0 0 1 6-6 5.7 5.7 0 0 1 6 6z" />
    </svg>
  )
}

export default withIcon('HelpIcon')(HelpIcon)
