import { type AreaIDType, AreaType } from '@legacy/definitions'
import { AppPushPreview, KeywordAdPreview, MainBoardPreview, KeyVisualPreview, CardKeyVisualPreview } from '@common/ui'
import { DeprecatedSearch } from './DeprecatedSearch'
import { MainContentPreview } from './MainContentPreview'
import { MainBoardPreview as MainBoardPreviewDeprecated } from './MainBoardPreview'
import styles from './PreviewSelector.module.scss'

// const previewTable = {
//   [AreaType.mainHome]: KeyVisualPreview,
//   // [AreaType.mainBanner]: AAA,
//   [AreaType.mainContentA]: MainContentPreview,
//   [AreaType.mainContentB]: MainContentPreview,
//   [AreaType.mainContentC]: MainContentPreview,
//   // [AreaType.fundingList]: AAA,
//   [AreaType.fundingHome]: KeyVisualPreview,
//   [AreaType.comingSoonHome]: KeyVisualPreview,
//   // [AreaType.comingSoonList]: AAA,
//   [AreaType.messagePush]: AppPushPreview,
//   // [AreaType.storeList]: AAA,
//   [AreaType.mainBoardDeprecated]: MainBoardPreviewDeprecated,
//   // [AreaType.storeHome]: AAA,
//   [AreaType.preOrderBanner]: KeyVisualPreview,
//   // [AreaType.preOrderList]: AAA,
//   [AreaType.searchKeyword]: KeywordAdPreview,
//   [AreaType.mainBoard]: MainBoardPreview,
//   // [AreaType.deprecated1]: AAA,
//   [AreaType.deprecated2]: DeprecatedSearch,
//   // [AreaType.unassigned1]: AAA,
//   // [AreaType.unassigned2]: AAA,
// }

type PreviewSelectorFunction = {
  areaType: AreaIDType
  mainCopy: string | undefined
  subCopy: string | undefined
  imageUrl: string | undefined
  onCloseImage: () => void
}
export const PreviewSelector: React.FC<PreviewSelectorFunction> = ({
  areaType,
  mainCopy,
  subCopy,
  imageUrl,
  onCloseImage,
}) => {
  if (
    areaType === AreaType.fundingHome ||
    areaType === AreaType.mainHome ||
    areaType === AreaType.comingSoonHome ||
    areaType === AreaType.preOrderBanner
  ) {
    return !imageUrl ? null : (
      <CardKeyVisualPreview mainCopy={mainCopy} subCopy={subCopy} imageUrl={imageUrl} onCloseImage={onCloseImage} />
    )
  }
  return <div className={styles.container}>{areaType}</div>
}
