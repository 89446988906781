import React from 'react'

// CSS module
import style from './FooterInfo.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(style)

const FooterInfo = () => {
  return (
    <div className={cx('footerInfo')}>
      <div className={cx('innerBox')}>
        <div className={cx('infoSection', 'secondSection')}>
          <div className={cx('infoList')}>
            <div className={cx('infoItem')}>
              <span>와디즈(주)</span>
              <span className={cx('sep')}>|</span>
            </div>
            <div className={cx('infoItem')}>
              <span>대표이사 신혜성</span>
              <span className={cx('sep')}>|</span>
            </div>
            <div className={cx('infoItem')}>
              <span>사업자등록번호 258-87-01370</span>
              <span className={cx('sep')}>|</span>
            </div>
            <div className={cx('infoItem')}>
              <span>경기도 성남시 분당구 판교로 242 (삼평동) 판교디지털센터 A동 4층 402호</span>
            </div>
          </div>
          <p className={cx('copyright')}>&copy; wadiz Co., Ltd.</p>
        </div>
      </div>
    </div>
  )
}

export default FooterInfo
