import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import UserHelpContent from '../../components/UserHelpContent/UserHelpContent'
import { setHelpMenuType } from '../../reducers/helpMenuTypeReducer'

import { network } from '../../network'

class UserHelpPage extends PureComponent {
  state = {
    tableType: 'FAQ',
    QAList: [],
    manualList: [],
  }

  categoryAddAd = {
    category: '광고등록',
    idPrefix: 'createAd',
    list: [
      {
        question: '와디즈에서 누구나 광고를 할 수 있나요?',
        content: (
          <span>
            와디즈의 메이커는 누구나 와디즈에서 광고할 수 있습니다. <br></br>
            단! 광고를 등록하기 위해서는 메이커님이 투자 또는 펀딩 프로젝트를 오픈해야 합니다. <br></br>
            프로젝트 오픈 후에 승인이 되면, 메이커 스튜디오의 승인된 프로젝트에서 [광고센터] 메뉴를 통해 광고를 등록하는
            화면으로 이동할 수 있습니다. <br></br>
          </span>
        ),
        isExpand: false,
      },
      {
        question: '광고를 등록하면 언제쯤 승인되나요?',
        content: (
          <span>
            광고를 등록하면 광고 관리자가 영업일 기준 2일 이내에 심사하여 승인여부를 결정하게 됩니다. <br></br>
            안정적인 광고 심사 및 운영을 위해서는 광고를 노출 시작일자를 기준으로 영업일 기준 7일 이전에 광고를
            등록해주세요. <br></br>
            만약 프로젝트 운영상 빠른 광고가 필요한 경우, 광고 등록 후에 광고 관리자에게 별도의 메일로 문의해주시면
            최대한 빠른 광고 스케쥴 확인을 안내해드리겠습니다. <br></br>
          </span>
        ),
        isExpand: false,
      },
      {
        question: '광고 갯수는 제한이 없나요?',
        content: (
          <span>
            프로젝트별로 메이커님이 원하는 만큼의 광고를 등록할 수 있습니다. <br></br>
            갯수 제한 없이 광고 등록을 진행하면서 광고 영역 및 구좌, 광고 등록 가능 여부를 확인해보세요.
          </span>
        ),
        isExpand: false,
      },
      {
        question: '광고 상품은 어떤 것들이 있나요?',
        content: (
          <span>
            와디즈에서는 광고 영역마다 메이커가 등록, 운영할 수 있는 광고 구좌가 지정되어 있습니다. <br></br>
            광고 등록을 진행하면서 광고 영역 및 구좌별 광고 상품을 확인하고 원하는 상품을 직접 선택하시면 됩니다.{' '}
            <br></br>
            광고 상품은 1일, 3일, 5일, 7일 등의 일자별 광고 상품을 선택할 수 있으며 기간이 긴 상품을 선택할수록 광고
            영역이나 구좌마다 할인 혜택이 숨어있을 수 있으니 다양한 광고 상품을 이용해보세요.
          </span>
        ),
        isExpand: false,
      },
      {
        question: '광고를 취소할 수 있나요?',
        content: (
          <span>
            광고 심사 중이거나 시작된 후에는 광고를 취소할 수 없으며, 광고 시작 전에만 취소할 수 있습니다. <br></br>
            <br></br>
            광고 심사 완료 후부터 광고 시작까지 72시간 이상 남은 경우에는 100% 환불, 광고 시작까지 72시간 이하로 남은
            경우에는 80%만 환불이 진행됩니다. <br></br>
            단, 환불 진행 시에는 카드 결제 수수료 차감 후에 환불됩니다. <br></br>
          </span>
        ),
        isExpand: false,
      },
    ],
  }

  categoryAdPrice = {
    category: '광고비',
    idPrefix: 'adPrice',
    list: [
      {
        question: '광고비 결제는 언제하나요?',
        content: (
          <span>
            광고 등록 단계에서는 예약 결제로 등록되며, 심사 완료 후에 결제 진행을 위한 신용카드 정보만 입력을 받고
            있습니다. <br></br>
            광고 심사 후 승인된 광고에 대해서만 예약한 결제가 이루어집니다. <br></br>
            만약, 광고 심사가 반려된 경우에는 예약 결제 역시 취소되고 결제가 진행되지 않습니다. <br></br>
          </span>
        ),
        isExpand: false,
      },
      {
        question: '광고비 결제 수단은 어떤 것이 있나요?',
        content: (
          <span>
            광고비 결제 수단은 현재 신용카드만 가능하며, <br></br>
            계좌이체 등의 결제는 지원하고 있지 않습니다. <br></br>
          </span>
        ),
        isExpand: false,
      },
      {
        question: '광고비의 과금방식은 어떤 것이 있나요?',
        content: (
          <span>
            와디즈 광고의 광고비 과금방식에는 CPT, CPM 방식을 적용하고 있습니다. <br></br>
            CPT는 특정 광고 영역에 1일 동안 노출한 것에 대한 광고비를 지불하는 방식이고, <br></br>
            CPM은 특정 광고 영역에 1일 동안의 보장된 노출 횟수에 대한 광고비를 지불하는 방식입니다. <br></br>
            예를 들어 CPT 광고 상품은 1일 동안 노출한 광고비 100,000원을 지불하는 방식이라면, <br></br>
            CPM 광고 상품은 1일 동안 10,000회 노출 보장에 대한 광고비 10,000원을 지불하는 방식입니다.
          </span>
        ),
        isExpand: false,
      },
      {
        question: '광고비 할인 정책은 없나요?',
        content: (
          <span>
            와디즈 광고는 광고 상품에 따른 할인을 적용하고 있습니다. <br></br>
            1일, 3일, 5일, 7일 등 기간에 따른 광고 상품 및 금액을 적용하고 있으며 <br></br>
            기간이 긴 상품을 선택할수록 광고 영역이나 구좌마다 할인 혜택이 숨어있으니 다양한 광고 상품을 등록해보세요.{' '}
            <br></br>
          </span>
        ),
        isExpand: false,
      },
      {
        question: '광고가 취소되어 환불 받을 경우, 언제 받을 수 있나요?',
        content: (
          <span>
            취소된 광고는 취소된 달의 다음 달 25일에 환불 금액이 지급됩니다. <br></br>
            만약 10월 12일에 광고가 취소되었다면, 환불 금액을 산정하여 지급할 금액이 있을 경우 11월 25일에 환불이
            진행됩니다.
          </span>
        ),
        isExpand: false,
      },
      {
        question: '광고비 환불 규정은 무엇인가요?',
        content: (
          <span>
            1) 광고 100% 환불 <br></br>
            광고 승인이 완료되었으나 광고 노출까지 72시간 이상 남은 경우에 직접 광고를 취소했다면 광고비가 100%
            환불됩니다. <br></br>
            단, 환불 진행 시에 카드 결제 수수료는 차감 후 환불됩니다. <br></br>
            <br></br>
            2) 광고 80% 환불 <br></br>
            광고 승인이 완료되었으나 광고 노출까지 72시간 이하 남은 경우에 직접 광고를 취소했다면 광고비가 80%
            환불됩니다. <br></br>
            단, 환불 진행 시에 카드 결제 수수료는 차감 후 환불됩니다. <br></br>
            <br></br>
            3) 환불 비용 정산 후 환불 <br></br>
            광고 노출 중에 와디즈 광고 심사 가이드 라인에 따른 광고 취소가 되었다면 과금방식에 따른 운영 비용 정산 후에
            광고비가 환불됩니다. <br></br>
            선택한 광고 상품의 과금 방식이 CPT인 경우에는 광고가 취소되는 다음 날부터 환불금을 계산하고, <br></br>
            선택한 광고 상품의 과금 방식이 CPM인 경우 광고가 취소된 시간으로부터 1시간 후의 노출 횟수를 확인하여
            환불금을 계산합니다. <br></br>
            예를 들어 CPT 방식의 5일에 50,000원 광고 상품을 운영 중이었으나 3일째 광고가 취소된 경우 2일 비용인
            20,000원이 환불되고, <br></br>
            CPM 방식의 5일 동안 50,000회 노출에 대한 50,000원 광고 상품을 운영 중이었으나 30,000회 노출 후 취소되었다면
            노출되지 않은 20,000회에 대한 20,000원이 환불처리 됩니다. <br></br>
            단, 환불 진행 시에 카드 결제 수수료 차감 후 환불됩니다. <br></br>
            <br></br>
          </span>
        ),
        isExpand: false,
      },
      {
        question: '간편 결제는 무엇인가요?',
        content: (
          <span>
            광고비를 간편하게 결제할 수 있도록 와디즈의 간편 결제 기능을 동일하게 적용하고 있습니다. <br></br>
            <br></br>
            간편 결제를 등록하는 방법은 <br></br>
            1. 광고 등록 중에 예약 결제 정보를 간편 결제 정보로 등록하거나 <br></br>
            2. 광고 설정의 간편결제 관리에서 간편 결제 정보를 등록하는 방법이 있습니다. <br></br>
            <br></br>
            간편 결제 기능을 이용하여 와디즈 광고를 쉽게 등록해보세요.
          </span>
        ),
        isExpand: false,
      },
    ],
  }

  categoryTax = {
    category: '세금계산서',
    idPrefix: 'taxBill',
    list: [
      {
        question: '세금계산서를 받을 수 있나요?',
        content: (
          <span>
            프로젝트 생성 시에 등록된 사업자 정보가 있다면 와디즈 광고센터에서도 세금계산서 발행 신청을 할 수 있습니다.
            광고센터 내 설정 메뉴를 확인해보세요. <br></br>
          </span>
        ),
        isExpand: false,
      },
      {
        question: '세금계산서는 언제 받을 수 있나요?',
        content: (
          <span>
            세금계산서는 결제가 이루어진 날의 다음 달 25일에 세금계산서를 발행합니다. <br></br>
            만약, 10월 12일에 결제가 진행되었다면 11월 25일에 세금계산서가 발행됩니다.
          </span>
        ),
        isExpand: false,
      },
      {
        question: '환불 시 세금계산서를 받을 수 있나요?',
        content: (
          <span>
            환불 시에도 세금계산서를 받을 수 있습니다. <br></br>
            환불이 이루어진 날의 다음 달 25일에 마이너스 세금계산서가 발행됩니다.
          </span>
        ),
        isExpand: false,
      },
    ],
  }

  categoryReport = {
    category: '광고 보고서',
    idPrefix: 'adReport',
    list: [
      {
        question: '광고는 메이커를 위해 어떤 보고서를 제공하나요?',
        content: (
          <span>
            와디즈 광고는 성과 보고서와 광고비 보고서를 제공하고 있습니다. <br></br>
            <br></br>
            1) 성과 보고서 <br></br>
            광고 영역 및 구좌별로 노출수, 클릭수, 클릭률을 확인할 수 있으며 <br></br>
            광고 기간, 광고 시간, 광고 요일에 따른 분석도 가능합니다. <br></br>
            <br></br>
            2) 광고비 보고서 <br></br>
            광고비 처리 상태, 남은 광고비 잔액 및 시간을 광고비 보고서에서 확인할 수 있습니다.
          </span>
        ),
        isExpand: false,
      },
    ],
  }

  navList = [{ name: '도움말', link: '/main/ad/user/help' }]

  getAreaList = () => {
    const url = 'pub-area/list/help?advertiser_type=0&project_type=1'
    network.callApi({
      url,
      method: 'get',
      header: { 'Content-Type': 'application/json;charset=UTF-8' },
      successFunc: (jsonData) => {
        const { result } = jsonData
        if (result) {
          this.setState({ manualList: result })
        }
      },
      failFunc: () => {
        console.error(`fail- ${url}`)
      },
    })
  }

  onClickTableType = (tableType) => {
    this.setState({ tableType }, () => {
      this.props.setHelpMenuType(tableType)
    })
  }

  onClickQuestion = (categoryIdx, questionIdx) => {
    const { QAList } = this.state

    QAList[categoryIdx].list[questionIdx].isExpand = !QAList[categoryIdx].list[questionIdx].isExpand
    this.setState({ QAList: [...QAList] })
  }

  componentDidMount = () => {
    const QAList = []
    QAList.push(this.categoryAddAd)
    QAList.push(this.categoryAdPrice)
    QAList.push(this.categoryTax)
    QAList.push(this.categoryReport)
    this.getAreaList()

    let tableType = this.props.helpMenu.helpMenuType
    const { hash } = this.props.location

    if (hash) {
      const idPrefix = hash.split('-')[0].replace('#', '')
      const index = hash.split('-')[1]

      const qaIndex = QAList.findIndex((qa) => qa.idPrefix === idPrefix)
      QAList[qaIndex].list[index].isExpand = true
      tableType = 'FAQ'
    }
    this.setState({ QAList, tableType })
  }

  render() {
    const { tableType, QAList, manualList } = this.state
    const { navList, onClickTableType, onClickQuestion } = this

    return (
      <UserHelpContent
        tableType={tableType}
        navList={navList}
        QAList={QAList}
        manualList={manualList}
        onClickTableType={onClickTableType}
        onClickQuestion={onClickQuestion}
      />
    )
  }
}

export default connect(({ helpMenu }) => ({ helpMenu }), { setHelpMenuType })(UserHelpPage)
