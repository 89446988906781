import React from 'react';
import { NavLink } from 'react-router-dom-v5';

import className from 'classnames/bind';
import style from './NavBar.module.scss';
const cx = className.bind(style);

const NavBar = ({ list, handleMobileMenuOpen }) => {
  return (
    <div className={cx('navbar')}>
      <ul className={cx('navbar_list')}>
        { list && list.map((val, idx) => (
          <li key={idx} className={cx('navbar_item')}>
            <NavLink to={val.link} exact activeClassName={cx('active')} onClick={() => handleMobileMenuOpen(true)}>{val.name}</NavLink>
            <i className={cx('icon chevron-right')}></i>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavBar;
