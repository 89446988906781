import { createAction, handleActions } from 'redux-actions';

const SET_AD_STATUS = 'status/SET_AD_STATUS';
export const setAdStatus = createAction(SET_AD_STATUS);

const initState = {
  adStatus: 'loading',
};

export default handleActions({
  [SET_AD_STATUS]: (_, action) => ({
    adStatus: action.payload,
  }),
}, initState);
