import React, { forwardRef, useState } from 'react';
import { createPortal, findDOMNode } from 'react-dom';

import { setRef } from '../useForkRef';

const useEnhancedEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

function getContainer(container?: React.ReactInstance | (() => React.ReactInstance | null) | null) {
  // eslint-disable-next-line no-param-reassign
  container = typeof container === 'function' ? container() : container;
  // #StrictMode ready
  return findDOMNode(container) as Element | null;
}

export interface Props {
  children: React.ReactNode;
  container?: React.ReactInstance | (() => React.ReactInstance | null) | null;
  disablePortal?: boolean;
  onRendered?: () => void;
}

export const Portal = forwardRef<Element, Props>(({
  children,
  container,
  disablePortal,
  onRendered,
}, ref) => {
  const [mountNode, setMountNode] = useState<Element | null>(null);

  useEnhancedEffect(() => {
    if (!disablePortal) {
      setMountNode(getContainer(container) || document.body);
    }
  }, [container, disablePortal]);

  useEnhancedEffect(() => {
    if (mountNode && !disablePortal) {
      setRef(ref, mountNode);
      return () => {
        setRef(ref, null);
      };
    }

    return undefined;
  }, [ref, mountNode, disablePortal]);

  useEnhancedEffect(() => {
    if (onRendered && (mountNode || disablePortal)) {
      onRendered();
    }
  }, [onRendered, mountNode, disablePortal]);

  if (disablePortal) {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, {
        ref,
      } as { ref: any });
    }
    return children as unknown as JSX.Element;
  }

  return mountNode ? createPortal(children, mountNode) : mountNode;
});

export default Portal;
