import { useCallback, useState } from 'react'
import { Button, Upload } from 'antd'
import { CameraOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import { UploadRequestOption } from './ImageUploader.interface'
import { useImageUploadMutation } from './_queries/useImageUploadMutation'
import styles from './ImageUploader.module.scss'

type ImageUploaderProps = {
  width: number
  height: number
  size: number
  isBadgeCombination: boolean
  errMessage: string | undefined
  onSuccess: (imgUrl: string) => void
}
export const ImageUploader: React.FC<ImageUploaderProps> = ({
  width,
  height,
  size,
  isBadgeCombination,
  onSuccess,
  errMessage = '',
}) => {
  const mutation = useImageUploadMutation()

  const customRequest = useCallback(
    async (options: UploadRequestOption) => {
      const { onSuccess: onUploadSuccess, onError, file: tempfile } = options

      const fr = new FileReader()
      const image = new Image()
      if (!tempfile) return

      fr.readAsDataURL(tempfile as Blob)
      fr.onload = (file: ProgressEvent<FileReader>) => {
        image.src = file?.target?.result as string
        image.onload = () => {
          const { height: imageHeight, width: imageWidth } = image
          if (imageWidth !== width || imageHeight !== height) {
            toast('이미지 사이즈를 확인하세요.')
            return
          }
          if ((tempfile as Blob).size > size * 1024 * 1024) {
            toast('이미지 용량을 확인하세요.')
            return
          }

          const formBody = new FormData()
          formBody.append('img_file', tempfile)
          formBody.append('img_type', isBadgeCombination ? 'COMBINATION' : 'NORMAL')

          mutation.mutateAsync(formBody as FormData).then(
            (res) => {
              const { response, result } = res
              if (!result || !response?.imgUrl) {
                toast('이미지 업로드 실패, 잠시 후 다시 시도해주세요.')
                return
              }
              if (onUploadSuccess !== undefined) {
                onUploadSuccess(res)
              }
              onSuccess(response?.imgUrl)
            },
            (err) => {
              if (onError !== undefined) {
                onError(err)
              }
            }
          )
        }
      }
    },
    [height, isBadgeCombination, mutation, onSuccess, size, width]
  )
  return (
    <div>
      <Upload
        className={styles.container}
        accept={'image/jpg,image/jpeg,image/png'}
        customRequest={customRequest}
        showUploadList={false}
      >
        <Button size="large" icon={<CameraOutlined />} block>
          이미지 등록하기
        </Button>
      </Upload>
      {!!size && !!width && !!height && (
        <em className={styles.helper}>{`${size}MB 이하 / JPEG, JPG, PNG 파일 소재 사이즈 ${width}*${height} 픽셀`}</em>
      )}
      <p className={styles.errMessage}>{errMessage}</p>
    </div>
  )
}
