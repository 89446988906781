import { useState } from 'react'
// import { theme } from 'antd'

// TODO: 추후 어드민에서 입력  하는 이미지 사이즈에  동적으로 반응하게 하려고 사이즈를 빼둔다.
const imageWidth = 2880 // from admin
const imageHeight = 760 // from admin
const downScaleRatio = 0.25 // 개발자가 지정한 상수
// 대상 화면 크기 -> wadiz_frontend/~/$breakpoints-map 의 선언을 기준으로 함.
const minimumWidth = 360
const minimumHeight = 206
const maximumWidth = 1920
const maximumHeight = 356
const mobileBaseWidth = 480 + 1
const mobileBaseHeight = 206
const tabletBaseWidth = 768 + 1
const tabletBaseHeight = 400
const desktopBaseWidth = 1096 + 1
const wideDesktopBaseWidth = 1200 + 1
const desktopBaseHeight = 352.398
// 사라지는 영역 크기
const oneSideMissingWidth = ((imageWidth - (minimumWidth * imageHeight) / minimumHeight) / 2) * downScaleRatio
const oneSideMissingHeight = ((imageHeight - (imageWidth * maximumHeight) / maximumWidth) / 2) * downScaleRatio
// 화면에 보이는 이미지 비율
const displayWidth = Math.round(imageWidth * downScaleRatio)
const displayHeight = Math.round(imageHeight * downScaleRatio)
// 계산된 스타일
export const previewPanelStyle = { width: `${displayWidth + 8}px`, height: `${displayHeight + 8}px` }
export const previewAreaStyle = {
  width: `${displayWidth}px`,
  height: `${displayHeight}px`,
}
export const missingHorizontalStyle = {
  width: `${oneSideMissingWidth}px`,
  height: `${displayHeight}px`,
}
export const missingVerticalStyle = {
  width: `${displayWidth}px`,
  height: `${oneSideMissingHeight}px`,
}

type BoxStyleProps = {
  height: string
  width: string
  left: string
  top: string
  borderTopWidth: string
  borderBottomWidth: string
  borderLeftWidth: string
  borderRightWidth: string
}
type CalcDisplayAreaFunction = (value: number) => BoxStyleProps
const cutHorizontalArea: CalcDisplayAreaFunction = (targetWidth) => {
  const gap = (imageWidth - targetWidth) / 2
  return {
    height: `${displayHeight}px`,
    width: `${Math.round(imageWidth * downScaleRatio)}px`,
    left: '0px', //`${Math.round((gap - gap / 2) * downScaleRatio)}px`,
    top: '0px',
    borderTopWidth: `0px`,
    borderBottomWidth: `0px`,
    borderLeftWidth: `${Math.round(gap * downScaleRatio)}px`,
    borderRightWidth: `${Math.round(gap * downScaleRatio)}px`,
  }
}
const cutVerticalArea: CalcDisplayAreaFunction = (targetHeight) => {
  const gap = (imageHeight - targetHeight) / 2
  return {
    height: `${Math.round(imageHeight * downScaleRatio)}px`,
    width: `${displayWidth}px`,
    left: '0px',
    top: '0px', //`${(gap - gap / 2) * downScaleRatio}px`,
    borderTopWidth: `${Math.round(gap * downScaleRatio)}px`,
    borderBottomWidth: `${Math.round(gap * downScaleRatio)}px`,
    borderLeftWidth: '0px',
    borderRightWidth: '0px',
  }
}
const sameDisplayImageArea: () => BoxStyleProps = () => ({
  height: `${displayHeight}px`,
  width: `${displayWidth}px`,
  left: '0px',
  top: '0px',
  borderTopWidth: '0px',
  borderBottomWidth: '0px',
  borderLeftWidth: '0px',
  borderRightWidth: '0px',
})
export const calcDisplayAreaStyle: CalcDisplayAreaFunction = (width) => {
  if (width < tabletBaseWidth) {
    return cutHorizontalArea((imageHeight * width) / mobileBaseHeight)
  }
  if (width < desktopBaseWidth) {
    return cutHorizontalArea((imageHeight * width) / tabletBaseHeight)
  }
  const targetHeight = (imageWidth * desktopBaseHeight) / width
  if (targetHeight >= imageHeight) {
    return sameDisplayImageArea()
  }
  return cutVerticalArea(targetHeight)
}

type ContentProps = { paddingLeft: number; paddingTop: number; largeSize: boolean; scale: number }
type CalcContentPropsFunction = (width: number) => ContentProps
export const calcContentProps: CalcContentPropsFunction = (width) => {
  if (width < tabletBaseWidth) {
    const scale = Math.round((imageHeight / mobileBaseHeight) * downScaleRatio * 100) / 100
    const boxHeight = 136 // small size
    return {
      paddingLeft: 16 * scale,
      paddingTop: displayHeight - (boxHeight + 16) * scale,
      largeSize: false,
      scale,
    }
  }

  if (width < desktopBaseWidth) {
    const scale = Math.round((imageHeight / tabletBaseHeight) * downScaleRatio * 100) / 100
    const boxHeight = 160 // large size
    return {
      paddingLeft: 44 * scale,
      paddingTop: displayHeight - (boxHeight + 45) * scale,
      largeSize: true,
      scale,
    }
  }

  const valuablePadding = width - 80 * 2
  const wideScreenWidth = 1280
  const scale = Math.round((imageWidth / width) * downScaleRatio * 100) / 100
  const boxHeight = 160 // large size
  return {
    paddingLeft:
      (valuablePadding <= wideScreenWidth ? 80 : Math.round(((width - wideScreenWidth) / 2) * 100) / 100) * scale,
    paddingTop: (boxHeight - 25) * scale,
    largeSize: true,
    scale,
  }
}

export type SizerProps = {
  modalWidth: number
  boxStyle: BoxStyleProps
  contentProps: ContentProps
  sliderProp: {
    min: number
    max: number
    defaultValue: number
    onChange: (value: number) => void
  }
}
export const useSizer: () => SizerProps = () => {
  const [boxStyle, setBoxStyle] = useState(calcDisplayAreaStyle(desktopBaseWidth))
  const [contentProps, setContentProps] = useState(calcContentProps(desktopBaseWidth))
  // // const { useToken } = theme
  // // const { token } = useToken()
  // FIXME: theme 라이브러리를 import 하면 때에 따라서 antd/lib/theme/style 을 가져오지 못한다는 에러가 발생한다. 근데 원래 없다. ...
  // 일단, 값을 antd의 디폴트값으로 넣어두고 차후 수정한다.
  const token = { paddingContentHorizontalLG: 24 }
  return {
    modalWidth: displayWidth + 10 + token.paddingContentHorizontalLG * 2,
    boxStyle,
    contentProps,
    sliderProp: {
      min: minimumWidth,
      max: maximumWidth,
      defaultValue: desktopBaseWidth,
      onChange: (value: number) => {
        setBoxStyle(calcDisplayAreaStyle(value))
        setContentProps(calcContentProps(value))
      },
    },
  }
}
