import React from 'react'

// Components
import Tooltip from '../../Tooltip'
import { stringDateToyymm, stringDateToyymmddhhmmss } from '../../../datetostring'

// CSS module
import className from 'classnames/bind'
import style from '../SettingContent.module.scss'
import PaginatorContainer from '../../../containers/PaginatorContainer/PaginatorContainer'
import Lnb from '../../Lnb'
import NavBar from '../../NavBar'
import LoadingBar from '../../LoadingBar/LoadingBar'
const cx = className.bind(style)

const navList = [
  { name: '설정', link: '/main/ad/settings/' },
  { name: '세금계산서 발행 현황', link: '/main/ad/settings/taxbillStatus' },
]

const TaxBillStatus = ({
  checkRegistrationNumber,
  makerInfo,
  invoiceList,
  selectedOption,
  totalInvoiceListCount,
  openGuide,
  loadingBarFlag,
  onChangeOpenGuideType,
  onChangeInvoiceStatus,
  onChangeInvoiceEmail,
  onClickUpdateInvoiceEmail,
  onClickTaxBillDetailModalOpen,
  onChangePageNumber,
}) => {
  return (
    <main className={cx('main_container', 'settings')}>
      <Lnb type="settings" />
      <div className={cx('content_wrap')}>
        <NavBar list={navList} />
        {loadingBarFlag ? (
          <LoadingBar />
        ) : (
          <>
            {/* 컨텐츠 시작 */}
            {!checkRegistrationNumber ? (
              <>
                {/* 세금계산서가 없을때 */}
                <div className={cx('report_empty')}>
                  <h2>
                    사업자 정보(기업 정보)가 있는 메이커님이 광고를 만들면, <br></br>
                    세금계산서 발행을 신청할 수 있습니다.
                  </h2>
                  <p>
                    사업자 정보(기업 정보)를 먼저 확인 후, 광고를 만드시기 바랍니다.<br></br>
                    {/* 사업자 정보는 <a href="#!" className={cx('point_link_blue')}>이곳</a>에서 변경할 수 있습니다. */}
                  </p>
                </div>
              </>
            ) : (
              <>
                {/* 세금계산서가 있을때 */}
                <div className={cx('settings_taxBill')}>
                  <hgroup>
                    <h2>세금계산서 발행 현황</h2>
                    <p>
                      와디즈에서 발행한 세금계산서 현황입니다.<br></br>
                      세금계산서 관련 문의 사항은{' '}
                      <a
                        href={process.env.REACT_APP_WADIZ_HELP_CENTER}
                        target="_blank"
                        className={cx('point_link_blue')}
                        rel="noreferrer"
                      >
                        광고 문의하기
                      </a>{' '}
                      로 문의 부탁드립니다.
                    </p>
                  </hgroup>
                  <section>
                    <div
                      className={cx('guide_wrap', { active: openGuide.type === 'taxbillstatus' })}
                      onClick={() => onChangeOpenGuideType(0, 'taxbillstatus')}
                    >
                      <h3>세금계산서 발급 여부</h3>
                      <Tooltip
                        title="세금 계산서 발급 여부"
                        text={
                          '세금 계산서 발급을 위해서는 기업정보(사업자정보)가 필요합니다. 세금 계산서를 받으시려면 체크하세요.'
                        }
                      />
                    </div>
                    <label className={cx('wz checkbox circular', 'taxbill_checkbox')}>
                      <input
                        type="checkbox"
                        name="pseudo2"
                        value={2}
                        checked={makerInfo.invoiceYn === 2}
                        onChange={() => onChangeInvoiceStatus()}
                      />
                      <span>세금 계산서를 받겠습니다.</span>
                    </label>
                  </section>
                  <section>
                    <div
                      className={cx('guide_wrap', { active: openGuide.type === 'emailinfo' })}
                      onClick={() => onChangeOpenGuideType(0, 'emailinfo')}
                    >
                      <h3>이메일 정보</h3>
                      <Tooltip
                        title="이메일 정보"
                        text={
                          '세금계산서를 발급받을 이메일주소를 입력하세요. 최초에는 로그인 아이디(이메일)이 노출됩니다.'
                        }
                      />
                    </div>
                    <div className={cx('wz input')}>
                      <input
                        type="text"
                        value={makerInfo.invoiceEmail}
                        maxLength="50"
                        onChange={(e) => onChangeInvoiceEmail(e.target.value)}
                      />
                      <button
                        type="button"
                        className="wz icon button radius"
                        onClick={() => onClickUpdateInvoiceEmail()}
                      >
                        수정
                      </button>
                    </div>
                  </section>
                  <section className={cx('wz container table', 'cancellation')}>
                    <div
                      className={cx('guide_wrap', { active: openGuide.type === 'issuehistory' })}
                      onClick={() => onChangeOpenGuideType(0, 'issuehistory')}
                    >
                      <h3>발행내역</h3>
                      <Tooltip
                        title="발행내역"
                        text={
                          <>
                            관리자가 등록한 세금계산서 발급내역입니다. 혹시, 변경 또는 문의 사항이 있으면{' '}
                            <a href="mailto:ad@wadiz.kr">ad@wadiz.kr</a>로 문의주세요.
                          </>
                        }
                      />
                    </div>
                    <div className={cx('wz table track')}>
                      <table className={cx('wz structured table', 'ad_table')}>
                        <caption>발행내역</caption>
                        <thead>
                          <tr>
                            <th style={{ width: '52px' }}>No.</th>
                            <th style={{ width: '147px' }}>계산서 번호</th>
                            <th style={{ width: '96px' }}>발행일</th>
                            <th style={{ width: '136px' }}>사업자 등록번호</th>
                            <th style={{ width: '100px' }}>공급월</th>
                            <th style={{ width: '130px' }}>공급가액</th>
                            <th style={{ width: '130px' }}>부가세액</th>
                            <th style={{ width: '183px' }}>총금액</th>
                            <th style={{ width: '100px' }}>상태</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceList.length > 0 ? (
                            invoiceList.map((val, idx) => (
                              <tr key={idx}>
                                <td>{invoiceList.length - idx}</td>
                                <td>{val.invoiceNum ? val.invoiceNum : '-'}</td>
                                <td>
                                  {/* 발행일 */}
                                  {val.pubDate && val.pubDate !== '-' ? (
                                    <>
                                      {stringDateToyymmddhhmmss(val.pubDate)[0]}
                                      <span className={cx('overview_table_time')}>
                                        {stringDateToyymmddhhmmss(val.pubDate)[1]}
                                      </span>
                                    </>
                                  ) : (
                                    '-'
                                  )}
                                </td>
                                <td>{val.registrationNumber ? val.registrationNumber : '-'}</td>
                                <td>{stringDateToyymm(val.supMonth, '-')}</td>
                                <td>{val.supPrice.toLocaleString()}원</td>
                                <td>{val.vat.toLocaleString()}원</td>
                                <td>{val.totalPrice.toLocaleString()}원</td>
                                <td className={cx('point_primary')} onClick={() => onClickTaxBillDetailModalOpen(val)}>
                                  {val.status === 1 ? '미발행' : '발행완료'}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className={cx('error_table')} colSpan="9">
                                <div>
                                  <i className={cx('icon error-o')}></i>세금계산서 발행 내역이 없습니다.
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {invoiceList.length > 0 && (
                      <PaginatorContainer
                        onChange={onChangePageNumber}
                        activePage={selectedOption.start}
                        totalCount={totalInvoiceListCount}
                        viewCount={selectedOption.size}
                      />
                    )}
                  </section>
                </div>
                {/* 컨텐츠 끝 */}
              </>
            )}
          </>
        )}
      </div>
    </main>
  )
}

export default TaxBillStatus
