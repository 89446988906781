import { api } from '@wad/api'

type ReturnType = {
  data: unknown
}

type ParameterType = {
  wdzStoreProjectId: number
  adMakerId: number
}

type FunctionType = (param: ParameterType) => Promise<boolean>
export const getWadizOnlyCondition: FunctionType = ({ adMakerId, wdzStoreProjectId }) =>
  api('GET', `/makers/${adMakerId}/store-projects/${wdzStoreProjectId}`).then(
    (res) => (res as ReturnType)?.data?.wadizOnly as boolean
  )
