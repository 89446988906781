import { useQuery, UseQueryResult } from '@tanstack/react-query'
import type { FunctionKeywordValidation, KeywordValidationReturnType, KeywordValidationParam } from '@wad/api'

type FunctionType = (param: {
  validationData: KeywordValidationParam
  fetchGetKeywordValidation: FunctionKeywordValidation
  enabled: boolean
  onSettled: (data?: KeywordValidationReturnType, error?: unknown) => void
}) => UseQueryResult<KeywordValidationReturnType>
export const useValidationKeyword: FunctionType = ({ validationData, fetchGetKeywordValidation, enabled, onSettled }) =>
  useQuery({
    queryKey: ['validationKeyword', validationData],
    queryFn: () => fetchGetKeywordValidation(validationData),
    enabled,
    onSettled,
  })
