import React from 'react'
import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import className from 'classnames/bind'
import { AreaType } from '@legacy/definitions'
import { AdStaticPreview } from '@common/ui'
import { KeywordAdField } from '@legacy/share/ui'
import { useBusinessDays } from '@center/query'
import { numberFormat } from '@wadiz-frontend/utils-modified'
import {
  ImageUploadButton, AdvertisementMatter,
  AdCopy,
  AdGoods,
  AdSelectDate,
  AdBgColorList,
  AdLandingUrl,
} from '@center/ui';

import { fetchGetKeyword, fetchGetKeywordValidation, fetchGetPopularKeyword } from '../../../pages/_api'
import style from './ModifyAdStep1.module.scss'
import PreviewContainer from '../../../containers/PreviewContainer/PreviewContainer'
const cx = className.bind(style)

const discountInfoFormat = (accountData, isDiscounted) => {
  const { pricingType, impNum, discountPrice, impPeriod, price } = accountData

  if (pricingType === 'CPT') return `${impPeriod}일 ${numberFormat(isDiscounted ? discountPrice : price)}원`
  return `${impPeriod}일 ${numberFormat(isDiscounted ? discountPrice : price)}원 ${impNum}회 노출`
}

const dateFormat = 'YYYY-MM-DD'
// o: 영업일, h: 비영업일

const ModifyAdStep1 = ({
  isWadizAdmin,
  handleChangeProject,
  handleOpenGuideType,
  handleChangeAdName,
  adAccountListDel,
  onSelectGoods,
  onSelectDiscountCoupon,
  imgDel,
  handleClickDatepicker,
  imgFileSel,
  handleChangeMainCopy,
  handleChangeKeyword,
  handleChangeSubCopy,
  handleChangeLandingUrl,
  selectColor,
  handleAdAccountModalOpen,
  handleClickPrevButton,
  onChangeDateRange,
  onFocusChange,
  handleOpenReservationPaymentDialog,
  accountList,
  onChangeLandingURL,
  makerInfo,
  setImage,
  ...rest
}) => {
  const {
    openGuide,
    projectId,
    projectList,
    selectProjectErrMsg,
    adName,
    changeAdNameErrMsg,
    selectedAdAccountCardId,
    selectedAdAccountCardRef,
    projectType,
    lossText,
    // bgColorList,
    adsPrice,
    adsVat,
    totalPrice,
    landingUrl,
  } = rest
  const { data: businessDays } = useBusinessDays({ startDate: dayjs().format(dateFormat) })

  const packagedIdList = accountList.reduce((a, item) => [...a, ...(!item.packagedId ? [] : [item.packagedId])], [])
  packagedIdList.forEach((areaID) => {
    const slaveItem = accountList.find((a) => a.adCenterPubAreaId === areaID)
    if (!slaveItem) {
      return
    }
    slaveItem.isPackagedSlave = true
  })

  const handleSelectGoods = (goods, val, idx) => {
    onSelectGoods(val.goodsList.findIndex((i) => i.id === goods.id).toString(), idx)
  }

  const handleDate = (date, normalDays, holidays, idx) => {
    onChangeDateRange(date.startDate, date.endDate, idx, { normalDays, holidays })
  }

  return (
    <div className={cx('modify_ad')}>
      <hgroup>
        <h2>광고 수정요청하기</h2>
        <p>
          계약이 체결 중이므로, 광고 상품의 추가・삭제・기간 수정이 불가합니다. 광고 소재 혹은 결제 정보만 수정할 수
          있습니다.
        </p>
      </hgroup>
      {/* 프로젝트 선택 */}
      <section className={cx('wz form', 'ad_element')}>
        <div
          className={cx('guide_wrap', { active: openGuide.type === 'selectProject' })}
          onClick={() => handleOpenGuideType(-1, 'selectProject')}
        >
          <h3>광고할 프로젝트 선택하기</h3>
        </div>
        <div className={cx('field')}>
          <label className={cx('wz', 'disabled', 'outline', 'block', 'dropdown')}>
            <select value={projectId} onChange={handleChangeProject} disabled>
              {projectList.map((val, idx) => (
                <option key={idx} value={val.id}>
                  {val.projectName}
                </option>
              ))}
            </select>
          </label>
          <p className={cx('msgError', 'point_red')}>{selectProjectErrMsg}</p>
        </div>
      </section>
      {/* 프로젝트 광고명 수정 */}
      <section className={cx('wz form', 'ad_element')}>
        <div
          className={cx('guide_wrap', { active: openGuide.type === 'editAdName' })}
          onClick={() => handleOpenGuideType(-1, 'editAdName')}
        >
          <h3>광고프로젝트 명 수정하기</h3>
        </div>
        <div className={cx('wz input', 'field')}>
          <input
            type="text"
            id="pseudoText"
            name="pseudo-text"
            maxLength="50"
            value={adName}
            onChange={handleChangeAdName}
          />
          <em className={cx('helper')}>{`${adName ? adName.length : 0}/50자`}</em>
          <p className={cx('msgError', 'point_red')}>{changeAdNameErrMsg}</p>
        </div>
      </section>
      <section>
        <div className={cx('cautionMsg')}>
          <i className={'icon error'}></i> 지금 작성 중인 페이지를 닫거나 MY 광고 관리로 이동할 경우, 작성 중이던 내용은
          저장되지 않습니다.
        </div>
      </section>
      {accountList.length > 0 ? (
        <>
          {accountList.map((val, idx) => (
            <div
              key={idx}
              className={cx(
                'account_card',
                'account_card_modify',
                { cardModifyReviewing: val.adState === 'Submitted' && !isWadizAdmin },
                { cardModifyScheduled: val.adProcessStatus === 1 && !isWadizAdmin },
                { cardModifyCanceled: val.adProcessStatus === 3 && val.status !== 1 },
                { cardModifyAdvertising: val.adProcessStatus === 2 && !isWadizAdmin },
                { cardModifyCompleted: val.adProcessStatus === 4 && !isWadizAdmin }
              )}
            >
              {((selectedAdAccountCardId === 0 && idx === 0) ||
                (selectedAdAccountCardId !== 0 && val.id && val.id === selectedAdAccountCardId)) && (
                  <div ref={selectedAdAccountCardRef} />
                )}
              <h4>
                {val.shared ? val.packageName : val.area}
              </h4>
              <div className={cx('account_cardForm')}>
                {/* 광고상품 */}
                {val.isPackagedSlave !== true && (
                  <section>
                    <AdGoods
                      defaultGoods={val.goodsList[val.goodsIdx]}
                      goodsList={val.goodsList}
                      handleGoods={(goods) => handleSelectGoods(goods, val, idx)}
                      disabled
                    />
                  </section>
                )}
                {/* 광고기간 */}
                {val.isPackagedSlave !== true && (
                  <section>
                    <AdSelectDate
                      selectedGoods={val}
                      selectedDate={{ startDate: val.startAt, endDate: val.endAt }}
                      businessDays={businessDays?.result || []}
                      handleDate={(date, normalDays, holidays) => handleDate(date, normalDays, holidays, idx)}
                      price={val.selectedGoods.price || 0}
                      holidayPrice={val.selectedGoods.holidayPrice || 0}
                      impMinPeriod={val.selectedGoods.impMinPeriod}
                      errorMsg={val.dateErrMsg}
                      disabled
                    />
                  </section>
                )}
                {val.discountCoupon.length > 0 && (
                  <>
                    <section className={cx('wz form', 'ad_element', 'account_card_discount_coupon')}>
                      <div
                        htmlFor="id"
                        className={cx('guide_wrap', {
                          active: openGuide.idx === idx && openGuide.type === 'discountCoupon',
                        })}
                        onClick={() => handleOpenGuideType(idx, 'product')}
                      >
                        <label>할인코드 선택</label>
                      </div>
                      <div className={cx('field')}>
                        <label className={cx('wz outline block dropdown')}>
                          <select onChange={(e) => onSelectDiscountCoupon(e.target.value, idx)} value={val.discountIdx}>
                            {val.discountCoupon.map((discountCouponVal, discountCouponIdx) => (
                              <option key={discountCouponIdx} value={discountCouponIdx}>
                                {discountCouponVal.discountCode}
                              </option>
                            ))}
                            <option value="">할인코드를 선택하지 않습니다.</option>
                          </select>
                        </label>
                        <p className={cx('msgError', 'point_red')}>{val.nameErrMsg}</p>
                      </div>
                    </section>
                    <section className={cx('wz form', 'ad_element', 'account_card_discount_coupon')}>
                      <div
                        htmlFor="id"
                        className={cx('guide_wrap', {
                          active: openGuide.idx === idx && openGuide.type === 'discountCoupon',
                        })}
                        onClick={() => handleOpenGuideType(idx, 'product')}
                      >
                        <label>할인금액</label>
                      </div>
                      <div className={cx('field')}>
                        <div className={cx('wz icon input disabled')}>
                          <input
                            type="text"
                            name="pseudo-text"
                            value={
                              val.discountPrice || val.discountPrice === 0
                                ? discountInfoFormat(val, true)
                                : discountInfoFormat(val, false)
                            }
                            autoComplete="off"
                            disabled
                          />
                        </div>
                        <em className={cx('helper')}>* 할인코드를 입력하시면 할인금액이 계산됩니다.</em>
                      </div>
                    </section>
                  </>
                )}
                {/* XXX: 일단, 모든 데이터가 여기로 몰리므로 이 부분에서 데이터를 가져다가 그림을 그린다. 추후 모듈화 시기에는 아래 서술된 데이터들을 react query로 정리한다. */}
                {(val.adCenterPubAreaId === AreaType.mainHome ||
                  val.adCenterPubAreaId === AreaType.fundingHome ||
                  val.adCenterPubAreaId === AreaType.comingSoonHome ||
                  val.adCenterPubAreaId === AreaType.preOrderBanner) && (
                    <>
                      <AdvertisementMatter
                        areaType={val.adCenterPubAreaId}
                        getManualUrl={() => val.manualUrl}
                        haveImage={val.info.imageYn !== 0}
                        imageUrl={val.imgUrl}
                        imageSize={val?.info?.imageSize}
                        imageWidth={val?.info?.imageWidth}
                        imageHeight={val?.info?.imageHeight}
                        imageErrMsg={val.imageErrMsg}
                        isBadgeCombination={(() => {
                          const { goodsIdx, goodsList, adCenterPubAreaId } = val
                          const { badgeType } = goodsList[goodsIdx]
                          return badgeType === 'Popularity' && adCenterPubAreaId === AreaType.comingSoonList
                        })()}
                        onImageUploadSuccess={(imgUrl) => setImage(imgUrl, idx)}
                        onCloseImage={() => imgDel(idx)}
                        haveColor={val.info.bgColorYn !== 0}
                        haveMainCopy={val.info.mainCopyYn !== 0}
                        mainCopy={val.mainCopy}
                        haveSubCopy={val.info.subCopyYn !== 0}
                        subCopy={val.subCopy}
                        haveKeyword={val.info.adCenterPubAreaId === AreaType.searchKeyword}
                        keyword={val.keyword}
                      />
                      {val.imgUrl && val.adCenterPubAreaId !== AreaType.deprecated2 && (
                        <div className={cx('cautionMsg')} style={{ marginTop: 0 }}>
                          ※ 광고센터 미리보기 화면에서 보이는 지면과 실제 게재지면과의 차이가 있을 수 있습니다. 소재의
                          위아래가 잘려 보이지 않도록 제품 및 중요 이미지를 배너 중앙에 배치해주세요.
                        </div>
                      )}
                    </>
                  )}
                {val.adCenterPubAreaId !== AreaType.mainHome &&
                  val.adCenterPubAreaId !== AreaType.fundingHome &&
                  val.adCenterPubAreaId !== AreaType.comingSoonHome &&
                  val.adCenterPubAreaId !== AreaType.preOrderBanner &&
                  val.info.imageYn === 1 && (
                    <>
                      {/* 이미지 선택 */}
                      <section className={cx('wz form', 'ad_element', 'account_card_img')}>
                        <div className={cx('guide_wrap')}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              borderBottom: '2px dotted #dadce0',
                            }}
                          >
                            <div style={{ fontSize: '17px' }}>광고 소재</div>
                            <Button type="text" icon={<DownloadOutlined />} onClick={() => window.open(val.manualUrl)}>
                              {'영역별 광고 소재 제작 가이드 확인하기'}
                            </Button>
                          </div>
                        </div>
                        <div className={cx('field')}>
                          {!val.imgUrl && (
                            <>
                              <ImageUploadButton
                                onChange={(e) => {
                                  imgFileSel(e, idx, val.info.imageWidth, val.info.imageHeight, val.info.imageSize)
                                }}
                              />
                              <em className={cx('helper')}>
                                {val.info.imageSize}MB 이하 / JPEG, JPG, PNG 파일 소재 사이즈 {val.info.imageWidth}*
                                {val.info.imageHeight} 픽셀
                              </em>
                              <p className={cx('msgError', 'point_red')}>{val.imageErrMsg}</p>
                            </>
                          )}
                          {val.imgUrl && val.adCenterPubAreaId !== AreaType.deprecated2 && (
                            <PreviewContainer
                              mainCopy={val.mainCopy}
                              keyword={val.keyword}
                              subCopy={val.subCopy}
                              imgUrl={val.imgUrl}
                              bgColor={val.bgColor}
                              area={val.area}
                              accountNum={val.accountNum}
                              adCenterPubAreaId={val.adCenterPubAreaId}
                              imgDel={imgDel}
                              imgDelIdx={idx}
                              projectType={projectType}
                              lossText={lossText}
                            />
                          )}
                        </div>
                        {val.imgUrl && val.adCenterPubAreaId !== AreaType.deprecated2 && (
                          <div className={cx('cautionMsg')}>
                            ※ 광고센터 미리보기 화면에서 보이는 지면과 실제 게재지면과의 차이가 있을 수 있습니다. 소재의
                            위아래가 잘려 보이지 않도록 제품 및 중요 이미지를 배너 중앙에 배치해주세요.
                          </div>
                        )}
                      </section>
                    </>
                  )}
                {/* 배경색 */}
                {
                  val.info?.bgColorYn === 1 && val.bgColor && (
                    <section>
                      <AdBgColorList
                        defaultColor={val.bgColor}
                        bgColorDataList={val.bgColorList}
                        handleBgColor={(bgColorData) => selectColor(bgColorData, idx)}
                        errorMsg={val.backgroundColorErrMsg}
                      />
                    </section>
                  )
                }
                {/* 미리보기 - 고정 이미지 인 경우 */}
                <AdStaticPreview areaId={val.adCenterPubAreaId} />
                {/* 키워드 */}
                {val.adCenterPubAreaId === AreaType.searchKeyword && (
                  <section>
                    <KeywordAdField
                      {...{ fetchGetKeyword, fetchGetKeywordValidation, fetchGetPopularKeyword }}
                      {...{ creative_id: val.id, start_date: val.startAt, end_date: val.endAt }}
                      keyword={val.keyword}
                      onChange={(value) => handleChangeKeyword(value, idx)}
                      disabled
                    />
                  </section>
                )}
                {/* 메인카피 */}
                {val.info?.mainCopyYn === 1 && (
                  <section>
                    <AdCopy
                      title="메인카피"
                      defaultValue={val.mainCopy || ''}
                      rows={val.info.mainCopyLineNum}
                      maxLength={val.info.mainCopyCharNum}
                      handleCopy={(value) => handleChangeMainCopy(value, idx)}
                      errorMsg={val.mainCopyErrMsg}
                      areaId={val.info.adCenterPubAreaId}
                    />
                  </section>
                )}
                {/* 서브카피 */}
                {val.info?.subCopyYn === 1 && (
                  <section>
                    <AdCopy
                      title="서브카피"
                      defaultValue={val.subCopy || ''}
                      rows={val.info.subCopyLineNum}
                      maxLength={val.info.subCopyCharNum}
                      handleCopy={(value) => handleChangeSubCopy(value, idx)}
                      errorMsg={val.subCopyErrMsg}
                      areaId={val.info.adCenterPubAreaId}
                    />
                  </section>
                )}
                <section>
                  <AdLandingUrl
                    adCenterPubAreaId={val.adCenterPubAreaId}
                    makerInfo={makerInfo}
                    projectDetailURL={landingUrl}
                    makerPageUrlSelected={val.makerPageUrlSelected}
                    isWadizAdmin={isWadizAdmin}
                    handleLandingURL={onChangeLandingURL}
                    inputTextChange={(e) => handleChangeLandingUrl(e, idx)}
                  />
                </section>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className={cx('ad_listNull')}>
          <i className={cx('icon error-o')}></i>
          <p>아래 버튼을 클릭하여 광고 등록을 시작해 주세요.</p>
        </div>
      )}
      <div className={cx('ad_payment')}>
        <div className={cx('payment_count')}>
          <h3>광고 예상 금액</h3>
          <p>
            광고 금액 <em>{numberFormat(adsPrice)}원</em>
            <br></br>
            부가세 <em>{numberFormat(adsVat)}원</em>
          </p>
          <strong>
            {numberFormat(totalPrice)}
            <small>원</small>
          </strong>
        </div>
        <button type="button" className={cx('wz button primary')} onClick={handleOpenReservationPaymentDialog}>
          수정요청 및 결제 예약하기
        </button>
        <p>
          <i className={cx('icon error-o')}></i>
          광고 프로젝트의 수정요청이 접수되며, 수정요청 결과는 <em className={cx('point_primary')}>최대 2영업일 내</em>
          안내드립니다.
          <span className={cx('point_red')}>
            아직 최종 결제 단계가 아닙니다. <br /> 접수요청서 서명 및 광고 프로젝트 최종 승인 시 예약된 광고비가
            결제됩니다.
          </span>
        </p>
      </div>
      <div className={cx('buttonArea')}>
        <button
          type="button"
          className={cx('wz button less', 'button_historyBack')}
          onClick={() => handleClickPrevButton(0)}
        >
          <i className={cx('icon chevron-left')} />
          이전
        </button>
      </div>
    </div>
  )
}

export default ModifyAdStep1
