import React, { PureComponent } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom-v5'
import { connect } from 'react-redux'
import { getProjectList } from '../_api'
import { resetActivatedAdInfo } from '../../reducers/adListReducer'
import { setAdStatus } from '../../reducers/adStatusReducer'
import AdCreatePage from '../CreatePage/AdCreatePage'
import AdListPage from '../AdListPage'
import AdModifyPage from '../ModifyPage/AdModifyPage'
import AdModifyPage2 from '../ModifyPage/AdModifyPage2'
import AccountManagePage from '../AccountManagePage'
import { IntroPage, ErrorPage, HelpPage } from '..'
import {
  AdCostDetailPage,
  AdCostReportPage,
  AdFavoriteReportPage,
  AdResultDetailPage,
  // AdResultReportPage as ResultReportPage,
  ResultReportPage,
} from '../ReportPages'
import {
  SettingTaxBillStatusPage,
  SettingBusinessInfoPage,
  SettingRefundAccountPage,
  SettingSimpleAccountPage,
  SettingTermsPage,
} from '../SettingPages'

import { network } from '../../network'
import LoadingBar from '../../components/LoadingBar/LoadingBar'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ErrorBoundary from './ErrorBoundary'
class MainPage extends PureComponent {
  state = {
    adStatus: 'loading', // no_project(광고와 프로젝트 모두 없음), no_ad(광고만 없음), ad (광고가 있음)
  }

  componentDidMount() {
    this.getProjectList()
  }

  getProjectList = () => {
    const { maker_id: makerId } = this.props.user
    const { pathname } = this.props.location
    getProjectList({ makerId }).then((projectList) => {
      const url = `project/list?maker_id=${makerId}`
      network.callApi({
        url,
        method: 'get',
        successFunc: ({ result: adList }) => {
          let adStatus = 'loading'
          if (projectList.length === 0 && pathname.indexOf('/main/ad/create') < 0) {
            adStatus = adList.length > 0 ? 'no_ad' : 'no_project'
          } else if (projectList.length !== 0) {
            adStatus = this.checkAdvertisementLength(projectList) ? 'ad' : 'no_ad'
            this.props.resetActivatedAdInfo()
          }

          this.props.setAdStatus(adStatus)
          this.setState({ adStatus })
        },
        failFunc: () => {
          console.error(`fail- ${url}`)
        },
      })
    })
  }

  checkAdvertisementLength = (projectList) => {
    let checkAdvertisementFlag = false // true: 광고 있음, false: 광고 없음
    for (let i = 0; i < projectList.length; i += 1) {
      if (projectList[i].advertisementList.length > 0) {
        checkAdvertisementFlag = true
        break
      }
    }
    return checkAdvertisementFlag
  }

  render() {
    const { adStatus } = this.state
    const { pathname: currentPathname } = this.props.location

    return (
      <ErrorBoundary>
        {currentPathname.indexOf('/intro') < 0 && <Header />}
        {adStatus === 'loading' ? (
          <LoadingBar />
        ) : (
          <Switch>
            <Redirect exact from="/main" to="/main/ad" />
            <Redirect exact from="/main/ad" to={`/main/intro/${adStatus}`} />
            <Route path="/main/intro/:target" render={(props) => <IntroPage {...props} />} />
            <Route path="/main/ad/modify/:id" render={(props) => <AdModifyPage {...props} />} />
            <Route path="/main/ad/modify2/:id" render={(props) => <AdModifyPage2 {...props} />} />
            <Route path="/main/ad/list" render={(props) => <AdListPage {...props} />} />
            <Redirect exact from="/main/ad/create" to="/main/ad/create/step1" />
            <Route path="/main/ad/create/:step" render={(props) => <AdCreatePage {...props} />} />
            <Redirect exact from="/main/ad/report" to="/main/ad/report/result" />
            <Route exact path="/main/ad/report/result" render={(props) => <ResultReportPage />} />
            <Route path="/main/ad/report/result/detail/:id" render={(props) => <AdResultDetailPage {...props} />} />
            <Route exact path="/main/ad/report/cost" render={(props) => <AdCostReportPage {...props} />} />
            <Route path="/main/ad/report/cost/detail/:id" render={(props) => <AdCostDetailPage {...props} />} />
            <Route exact path="/main/ad/report/favorit" render={(props) => <AdFavoriteReportPage {...props} />} />
            <Redirect exact from="/main/ad/settings" to="/main/ad/settings/taxbillStatus" />
            <Route path="/main/ad/settings/taxbillStatus" render={(props) => <SettingTaxBillStatusPage {...props} />} />
            <Route path="/main/ad/settings/businessInfo" render={(props) => <SettingBusinessInfoPage {...props} />} />
            <Route path="/main/ad/settings/refundAccount" render={(props) => <SettingRefundAccountPage {...props} />} />
            <Route path="/main/ad/settings/simpleAccount" render={(props) => <SettingSimpleAccountPage {...props} />} />
            <Route path="/main/ad/settings/terms" render={(props) => <SettingTermsPage {...props} />} />
            <Route path="/main/ad/user/accountManage" render={(props) => <AccountManagePage {...props} />} />
            <Route path="/main/ad/user/help" render={(props) => <HelpPage {...props} />} />
            <Route component={ErrorPage} />
          </Switch>
        )}
        {currentPathname.indexOf('/main/intro') < 0 && <Footer />}
      </ErrorBoundary>
    )
  }
}
export default connect(
  ({ user, error }) => ({
    user,
    error,
  }),
  {
    resetActivatedAdInfo,
    setAdStatus,
  }
)(MainPage)
