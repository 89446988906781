import withIcon, { Props } from './utils/withIcon'

function RemoveIcon(props: Props) {
  return (
    <svg viewBox="0 0 16 2" {...props}>
      <path fillRule="evenodd" d="M0 0h16v2H0z" />
    </svg>
  )
}

export default withIcon('RemoveIcon')(RemoveIcon)
