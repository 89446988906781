import { api } from './api'

export type PerformanceType = {
  // https://wadiz.atlassian.net/wiki/spaces/WadizDev/pages/16006938817/API
  adCenterAdvertisementId: number // 광고 ID
  adName: string // 광고 이름
  projectId: number
  projectName: string // 프로젝트 이름
  area: string // 광고 영역
  startAt: string // 광고내 소재의 시작일 중 가장 빠른 시간
  endAt: string // 광고내 소재의 종료일 중 가장 느린 시간
  adPrice: number // 광고비
  numClick: number //클릭수
  numImp: number // 노출수
  ctr: number //클릭률
  numOpenAlarm: number // 오픈알림 신청
  numFunding: number // 전환수
  numFundingPrice: number // 전환 금액
}
type ResultReportListReturnType = {
  message: string | null
  totalCount: number | null
  result: Array<PerformanceType>
}
export type ResultReportListParameter = {
  makerId: number
  start: number
  size: number
  projectSort: number | undefined
  areaSort: number | undefined
}
type ResultReportListFunctionType = (param: ResultReportListParameter) => Promise<ResultReportListReturnType>
export const fetchResultReportList: ResultReportListFunctionType = ({ makerId, start, size, projectSort, areaSort }) =>
  api(
    'GET',
    `result-report/all/list?maker_id=${makerId}&start=${start}&size=${size}${
      projectSort === undefined ? '' : `&project_sort=${projectSort}`
    }${areaSort === undefined ? '' : `&area_sort=${areaSort}`}`
  )
