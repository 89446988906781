import { Select, Typography } from 'antd'
import { useEffect, useState } from 'react'

import { Goods } from '@center/api'
import { numberFormat } from '@wadiz-frontend/utils-modified'

interface SelectGoodsProps {
  goodsList: Goods[]
  defaultGoods?: Goods
  onSelect?: (goods: Goods) => void
  onChange?: (goods: Goods, idx: number) => void
  disabled?: boolean
}

const { Option } = Select
const { Title, Text } = Typography

export const SelectGoods: React.FC<SelectGoodsProps> = ({
  defaultGoods,
  goodsList = [],
  onSelect,
  onChange,
  disabled = false,
}) => {
  const [goods, setSelectGoods] = useState<null | Goods>({} as Goods)
  const handleSelect = (id: string) => {
    if (goods?.id === Number(id)) return
    const newGoods: Goods = goodsList.find((item) => item.id === Number(id))!

    if (onChange) {
      onChange(
        newGoods,
        goodsList.findIndex((item) => item.id === Number(id))
      )
      return
    }

    if (onSelect) {
      onSelect(newGoods)
      setSelectGoods(newGoods)
    }
  }
  const optionValue = (goods: Goods | null) => {
    if (!goods) return null
    return `${goods.impMinPeriod}일: 1일 기준 평일 ${numberFormat(goods.price)}원 / 휴일 ${numberFormat(
      goods.holidayPrice
    )}원  ${goods?.discount ? goods.discount : ''}`
  }
  const optionText = (goods: Goods | null) => {
    if (!goods) return null
    return (
      <div>
        <Text>
          {goods.impMinPeriod}일: 1일 기준 평일 {numberFormat(goods.price)}원 / 휴일 {numberFormat(goods.holidayPrice)}
          원
        </Text>
        {goods?.discount && (
          <Text strong style={{ color: '#00C4C4', marginLeft: 16 }}>
            {goods.discount}
          </Text>
        )}
      </div>
    )
  }

  useEffect(() => {
    setSelectGoods(null)
  }, [goodsList])

  useEffect(() => {
    if (defaultGoods) {
      setSelectGoods(defaultGoods)
    }
  }, [defaultGoods])

  return (
    <div style={{ marginBottom: 16 }}>
      {!defaultGoods && (
        <div style={{ marginBottom: 8 }}>
          <Title level={5}>광고 상품 선택 & 기간 설정</Title>
          <Text style={{ marginBottom: 40, display: 'block' }}>
            각 상품별 평일과 휴일(주말, 공휴일)의 광고 금액이 다르며, 아래 상품 금액은 부가세 별도이니 광고 집행 계획에
            참고해주세요.
          </Text>
          <Text>원하시는 상품을 선택해 주세요.</Text>
        </div>
      )}
      <div>
        <Select
          placeholder="상품을 선택하세요."
          onChange={handleSelect}
          disabled={disabled}
          value={optionValue(goods)}
          style={{ width: '100%' }}
        >
          {goodsList.map((item) => (
            <Option key={item.id} value={item.id}>
              {optionText(item)}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  )
}
