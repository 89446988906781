import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { type PerformanceType, fetchResultReportList } from '@center/api'
import { useUserQuery } from '@center/query'

export type PerformanceReportParams = {
  page: number
  pageSize: number
  projectName?: 'ascend' | 'descend' | undefined
  area?: 'ascend' | 'descend' | undefined
}

type PerformanceReportDataType = {
  totalCount: number
  list: Array<PerformanceType>
}

type FunctionType = (param: PerformanceReportParams) => UseQueryResult<PerformanceReportDataType>
export const usePerformanceReportQuery: FunctionType = (param) => {
  const { data: userInfo } = useUserQuery()
  const { id } = userInfo || {}
  const { page: start, pageSize: size, projectName, area } = param
  const requestParam = {
    makerId: id as number,
    start,
    size,
    projectSort: projectName === undefined ? undefined : projectName === 'ascend' ? 1 : 0,
    areaSort: area === undefined ? undefined : area === 'ascend' ? 1 : 0,
  }
  const requestKey = JSON.stringify(requestParam)
  return useQuery({
    queryKey: ['performanceReport', requestKey],
    queryFn: () =>
      fetchResultReportList(requestParam).then((res) => ({
        totalCount: res?.totalCount ?? 0,
        list: (res?.result || []).map((item) => ({
          makerId: id as number,
          ...item,
        })),
      })),
    enabled: !!id,
  })
}
