import React from 'react'
import className from 'classnames/bind'
import { Button } from 'antd'
import { AreaType } from '@legacy/definitions'
import { AppPushPreview, MainBoardPreview, CardKeyVisualPreview } from '@common/ui'
// import { MainBoardPreview as MainBoardPreviewDeprecated } from './MainBoardPreview'

import style from './Preview.module.scss'
import noimage from '../../assets/images/noimage.svg'

const cx = className.bind(style)

const printContent = (content) => {
  const tmpContent = content.split('\n')

  return tmpContent.map((val, idx) => (
    <React.Fragment key={idx}>
      {idx !== 0 && <br />}
      {val}
    </React.Fragment>
  ))
}

const Preview = ({
  // variables
  mainCopy,
  subCopy,
  imgUrl,
  bgColor,
  area,
  accountNum,
  adCenterPubAreaId,
  // preview modal open function
  handlePreviewModalOpen,
  // image delete function
  imgDel,
  imgDelIdx,

  lossText,
  projectType,
}) => {
  if (adCenterPubAreaId === AreaType.messagePush) {
    return (
      <div style={{ marginBottom: '40px' }}>
        <AppPushPreview mainCopy={mainCopy} subCopy={subCopy} />
      </div>
    )
  }

  if (
    adCenterPubAreaId === AreaType.mainBoard ||
    adCenterPubAreaId === AreaType.multiDetailContents ||
    adCenterPubAreaId === AreaType.multiDetailContents ||
    adCenterPubAreaId === AreaType.multiMyPage
  ) {
    return (
      <MainBoardPreview
        mainCopy={mainCopy}
        imageUrl={imgUrl}
        accountNum={accountNum}
        onDelete={imgDel ? () => imgDel(imgDelIdx) : null}
      />
    )
  }

  if (adCenterPubAreaId === AreaType.fundingHome || adCenterPubAreaId === AreaType.comingSoonHome) {
    return <CardKeyVisualPreview mainCopy={mainCopy} subCopy={subCopy} imageUrl={imgUrl} />
  }
  return (
    <div className={cx('list_preview')}>
      <div className={cx('previewTypeImg')}>
        <div>
          <p className={cx({ previewCopy: !(!mainCopy && !subCopy && !bgColor) })}>
            <strong>{printContent(mainCopy)}</strong>
            <small>{printContent(subCopy)}</small>
            <span className={cx('previewBg')} style={{ backgroundColor: bgColor }}>
              {bgColor}
            </span>
          </p>
          <img
            id={imgDelIdx !== undefined ? `fileImg${imgDelIdx}` : 'fileImg'}
            src={imgUrl && imgUrl !== '' ? imgUrl : noimage}
            alt="광고제목 넣어주세요"
          />
          {imgDel !== undefined && (
            <button type="button" className={cx('wz button', 'list_previewDel')} onClick={() => imgDel(imgDelIdx)}>
              <i className={cx('icon close')}></i>
              <span>이미지 삭제하기</span>
            </button>
          )}
        </div>
        <Button
          size="middle"
          onClick={() => {
            handlePreviewModalOpen(
              mainCopy,
              subCopy,
              imgUrl,
              bgColor,
              area,
              accountNum,
              adCenterPubAreaId,
              lossText,
              projectType
            )
          }}
          block
          style={{ marginTop: '4px' }}
        >
          광고 미리보기
        </Button>
      </div>
    </div>
  )
}

export default Preview
