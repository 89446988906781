import { combineReducers } from 'redux'
import user from './userReducer'
import dialog from './dialogReducer'
import alert from './alertReducer'
import adList from './adListReducer'
import secondDialog from './secondDialogReducer'
import projectMenu from './projectMenuReducer'
import error from './errorReducer'
import helpMenu from './helpMenuTypeReducer'
import adStatus from './adStatusReducer'
import waffleModal from './waffleModalReducer'

const appReducer = combineReducers({
  user,
  dialog,
  alert,
  adList,
  secondDialog,
  projectMenu,
  error,
  helpMenu,
  adStatus,
  waffleModal,
})

const rootReducer = (state, action) => {
  if (action.type === 'user/LOGOUT') return appReducer(undefined, action)
  return appReducer(state, action)
}

export default rootReducer
