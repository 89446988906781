import React from 'react';

// CSS module
import style from './AdBar.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(style);

const AdBar = ({
  item, // 광고 데이터
  onClickAdBar, // 광고 클릭 이벤트
  targetPage, // 현재 페이지 정보
  timelineContext,
  itemContext,
  getItemProps,
  getResizeProps,
  history,
}) => {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  const {
    advertiserDivision, status, adProcessStatus, approved,
  } = item;
  let adStatusText = '';
  let pointColor = '#fff';

  if (targetPage === 'APPROVAL_PAGE') {
    if (approved) {
      adStatusText = '승인가능';
      pointColor = '#00b2b2';
    } else {
      adStatusText = '승인불가';
      pointColor = '#ffb9b9';
    }
  } else if (targetPage === 'DAILY_OVERVIEW_PAGE') {
    if (advertiserDivision === 0) {
      if (status === 3) {
        if (adProcessStatus === 1) {
          adStatusText = '예정';
          pointColor = '#f2ae00';
        } else if (adProcessStatus === 2) {
          adStatusText = '진행';
          pointColor = '#4167d9';
        } else if (adProcessStatus === 4) {
          adStatusText = '완료';
          pointColor = '#60656a';
        } else if (adProcessStatus === 3) {
          adStatusText = '취소';
          pointColor = '#A3A3A3';
        }
      } else if (status === 2) {
        adStatusText = '승인요청';
        pointColor = '#00b2b2';
      } else if (status === 4) {
        adStatusText = '반려';
        pointColor = '#ffb9b9';
      }
    } else {
      adStatusText = 'wadiz';
      pointColor = '#60656a';
    }
  }

  return (
    <div
      {...getItemProps({
        style: {
          backgroundColor: '#fff',
          color: item.color,
          borderColor: '#dadce0',
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: 11,
          borderLeftWidth: itemContext.selected ? 3 : 1,
          borderRightWidth: itemContext.selected ? 3 : 1,
        },
        onClick: (event) => {
          event.preventDefault();
        },
        onMouseDown: () => {
          if (onClickAdBar) {
            onClickAdBar(item);
          }
        },
      })}
    >
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
      <div
        className={'rct-item-content'}
        style={{
          position: 'sticky',
          width: '100%',
          height: itemContext.dimensions.height,
          overflow: 'hidden',
          paddingLeft: 10,
          paddingRight: 10,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontWeight: 'bold',
        }}
      >
        <span
          className={cx('adDate-ad-status')}
          style={{
            display: 'inline-block',
            color: pointColor,
          }}
        >
          <i
            className={cx('bullet')}
            style={{
              backgroundColor: pointColor,
              marginRight: '6px',
              marginBottom: '1px',
            }}
          ></i>
          {adStatusText}
        </span>
        {itemContext.title}
      </div>
      {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
    </div>
  );
};

export default AdBar;
