import { createAction, handleActions } from 'redux-actions';

const SET_HELP_MENU_TYPE = 'help/SET_HELP_MENU_TYPE';

export const setHelpMenuType = createAction(SET_HELP_MENU_TYPE);


const initState = {
  helpMenuType: 'FAQ',
};

export default handleActions({
  [SET_HELP_MENU_TYPE]: (state, action) => {
    return {
      ...state,
      helpMenuType: action.payload,
    };
  },
}, initState);
