import React from 'react';

// Components
// import FooterMenu from './FooterMenu';
import FooterInfo from './FooterInfo';
// import FooterNotice from './FooterNotice';

const Footer = () => {
  return (
    <footer id='footer'>
      {/* <FooterMenu /> */}
      <FooterInfo />
      {/* <FooterNotice /> */}
    </footer>
  );
};

export default Footer;
