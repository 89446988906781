import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect, Switch } from 'react-router-dom-v5'

import TokenCheck from './TokenCheck'
import { IntroPage, MainPage, ErrorPage, HelpPage } from '../pages'

const isLoggedIn = (user) => user && user.isAuthed
const RouteCenter = ({ user }) => {
  const [isLoading, setLoading] = React.useState(true)
  console.log('isLoading', isLoading, user)
  // 토큰 검사부터 하고
  if (isLoading) {
    return <TokenCheck setLoading={setLoading} />
  }
  // 로그인이 되지 않은 유저(!isAuthed)면 인트로 페이지로
  if (!isLoggedIn(user)) {
    return <IntroPage />
  }
  return (
    <Switch>
      {/* XXX: /login페이지로 리다이렉트 하는 부분을 모두 수정하는 것보다
      로그인을 체크하고 로그인일 경우 main 리다이렉트로 변경함,
      추후 재작업시에는 로그인 페이지 리다이렉트 쪽을 수정 */}
      <Redirect exact from="/login" to="/main/ad" />
      <Route path="/main" component={MainPage} />
      <Route exact path="/help" component={HelpPage} />
      <Route exact path="/error" component={ErrorPage} />
      <Route component={ErrorPage} />
    </Switch>
  )
}

export default connect(({ user }) => ({ user }))(RouteCenter)
