import { useQuery, UseQueryResult, QueryClient } from '@tanstack/react-query'
import Cookies from 'universal-cookie'
import { fetchMakerInfo, MakerInfoType } from '@center/api'

const queryBaseKey = '@center/query/useUserQuery'
const gotoLogin = () => {
  if (window.location.pathname.indexOf('login') < 0) {
    window.location.href = '/login'
  }
}
const cookies = new Cookies()
const checkToken = () => {
  const tokenFromUrl = new URLSearchParams(window.location.search).get('token')
  if (tokenFromUrl) {
    cookies.set('token', tokenFromUrl, { path: '/' })
  }

  const tokenFromCookies = cookies.get('token')
  if (!tokenFromCookies) {
    gotoLogin()
    return false
  }
  return true
}

const queryKey = [queryBaseKey]
const makeQueryOptions = () => ({
  queryKey,
  queryFn: () =>
    fetchMakerInfo().then(
      (res) => {
        if (!res || !res.result) {
          cookies.remove('token', { path: '/' })
          console.error('useUserQuery - fetch error')
          gotoLogin()
          return {}
        }
        return res.result
      },
      (err) => {
        cookies.remove('token', { path: '/' })
        console.error('useUserQuery - query error', err)
        gotoLogin()
        return err
      }
    ),
  enabled: checkToken(),
  staleTime: 60 * 1000,
})

type UseFunctionType = () => UseQueryResult<MakerInfoType>
export const useUserQuery: UseFunctionType = () => useQuery(makeQueryOptions())

type LoadFunctionType = (queryClient: QueryClient) => () => Promise<MakerInfoType>
export const loaderUser: LoadFunctionType = (queryClient) => async () =>
  queryClient.getQueryData(queryKey) ?? (await queryClient.fetchQuery(makeQueryOptions()))
