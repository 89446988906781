import React from 'react'
import { AreaType } from '@legacy/definitions'
import { AdStaticPreview } from '@common/ui'
import PreviewContainer from '../../containers/PreviewContainer/PreviewContainer'
import { AdStateBadge, AdStateChangeButton, AdStateInformation } from './components'

import className from 'classnames/bind'
import style from './AdListCard.module.scss'
const cx = className.bind(style)

const adDate = (startAt, endAt) => {
  if (startAt === '') return '설정안됨'
  const startDateData = new Date(startAt.replace(/\s/, 'T'))
  const endDateData = new Date(endAt.replace(/\s/, 'T'))
  const startDate = {
    fullYear: String(startDateData.getFullYear()),
    year: String(startDateData.getFullYear()).substring(2, 4),
    month: String(
      startDateData.getMonth() + 1 < 10
        ? `0${Number(startDateData.getMonth() + 1)}`
        : Number(startDateData.getMonth() + 1)
    ),
    date: String(startDateData.getDate() < 10 ? `0${startDateData.getDate()}` : startDateData.getDate()),
    hours: String(startDateData.getHours() < 10 ? `0${startDateData.getHours()}` : startDateData.getHours()),
    minutes: String(startDateData.getMinutes() < 10 ? `0${startDateData.getMinutes()}` : startDateData.getMinutes()),
    seconds: String(startDateData.getSeconds() < 10 ? `0${startDateData.getSeconds()}` : startDateData.getSeconds()),
  }
  const endDate = {
    fullYear: String(endDateData.getFullYear()),
    year: String(endDateData.getFullYear()).substring(2, 4),
    month: String(
      endDateData.getMonth() + 1 < 10 ? `0${Number(endDateData.getMonth() + 1)}` : Number(endDateData.getMonth() + 1)
    ),
    date: String(endDateData.getDate() < 10 ? `0${endDateData.getDate()}` : endDateData.getDate()),
    hours: String(endDateData.getHours() < 10 ? `0${endDateData.getHours()}` : endDateData.getHours()),
    minutes: String(endDateData.getMinutes() < 10 ? `0${endDateData.getMinutes()}` : endDateData.getMinutes()),
    seconds: String(endDateData.getSeconds() < 10 ? `0${endDateData.getSeconds()}` : endDateData.getSeconds()),
  }

  return `${startDate.year}-${startDate.month}-${startDate.date}${startDate !== endDate && ` ~ ${endDate.year}-${endDate.month}-${endDate.date}`
    }`
}

const AdListCard = ({
  isWadizAdmin,
  data,
  projectType,
  lossText,
  handleDeleteAdAccountModalOpen,
  handleCancelRequestForReviewAdAccountModalOpen,
  handleCancelScheduledAdAccountModalOpen,
  handleCancelRejectReasonModalOpen,
  handleMoveToModifyAd,
  isAddBtn,
}) => {
  console.log('card', data);
  const doClickButton = React.useCallback(
    (e, cmd) => {
      if (cmd === 'Creating:Modify' || cmd === 'Feedback:Modify' || cmd === 'Recreating:Modify') {
        handleMoveToModifyAd(data.id, '')
      }
      if (cmd === 'Accepted:Modify' || cmd === 'PaymentFailed:Modify') {
        handleMoveToModifyAd(data.id, '2')
      }
      if (cmd === 'Creating:Delete') {
        // 삭제
        handleDeleteAdAccountModalOpen(data)
      }
      if (cmd === 'Submitted:Cancel' || cmd === 'Modified:Cancel') {
        handleCancelScheduledAdAccountModalOpen(data.id, cmd)
      }
      // handleCancelScheduledAdAccountModalOpen(data.id) 예정 중일때,
    },
    [data, handleMoveToModifyAd, handleDeleteAdAccountModalOpen, handleCancelScheduledAdAccountModalOpen]
  )
  return !isAddBtn ? (
    <dl className={cx('ad_list_card')}>
      <dt>
        <AdStateBadge adState={data.adState} />
        {!(data.adState === 'Submitted' && data.adCenterPubAreaId === AreaType.messagePush) && (
          <AdStateChangeButton adState={data.adState} doClick={doClickButton} />
        )}
        <AdStateInformation adState={data.adState} reason={data.cancelReason || ''} />
        <strong>
          {data.shared ? data.packageName : data.area}</strong>
      </dt>
      <dd className={cx('ad_list_price')}>
        <div className={cx('ad_list_date')}>
          광고기간
          <strong>{adDate(data.startAt, data.endAt)}</strong>
        </div>
      </dd>
      <dd>
        {/* 미리보기 - 고정 이미지 인 경우 */}
        <AdStaticPreview areaId={data.adCenterPubAreaId} />
        {/* 앱푸시 || 이미지가 있는 경우 */}
        {(data.adCenterPubAreaId === AreaType.messagePush || data.imgUrl) && (
          <PreviewContainer
            mainCopy={data.mainCopy}
            keyword={data.keyword}
            subCopy={data.subCopy}
            imgUrl={data.imgUrl}
            bgColor={data.bgColor}
            area={data.area}
            accountNum={data.accountNum}
            adCenterPubAreaId={data.adCenterPubAreaId}
            lossText={lossText}
            projectType={projectType}
          />
        )}
        {/* <PreviewContainer
          mainCopy={data.mainCopy}
          keyword={data.keyword}
          subCopy={data.subCopy}
          imgUrl={data.imgUrl}
          bgColor={data.bgColor}
          area={data.area}
          accountNum={data.accountNum}
          adCenterPubAreaId={data.adCenterPubAreaId}
          lossText={lossText}
          projectType={projectType}
        /> */}
      </dd>
    </dl>
  ) : (
    <span></span>
  )
}

export default AdListCard
