import { Typography } from 'antd'
import BigNumber from 'bignumber.js'
import { Goods } from '@center/api'

const { Text } = Typography

export function numberFormat(value: number | string): string {
  const string = /^([0-9]+)\.([0-9]+)$/.exec(String(value))
  if (string && string.length === 3) {
    // 소수점이 존재할 경우 소수점은 콤마를 제외시킨다.
    return `${numberFormat(string[1])}.${string[2]}`
  }
  const number = new BigNumber(value)
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const OptionText: React.FC<{
  goods: Goods | null
}> = ({ goods }) => {
  return !goods ? null : (
    <>
      <Text>
        {goods.impMinPeriod}일: 1일 기준 평일 {numberFormat(goods.price)}원 / 휴일 {numberFormat(goods.holidayPrice)}원
      </Text>
      {goods.discount && (
        <Text strong style={{ color: '#00C4C4', marginLeft: 16 }}>
          {goods.discount}
        </Text>
      )}
    </>
  )
}
