import { toast } from 'react-toastify'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { fetchNotification, type NotificationContent } from '@center/api'

type FunctionType = () => UseQueryResult<NotificationContent[]>
export const useNotificationQuery: FunctionType = () =>
  useQuery({
    queryKey: ['Notification'],
    queryFn: () =>
      fetchNotification().then(
        (res) => res?.data,
        (error) => {
          console.error(error?.message || '공지사항을 가져올 수 없습니다')
        }
      ),
    staleTime: 100 * 60 * 60,
  })
