import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Input, Menu } from 'antd'
import type { ItemType, MenuItemType } from 'antd/es/menu/hooks/useItems'
// import { toast } from 'react-toastify'
import { useAdvertisementProjectList } from '@center/query'
import { EmptyList } from './EmptyList'
import { LoadingPanel } from './LoadingPanel'
import { useAnalyzeParamId } from './_hooks/useAnalyzeParamId'
import styles from './ListPanel.module.scss'

const AdList: React.FC<{ list: Array<ItemType<MenuItemType>> }> = ({ list }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isEmptyList, id, lastMenu, lastSubMenu, currentMenu, currentSubMenu } = useAnalyzeParamId(list)
  const isMakePage = pathname === '/list/make'
  useEffect(() => {
    if (!isMakePage) {
      if (isEmptyList) {
        navigate('/list/make')
      } else if (id === undefined) {
        if (!lastSubMenu) {
          navigate('/list/make')
        } else {
          navigate(`/list/${lastSubMenu}`)
        }
      } else if (currentSubMenu === undefined) {
        console.error('currentSubMenu is undefined', list)
      } else if (`${currentSubMenu}` !== id) {
        navigate(`/list/${lastSubMenu}`)
      }
    }
  }, [currentSubMenu, id, isEmptyList, lastSubMenu, list, navigate, pathname])

  if (isEmptyList) {
    return <EmptyList />
  }

  if (!isMakePage) {
    if (id === undefined) {
      return null
    }

    if (`${currentSubMenu}` !== id) {
      console.warn(isEmptyList, id, lastMenu, lastSubMenu, currentMenu, currentSubMenu)
      // toast.error(`사용하지 않는 URL을 선택하셨습니다. 마지막에 편집했던 광고를 가져오겠습니다.`)
      return null
    }
  }

  return (
    <section className={styles.container}>
      <div className={styles.searchContainer}>
        <Search className={styles.searchBox} placeholder="광고명" onSearch={() => console.log('search')} />
      </div>
      <div className={styles.treeContainer}>
        <Menu
          mode="inline"
          items={list}
          inlineIndent={12}
          onSelect={(submenu) => navigate(`/list/${submenu.key}`)}
          // onOpenChange={(a) => console.log('onOpenChange', a)}
          defaultSelectedKeys={[`${currentSubMenu}`]}
          defaultOpenKeys={[`${currentMenu?.key}`]}
        />
      </div>
    </section>
  )
}

const { Search } = Input
export const ListPanel: React.FC = () => {
  const { isInitialLoading: isAdListLoading, data: adList } = useAdvertisementProjectList()

  if (isAdListLoading) {
    return <LoadingPanel />
  }

  if (!adList) {
    return null
  }

  return <AdList list={adList} />
}
