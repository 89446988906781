import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import styles from './PreviewBox.module.scss'

type PreviewBoxFunction = {
  children: React.ReactNode
  onCloseImage?: () => void | undefined
}

export const PreviewBox: React.FC<PreviewBoxFunction> = ({ children, onCloseImage }) => (
  <div className={styles.container}>
    {children}
    {!!onCloseImage && (
      <Button type="default" className={styles.antCloseButton} icon={<CloseOutlined />} onClick={onCloseImage} />
    )}
  </div>
)
