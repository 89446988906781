import React, { PureComponent } from 'react'

import { connect } from 'react-redux'
import TaxBillStatus from '../../components/SettingContent/contents/TaxBillStatus'
import { openPopup } from '../../reducers/dialogReducer'
import { network } from '../../network'

import { toast } from 'react-toastify'

class SettingTaxBillStatusPage extends PureComponent {
  state = {
    checkRegistrationNumber: false, // 체크 플래그
    makerInfo: {},
    invoiceList: [], // 세금 계산서 리스트
    totalInvoiceListCount: 0,
    selectedOption: {
      start: 1,
      size: 10,
    },

    loadingBarFlag: false,

    openGuide: { idx: -1, type: '' }, // 설정 가이드
  }

  componentDidMount() {
    this.getData()
  }

  // 가이드 flag change function
  handleOpenGuideType = (idx, type) => {
    this.setState((prevState) => ({
      openGuide: { idx, type: prevState.openGuide.type === type && prevState.openGuide.idx === idx ? '' : type },
    }))
  }

  // 데이터 load function
  getData = () => {
    const promiseList = []
    let makerInfo = {}
    let invoiceList = []
    let invoiceListTotalCount = 0
    let check = false

    this.setState({ loadingBarFlag: true }, () => {
      promiseList.push(
        new Promise((resolve, reject) => {
          return network.callApi({
            url: `project/registration-number/check?maker_id=${this.props.user.maker_id}`,
            method: 'get',
            successFunc: ({ result }) => {
              if (result) check = result
              resolve()
            },
            failFunc: (error) => {
              reject(error)
            },
          })
        })
      )

      promiseList.push(
        new Promise((resolve, reject) => {
          return network.callApi({
            url: `maker/info?maker_id=${this.props.user.maker_id}`,
            method: 'get',
            successFunc: (jsonData) => {
              makerInfo = jsonData.result
              if (makerInfo.invoiceEmail === '' || !makerInfo.invoiceEmail) {
                makerInfo.invoiceEmail = makerInfo.makerEmail
              }

              resolve()
            },
            failFunc: (error) => {
              reject(error)
            },
          })
        })
      )

      if (!this.state.makerInfo.registrationNumber) {
        promiseList.push(
          new Promise((resolve, reject) => {
            return network.callApi({
              url: `invoice/list?maker_id=${this.props.user.maker_id}&size=${10}&start=${1}`,
              method: 'get',
              successFunc: (jsonData) => {
                const { result, totalCount } = jsonData
                invoiceList = result
                invoiceListTotalCount = totalCount
                resolve()
              },
              failFunc: (error) => {
                reject(error)
              },
            })
          })
        )
      }

      Promise.all(promiseList).then(() => {
        this.setState({
          checkRegistrationNumber: check,
          makerInfo,
          invoiceList,
          totalInvoiceListCount: invoiceListTotalCount,
          loadingBarFlag: false,
        })
      })
    })
  }

  // 세금계산서 발급 여부 수정 api
  handleChangeInvoiceStatus = () => {
    this.setState(
      (prevState) => ({
        makerInfo: { ...prevState.makerInfo, invoiceYn: prevState.makerInfo.invoiceYn === 1 ? 2 : 1 },
      }),
      () => {
        const { id, invoiceYn } = this.state.makerInfo
        const newDate = new Date()
        const url = 'maker/update'
        network.callApi({
          url,
          method: 'POST',
          body: {
            id,
            invoiceYn,
            invoiceYnDate: `${newDate.getFullYear()}-${
              newDate.getMonth() + 1
            }-${newDate.getDate()} ${newDate.getHours()}-${newDate.getMinutes()}-${newDate.getSeconds()}`,
          },
          successFunc: (jsonData) => {
            if (jsonData.result) {
              toast('세금계산서 발급 여부 상태가 변경되었습니다.')
            }
          },
          failFunc: () => {
            console.error(`fail- ${url}`)
          },
        })
      }
    )
  }

  // 이메일 정보 수정 func
  handleInvoiceEmail = (email) => {
    this.setState((prevState) => ({ makerInfo: { ...prevState.makerInfo, invoiceEmail: email } }))
  }

  // 이메일 정보 수정 api
  handleUpdateInvoiceEmail = () => {
    const { id, invoiceEmail } = this.state.makerInfo

    const emailValidate = /^[A-Za-z0-9_.+-]+@[A-Za-z0-9-]+\.[A-Za-z0-9-]+/gi.test(invoiceEmail)
    if (!invoiceEmail || invoiceEmail === '') {
      toast('이메일 정보를 입력해 주세요.')
      return
    }
    if (!emailValidate) {
      toast('올바른 이메일 형식이 아닙니다.')
      return
    }
    const url = 'maker/update'
    network.callApi({
      url,
      method: 'POST',
      body: {
        id,
        invoiceEmail,
      },
      successFunc: (jsonData) => {
        if (jsonData.result) {
          toast('이메일 정보가 변경되었습니다.')
        }
      },
      failFunc: () => {
        console.error(`fail- ${url}`)
      },
    })
  }

  handlePageNumber = (start) => {
    // 선택한 page에 대한 정보 가져오기
    const { selectedOption } = this.state
    this.setState({ selectedOption: { ...selectedOption, start } }, () => {
      if (this.state.makerInfo.registrationNumber) {
        const url = `invoice/list?maker_id=${this.props.user.maker_id}&size=${selectedOption.size}&start=${start}`
        network.callApi({
          url,
          method: 'get',
          successFunc: (jsonData) => {
            this.setState({ invoiceList: jsonData.result })
          },
          failFunc: () => {
            console.error(`fail- ${url}`)
          },
        })
      }
    })
  }

  handleTaxBillDetailModalOpen = (data) => {
    this.props.openPopup({
      dialogType: 'taxBillDetailDialog',
      dialogData: {
        title: '세금계산서 내역',
        data,
        closeText: '닫기',
      },
    })
  }

  render() {
    const {
      checkRegistrationNumber,
      makerInfo,
      invoiceList,
      selectedOption,
      totalInvoiceListCount,
      openGuide,
      loadingBarFlag,
    } = this.state

    const {
      handleOpenGuideType,
      handleChangeInvoiceStatus,
      handleInvoiceEmail,
      handleUpdateInvoiceEmail,
      handleTaxBillDetailModalOpen,
      handlePageNumber,
    } = this
    return (
      <TaxBillStatus
        checkRegistrationNumber={checkRegistrationNumber}
        makerInfo={makerInfo}
        invoiceList={invoiceList}
        selectedOption={selectedOption}
        totalInvoiceListCount={totalInvoiceListCount}
        openGuide={openGuide}
        loadingBarFlag={loadingBarFlag}
        onChangeOpenGuideType={handleOpenGuideType}
        onChangeInvoiceStatus={handleChangeInvoiceStatus}
        onChangeInvoiceEmail={handleInvoiceEmail}
        onClickUpdateInvoiceEmail={handleUpdateInvoiceEmail}
        onClickTaxBillDetailModalOpen={handleTaxBillDetailModalOpen}
        onChangePageNumber={handlePageNumber}
      />
    )
  }
}

export default connect(({ user, loadingBar }) => ({ user, loadingBar }), { openPopup })(SettingTaxBillStatusPage)
