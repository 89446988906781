import React from 'react';
import classNames from 'classnames/bind';
import TitleSection from '../TitleSection';
import style from './RecommandAdSection.module.scss';
const cx = classNames.bind(style);

const contentList = [
  {
    product: '30만원',
    list: [
      {
        name: '오픈예정 패키지',
        price: (
          <>
            <b>평일</b> 30만원 <span className={cx('line')}></span> <b>휴일</b> 24만원
          </>
        ),
        account: '6구좌',
        title: '오픈예정 홈 + 리스트',
        desc: '알림신청자를 빠르게 모으고 인기 프로젝트가 되어 보세요. 노출수 대비 전환율이 높아 인기가 많은 패키지에요.',
        group: false,
      },
      {
        name: '멀티 콘텐츠',
        price: (
          <>
            <b>평일</b> 30만원 <span className={cx('line')}></span> <b>휴일</b> 24만원
          </>
        ),
        account: '11구좌',
        title: '앱/모바일웹 메인 홈 중간 노출\n+베스트+친구+구독 영역 노출',
        desc: '서포터가 자주 이용하는 경로에서 프로젝트를 반복적으로 노출하여 효과적인 주목도 상승/프로젝트 유입을 달성할 수 있어요.',
        group: false,
        button: '멀티 콘텐츠 자세히 보기',
      },
    ],
  },
  {
    product: '60만원',
    desc: '오픈예정 패키지 상품과 펀딩 패키지, 메인 보드 등 상품을 평일/휴일 단가에 따라 조합해서 이용해 보세요.',
    list: [
      {
        name: '오픈예정 패키지',
        price: (
          <>
            <b>평일</b> 30만원 <span className={cx('line')}></span> <b>휴일</b> 24만원
          </>
        ),
        account: '6구좌',
        title: '오픈예정 홈 + 리스트',
        desc: '알림신청자를 빠르게 모으고 인기 프로젝트가 되어 보세요. 노출수 대비 전환율이 높아 인기가 많은 패키지에요.',
        group: false,
      },
      {
        name: '+ 추천 조합 상품 (택1)',
        group: true,
        grouplist: [
          {
            name: '멀티 콘텐츠',
            price: (
              <>
                <b>평일</b> 30만원 <span className={cx('line')}></span> <b>휴일</b> 24만원
              </>
            ),
            account: '11구좌',
            title: '앱/모바일웹 메인 홈 중간 노출\n+베스트+친구+구독 영역 노출',
            desc: '서포터가 자주 이용하는 경로에서 프로젝트를 반복적으로 노출하여 효과적인 주목도 상승/프로젝트 유입을 달성할 수 있어요.',
            group: false,
          },
          {
            name: '펀딩 패키지',
            price: (
              <>
                <b>평일</b> 40만원 <span className={cx('line')}></span> <b>휴일</b> 30만원
              </>
            ),
            account: '6구좌',
            title: '펀딩 홈 + 리스트',
            desc: '프로젝트에 참여할 목적으로 [펀딩+] 페이지에 유입한 서포터에게 홍보할 수 있는 상품이에요.',
            group: false,
          },
          {
            name: '멀티 보드',
            price: (
              <>
                <b>평일</b> 30만원 <span className={cx('line')}></span> <b>휴일</b> 24만원
              </>
            ),
            account: '4구좌',
            title: '앱/모바일웹 메인 홈+상세페이지 하단\n+마이와디즈 서포터탭 하단 노출',
            desc: '서포터가 자주 방문하는 동선에서 메이커님이 제작한 광고 소재를 노출할 수 있어요.',
            group: false,
          },
        ],
      },
    ],
  },
  {
    product: '100만원 이상',
    desc: '메인 콘텐츠 또는 추천 광고 세트상품을 평일/휴일 단가에 따라 조합해서 이용해 보세요.',
    list: [
      {
        name: '메인 콘텐츠',
        price: (
          <>
            <b>평일</b> 100만원 <span className={cx('line')}></span> <b>휴일</b> 80만원
          </>
        ),
        account: '8구좌',
        title: '앱/모바일웹 메인 홈 상단 노출',
        desc: '대규모 서포터 모집을 기대할 수 있어, 가장 인기있는 광고상품이에요',
        group: false,
      },
      {
        name: '추천 광고 세트',
        group: true,
        grouplist: [
          {
            name: '오픈예정 패키지',
            price: (
              <>
                <b>평일</b> 30만원 <span className={cx('line')}></span> <b>휴일</b> 24만원
              </>
            ),
            account: '6구좌',
            title: '오픈예정 홈 + 리스트',
            desc: '알림신청자를 빠르게 모으고 인기 프로젝트가 되어 보세요. 노출수 대비 전환율이 높아 인기가 많은 패키지에요.',
            group: false,
          },
          {
            name: '멀티 콘텐츠',
            price: (
              <>
                <b>평일</b> 30만원 <span className={cx('line')}></span> <b>휴일</b> 24만원
              </>
            ),
            account: '11구좌',
            title: '앱/모바일웹 메인 홈 중간 노출\n+베스트+친구+구독 영역 노출',
            desc: '서포터가 자주 이용하는 경로에서 프로젝트를 반복적으로 노출하여 효과적인 주목도 상승/프로젝트 유입을 달성할 수 있어요.',
            group: false,
          },
          {
            name: '펀딩 패키지',
            price: (
              <>
                <b>평일</b> 40만원 <span className={cx('line')}></span> <b>휴일</b> 30만원
              </>
            ),
            account: '6구좌',
            title: '펀딩 홈 + 리스트',
            desc: '프로젝트에 참여할 목적으로 [펀딩+] 페이지에 유입한 서포터에게 홍보할 수 있는 상품이에요.',
            group: false,
          },
        ],
      },
    ],
  },
]

const ContentItem = ({
  list,
  group,
  grouplist,
  account
}) => (
  <>
    {list.map((n, index) => (
      <section className={cx('item', `item-${index}`)}>
        <div className={cx('header')} key={index}>
          <h4>{n.name}</h4>
          {n.price && <span className={cx('price')}>{n.price}</span>}
        </div>
        <div className={cx('content')}>
          { n.group ?
            <>
              {n.grouplist.map((m, index) => (

                <section className={cx('item', `item-${index}`)}>
                  <div className={cx('header')} key={index}>
                    <h4>{m.name}</h4>
                    { m.price && <span className={cx('price')}>{m.price}</span>}
                  </div>
                  <div className={cx('content')}>
                    <div className={cx('title')}>
                      <span>{m.account}</span>
                      <strong>{m.title}</strong>
                    </div>
                    <p>{m.desc}</p>
                  </div>
                </section>
              ))}
            </>
            :
            <>
              <div className={cx('title')}>
                <span>{n.account}</span>
                <strong>{n.title}</strong>
              </div>
              <p>{n.desc}</p>
              {n.button &&
                <a href="https://makercenter.wadiz.kr/board/card/583"
                  target="_blank"
                  className={cx('btn', 'contentBtn')} rel="noreferrer"> {n.button}</a>}
            </>
          }
        </div>
      </section>
    ))}
  </>
)

const RecommandAdSection = () => {
  return (
    <section className={cx('container')}>
      <TitleSection section="RecommandAdSection" />
      <div className={cx('productWrap')}>
        {contentList.map((item, index) => (
          <section className={cx('product', `product-${index}` )}>
            <div className={cx('titleWrap')}>
              <div className={cx('title')}>
                <span>광고예산</span>
                <h3>{item.product}</h3>
              </div>
              <span>*VAT 별도</span>
            </div>
            {
              item.desc && <p className={cx('description')}>{item.desc}</p>
            }
            <ContentItem
              key={`contentItem${index}`}
              list={item.list}
            />
          </section>
        ))}
      </div>
      <a href="https://makercenter.wadiz.kr/board/card/557"
        target="_blank"
        rel="noreferrer"
        className={cx('btn')}>
        예산별 광고 활용 Tip
      </a>
    </section>
  )
};

export default RecommandAdSection;
