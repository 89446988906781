import React, { PureComponent } from 'react';

// Plugin
import ReactDOM from 'react-dom';

// Components
import SearchResult from '../../components/SearchResult';


class SearchResultContainer extends PureComponent {
  componentDidMount() {
    // add event listener for clicks
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    // make sure you remove the listener when the component is destroyed
    document.removeEventListener('click', this.handleClick, false);
  }


  handleClick = e => {
    if (!ReactDOM.findDOMNode(this).contains(e.target)) {
      this.props.close();
    }
  }

  render() {
    const {
      searchResult,
      searchKeyword,
      close,
      handleSearchAdClick,
    } = this.props;
    return (
      <SearchResult
        searchResult={searchResult}
        searchKeyword={searchKeyword}
        close={close}
        handleSearchAdClick={handleSearchAdClick}
      />
    );
  }
}

export default SearchResultContainer;
