import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import BusinessInformation from '../../components/SettingContent/contents/BusinessInformation';
import { network } from '../../network';

class SettingBusinessInfoPage extends PureComponent {
  state = {
    businessInfo: { investList: [], rewardList: [] },

    loadingBarFlag: false,

    openGuide: { idx: -1, type: '' }, // 설정 가이드
  };

  componentDidMount() {
    const promiseList = [];
    let businessInfo = { investList: [], rewardList: [] };

    this.setState({ loadingBarFlag: true }, () => {
      promiseList.push(new Promise((resolve, reject) => {
        return network.callApi({
          url: 'business-info/list',
          method: 'get',
          successFunc: (jsonData) => {
            businessInfo = jsonData.result;
            resolve();
          },
          failFunc: (error) => {
            reject(error);
          },
        });
      }));

      Promise.all(promiseList).then(() => {
        this.setState({
          businessInfo, loadingBarFlag: false,
        });
      });
    });
  }

  // 가이드 flag change function
  handleOpenGuideType = (idx, type) => {
    this.setState(prevState => ({ openGuide: { idx, type: (prevState.openGuide.type === type && prevState.openGuide.idx === idx) ? '' : type } }));
  }

  render() {
    const { handleOpenGuideType } = this;
    const { businessInfo, openGuide, loadingBarFlag } = this.state;
    return (
      <BusinessInformation
        businessInfo={businessInfo}
        openGuide={openGuide}
        loadingBarFlag={loadingBarFlag}
        onChangeOpenGuideType={handleOpenGuideType}
      />
    );
  }
}

export default connect(({ loadingBar }) => ({ loadingBar }))(SettingBusinessInfoPage);
