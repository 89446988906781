// eslint-disable-next-line
import { connect } from 'react-redux'
import download from '../../../../../assets/images/intro/icon_download.svg'
import style from './KeyVisualButton.module.scss'
function encodePrivateURL(url: string) {
  return url.replace(/[:]/gi, '%3A').replace(/[/]/gi, '%2F').replace(/[?]/gi, '%3F').replace(/[=]/gi, '%3D')
}

const makeReturnURL = () => {
  const sessionCode = `${process.env.REACT_APP_WADIZ_APP_ROOT}web/session2token/session?returnURL=${window.location.origin}`
  const enCode = encodePrivateURL(sessionCode)
  return `${process.env.REACT_APP_WADIZ_APP_ROOT}web/waccount/wAccountLogin?returnURL=${enCode}`
}

const KeyVisualButton: React.FC<{ isAuthed: boolean }> = ({ isAuthed }) => (
  <article className={style.KeyVisualButton}>
    <a href={'https://business.wadiz.kr'} className={style.btn}>
      {'새로운 광고센터 가기'}
    </a>
    <a href={!isAuthed ? `${makeReturnURL()}` : '/main/ad/list'} className={style.btn}>
      {'구 광고센터 가기'}
    </a>
    <a
      href="https://cdn1.wadiz.kr/images/manual/introduction.pdf"
      target="_blank"
      className={style.btn}
      rel="noreferrer"
    >
      상품소개서 <img src={download} alt="download" style={{ paddingLeft: 6, width: 20, height: 20 }} />
    </a>
  </article>
)

const mapStateToProps = (state) => {
  return {
    isAuthed: state.user.isAuthed,
    adStatus: state.adStatus.adStatus,
  }
}

export default connect(mapStateToProps)(KeyVisualButton)
