import React from 'react'

// Components
import Lnb from '../Lnb'
import NavBar from '../NavBar'

// CSS module
import style from './AdFavoritReportContent.module.scss'
import className from 'classnames/bind'

import PaginatorContainer from '../../containers/PaginatorContainer/PaginatorContainer'
// import ExcelExportWithZip from '../../components/ExcelExport/ExcelExportWithZip';
// import ExcelExportContainer from '../../containers/ExcelExportContainer/ExcelExportContainer'
import LoadingBar from '../LoadingBar/LoadingBar'

const cx = className.bind(style)

const navList = [
  { name: '보고서', link: '/main/ad/report/' },
  { name: '보고서 즐겨찾기', link: '/main/ad/report/favorit' },
]

const AdFavoritReportContent = ({
  state,
  dateToStringCreatedDate,
  dateToStringAdPeriod,
  handlePageNumber,
  handleViewSizeSelect,
  handleCheckBoxItemClick,
  handleAllCheckBoxItemClick,
  handleGoToADDetail,
  handleSearchReportName,
  handleSearchClick,
  handleReportDeleteClick,
  handleExcelDownload,
  viewSizeList,
  selectedOption,
  checkBoxList,
  tableData,
  fileName,
  isDownload,
  zipName,
}) => {
  const { itemList, allItemTotalCount, totalCount, loadingBarFlag } = state

  return (
    <main className={cx('main_container', 'report')}>
      <Lnb type="report" />
      <div className={cx('report_result', 'content_wrap')}>
        <NavBar list={navList} />
        {loadingBarFlag ? (
          <LoadingBar />
        ) : (
          <>
            <hgroup>
              <h2>보고서 즐겨찾기</h2>
              {/* <p>즐겨찾기 추가한 보고서의 리스트를 엑셀파일 다운로드 및 삭제가 가능합니다.</p> */}
            </hgroup>
            <div className={cx('result_select')}>
              <div className={cx('wz input', 'result_search')}>
                <input
                  onChange={handleSearchReportName}
                  type="search"
                  name="pseudo-text"
                  value={selectedOption.searchName}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') handleSearchClick()
                  }}
                  placeholder="보고서명을 입력하세요."
                />
                <button onClick={handleSearchClick} className={cx('wz button')}>
                  검색
                </button>
              </div>
              {document.body.offsetWidth > 768 && (
                <label className={cx('wz dropdown outline', 'rpDropdown', 'tablePageLength')}>
                  <select defaultValue={selectedOption.size} onChange={handleViewSizeSelect}>
                    {viewSizeList.map((val, idx) => (
                      <option key={idx} value={val}>
                        {val}개씩 보기
                      </option>
                    ))}
                  </select>
                </label>
              )}
            </div>

            <div className={cx('wz container table', 'cancellation')}>
              <div className={cx('wz table track', 'tableWrap')}>
                <table className={cx('wz structured table', 'ad_table')}>
                  <caption>성과보고서</caption>
                  <thead>
                    <tr>
                      <th style={{ width: '60px' }}>
                        <label className={cx('wz checkbox')}>
                          <input
                            onChange={handleAllCheckBoxItemClick}
                            checked={itemList.length && itemList.length === checkBoxList.length}
                            type="checkbox"
                            name="pseudo1"
                            value="Y"
                          />
                          <span>
                            <span>전체 선택</span>
                          </span>
                        </label>
                      </th>
                      <th style={{ width: '44px' }}>No.</th>
                      <th style={{ width: '100px' }}>구분</th>
                      <th style={{ width: '600px' }}>보고서명</th>
                      <th style={{ width: '100px' }}>광고기간</th>
                      <th style={{ width: '90px' }}>등록일</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {아이템이 있는 경우} */}
                    {allItemTotalCount > 0 &&
                      itemList.map((val, idx) => (
                        <tr key={idx}>
                          <td>
                            <label className={cx('wz checkbox')}>
                              <input
                                type="checkbox"
                                onChange={handleCheckBoxItemClick}
                                value={idx}
                                checked={checkBoxList.indexOf(idx) !== -1}
                              />
                              <span>
                                <span>선택</span>
                              </span>
                            </label>
                          </td>
                          <td>{val.idx}</td>
                          <td>{val.reportType}</td>
                          <td
                            onClick={() => handleGoToADDetail(val.reportType, val.adCenterAdvertisementId)}
                            className={cx('aligned left', 'point_primary')}
                          >
                            {val.reportName}
                          </td>
                          <td>
                            {val.startAt.slice(2, 10)} ~<br></br>
                            {val.endAt.slice(2, 10)}
                          </td>
                          <td>
                            {dateToStringCreatedDate(val.createdAt)[0]}
                            <span className={cx('table_time')}>{dateToStringCreatedDate(val.createdAt)[1]}</span>
                          </td>
                        </tr>
                      ))}
                    {allItemTotalCount === 0 && (
                      <tr>
                        <td className={cx('error_table')} colSpan="6">
                          <div>
                            <i className={cx('icon error-o')}></i>
                            {totalCount === 0
                              ? '검색한 이름의 즐겨찾기된 보고서가 없습니다.'
                              : '즐겨찾기 내역이 없습니다.'}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {allItemTotalCount !== 0 && (
                <>
                  <PaginatorContainer
                    onChange={handlePageNumber}
                    activePage={selectedOption.start}
                    totalCount={totalCount}
                    viewCount={selectedOption.size}
                  />
                  <div className={cx('ad_table_btnArea')}>
                    {/* <button onClick={handleExcelDownload} className={cx('wz button')}>
                      <i className={cx('icon download')}></i>
                      엑셀 파일로 정보 다운로드
                      <ExcelExportContainer
                        zipName={zipName}
                        fileName={fileName}
                        tableData={tableData}
                        isDownload={isDownload}
                      />
                    </button> */}
                    <button onClick={handleReportDeleteClick} className={cx('wz button')}>
                      삭제
                    </button>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </main>
  )
}

export default AdFavoritReportContent
