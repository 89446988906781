import FroalaEditor, { SVG } from 'froala-editor'
import { TRANSLATION } from './language'

FroalaEditor.DefineIconTemplate(
  'waffle-icons-with-label',
  `
  <i class="icon-[NAME]" aria-hidden="true">
    <label aria-hidden="true">[ALT]</label>
  </i>
`
)

FroalaEditor.DefineIconTemplate(
  'svg-with-label',
  `
  <i aria-hidden="true">
    <svg viewBox="0 0 24 24">
      <path d="[PATH]"></path>
    </svg>
    <label aria-hidden="true">[ALT]</label>
  </i>
`
)

FroalaEditor.DefineIconTemplate(
  'svg',
  `
  <i aria-hidden="true">
    <svg viewBox="0 0 24 24">
      <path d="[PATH]"></path>
    </svg>
  </i>
`
)

SVG.highlighter =
  'M12.6,6.4l4.2,4.2l-8.5,8.5L3.2,20l0.9-5.1L12.6,6.4z M15,4l4.2,4.2l-1.8,1.8l-4.2-4.2L15,4z M10.4,18.4h10.4V20H10.4V18.4z'
SVG.quoteDefault = 'M6 11h12v12l-4 7h-3l4-7H6zm15 0v12h9l-4 7h3l4-7V11z'
SVG.quoteLine = 'M8 32H4V8h4zm28-20H12v2h24zm0 7H12v2h24zm-7.7 7H12v2h16.3z'
SVG.unquote = 'M28.8 8.8H3.2V7.2h25.6zm0 6.4H3.2v1.6h25.6zm0 8H3.2v1.6h25.6z'

FroalaEditor.DefineIcon('align', { PATH: SVG.alignJustify, ALT: TRANSLATION.Align, template: 'svg-with-label' })
FroalaEditor.DefineIcon('align-center', {
  PATH: SVG.alignCenter,
  ALT: TRANSLATION['Align Center'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('align-justify', {
  PATH: SVG.alignJustify,
  ALT: TRANSLATION['Align Justify'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('align-left', {
  PATH: SVG.alignLeft,
  ALT: TRANSLATION['Align Left'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('align-right', {
  PATH: SVG.alignRight,
  ALT: TRANSLATION['Align Right'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('bold', { PATH: SVG.bold, ALT: TRANSLATION.Bold, template: 'svg-with-label' })
FroalaEditor.DefineIcon('formatOL', {
  PATH: SVG.orderedList,
  ALT: TRANSLATION['Ordered List'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('formatUL', {
  PATH: SVG.unorderedList,
  ALT: TRANSLATION['Unordered List'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('highlighter', { PATH: SVG.highlighter, ALT: '형광펜', template: 'svg-with-label' })
FroalaEditor.DefineIcon('imageAlign', { PATH: SVG.alignJustify, ALT: TRANSLATION.Align, template: 'svg-with-label' })
FroalaEditor.DefineIcon('image-align-center', {
  PATH: SVG.alignCenter,
  ALT: TRANSLATION['Align Center'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('image-align-left', {
  PATH: SVG.alignLeft,
  ALT: TRANSLATION['Align Left'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('image-align-right', {
  PATH: SVG.alignRight,
  ALT: TRANSLATION['Align Right'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('imageAlt', {
  PATH: SVG.imageAltText,
  ALT: TRANSLATION['Alternative Text'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('imageCaption', {
  PATH: SVG.imageCaption,
  ALT: TRANSLATION['Image Caption'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('imageDisplay', {
  PATH: SVG.imageDisplay,
  ALT: TRANSLATION.Display,
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('imageLink', {
  PATH: SVG.insertLink,
  ALT: TRANSLATION['Insert Link'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('imageRemove', { PATH: SVG.remove, ALT: TRANSLATION.Remove, template: 'svg-with-label' })
FroalaEditor.DefineIcon('imageReplace', {
  PATH: SVG.replaceImage,
  ALT: TRANSLATION.Replace,
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('insertHR', {
  PATH: SVG.horizontalLine,
  ALT: TRANSLATION['Insert Horizontal Line'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('insertImage', {
  PATH: SVG.insertImage,
  ALT: TRANSLATION['Insert Image'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('insertLink', {
  PATH: SVG.insertLink,
  ALT: TRANSLATION['Insert Link'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('insertTable', {
  PATH: SVG.insertTable,
  ALT: TRANSLATION['Insert Table'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('insertVideo', {
  PATH: SVG.insertVideo,
  ALT: TRANSLATION['Insert Video'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('linkEdit', { PATH: SVG.edit, ALT: TRANSLATION['Edit Link'], template: 'svg-with-label' })
FroalaEditor.DefineIcon('linkOpen', { PATH: SVG.openLink, ALT: TRANSLATION['Open Link'], template: 'svg-with-label' })
FroalaEditor.DefineIcon('linkRemove', { PATH: SVG.unlink, ALT: TRANSLATION.Unlink, template: 'svg-with-label' })
FroalaEditor.DefineIcon('quote', { PATH: SVG.blockquote, ALT: TRANSLATION.Quote, template: 'svg-with-label' })
FroalaEditor.DefineIcon('quoteDefault', {
  PATH: SVG.quoteDefault,
  ALT: TRANSLATION.QuoteDefault,
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('quoteLine', { PATH: SVG.quoteLine, ALT: TRANSLATION.QuoteLine, template: 'svg-with-label' })
FroalaEditor.DefineIcon('tableCells', { PATH: SVG.cellOptions, ALT: TRANSLATION.Cell, template: 'svg-with-label' })
FroalaEditor.DefineIcon('tableColumns', { PATH: SVG.columns, ALT: TRANSLATION.Column, template: 'svg-with-label' })
FroalaEditor.DefineIcon('tableHeader', {
  PATH: SVG.tableHeader,
  ALT: TRANSLATION['Table Header'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('tableCellBackground', {
  PATH: SVG.cellBackground,
  ALT: TRANSLATION['Cell Background'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('tableCellHorizontalAlign', {
  PATH: SVG.alignLeft,
  ALT: TRANSLATION['Horizontal Align'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('tableCellVerticalAlign', {
  PATH: SVG.verticalAlignMiddle,
  ALT: TRANSLATION['Vertical Align'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('tableCellStyle', {
  PATH: SVG.cellStyle,
  ALT: TRANSLATION['Cell Style'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('tableRemove', {
  PATH: SVG.removeTable,
  ALT: TRANSLATION['Remove Table'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('tableRows', { PATH: SVG.row, ALT: TRANSLATION.Row, template: 'svg-with-label' })
FroalaEditor.DefineIcon('textColor', {
  PATH: SVG.textColor,
  ALT: TRANSLATION['Text Color'],
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('underline', { PATH: SVG.underline, ALT: TRANSLATION.Underline, template: 'svg-with-label' })
FroalaEditor.DefineIcon('unquote', { PATH: SVG.unquote, ALT: TRANSLATION.UnQuote, template: 'svg-with-label' })
FroalaEditor.DefineIcon('videoDisplay', {
  PATH: SVG.imageDisplay,
  ALT: TRANSLATION.Display,
  template: 'svg-with-label',
})
FroalaEditor.DefineIcon('videoRemove', { PATH: SVG.remove, ALT: TRANSLATION.Remove, template: 'svg-with-label' })
FroalaEditor.DefineIcon('videoReplace', {
  PATH: SVG.replaceImage,
  ALT: TRANSLATION.Replace,
  template: 'svg-with-label',
})
