import React, { useState, useEffect, useCallback } from 'react'
import { useForm } from 'react-hook-form-v6'
import { toast } from 'react-toastify'
import { TermsModal } from '../TermsModal'
import { useSelector } from 'react-redux'
import { centerPostTermsAgreements } from '@wad/api'

const formId = 'terms-agreement-container'
export const TermsModalContainer = ({ isOpen, onAgreementConfirm }) => {
  const user = useSelector((store) => store.user)
  const { handleSubmit } = useForm()
  const [isSubmitted, setSubmit] = useState(false)
  const doSubmit = useCallback(() => {
    setSubmit(true)
  }, [])

  useEffect(() => {
    const adMakerId = user?.maker_id
    if (isSubmitted && adMakerId) {
      const fn = async () => {
        try {
          const { data: agreementInfo } = await centerPostTermsAgreements({ adMakerId })
          onAgreementConfirm(agreementInfo?.adMakerId === adMakerId)
          if (agreementInfo) {
            toast('동의 완료 되었습니다.')
          }
        } catch (e) {
          console.warn(e)
        } finally {
          setSubmit(false)
        }
      }
      fn()
    }
  }, [isSubmitted, onAgreementConfirm, user])

  return (
    <form id={formId} onSubmit={handleSubmit(doSubmit)}>
      <TermsModal
        isOpen={isOpen}
        onCancel={() => {
          onAgreementConfirm(false)
        }}
        formid={formId}
      />
    </form>
  )
}
