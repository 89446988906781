import { Modal } from 'antd'
import styles from './PreviewModal.module.scss'

type Props = {
  children: React.ReactNode
  isOpen: boolean
  onClose: () => void
}
export const PreviewModal: React.FC<Props> = ({ children, isOpen, onClose }) => (
  <Modal wrapClassName={styles.antModal} open={isOpen} onCancel={onClose} footer={null}>
    <>
      <div className={styles.mobile}>MOBILE only</div>
      {children}
    </>
  </Modal>
)
