import React, { useState } from 'react'

import { AdRegistrationModal } from '@center/ui'
import AccountCard from './AccountCard'

import className from 'classnames/bind'
import style from './CreateAdStep2.module.scss'
const cx = className.bind(style)

const comma = (val) => {
  if (typeof val === 'string') return val.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  if (typeof val === 'number') return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return val
}

const CreateAdStep2 = ({
  isWadizAdmin,
  isSuperUser,
  onClickSave,
  onClickStep,
  onClickRegister,
  onClickDatepicker,
  imgFileSel,
  selectColor,
  inputTextChange,
  onSelectGoods,
  onSelectDiscountCoupon,
  onChangeDateRange,
  onFocusChange,
  imgDel,
  accountListDel,
  onChangeOpenGuideType,
  handleAdPreview,
  handleToAdStep,
  accountList,
  projectType,
  lossText,
  comingsoon,
  campaignId,
  onChangeLandingURL,
  setImage,
  ...rest
}) => {
  const [isVisibleModal, setVisibleModal] = useState(false)
  const handleStartAd = () => {
    // onClickRegister();
    setVisibleModal(true)
  }
  const onFinish = (data) => {
    onClickRegister(data)
    setVisibleModal(false)
  }
  return (
    <>
      <div className={cx('create_ad', 'create_ad_container_step2')}>
        <hgroup>
          <h2>광고만들기</h2>
          <h3>광고 등록하기</h3>
          <p>
            {accountList.length > 0
              ? '선택하신 광고 상품을 확인하시고, 추가 내용을 등록해주세요.'
              : '광고영역을 선택하고 필요한 정보를 등록해주세요.'}
          </p>
        </hgroup>
        <section className={cx('wz form', 'ad_element')}>
          <div className={cx('wz input', 'field')}>
            <input type="text" defaultValue={rest.projectName} readOnly />
          </div>
        </section>
        {accountList.length > 0 ? (
          <>
            {accountList.map((val, idx) => (
              <AccountCard
                key={idx}
                isWadizAdmin={isWadizAdmin}
                imgDel={imgDel}
                onSelectGoods={onSelectGoods}
                onSelectDiscountCoupon={onSelectDiscountCoupon}
                onChangeDateRange={onChangeDateRange}
                onFocusChange={onFocusChange}
                data={val}
                idxdata={idx}
                onClickDatepicker={onClickDatepicker}
                imgFileSel={imgFileSel}
                selectColor={selectColor}
                inputTextChange={inputTextChange}
                // bgColorList={rest.bgColorList}
                accountListDel={accountListDel}
                accountList={accountList}
                onChangeOpenGuideType={onChangeOpenGuideType}
                openGuide={rest.openGuide}
                handleAdPreview={handleAdPreview}
                projectType={projectType}
                lossText={lossText}
                landingUrl={rest.landingUrl}
                onChangeLandingURL={onChangeLandingURL}
                makerInfo={rest.makerInfo}
                setImage={setImage}
                isSuperUser={isSuperUser}
              />
            ))}
            <div className={cx('ad_payment')}>
              <div className={cx('payment_count')}>
                <h3>광고 예상 금액</h3>
                <p>
                  광고 금액 <em>{comma(rest.adsPrice)}원</em>
                  <br></br>
                  부가세 <em>{comma(rest.adsVat)}원</em>
                </p>
                <strong>
                  {comma(rest.totalPrice)}
                  <small>원</small>
                </strong>
              </div>
              {/* <button type="button" className={cx('wz button primary-outline')} onClick={onClickRegister}>
                <i className={cx('icon add')}></i>광고 상품 추가하기
              </button> */}
              <p>
                <i className={cx('icon error-o')}></i>
                광고 상품과 광고 기간을 선택하면 광고 예상 금액이 자동 계산됩니다.
              </p>
            </div>
          </>
        ) : (
          <div className={cx('ad_listNull')}>
            <i className={cx('icon error-o')}></i>
            <p>아래 버튼을 클릭하여 광고 등록을 시작해 주세요</p>
            <button type="button" className={cx('wz button gray')} onClick={handleStartAd} disabled={!isSuperUser}>
              <i className={cx('icon add')} />
              광고 등록 시작하기
            </button>
          </div>
        )}
        <div className={cx('buttonArea')}>
          <button
            type="button"
            className={cx('wz button less', 'button_historyBack')}
            onClick={() => handleToAdStep(1)}
          >
            <i className={cx('icon chevron-left')} />
            이전
          </button>
          <button
            type="button"
            className={cx('wz button primary', 'button_320')}
            onClick={() => onClickStep(2)}
            disabled={accountList.length < 1}
          >
            다음
          </button>
        </div>
      </div>
      {isVisibleModal && (
        <AdRegistrationModal
          isVisible={isVisibleModal}
          onClose={() => setVisibleModal(false)}
          isWadizAdmin={isWadizAdmin}
          isSuperUser={isSuperUser}
          projectType={projectType}
          comingsoon={comingsoon}
          campaignId={campaignId}
          onFinish={onFinish}
        />
      )}
    </>
  )
}

export default CreateAdStep2
