import React from 'react';
import { Link, withRouter } from 'react-router-dom-v5';
import { connect } from 'react-redux';

import { logout } from '../../reducers/userReducer';
import { setMenuStatus } from '../../reducers/projectMenuReducer';
import { resetActivatedAdInfo } from '../../reducers/adListReducer';
import logo from '../../assets/images/adLogo.svg'

import className from 'classnames/bind';
import style from './Header.module.scss';

import GnbContainer from '../../containers/GnbContainer/GnbContainer';
const cx = className.bind(style);

const Header = (props) => {
  const { user, history } = props;
  const { pathname } = history.location
  return (
    <header className={cx('header_wrap')}>
      <div className={cx('header_inner')}>
        <h1 className={cx({ login_logo: pathname === '/login' || pathname.indexOf('intro') > 0 })}>
          <Link to="/main" onClick={() => props.setMenuStatus(false)}>
            <img src={logo} alt="wadiz AD" />
          </Link>
        </h1>
        <h3 className={cx({ login_logo: pathname === '/login' || pathname.indexOf('intro') > 0 })}> </h3>
        <div className={cx('right')}>
          <a
            href={'https://business.wadiz.kr/adstudio/home'}
            style={{ padding: '0 16px' }}
            className={cx('wz button primary-outline circular dense')}
          >
            {'새로운 광고센터 바로가기 '}
            <i className={cx('icon chevron-right')} />
          </a>
          <button
            type="button"
            className={cx('wz button primary circular dense', 'create_ad')}
            disabled={!user.token || pathname.indexOf('no_project') > 0 || !user.isSuperUser}
            onClick={() => {
              history.push('/main/ad/create')
              props.resetActivatedAdInfo()
            }}
          >
            <i className={cx('icon add')} />
            광고만들기
          </button>
          {/* {user.token ? (
              <button type='button' className={cx('wz button less', 'isDeskTop')} onClick={props.logout}>로그아웃</button>
            ) : (
              <button type='button' className={cx('wz button less', 'isDeskTop')}>로그인</button>
            )} */}
        </div>
      </div>
      {/* 인트로 화면에서 gnb 안보이게 해주세요 */}
      <GnbContainer />
    </header>
  )
};

export default connect(({ user }) => ({ user }), ({ logout, setMenuStatus, resetActivatedAdInfo }))(withRouter(Header));
