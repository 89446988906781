/* eslint-disable react/no-danger */
import React from 'react'

// CSS module
import style from './SearchResult.module.scss'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)

const SearchResult = ({ searchResult, searchKeyword, close, handleSearchAdClick }) => {
  return (
    <div className={cx('sidebar_searchResult')}>
      {/* 검색결과가 없을때 */}
      {searchResult.length === 0 ? (
        <section className={cx('sidebar_searchResult_null')}>
          <i className={cx('icon error-o')}></i>
          <p>검색결과가 없습니다.</p>
        </section>
      ) : (
        <section className={cx('sidebar_searchResult_list')}>
          {searchResult.map((val, idx) => (
            <dl key={idx}>
              <dt
                dangerouslySetInnerHTML={{
                  __html: String(val.projectName).replace(
                    searchKeyword,
                    `<em class=${cx('point_red')}>${searchKeyword}</em>`
                  ),
                }}
              ></dt>
              {val.advertisementList.map((val2, idx2) => (
                <dd
                  style={{ cursor: 'pointer' }}
                  key={idx2}
                  onClick={() =>
                    handleSearchAdClick(
                      val2.adCenterProjectId,
                      val2.campaignId,
                      val2.projectName,
                      val2.adCenterAdvertisementId,
                      val2.adName,
                      val2.projectType
                    )
                  }
                  dangerouslySetInnerHTML={{
                    __html: `<i class='icon play'></i>${String(val2.adName).replace(
                      searchKeyword,
                      `<em class=${cx('point_red')}>${searchKeyword}</em>`
                    )}`,
                  }}
                ></dd>
              ))}
            </dl>
          ))}
        </section>
      )}
      <button type="button" className={cx('wz button circular mini')} onClick={close}>
        닫기
      </button>
    </div>
  )
}

export default SearchResult
