// eslint-disable-next-line
import React, { FC } from 'react'
import styles from './WadizOnlyDescriptor.module.scss'

type PropsType = {
  isWadizOnly: boolean
}
export const WadizOnlyDescriptor: FC<PropsType> = ({ isWadizOnly }) =>
  !isWadizOnly ? null : (
    <ul className={styles.wadizOnly}>
      <li>집행일 기준으로 와디즈 Only 프로젝트인 경우, 추가 할인이 적용됩니다.</li>
      <li>
        광고 신청 이후 와디즈 Only를 취소하는 경우, 집행일 기준 Only 여부에 따라 정가 결제 혹은 추가 결제가 진행될 수
        있습니다.
      </li>
    </ul>
  )
