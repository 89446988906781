import React from 'react'

// Plugin
import { Link, NavLink } from 'react-router-dom-v5'
import { connect } from 'react-redux'

// Components
import SearchResult from '../../containers/SearchResultContainer'

// CSS module
import className from 'classnames/bind'
import style from './Lnb.module.scss'
const cx = className.bind(style)

const Lnb = ({
  mobileMenuIsOpen,
  emptyLnb,
  type,
  step,
  adId,
  LnbData,
  toggleMoreLessButton,
  searchPopupIsShow,
  searchKeyword,
  searchResult,
  handleSearch,
  getSearchResult,
  closeSearchPopup,
  handleSearchAdClick,
  refreshAdAccountList,
  isModify,
  // handleMoveToResultReport,
  // handleMoveToCostReport,
  // handleClickDisabledLnbButton,
  user,
}) => {
  return (
    <>
      {!emptyLnb && type === 'adlist' && (
        <div className={cx('ad_sidebar', 'adlist', { is_mobile_none: !mobileMenuIsOpen })}>
          {/* // 광고 리스트 LNB */}
          <h3>MY 광고관리</h3>
          <div className={cx({ block_lnb: isModify })}>
            {LnbData.length === 0 ? (
              <div className={cx('ad_listNull')}>
                <i className={cx('icon error-o')}></i>
                <p>등록된 광고 구좌가 없습니다.</p>
              </div>
            ) : (
              <>
                <div className={cx('wz input', 'sidebar_search')}>
                  <input
                    type="search"
                    name="pseudo-text"
                    placeholder="광고명"
                    autoComplete="off"
                    onChange={handleSearch}
                    onKeyUp={(event) => {
                      if (!isModify && event.key === 'Enter') {
                        getSearchResult()
                      }
                    }}
                  />
                  <button
                    type="button"
                    className={cx('wz button less')}
                    onClick={() => {
                      if (!isModify) getSearchResult()
                    }}
                  >
                    <i className={cx('icon search')}></i>
                    <span>검색</span>
                  </button>
                  {/* 검색결과 */}
                  {searchPopupIsShow && (
                    <SearchResult
                      close={closeSearchPopup}
                      searchResult={searchResult}
                      searchKeyword={searchKeyword}
                      handleSearchAdClick={handleSearchAdClick}
                    />
                  )}
                  {/* // 검색결과 */}
                </div>
                <div className={cx('sidebar_list')}>
                  <ul className={cx('sidebar_list_pjName')}>
                    {!isModify
                      ? LnbData.map((val, idx) => (
                          <li key={idx}>
                            <div
                              className={cx('sidebar_list_pjButton')}
                              onClick={() => toggleMoreLessButton(val.id, !val.isOpen)}
                            >
                              <strong>{val.projectName}</strong>
                              {val.isOpen && (
                                <button className={cx('wz button less mini')}>
                                  <i className={cx('icon expand-less')}></i>
                                  <span>접기</span>
                                </button>
                              )}
                              {!val.isOpen && (
                                <button className={cx('wz button less mini')}>
                                  <i className={cx('icon expand-more')}></i>
                                  <span>펼치기</span>
                                </button>
                              )}
                            </div>
                            {/* <button className={cx('wz button less mini')}>
                          <i className={cx('icon expand-more')}></i>
                          <span>펼치기</span>
                        </button> */}
                            <ul className={cx('sidebar_list_adName', { hide: !val.isOpen })}>
                              {val.advertisementList.map((val2, idx2) => (
                                <li key={idx2} className={cx({ active: adId === val2.adCenterAdvertisementId })}>
                                  <i className="icon angle-right"></i>
                                  <span
                                    onClick={() =>
                                      refreshAdAccountList(
                                        val.id,
                                        val.campaignId,
                                        val.projectName,
                                        val2.adCenterAdvertisementId,
                                        val2.adName,
                                        val.projectType
                                      )
                                    }
                                  >
                                    {val2.adName}
                                  </span>
                                  {val2.adCenterAdvertisementId === adId &&
                                    (val2.creativeCount > 0 ? (
                                      <div className={cx('sidebar_list_btnWrap')}>
                                        {/* <button type="button" className={cx('wz button circular mini')} onClick={() => handleMoveToResultReport(val2.adCenterAdvertisementId)}>
                                              성과현황
                                            </button>
                                            <button type="button" className={cx('wz button circular mini')} onClick={() => handleMoveToCostReport(val2.adCenterAdvertisementId)}>
                                              광고비 현황
                                            </button> */}
                                      </div>
                                    ) : (
                                      <div className={cx('sidebar_list_btnWrap')} disabled>
                                        {/* <button type="button" className={cx('wz button circular mini')} onClick={handleClickDisabledLnbButton} disabled>
                                                성과현황
                                            </button>
                                            <button type="button" className={cx('wz button circular mini')} onClick={handleClickDisabledLnbButton} disabled>
                                                광고비 현황
                                            </button> */}
                                      </div>
                                    ))}
                                </li>
                              ))}
                              {/* <li>
                            <i className="icon angle-right"></i>
                            광고2 - 영화관을 집으로, 빔 프로젝터의 새로운 기준, 10만원대..
                            <button type="button" className={cx('wz button circular mini')}>
                              성과현황
                            </button>
                            <button type="button" className={cx('wz button circular mini')}>
                              광고비 현황
                            </button>
                          </li> */}
                            </ul>
                          </li>
                        ))
                      : LnbData.map((val, idx) => (
                          <li key={idx}>
                            <div className={cx('sidebar_list_pjButton')}>
                              <strong>{val.projectName}</strong>
                              {val.isOpen && (
                                <button className={cx('wz button less mini')}>
                                  <i className={cx('icon expand-less')}></i>
                                  <span>접기</span>
                                </button>
                              )}
                              {!val.isOpen && (
                                <button className={cx('wz button less mini')}>
                                  <i className={cx('icon expand-more')}></i>
                                  <span>펼치기</span>
                                </button>
                              )}
                            </div>
                            {/* <button className={cx('wz button less mini')}>
                        <i className={cx('icon expand-more')}></i>
                        <span>펼치기</span>
                      </button> */}
                            <ul className={cx('sidebar_list_adName', { hide: !val.isOpen })}>
                              {val.advertisementList.map((val2, idx2) => (
                                <li key={idx2} className={cx({ active: adId === val2.adCenterAdvertisementId })}>
                                  <i className="icon angle-right"></i>
                                  <span>{val2.adName}</span>
                                  {val2.adCenterAdvertisementId === adId &&
                                    (val2.creativeCount > 0 ? (
                                      <div className={cx('sidebar_list_btnWrap')}>
                                        {/* <button type="button" className={cx('wz button circular mini')}>
                                                성과현황
                                              </button>
                                              <button type="button" className={cx('wz button circular mini')}>
                                                광고비 현황
                                              </button> */}
                                      </div>
                                    ) : (
                                      <div className={cx('sidebar_list_btnWrap')} disabled>
                                        {/* <button type="button" className={cx('wz button circular mini')} disabled>
                                                  성과현황
                                              </button>
                                              <button type="button" className={cx('wz button circular mini')} disabled>
                                                  광고비 현황
                                              </button> */}
                                      </div>
                                    ))}
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                  </ul>
                </div>
              </>
            )}
          </div>
          <ul className={cx('sidebar_info')}>
            <li>
              <Link to="/main/ad/user/accountManage">
                <i className={cx('icon account-circle-o')} />
                계정정보
              </Link>
            </li>
            <li>
              {/* <Link to="/main/ad/user/help"><i className="icon help-o" />도움말</Link> */}
              <a
                href="https://makercenter.wadiz.kr/board/basic/918?rownum=197&search_type=title_contents&search_text=&category1_idx=&category2_idx=&page=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                도움말
              </a>
            </li>
          </ul>
        </div>
      )}
      {!emptyLnb && type === 'adCreate' && (
        <div className={cx('ad_sidebar', 'sidebar_step')}>
          {/* // 광고 만들기 LNB :: 완료되어서 넘어간 페이지는 className passed 넣어주세요 */}
          <h3>광고만들기</h3>
          <ol className={cx('ad_sidebar_stepNo')}>
            <li className={cx({ active: step === 0, passed: step > 0 })}>
              광고 프로젝트 만들기
              <ul className={cx('ad_sidebar_stepInfo')}>
                <li>프로젝트 선택</li>
                <li>광고프로젝트 명</li>
              </ul>
            </li>
            <li className={cx({ active: step === 1, passed: step > 1 })}>
              광고 등록하기
              <ul className={cx('ad_sidebar_stepInfo')}>
                <li>광고 상품 선택</li>
                <li>광고 기간 설정</li>
                <li>광고 소재 등록</li>
              </ul>
            </li>
            <li className={cx({ active: step === 2, passed: step > 2 })}>광고비 결제 예약 하기</li>
          </ol>
          <ul className={cx('sidebar_info')}>
            <li>
              <Link to="/main/ad/user/accountManage">
                <i className={cx('icon account-circle-o')} />
                계정정보
              </Link>
            </li>
            <li>
              {/* <Link to="/main/ad/user/help"><i className="icon help-o" />도움말</Link> */}
              <a
                href="https://makercenter.wadiz.kr/board/basic/918?rownum=197&search_type=title_contents&search_text=&category1_idx=&category2_idx=&page=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon help-o" />
                도움말
              </a>
            </li>
          </ul>
        </div>
      )}
      {!emptyLnb && type === 'report' && (
        <div className={cx('ad_sidebar')}>
          {/* // 보고서 LNB */}
          <h3>보고서</h3>
          <ul>
            <li>
              <NavLink to="/main/ad/report/result" activeClassName={cx('active')}>
                성과 보고서
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/main/ad/report/cost" activeClassName={cx('active')}>
                광고비 보고서
              </NavLink>
            </li>
            <li>
              <NavLink to="/main/ad/report/favorit" activeClassName={cx('active')}>
                보고서 즐겨찾기
              </NavLink>
            </li> */}
          </ul>
          <ul className={cx('sidebar_info')}>
            <li>
              <Link to="/main/ad/user/accountManage">
                <i className={cx('icon account-circle-o')} />
                계정정보
              </Link>
            </li>
            <li>
              {/* <Link to="/main/ad/user/help"><i className="icon help-o" />도움말</Link> */}
              <a
                href="https://makercenter.wadiz.kr/board/basic/918?rownum=197&search_type=title_contents&search_text=&category1_idx=&category2_idx=&page=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon help-o" />
                도움말
              </a>
            </li>
          </ul>
        </div>
      )}
      {!emptyLnb && type === 'settings' && (
        <div className={cx('ad_sidebar')}>
          {/* // 설정 LNB */}
          <h3>설정</h3>
          <ul>
            {/* <li>
                <NavLink to="/main/ad/settings/taxbillStatus" activeClassName={cx('active')}>
                  세금계산서 발행 현황
                </NavLink>
              </li>
              <li>
                <NavLink to="/main/ad/settings/businessInfo" activeClassName={cx('active')}>
                  사업자 정보 확인
                </NavLink>
              </li>
              <li>
                <NavLink to="/main/ad/settings/refundAccount" activeClassName={cx('active')}>
                  환불 계좌 관리
                </NavLink>
              </li> */}
            <li>
              <NavLink to="/main/ad/settings/simpleAccount" activeClassName={cx('active')}>
                간편 결제 관리
              </NavLink>
            </li>
            <li>
              <NavLink to="/main/ad/settings/terms" activeClassName={cx('active')}>
                약관 및 계약 관리
              </NavLink>
            </li>
          </ul>
          <ul className={cx('sidebar_info')}>
            <li>
              <Link to="/main/ad/user/accountManage">
                <i className={cx('icon account-circle-o')} />
                계정정보
              </Link>
            </li>
            <li>
              {/* <Link to="/main/ad/user/help"><i className="icon help-o" />도움말</Link> */}
              <a
                href="https://makercenter.wadiz.kr/board/basic/918?rownum=197&search_type=title_contents&search_text=&category1_idx=&category2_idx=&page=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon help-o" />
                도움말
              </a>
            </li>
          </ul>
        </div>
      )}
      {!emptyLnb && type === 'user_account' && (
        <div className={cx('ad_sidebar')}>
          {/* // 사용자 메뉴 LNB */}
          <h3>계정정보</h3>
          <ul></ul>
          <ul className={cx('sidebar_info')}>
            <li>
              <Link to="/main/ad/user/accountManage">
                <i className={cx('icon account-circle-o')} />
                계정정보
              </Link>
            </li>
            <li>
              {/* <Link to="/main/ad/user/help"><i className="icon help-o" />도움말</Link> */}
              <a
                href="https://makercenter.wadiz.kr/board/basic/918?rownum=197&search_type=title_contents&search_text=&category1_idx=&category2_idx=&page=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon help-o" />
                도움말
              </a>
            </li>
          </ul>
        </div>
      )}
      {!emptyLnb && type === 'user_help' && user.token && (
        <div className={cx('ad_sidebar')}>
          {/* // 사용자 메뉴 LNB */}
          <h3>도움말</h3>
          <ul></ul>
          <ul className={cx('sidebar_info')}>
            <li>
              <Link to="/main/ad/user/accountManage">
                <i className={cx('icon account-circle-o')} />
                계정정보
              </Link>
            </li>
            <li>
              {/* <Link to="/main/ad/user/help"><i className="icon help-o" />도움말</Link> */}
              <a
                href="https://makercenter.wadiz.kr/board/basic/918?rownum=197&search_type=title_contents&search_text=&category1_idx=&category2_idx=&page=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon help-o" />
                도움말
              </a>
            </li>
          </ul>
        </div>
      )}
    </>
  )
}

export default connect(({ user }) => ({ user }), {})(Lnb)
