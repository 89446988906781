import React, { PureComponent } from 'react'

// Plugin
import { connect } from 'react-redux'

// Components
import PreviewDialog from './PreviewDialog'

class SecondDialog extends PureComponent {
  render() {
    const { dialogType } = this.props.secondDialog

    let targetDialog = null
    if (dialogType === 'previewDialog') {
      targetDialog = <PreviewDialog />
    }
    return targetDialog
  }
}

export default connect(({ secondDialog }) => ({ secondDialog }))(SecondDialog)
