import axios, { Method, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios'
import Cookies from 'universal-cookie'

export interface ResultType<T> {
  message: string | null
  result: T
}

const makeHeader = (isFormData = false) => ({
  'Content-Type': isFormData ? 'multipart/form-data' : 'application/json;charset=UTF-8',
  token: new Cookies().get('token'),
})
const baseURL = `${process.env.REACT_APP_WADIZ_API_HOST || import.meta.env.VITE_API_HOST}api/v1/ad/`

const instance =
  ({ timeout = 5000, isFormData = false }) =>
  <T>(method: Method, url: string, opt?: AxiosRequestConfig): Promise<T> =>
    axios({
      headers: makeHeader(isFormData),
      baseURL,
      timeout,
      ...(opt || {}),
      method,
      url,
    }).then(
      (res: AxiosResponse<T>) => res.data,
      (err: AxiosError) => {
        if (err?.response?.status === 401) {
          if (window.location.pathname.indexOf('login') < 0) {
            window.location.href = '/login'
          }
          return err as T
        }
        throw err
      }
    )

//{ timeout = 5000, isFormData = false }
export const api = instance({})
export const apiForm = instance({ isFormData: true })
