import KeyVisualButton from './components/KeyVisualButton.tsx'
import logo from './small.svg'
import style from './KeyVisual.module.scss'

const KeyVisual = () => (
  <section className={style.container}>
    <div className={style.header}>
      <div className={style.headerContent}>
        <h1>
          <img src={logo} alt="wadiz AD"></img>
        </h1>
        <div className={style.headRight}>
          <a href={`${process.env.REACT_APP_WADIZ_APP_ROOT}`}>
            <span className={style.shortcut}>와디즈 바로가기</span>
          </a>
        </div>
      </div>
    </div>
    <div className={style.keyVisualWrap}>
      <div className={style.notice}>
        <h2>광고센터가 새로워졌어요!</h2>
        <div className={style.pcText}>
          <div>새로워진 광고센터에서</div>
          <div>디스플레이 광고와 푸시 광고를 만들어 보세요!</div>
        </div>
        <div className={style.moText}>
          <div>새로워진 광고센터에서</div>
          <br />
          <div>디스플레이 광고와 푸시 광고를</div>
          <br />
          <div>만들어 보세요!</div>
        </div>
        <KeyVisualButton />
      </div>
      <div className={style.keyVisual} aria-label="visual 이미지"></div>
    </div>
  </section>
)

export default KeyVisual
