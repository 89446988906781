import withIcon, { Props } from './utils/withIcon'

function ErrorIcon(props: Props) {
  return (
    <svg viewBox="0 0 40 40" {...props}>
      <path d="M0 0v40h40V0zm20 39a19 19 0 1 1 19-19 19.06 19.06 0 0 1-19 19z" fill="none" />
      <path d="M20 1a19 19 0 1 0 19 19A19.06 19.06 0 0 0 20 1zm-1 9.21h2v13h-2zm1 19.39a1.8 1.8 0 1 1 1.8-1.8 1.81 1.81 0 0 1-1.8 1.8z" />
    </svg>
  )
}

export default withIcon('ErrorIcon')(ErrorIcon)
