import React, { Component } from 'react'
import { withRouter } from 'react-router-dom-v5'
import { connect } from 'react-redux'
import { logout } from '../../reducers/userReducer'
import { setMenuStatus } from '../../reducers/projectMenuReducer'
import Gnb from '../../components/Gnb'
class GnbContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      menuList: [
        {
          name: 'MY 광고관리',
          id: 'list',
          link: '/main/ad/list',
          isExpand: true,
        },
        {
          name: '보고서',
          id: 'report',
          link: '/main/ad/report/result',
          children: [
            { name: '성과 보고서', link: '/main/ad/report/result' },
            // { name: '광고비 보고서', link: '/main/ad/report/cost' },
            // { name: '보고서 즐겨찾기', link: '/main/ad/report/favorit' },
          ],
          isExpand: true,
        },
        {
          name: '설정',
          id: 'settings',
          // link: '/main/ad/settings/taxbillStatus',
          link: '/main/ad/settings/simpleAccount',
          children: [
            // { name: '세금계산서 발행 현황', link: '/main/ad/settings/taxbillStatus' },
            // { name: '사업자 정보 확인', link: '/main/ad/settings/businessInfo' },
            // { name: '환불 계좌 관리', link: '/main/ad/settings/refundAccount' },
            { name: '간편 결제 관리', link: '/main/ad/settings/simpleAccount' },
          ],
          isExpand: true,
        },
      ],
      isOpen: false,
    }
  }

  static getDerivedStateFromProps = (nextProps, nextState) => {
    if (window.outerWidth > 768) return { ...nextState, isOpen: false }

    return { ...nextState }
  }

  handleMenuIsOpen = (isProjectList) => {
    if (isProjectList) this.props.setMenuStatus(true)
    this.setState(
      (prevState) => ({ isOpen: !prevState.isOpen }),
      () => {
        if (this.state.isOpen) {
          document.body.style.overflow = 'hidden'
          document.body.style.position = 'fixed'
          document.body.style.height = '100%'
        } else {
          document.body.style.overflow = 'unset'
          document.body.style.position = 'unset'
        }
      }
    )
  }

  handleLogout = () => {
    this.props.logout()
  }

  render() {
    const { state, handleMenuIsOpen, handleLogout } = this
    const { token } = this.props.user
    return (
      <Gnb
        {...state}
        onChangeMenuIsOpen={handleMenuIsOpen}
        onClickLogout={handleLogout}
        currentPath={this.props.location.pathname}
        token={token}
      />
    )
  }
}

export default connect(({ user }) => ({ user }), { logout, setMenuStatus })(withRouter(GnbContainer))
