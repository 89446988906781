import CreateDOM from 'react-dom/client';
import ToastContainer from './ToastContainer';
import React from 'react';

const TOAST_CONTAINER_ID = 'wadiz-waffle-toast-container-component';
/** window에 하나의 ToastContainer만 만들어서 사용 */

type request = [React.ReactElement | string, any?];

export const showToast = (function () {
  let toastContainer: any = null;
  let isPending = false;
  const requestQueue: request[] = [];
  return (content: React.ReactElement | string, toastProps?: any) => {
    if (toastContainer) {
      toastContainer.showToast(content, toastProps);
    } else {
      requestQueue.push([content, toastProps]);
      if (isPending) {
        return;
      }

      isPending = true;
      const element = document.createElement('div');
      element.setAttribute('id', TOAST_CONTAINER_ID);
      document.body.appendChild(element);

      const root = CreateDOM.createRoot(element);
      root.render(<ToastContainer onDidMount={(toastContainerInstance: any) => {
        toastContainer=toastContainerInstance;
        isPending = false;
        requestQueue.forEach(request => toastContainer.showToast(request[0], request[1]));
      }} />);
    }
  };
})();

export default {
  showToast,
};
