import React from 'react';

import className from 'classnames/bind';
import style from './Paginator.module.scss';
const cx = className.bind(style);

const Paginator = ({
  data, onChangePage, onChangeCurrPage,
}) => {
  return (
    <div className={cx('wz pagination', 'paginationDsg')}>
      <button
        className={cx('wz dense icon less button btn-prev', 'pagination_arrow', { active: data.activePageIdx !== 0 })}
        disabled={data.activePageIdx === 0}
        onClick={() => onChangePage('prev')}
      >
        <i className={cx('icon chevron-left')} aria-hidden="true"></i>
      </button>
      <ul>
        {data.activePageIdx !== 0 && (
          <>
            <li>
              <button className={cx('wz less dense button')} onClick={() => onChangeCurrPage(data.totalArr[0][0])}>
                {data.totalArr[0][0]}
              </button>
            </li>
            <li>
              <button className={cx('wz less dense button')}>
                ...
              </button>
            </li>
          </>
        )}
        {
          data.items.map((val, idx) => (
            <li key={idx} className={cx({ active: data.activeIdx === idx })}><button className={cx('wz less dense button')} onClick={() => onChangeCurrPage(val)}>{val}</button></li>
          ))
        }
        {data.activePageIdx !== data.totalArr.length - 1 && (
          <>
            <li>
              <button className={cx('wz less dense button')}>
                ...
              </button>
            </li>
            <li>
              <button className={cx('wz less dense button')} onClick={() => onChangeCurrPage(data.totalArr[data.totalArr.length - 1].slice(-1))}>
                {data.totalArr[data.totalArr.length - 1].slice(-1)}
              </button>
            </li>
          </>
        )}
      </ul>
      <button
        className={cx('wz dense icon less button btn-next', 'pagination_arrow', { active: data.activePageIdx !== data.totalArr.length - 1 })}
        disabled={data.activePageIdx === data.totalArr.length - 1}
        onClick={() => onChangePage('next')}
      >
        <i className={cx('icon chevron-right')} aria-hidden="true"></i>
      </button>
    </div>
  );
};

export default Paginator;
