import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './notosans.scss';
import './notoserif.scss';
import './roboto.scss';

import App from './App';

const { store } = configureStore;

export default function Root() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}
