import React from 'react';
import classNames from 'classnames/bind';
import TitleSection from '../TitleSection';
import style from './TargetPushAdSection.module.scss';
import facebook from '../../../../assets/images/intro/icon_facebook.png';
import instagram from '../../../../assets/images/intro/icon_instagram.png';
import wa from '../../../../assets/images/intro/icon_wa.png';
import kakao from '../../../../assets/images/intro/icon_kakao.png';
import arrowRight from '../../../../assets/images/intro/arrow_right.svg';


const cx = classNames.bind(style);

const contentList = [
  {
    name: '타겟 광고',
    copy: '페이스북, 인스타그램 등 외부 매체 타겟 광고',
    icon: [
      {
        name: facebook,
        alt: 'facebook',
      },
      {
        name: instagram,
        alt: 'instagram',
      },
    ],
    buttonName: '타겟 광고 신청하기',
    buttonLink: 'https://biz.wadiz.kr/camp/make02',
    desc: (
      <>
        와디즈가 보유한 유저 데이터 기반으로 <br className={cx('moBr')} />
        다이렉트 타겟팅 뿐 아니라
        <br />
        유사 타겟까지 외부 매체를 통한 광고 진행 가능
      </>
    ),
    service: ['페이스북 광고 / 인스타그램 광고'],
    image: [],
  },
  {
    name: '푸시 광고',
    copy: '로열티 높은 와디즈 이용 유저 타겟 광고',
    icon: [
      {
        name: wa,
        alt: 'wa',
      },
      {
        name: kakao,
        alt: 'kakao',
      },
    ],
    buttonName: '푸시 광고 신청하기',
    buttonLink: 'https://app.smartsheet.com/b/form/6d17ebc527e84dafafb2e8af5ce64ed0',
    desc: (
      <>
        와디즈로부터의 메시지를 본 후 서비스 탐색이 시작되기에
        <br />
        탐색 동선의 시작점으로 주목도 극대화 가능
      </>
    ),
    service: ['와디즈 앱 푸시', '카카오톡 푸시'],
  },
]

const ContentItem = ({
  icon,
  name,
  copy,
  buttonName,
  buttonLink,
  desc,
  service,
}) => (
  <section className={cx('adv')}>
    <div className={cx('social')}>
      {icon.map((m, index) => (
        <img src={m.name} alt={m.alt} />
      ))}
    </div>
    <h4 className={cx('name')}>{name}</h4>
    <p className={cx('copy')}>{copy}</p>
    <a href={buttonLink} target="_blank" className={cx('button')} rel="noreferrer">
      <span>{buttonName}</span>
      <img src={arrowRight} alt={buttonName} />
    </a>
    <hr />
    <p className={cx('desc')}>{desc}</p>
    <div className={cx('service')}>
      {service.map((m, index) => (
        <span>{m}</span>
      ))}
    </div>
    <div className={cx('visual')} />
  </section>
)

const TargetPushAdSection = () => {
  return (
    <section className={cx('container')}>
      <TitleSection section="TargetPushAdSection" className={cx('title')} />
      <div className={ cx('section')}>
        {contentList.map((item, index) => (
          <ContentItem
            key={`contentItem${index}`}
            icon={item.icon}
            name={item.name}
            copy={item.copy}
            buttonName={item.buttonName}
            buttonLink={item.buttonLink}
            desc={item.desc}
            service={item.service}
          />
        ))}
      </div>
      <a href="https://makercenter.wadiz.kr/board/card/555"
        target="_blank"
        rel="noreferrer"
        className={cx('btn')}>
        와디즈 광고 종류 제대로 알기
      </a>
    </section>
  )
};

export default TargetPushAdSection;
