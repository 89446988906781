import React, { PureComponent } from 'react'

import { connect } from 'react-redux'
import { openPopup } from '../../reducers/dialogReducer'
import { network } from '../../network'

import SimpleAccount from '../../components/SettingContent/contents/SimpleAccount'
import { toast } from 'react-toastify'

class SettingSimpleAccountPage extends PureComponent {
  state = {
    simplePaymentCardArray: [],

    loadingBarFlag: false,

    openGuide: { idx: -1, type: '' }, // 설정 가이드
  }

  componentDidMount() {
    this.refreshSimplePaymentCardArray()
  }

  refreshSimplePaymentCardArray = () => {
    const url = `pay-info/list?maker_id=${this.props.user.maker_id}`
    this.setState({ loadingBarFlag: true }, () => {
      network.callApi({
        url,
        method: 'get',
        successFunc: (jsonData) => {
          const simplePaymentCardArray = jsonData.result
          this.setState({
            simplePaymentCardArray,
            loadingBarFlag: false,
          })
        },
        failFunc: () => {
          console.error(`fail- ${url}`)
        },
      })
    })
  }

  handleRegisterSimplePaymentModalOpen = () => {
    this.props.openPopup({
      dialogType: 'simplePaymentRegisterDialog',
      dialogData: {
        title: '간편 결제 정보 등록',
        closeText: '닫기',
        confirmText: '등록',
        confirmFunc: () => this.refreshSimplePaymentCardArray(),
      },
    })
  }

  handleDeleteSimplePaymentModalOpen = (cardId, cardNum) => {
    this.props.openPopup({
      dialogType: 'confirmDialog',
      dialogData: {
        title: '간편 결제 정보 삭제',
        type: 'alert',
        content: (
          <>
            <table>
              <tbody>
                <tr>
                  <th>신용카드 번호</th>
                  <td>**** - **** - **** - {cardNum}</td>
                </tr>
              </tbody>
            </table>
            <p>위 간편결제 정보를 삭제하시겠습니까?</p>
          </>
        ),
        closeText: '닫기',
        confirmText: '삭제',
        cardId,
        confirmFunc: () => this.deleteSimplePaymentInfo(cardId),
      },
    })
  }

  deleteSimplePaymentInfo = (cardId) => {
    const url = `pay-info/delete?pay_info_id=${cardId}`
    network.callApi({
      url,
      method: 'GET',
      successFunc: ({ result, message }) => {
        if (result === 'fail') {
          toast(message)
          return
        }
        this.refreshSimplePaymentCardArray()
      },
      failFunc: () => {
        console.error(`fail- ${url}`)
      },
    })
  }

  handleDeleteAllSimplePaymentModalOpen = () => {
    this.props.openPopup({
      dialogType: 'confirmDialog',
      dialogData: {
        title: '신용카드 정보 모두 삭제',
        type: 'alert',
        content: (
          <>
            {/* Content Area */}
            <p>신용카드 정보를 모두 삭제하면, 간편 결제 서비스를 더 이상 사용할 수 없습니다.</p>
          </>
        ),
        closeText: '닫기',
        confirmText: '삭제',
        confirmFunc: () => this.deleteAllSimplePaymentInfo(),
      },
    })
  }

  deleteAllSimplePaymentInfo = () => {
    const url = `pay-info/delete/all?maker_id=${this.props.user.maker_id}`
    network.callApi({
      url,
      method: 'GET',
      successFunc: () => {
        this.refreshSimplePaymentCardArray()
      },
      failFunc: () => {
        console.error(`fail- ${url}`)
      },
    })
  }

  render() {
    const {
      state,
      handleOpenGuideType,
      handleRegisterSimplePaymentModalOpen,
      handleDeleteSimplePaymentModalOpen,
      handleDeleteAllSimplePaymentModalOpen,
    } = this

    const { simplePaymentCardArray, openGuide, loadingBarFlag } = state

    return (
      <SimpleAccount
        simplePaymentCardArray={simplePaymentCardArray}
        openGuide={openGuide}
        loadingBarFlag={loadingBarFlag}
        onChangeOpenGuideType={handleOpenGuideType}
        handleRegisterSimplePaymentModalOpen={handleRegisterSimplePaymentModalOpen}
        handleDeleteSimplePaymentModalOpen={handleDeleteSimplePaymentModalOpen}
        handleDeleteAllSimplePaymentModalOpen={handleDeleteAllSimplePaymentModalOpen}
      />
    )
  }
}

export default connect(({ user, project }) => ({ user, project }), { openPopup })(SettingSimpleAccountPage)
