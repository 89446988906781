/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { PLUGINS, POPUP_TEMPLATES, RegisterCommand } from 'froala-editor'
import FroalaEditor from 'froala-editor'
import type { WYSIWYGEditor } from 'froala-editor'

import { styleVariable } from '../../styles'

const COMMAND = {
  unhighlight: {
    className: 'REMOVE',
    color: '',
  },
  highlightMintColor: {
    className: 'color-mint',
    color: styleVariable.colorMint300,
  },
  highlightYellowColor: {
    className: 'color-yellow',
    color: styleVariable.colorYellow300,
  },
}

RegisterCommand('highlighter', {
  title: '형광펜',
  icon: 'highlighter',
  refreshAfterCallback: false,
  callback() {
    const { highlighter } = this as unknown as WYSIWYGEditor
    highlighter.show()
  },
  refresh: function () {
    const { highlighter } = this as unknown as WYSIWYGEditor
    highlighter.refresh()
  },
})

RegisterCommand('highlightMintColor', {
  callback() {
    const { highlighter } = this as unknown as WYSIWYGEditor

    highlighter.highlight(COMMAND.highlightMintColor.className)
    highlighter.hide()
  },
})

RegisterCommand('highlightYellowColor', {
  callback() {
    const { highlighter } = this as unknown as WYSIWYGEditor

    highlighter.highlight(COMMAND.highlightYellowColor.className)
    highlighter.hide()
  },
})

RegisterCommand('unhighlight', {
  callback() {
    const { highlighter } = this as unknown as WYSIWYGEditor

    highlighter.unhighlight()
    highlighter.hide()
  },
})

PLUGINS.highlighter = function (editor: FroalaEditor) {
  const id = `highlighter.popup.${editor.id}`
  Object.assign(POPUP_TEMPLATES, { [id]: '[_BUTTONS_]' })

  return {
    _init: () => {
      const { opts: options, popups } = editor as unknown as WYSIWYGEditor

      const templateProperties = {
        buttons: `
          <div class="fr-color-set fr-selected-set">
            ${options.highlighterButtons
              .map(
                (key) => `
                  <span
                    role="button"
                    class="fr-command fr-select-color"
                    tabindex="-1"
                    style="${
                      COMMAND[key as keyof typeof COMMAND].color &&
                      `background-color: ${COMMAND[key as keyof typeof COMMAND].color}`
                    }"
                    data-cmd="${key}"
                    data-param1="${COMMAND[key as keyof typeof COMMAND].color}"
                    data-param2="${COMMAND[key as keyof typeof COMMAND].className}"
                    aria-selected="false"
                  ></span>
                `
              )
              .join('')}
          </div>
        `,
      }

      return popups.create(id, templateProperties)
    },

    show: () => {
      const { $tb, opts: options, popups, selection } = editor as unknown as WYSIWYGEditor

      if ($tb.find('[data-cmd="highlighter"]').length === 0) {
        return
      }

      const { scrollTop } = document.documentElement
      const { left: leftOffset, top, height } = $tb.find('[data-cmd="highlighter"]')[0].getBoundingClientRect()
      const topOffset = scrollTop + top + height - 10

      popups.setContainer(id, $tb)
      popups.show(id, leftOffset, topOffset, height, false)

      options.highlighterButtons.forEach((command) => {
        $tb.find(`[data-cmd="${command}"]`).removeClass('fr-active-item').attr('aria-selected', 'false').html('')
      })

      let selectedElement = selection.element()

      if (selectedElement.parentElement?.tagName.toLowerCase() === 'mark') {
        selectedElement = selectedElement.parentElement
      }

      if (selectedElement.tagName.toLowerCase() === 'mark') {
        $tb
          .find(`[data-param2="${selectedElement.className}"]`)
          .addClass('fr-active-item')
          .attr('aria-selected', 'true')
          .html('<span class="fr-selected-color" aria-hidden="true">\uf00c</span>')
      }
    },

    hide: () => {
      const { popups } = editor
      popups.hide(id)
    },

    highlight: (className: string) => {
      const { format } = editor

      format.remove('mark', {})
      format.toggle('mark', { class: className })
    },

    unhighlight: () => {
      const { format } = editor
      format.remove('mark', {})
    },

    refresh: () => {
      const { $tb, selection } = editor

      $tb.find('[data-cmd="highlighter"]').removeClass('fr-active').attr('aria-selected', 'false')

      let selectedElement: HTMLElement | null = null

      if (selection.element().tagName.toLowerCase() === 'mark') {
        selectedElement = selection.element()
      } else if (selection.element().closest('mark')) {
        selectedElement = selection.element().closest('mark')
      }

      if (selectedElement) {
        $tb.find('[data-cmd="highlighter"]').addClass('fr-active').attr('aria-selected', 'true')
      }
    },
  }
}
