import { ChangeEventHandler, useRef } from 'react'
import { Button } from 'antd'
import { CameraOutlined } from '@ant-design/icons'
import styles from './ImageUploadButton.module.scss'

type ImageUploadButtonFunction = {
  onChange: ChangeEventHandler<HTMLInputElement>
}

export const ImageUploadButton: React.FC<ImageUploadButtonFunction> = ({ onChange }) => {
  const ref = useRef<HTMLInputElement>(null)
  return (
    <div className={styles.container}>
      <Button
        size="large"
        icon={<CameraOutlined />}
        block
        onClick={() => {
          ref?.current?.click()
        }}
      >
        이미지 등록하기
      </Button>
      <input ref={ref} type="file" accept="image/jpg,image/jpeg,image/png" onChange={onChange} />
    </div>
  )
}
