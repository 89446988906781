import React, { PureComponent } from 'react';

// Plugin
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

class BarChart extends PureComponent {
  componentDidMount() {
    am4core.addLicense('CH201042735');
    this.generateChart(this.props);
  }

  componentDidUpdate() {
    this.generateChart(this.props);
  }

  // 라인 차트 초기화 함수
  generateChart = (props) => {
    const chart = am4core.create(props.idItem, am4charts.XYChart);
    chart.data = props.data;

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.cellStartLocation = 0.3;
    categoryAxis.renderer.cellEndLocation = 0.7;
    categoryAxis.fontSize = 10;
    categoryAxis.renderer.labels.template.maxWidth = 1074 / chart.data.length;
    categoryAxis.renderer.labels.template.wrap = true;
    categoryAxis.renderer.labels.template.fill = am4core.color('rgba(68, 72, 75, 0.5)');

    const createAxisAndSeries = (field, name, color, oppositeFlag) => {
      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.renderer.opposite = oppositeFlag;
      valueAxis.renderer.line.strokeOpacity = 0.5;
      valueAxis.renderer.line.strokeWidth = 0;
      valueAxis.renderer.labels.template.fill = am4core.color(color);
      valueAxis.extraMax = 0.3;
      valueAxis.renderer.grid.template.strokeWidth = 0;
      valueAxis.fontSize = 13;
      // rotation용
      // valueAxis.calculateTotals = true;

      // function createGrid(value) {
      //   const range = valueAxis.axisRanges.create();
      //   range.value = value;
      //   range.label.text = '{value}';
      // }
      // if (name === '클릭률') {
      //   valueAxis.strictMinMax = true;
      //   valueAxis.max = 100;
      //   valueAxis.min = 0;
      //   createGrid(0);
      //   createGrid(25);
      //   createGrid(50);
      //   createGrid(75);
      //   createGrid(100);
      //   valueAxis.renderer.labels.template.disabled = true;
      //   valueAxis.renderer.grid.template.disabled = true;
      // }


      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = 'category';
      series.yAxis = valueAxis;
      series.strokeWidth = 1;
      series.columns.template.width = am4core.percent(50);
      series.name = name;
      series.columns.template.tooltipText = '{category}: {name}[bold]{valueY}[/]';

      series.stroke = color;
      series.fill = color;
    };
    // chart.cursor = new am4charts.XYCursor();

    createAxisAndSeries('impressionsCount', '노출수', '#56cece', false);
    createAxisAndSeries('clickCount', '클릭수', '#c5a6e8', false);
    createAxisAndSeries('clickRatio', '클릭률', '#ff8181', true);
    // chart.scrollbarX = new am4core.Scrollbar();
    // chart.legend = new am4charts.Legend();
    // chart.legend.position = 'left';
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    let { height, width } = this.props;
    if (height === undefined) height = '300px';
    if (width === undefined) width = '100%';
    return (
      <div id={this.props.idItem} style={{ width, height }}></div>
    );
  }
}

export default BarChart;
