import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom-v5'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

// Components
import { openPopup } from '../../reducers/dialogReducer'
import AdFavoritReportContent from '../../components/AdFavoritReportContent/AdFavoritReportContent'
import { network } from '../../network'
import { dateToStringAdPeriod, dateToStringCreatedDate } from '../../datetostring'

class AdFavoritReportPage extends PureComponent {
  constructor(props) {
    super(props)
    const { history } = props
    let prevState = {}
    prevState = JSON.parse(sessionStorage.getItem('report_favorit'))

    this.state = {
      itemList: [],
      checkBoxList: [],
      allItemTotalCount: -1,
      totalCount: -1,
      selectedOption: {
        searchName: '',
        start: 1,
        size: 10,
      },
      needInit: false,
      tempData: [],
      tableInfo: {
        tableLabel: [],
        tableValue: [],
      },
      reportTypes: ['', '성과', '광고비'],
      fileName: '',
      zipName: '',
      loadingBarFlag: false,
    }

    if (history.action === 'POP' && prevState) {
      sessionStorage.removeItem('report_favorit')
      const { state } = this
      this.state = { ...state, ...prevState }
    }
  }

  viewSizeList = [3, 5, 10, 15, 20, 25, 30]

  checkMakerHaveProject = (allItemTotalCount) => {
    if (this.state.allItemTotalCount === -1 || this.state.selectedOption.searchName === '') {
      this.setState({ allItemTotalCount })
    }
  }

  getAllItem = () => {
    return new Promise((resolve, reject) => {
      const makerId = this.props.user.maker_id
      const { searchName, start, size } = this.state.selectedOption
      network.callApi({
        url: `report/favorites/list?maker_id=${makerId}&start=${start}&size=${size}&report_name=${searchName}`,
        method: 'get',
        header: { 'Content-Type': 'application/json;charset=UTF-8' },
        successFunc: (jsonData) => {
          const { result, totalCount } = jsonData
          this.checkMakerHaveProject(totalCount)
          if (totalCount === 0) {
            // (선택적으로 뿌렸는데 해당 프로젝트에 광고가 없는 경우)
            this.setState({ itemList: [], totalCount })
            resolve()
            return
          }
          const items = []
          for (let i = 0; i < result.length; i += 1) {
            const { reportName, reportType, startAt, endAt, createdAt, id, adCenterAdvertisementId } = result[i]
            const idx = totalCount - ((start - 1) * size + i)
            items.push({
              idx,
              reportType,
              reportName,
              startAt: startAt || '',
              endAt: endAt || '',
              createdAt,
              favoritItemId: id,
              adCenterAdvertisementId,
            })
          }
          this.setState({ itemList: items, totalCount })
          this.setCheckBoxInit()
          resolve(jsonData)
        },
        failFunc: (error) => {
          reject(error)
        },
      })
    })
  }

  handlePageNumber = (start) => {
    // 선택한 page에 대한 정보 가져오기
    const { selectedOption } = this.state
    this.setState({ selectedOption: { ...selectedOption, start } }, () => {
      this.getAllItem()
    })
  }

  handleViewSizeSelect = (e) => {
    // 리스트에 뿌려줄 아이템 개수 선택
    const size = e.target.value
    const { selectedOption } = this.state
    this.setState({ selectedOption: { ...selectedOption, size, start: 1 } }, () => {
      this.getAllItem()
    })
  }

  handleAllCheckBoxItemClick = (e) => {
    const targetChecked = e.target.checked
    if (!targetChecked) {
      // 모두 취소
      this.setCheckBoxInit()
    } else {
      const newCheckBoxList = []
      for (let i = 0; i < this.state.itemList.length; i += 1) {
        // newCheckBoxList.push(this.state.itemList[i].idx);
        newCheckBoxList.push(i)
        this.setState({ checkBoxList: newCheckBoxList })
      }
    }
  }

  handleCheckBoxItemClick = (e) => {
    const targetIdx = e.target.value
    const { checkBoxList } = this.state
    const itemIdx = checkBoxList.indexOf(Number(targetIdx))
    if (itemIdx !== -1) {
      checkBoxList.splice(itemIdx, 1)
    } else {
      checkBoxList.push(Number(targetIdx))
    }
    this.setState({ checkBoxList: [...checkBoxList] })
  }

  handleGoToADDetail = (type, id) => {
    if (id) {
      const { state } = this
      sessionStorage.setItem(
        'report_favorit',
        JSON.stringify({
          ...state,
        })
      )
      if (type === '광고비') {
        this.props.history.push(`/main/ad/report/cost/detail/${id}`)
      } else if (type === '성과') {
        this.props.history.push(`/main/ad/report/result/detail/${id}`)
      }
    }
  }

  // handleGoToADDetail = (id) => {
  //   if (id) {
  //     const { state } = this;
  //     console.log(state);
  //     sessionStorage.setItem('report_result', JSON.stringify({
  //       ...state
  //     }));
  //     this.props.history.push(`/main/ad/report/result/detail/${id}`);
  //   }
  // }

  handleSearchReportName = (e) => {
    const searchName = e.target.value
    const { selectedOption } = this.state
    this.setState({ selectedOption: { ...selectedOption, searchName } })
  }

  handleSearchClick = () => {
    const { selectedOption } = this.state
    this.setState({ selectedOption: { ...selectedOption, start: 1 } }, () => {
      this.getAllItem()
      const toastText =
        selectedOption.searchName.trim() === ''
          ? '보고서명을 입력해 주세요.'
          : `${selectedOption.searchName}에 대한 검색 결과`
      toast(toastText)
    })
  }

  handleReportDeleteClick = () => {
    const favoritItemIds = []
    for (let i = 0; i < this.state.checkBoxList.length; i += 1) {
      const { favoritItemId } = this.state.itemList[this.state.checkBoxList[i]]
      favoritItemIds.push({ id: favoritItemId })
    }
    if (favoritItemIds.length <= 0) {
      toast('삭제할 보고서를 선택하세요.')
      return
    }

    network.callApi({
      url: 'report/favorites/delete?',
      method: 'post',
      header: { 'Content-Type': 'application/json;charset=UTF-8' },
      body: favoritItemIds,
      successFunc: (jsonData) => {
        if (jsonData.result === 'success') {
          toast('즐겨찾기 보고서 삭제 완료')
          this.getAllItem()
        } else {
          toast('즐겨찾기 보고서 삭제 실패')
        }
      },
      failFunc: (error) => {
        toast(`즐겨찾기 보고서 삭제 실패 : ${error}`)
      },
    })
  }

  handleExcelDownload = async () => {
    const favoritItemIds = []
    const baseFileNames = []
    const baseFileIdx = []
    const printFileNames = []
    for (let i = 0; i < this.state.checkBoxList.length; i += 1) {
      const { adCenterAdvertisementId, reportType, reportName, idx } = this.state.itemList[this.state.checkBoxList[i]]
      favoritItemIds.push({ id: adCenterAdvertisementId, reportType: this.state.reportTypes.indexOf(reportType) })
      baseFileNames.push(reportName)
      baseFileIdx.push(idx)
    }

    if (favoritItemIds.length <= 0) {
      // toast('엑셀 다운로드할 보고서를 선택하세요.')
      return
    }

    const url = 'report/favorites/all'
    network.callApi({
      url,
      method: 'POST',
      header: { 'Content-Type': 'application/json;charset=UTF-8' },
      body: favoritItemIds,
      successFunc: (jsonData) => {
        const { result } = jsonData
        const files = []
        for (let i = 0; i < result.length; i += 1) {
          const {
            reportType,
            resultReportAll,
            resultReportDaily,
            resultReportHourly,
            resultReportDailyOfWeek,
            adPayReport,
          } = result[i]
          if (reportType === '1') {
            files.push(this.genResultExcelData(resultReportAll, 0))
            printFileNames.push(`${baseFileIdx[i]}-all`)
            files.push(this.genResultExcelData(resultReportDaily, 1))
            printFileNames.push(`${baseFileIdx[i]}-day`)
            files.push(this.genResultExcelData(resultReportHourly, 2))
            printFileNames.push(`${baseFileIdx[i]}-time`)
            files.push(this.genResultExcelData(resultReportDailyOfWeek, 3))
            printFileNames.push(`${baseFileIdx[i]}-date`)
          } else if (reportType === '2') {
            files.push(this.genCostExcelData(adPayReport))
            printFileNames.push(`${baseFileIdx[i]}`)
          }
        }
        this.setState({
          tableData: files,
          isDownload: true,
          fileName: printFileNames,
          zipName: 'report_favorit',
        })
        this.handleDownloaded()
      },
      failFunc: () => {
        console.error(`fail- ${url}`)
      },
    })
  }

  genRow = (tableInfo, item) => {
    const { tableLabel, tableValue } = tableInfo
    const d = {}
    for (let i = 0; i < tableLabel.length; i += 1) {
      d[tableLabel[i]] = item[tableValue[i]]
    }

    return d
  }

  tableValues = {
    type: ['key', 'area', 'accountNum', 'numImp', 'numClick', 'ctr'],
    all: ['area', 'accountNum', 'period', 'price', 'numImp', 'numClick', 'ctr'],
  }

  tableLabels = {
    all: ['광고', '구좌', '기간', '광고비', '노출수', '클릭수', '클릭률'],
    daily: ['일자', '광고 영역', '구좌', '노출수', '클릭수', '클릭률'],
    hourly: ['시간', '광고 영역', '구좌', '노출수', '클릭수', '클릭률'],
    dailyOfWeek: ['요일', '광고 영역', '구좌', '노출수', '클릭수', '클릭률'],
  }

  genResultExcelData = (allItemList, tableType) => {
    const { tableInfo } = this.state
    const items = []
    if (tableType === 1) {
      tableInfo.tableValue = this.tableValues.type
      tableInfo.tableLabel = this.tableLabels.daily
    } else if (tableType === 2) {
      tableInfo.tableValue = this.tableValues.type
      tableInfo.tableLabel = this.tableLabels.hourly
    } else if (tableType === 3) {
      tableInfo.tableValue = this.tableValues.type
      tableInfo.tableLabel = this.tableLabels.dailyOfWeek
    } else if (tableType === 0) {
      tableInfo.tableValue = this.tableValues.all
      tableInfo.tableLabel = this.tableLabels.all
      allItemList.forEach((val) => {
        const row = {
          area: val.area,
          accountNum: val.accountNum,
          period: `${dateToStringAdPeriod(val.startAt)}~${dateToStringAdPeriod(val.endAt)}`,
          price: val.price,
          numImp: val.numImp,
          numClick: val.numClick,
          ctr: val.ctr,
        }
        items.push(this.genRow(tableInfo, row))
      })
      // 단일 파일 [items]
      return items
    }

    const typeItemList = allItemList

    typeItemList.forEach((val) => {
      const row = {
        key: val.key,
        area: val.creativeList[0].area,
        accountNum: val.creativeList[0].accountNum,
        numClick: val.creativeList[0].numClick,
        numImp: val.creativeList[0].numImp,
        ctr: val.creativeList[0].ctr,
      }
      items.push(this.genRow(tableInfo, row))
      val.creativeList.slice(1).forEach((val2) => {
        const row2 = {
          area: val2.area,
          accountNum: val2.accountNum,
          numClick: val2.numClick,
          numImp: val2.numImp,
          ctr: val2.ctr,
        }
        items.push(this.genRow(tableInfo, row2))
      })
      const row3 = {
        area: '합계',
        numClick: val.totalNumClick,
        numImp: val.totalNumImp,
        ctr: val.totalCtr,
      }
      items.push(this.genRow(tableInfo, row3))
    })
    return items
  }

  genCostExcelData = (allItemList) => {
    // const tableLabel = ['No.', '광고 영역', '구좌', '기간', '광고비', '승인단계', '운영단계', '결제 예약일', '결제 예약취소일',
    //   '결제일', '환불일', '환불액', '반려일', '취소일', '광고비잔금', '남은광고일', '결제상태'];
    const tableLabel = [
      'No.',
      '광고 영역',
      '구좌',
      '기간',
      '광고비',
      '승인단계',
      '운영단계',
      '결제 예약일',
      '결제 예약취소일',
      '결제일',
      '환불일',
      '환불액',
      '반려일',
      '취소일',
      '광고비잔금',
      '남은광고시간',
      '결제상태',
    ]

    // const tableValue = ['idx', 'area', 'accountNum', 'period', 'price', 'status', 'adProcessStatus', 'reservedPayDate', 'reservedPayCancelDate',
    //   'payDate', 'refundDate', 'refundPrice', 'rejectDate', 'cancelDate', 'adRemainPrice', 'adRemainDay', 'adPayStatus'];
    const tableValue = [
      'idx',
      'area',
      'accountNum',
      'period',
      'price',
      'status',
      'adProcessStatus',
      'reservedPayDate',
      'reservedPayCancelDate',
      'payDate',
      'refundDate',
      'refundPrice',
      'rejectDate',
      'cancelDate',
      'adRemainPrice',
      'adRemainHour',
      'adPayStatus',
    ]

    const statusType = {
      status: ['', '작성중', '승인요청', '승인완료', '반려'],
      adProcessStatus: ['', '예정', '진행중', '취소', '완료', '삭제'],
      adPayStatus: ['', '결제 예약취소', '결제 예약완료', '결제완료', '결제실패', '환불대상', '환불완료', '정산완료'],
      cancelStatus: [
        '',
        '관리자 광고 진행중 취소',
        '관리자 광고 예정중 취소',
        '메이커의 심사중 광고취소',
        '메이커의 예정중 광고 취소',
        '시스템 취소',
        '반려-심사중 관리자가 광고 반려',
        '결제실패',
      ],
    }
    const tableInfo = { tableLabel, tableValue }
    const items = []
    allItemList.forEach((val, idx) => {
      const {
        area,
        accountNum,
        price,
        status,
        adProcessStatus,
        reservedPayDate,
        reservedPayCancelDate,
        payDate,
        refundDate,
        refundPrice,
        rejectDate,
        cancelDate,
        adRemainPrice,
        adRemainDay,
        adRemainHour,
        adPayStatus,
        startAt,
        endAt,
      } = val
      const period = `${dateToStringAdPeriod(startAt)}~${dateToStringAdPeriod(endAt)}`
      const row = {
        idx: idx + 1,
        area,
        accountNum,
        period,
        price,
        status: statusType.status[status],
        adProcessStatus: statusType.adProcessStatus[adProcessStatus],
        reservedPayDate,
        reservedPayCancelDate,
        payDate,
        refundDate,
        refundPrice,
        rejectDate,
        cancelDate,
        adRemainPrice,
        adRemainDay,
        adRemainHour,
        adPayStatus: statusType.adPayStatus[adPayStatus],
      }
      items.push(this.genRow(tableInfo, row))
    })
    return items
  }

  handleDownloaded = () => {
    const isDownload = this.state.isDownload !== true
    this.setState({ isDownload })
  }

  setCheckBoxInit = () => {
    this.setState({ checkBoxList: [] })
  }

  componentDidMount() {
    this.setState({ loadingBarFlag: true }, () => {
      this.getAllItem().then(() => {
        this.setState({ loadingBarFlag: false })
      })
    })
  }

  render() {
    const {
      state,
      handlePageNumber,
      handleViewSizeSelect,
      handleCheckBoxItemClick,
      handleAllCheckBoxItemClick,
      handleGoToADDetail,
      handleSearchReportName,
      handleSearchClick,
      handleReportDeleteClick,
      handleExcelDownload,
      viewSizeList,
    } = this
    const {
      itemList,
      allItemTotalCount,
      totalCount,
      selectedOption,
      checkBoxList,
      tableData,
      isDownload,
      fileName,
      zipName,
    } = state
    return (
      <AdFavoritReportContent
        state={state}
        handlePageNumber={handlePageNumber}
        handleViewSizeSelect={handleViewSizeSelect}
        handleCheckBoxItemClick={handleCheckBoxItemClick}
        handleAllCheckBoxItemClick={handleAllCheckBoxItemClick}
        handleGoToADDetail={handleGoToADDetail}
        handleSearchReportName={handleSearchReportName}
        handleSearchClick={handleSearchClick}
        handleReportDeleteClick={handleReportDeleteClick}
        handleExcelDownload={handleExcelDownload}
        dateToStringCreatedDate={dateToStringCreatedDate}
        dateToStringAdPeriod={dateToStringAdPeriod}
        itemList={itemList}
        allItemTotalCount={allItemTotalCount}
        totalCount={totalCount}
        viewSizeList={viewSizeList}
        selectedOption={selectedOption}
        checkBoxList={checkBoxList}
        tableData={tableData}
        isDownload={isDownload}
        fileName={fileName}
        zipName={zipName}
      />
    )
  }
}

export default connect(({ user }) => ({ user }), { openPopup })(withRouter(AdFavoritReportPage))
