import React from 'react';
import classNames from 'classnames/bind';
import style from './AskSection.module.scss';
import TitleSection from '../TitleSection'
const cx = classNames.bind(style);

export default function AskSection() {
  return (
    <section className={cx('container')}>
      <TitleSection section="AskSection" className={cx('title')} />

      <a className={cx('btnAsk')} href={process.env.REACT_APP_WADIZ_HELP_CENTER} target="_blank" rel="noreferrer">
        광고 문의하기
      </a>
    </section>
  )
}
