// eslint-disable-next-line
import React, { FC, BaseSyntheticEvent } from 'react'
import shortid from 'shortid'
import { TypeState } from './AdState.interface'
import styles from './AdStateChangeButton.module.scss'

const sid = shortid.generate()
//https://wiki.wadizcorp.com/pages/viewpage.action?pageId=67347663
const AdStateTable = {
  Submitted: [{ name: '요청취소', cmd: 'Submitted:Cancel' }],
  Accepted: [{ name: '수정요청', cmd: 'Accepted:Modify' }], // 광고 수정하기로 이동, 수정페이지2
  Scheduled: [], //
  In_Progress: [], //
  Creating: [
    { name: '작성', cmd: 'Creating:Modify' },
    { name: '삭제', cmd: 'Creating:Delete' },
  ],

  Modified_in_Contr: [{ name: '요청취소', cmd: 'Modified:Cancel' }],
  Modified_in_Failed: [{ name: '요청취소', cmd: 'Modified:Cancel' }],
  // 'Modified_in_Sched': [{ name: '요청취소', cmd: 'cancel' }],
  // 'Modified_in_Prog': [{ name: '요청취소', cmd: 'cancel' }],

  Payment_Failed: [
    { name: '수정요청', cmd: 'PaymentFailed:Modify' }, // 광고 수정하기로 이동, 수정페이지2
  ],
  Feedback: [{ name: '수정', cmd: 'Feedback:Modify' }],
  Feedback_in_Contr: [{ name: '수정', cmd: 'Feedback:Modify' }],
  // 'Feedback_in_Sched': [{ name: '수정', cmd: 'Feedback:Modify' }],
  // 'Feedback_in_Prog': [{ name: '수정', cmd: 'Feedback:Modify' }],
  Recreating: [{ name: '수정', cmd: 'Recreating:Modify' }], // 수정요청 취소 확정 modal 노출 , 계약 중으로 변경

  Rejected: [], //
  Rejected_in_Contr: [], //
  Canceled_in_Sched: [],
  Canceled_in_Prog: [],
  Completed: [], //
}

const ButtonContainer: FC = ({ children }) => <div className={styles.buttonContainer}>{children}</div>

type PropsButtonContainer = {
  adState: TypeState
  doClick: (e: BaseSyntheticEvent, cmd: string) => void
}
export const AdStateChangeButton: FC<PropsButtonContainer> = ({ adState, doClick }) => (
  <ButtonContainer>
    {(AdStateTable[adState] || []).map((item, index) => (
      <button
        key={sid + index}
        type="button"
        className={'wz button circular mini'}
        onClick={(e) => {
          doClick(e, item.cmd)
        }}
      >
        {item.name}
      </button>
    ))}
  </ButtonContainer>
)
