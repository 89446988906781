const formatTable: { [index: number]: React.ReactNode } = {
  360: (
    <>
      <strong>Galaxy</strong>
      <span>(Note 8~10+, S6~10), </span>
      <strong>iPhone</strong>
      <span>(12 Mini)</span>
    </>
  ),
  375: (
    <>
      <strong>iPhone</strong>
      <span> (6 ~ 8, 11Pro, X, XS) </span>
    </>
  ),
  390: (
    <>
      <strong>iPhone</strong>
      <span> (12, 12pro) </span>
    </>
  ),
  414: (
    <>
      <strong>iPhone</strong>
      <span> (6+ ~ 8+, 11 Pro Max, XS Max) </span>
    </>
  ),
  428: (
    <>
      <strong>iPhone</strong>
      <span> (12 Pro Max) </span>
    </>
  ),
  480: (
    <>
      <strong>Galaxy</strong>
      <span>(S20, S20+, S20 Ultra )</span>
    </>
  ),
  768: <strong>iPad</strong>,

  800: <strong>Galaxy tab</strong>,
  1024: <strong>iPad Pro</strong>,

  1097: <strong>Desktop</strong>,

  1200: <strong>Full Size Desktop</strong>,
}
export const tooltipFormatter: (value: number | undefined) => React.ReactNode = (value) => {
  const node = formatTable[value || 0]
  return !node ? <strong>{`${value}픽셀`}</strong> : node
}
