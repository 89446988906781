import { api, ResultType } from '../api'

export interface GoodsParams {
  id: number
  isWadizAdmin: boolean
  isPackaged: boolean
  projectType: number
  campaignId: number
}
export interface Goods {
  adCenterPubAccountId: number
  discount: string
  goodsNum: number
  id: number
  impNum: number
  impPeriod: number
  price: number
  impMinPeriod: number
  holidayPrice: number
  refGoodsId: number
}

export const fetchGoodsList = ({
  id,
  isWadizAdmin,
  isPackaged,
  projectType,
  campaignId,
}: GoodsParams): Promise<ResultType<Goods[]>> => {
  return api(
    'GET',
    `goods/list?pub_account_id=${id}&advertiser_type=${isWadizAdmin ? '1' : '0'}&type=${!isPackaged ? 0 : 2}&${
      projectType === 3 ? 'wdz_store_project_id' : 'wdz_campaign_id'
    }=${campaignId}`
  )
}
