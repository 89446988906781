import { useQuery, UseQueryResult } from '@tanstack/react-query'
import type { FunctionPopularKeyword, KeywordListType, KeywordReturnType } from '@wad/api'

type FunctionType = (param: {
  fetchGetPopularKeyword: FunctionPopularKeyword
  enabled: boolean
}) => UseQueryResult<KeywordListType>
export const usePopularKeyword: FunctionType = ({ fetchGetPopularKeyword, enabled }) =>
  useQuery({
    queryKey: ['popularKeyword'],
    queryFn: () => fetchGetPopularKeyword().then((res: KeywordReturnType) => res.data),
    enabled,
  })
