import React from 'react'
import className from 'classnames/bind'
import style from './CreateAdStep1.module.scss'
const cx = className.bind(style)

const adSegmentTable = {
  1: '투자',
  2: '펀딩',
  3: '스토어',
  4: '프리오더',
}
const getProjectSelectText = (projectType, campaignId, projectTitle) =>
  `${adSegmentTable[projectType] || '펀딩'} / ${campaignId} / ${projectTitle}`

const CreateAdStep1 = ({ onClickHistoryBack, onClickStep, onChangeProject, onChangeState, onChangeGuide, ...rest }) => {
  return (
    <div className={cx('create_ad', 'create_ad_container_step1')}>
      <hgroup>
        <h2>광고만들기</h2>
        <h3>광고 프로젝트 만들기</h3>
        <p>
          오픈을 확정한 프로젝트가 있는 경우, 광고를 만들 수 있습니다. <br />
          광고를 통해 메이커님의 프로젝트를 홍보해보세요.
        </p>
      </hgroup>
      <section className={cx('wz form', 'ad_element')}>
        <div className={cx('guide_wrap', { active: rest.guide1 })} onClick={() => onChangeGuide(1)}>
          <h4>광고할 프로젝트 선택하기</h4>
          {/* <Tooltip
            title='광고할 프로젝트 선택'
            text={(
              <>
                메이커께서 진행하고 계신 프로젝트를 선택해 주세요.<br></br>
                만약, 프로젝트가 없다면 프로젝트를 먼저 생성한 후에 광고 진행을 부탁드립니다.
              </>
            )}
          /> */}
        </div>
        <div className={cx('field')}>
          <label className={cx('wz outline block dropdown')}>
            <select
              value={rest.campaignId}
              onChange={(e) => {
                onChangeState({
                  campaignId: e.target.value,
                  projectTitle: rest.projectList.find((ex) => ex.campaignId === Number(e.target.value)).projectTitle,
                  projectType: rest.projectList.find((ex) => ex.campaignId === Number(e.target.value)).projectType,
                  projectName: `광고-${
                    rest.projectList.find((ex) => ex.campaignId === Number(e.target.value)).projectTitle
                  }`,
                  landingUrl: rest.projectList.find((ex) => ex.campaignId === Number(e.target.value)).landingUrl,
                  comingsoon: rest.projectList.find((ex) => ex.campaignId === Number(e.target.value)).comingsoon,
                  accountList: [],
                  thAccountList: [],
                  adsPrice: 0,
                  adsVat: 0,
                  totalPrice: 0,
                  adId: 0,
                })
              }}
            >
              {rest.projectList.map((val, idx) => (
                <option key={idx} value={val.campaignId}>
                  {getProjectSelectText(val.projectType, val.campaignId, val.projectTitle)}
                </option>
              ))}
            </select>
          </label>
          <p className={cx('msgError', 'point_red')}>{rest.msg1 && rest.msg1}</p>
        </div>
      </section>
      <section className={cx('wz form', 'ad_element')}>
        <div className={cx('guide_wrap', { active: rest.guide2 })} onClick={() => onChangeGuide(2)}>
          <h4>광고프로젝트 명 입력하기</h4>
          <span className={cx('ad_notification_info')}>
            ※ 광고 프로젝트명은 광고 등록을 위한 광고명으로 광고집행 시 노출되지 않습니다.
          </span>
          {/* <Tooltip
            title='광고명 입력'
            text={'프로젝트에 등록할 광고명을 입력하세요.'}
          /> */}
        </div>
        <div className={cx('wz input', 'field')}>
          <input
            type="text"
            id="pseudoText"
            name="pseudo-text"
            value={rest.projectName}
            className={cx(rest.projectName ? '' : 'error')}
            onChange={(e) => onChangeState({ projectName: e.target.value })}
            maxLength="50"
          />
          <em className={cx('helper')}>{`${rest.projectName ? rest.projectName.length : 0}/50자`}</em>
          <p className={cx('msgError', 'point_red')}>{rest.msg2 && rest.msg2}</p>
        </div>
      </section>
      <div className={cx('buttonArea')}>
        <button type="button" className={cx('wz button primary', 'button_320')} onClick={() => onClickStep(1)}>
          다음
        </button>
      </div>
    </div>
  )
}

export default CreateAdStep1
