import React, { ReactElement } from 'react';
import ConfirmModal, { ButtonsDirection, ModalSize }  from '../ConfirmModal';
export interface InfoModalProps {
  title: string,
  message?: string,
  buttonLabel?: string | null,
  onButtonClick?: Function,
  buttonProps?: Object,
  buttonsDirection? : keyof typeof ButtonsDirection,
  size?: keyof typeof ModalSize | Object,
  showCloseButton?: boolean,
  onClose?: Function,
  overlayRef?: Function;
  autoClose?: boolean,
  closeClickOutside?:  boolean,
  cleanUp?: Function,
  zIndex?: number,
  children?: string | ReactElement,
  preventBodyScroll?: boolean,
  fullHeight?: boolean;
  headerClassName?: string;
  contentClassName?: string;
  footerClassName?: string;
}

export const InfoModal = ({
    title,
    message,
    buttonLabel = '확인',
    onButtonClick,
    showCloseButton = true,
    onClose,
    overlayRef,
    buttonsDirection = 'horizontal',
    size = 'md',
    preventBodyScroll ,
    autoClose = true,
    zIndex,
    children,
    closeClickOutside,
    cleanUp,
    buttonProps = {},
    fullHeight,
    headerClassName,
    contentClassName,
    footerClassName,
  }: InfoModalProps) => {
  return (
    <ConfirmModal
      title={title}
      message={message}
      negativeButtonLabel={null}
      positiveButtonLabel={buttonLabel}
      onPositiveButtonClick={onButtonClick}
      buttonsDirection={buttonsDirection}
      size={size}
      positiveButtonProps={buttonProps}
      showCloseButton={showCloseButton}
      zIndex={zIndex}
      autoClose= {autoClose}
      onClose={onClose}
      overlayRef={overlayRef}
      preventBodyScroll={preventBodyScroll}
      closeClickOutside={closeClickOutside}
      cleanUp={cleanUp}
      fullHeight={fullHeight}
      headerClassName={headerClassName}
      contentClassName={contentClassName}
      footerClassName={footerClassName}
    >
      {children}
    </ConfirmModal>
  );
};

export default InfoModal;
