import React, { Fragment } from 'react'

// Components
import Lnb from '../Lnb'
import NavBar from '../NavBar'

// CSS module
import style from './UserHelpContent.module.scss'
import className from 'classnames/bind'
const cx = className.bind(style)

const UserHelpContent = ({ navList, QAList, manualList, tableType, onClickTableType, onClickQuestion, refundRule }) => {
  return (
    <main className={cx('main_container', 'user')}>
      <Lnb type="user_help" />
      <div className={cx('account_manage', 'content_wrap')}>
        <NavBar list={navList} />
        {/* 컨텐츠 시작 */}
        <div className={cx('account_manage_help')}>
          <h2>도움말</h2>
          {/* FAQ */}
          <ul className={cx('account_manage_helpTab')}>
            <li onClick={() => onClickTableType('FAQ')} className={tableType === 'FAQ' ? cx('active') : ''}>
              FAQ
            </li>
            <li onClick={() => onClickTableType('MAKER')} className={tableType === 'MAKER' ? cx('active') : ''}>
              광고주 메뉴얼
            </li>
          </ul>
          {tableType === 'FAQ' && (
            <div className={cx('account_manage_helpFAQ')}>
              <p>
                <i className={cx('icon error-o')}></i>
                와디즈의 광고서비스에 대한 궁금한 사항은 아래 내용에서 확인해 보세요.<br></br>
                추가적인 질의사항은{' '}
                <a href="mailto:ad@wadiz.kr" className={cx('point_link_blue')}>
                  ad@wadiz.kr
                </a>
                로 메일 주시면 빠른 시간내에 답변 드리겠습니다.
              </p>
              {QAList.map((val, categoryIdx) => (
                <Fragment key={categoryIdx}>
                  <section>
                    <h3>{val.category}</h3>
                    {val.list.map((val2, questionIdx) => (
                      <Fragment key={questionIdx}>
                        <dl id={`${val.idPrefix}-${questionIdx}`}>
                          <dt onClick={() => onClickQuestion(categoryIdx, questionIdx)}>{val2.question}</dt>
                          {val2.isExpand && (
                            <dd>
                              <i className={cx('icon wadiz')}></i>
                              {val2.content}
                            </dd>
                          )}
                        </dl>
                      </Fragment>
                    ))}
                  </section>
                </Fragment>
              ))}
            </div>
          )}
          {tableType === 'MAKER' && (
            <div className={cx('account_manage_helpManual')}>
              <p>
                <i className={cx('icon error-o')}></i>
                와디즈의 광고서비스에서 제공하는 <em className={cx('point_primary')}>광고주 메뉴얼</em>입니다.<br></br>
                추가적인 메뉴얼 내용 요청은{' '}
                <a href="mailto:ad@wadiz.kr" className={cx('point_link_blue')}>
                  ad@wadiz.kr
                </a>
                로 메일 주시면 빠른 시간내에 답변 드리겠습니다.
              </p>
              <ul>
                {manualList.map((val, idx) => (
                  <li key={idx}>
                    <strong>{val.area} 광고 메뉴얼</strong>
                    <button onClick={() => window.open(val.manualUrl)} type="button" className={cx('wz button')}>
                      <i className={cx('icon download')}></i>
                      <span>PDF 다운로드</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        {/* 컨텐츠 끝 */}
      </div>
    </main>
  )
}

export default UserHelpContent
