import { api } from '../api/api'
import {
  AppPushResponseFormat,
  AppPushListFormat,
  AppPushRequestResultFormat,
  AppPushDetailItemFormat,
} from './adAppPush.interface'

type ResponseFormat = {
  data?: AppPushResponseFormat | AppPushListFormat | Array<AppPushRequestResultFormat> | AppPushDetailItemFormat
}

// export const apiGetAdminProjectInfo: (
//   apiHost: string,
//   projectId: number) => AxiosPromise<ResponseFormat> = (projectId: number) =>
//   api<ResponseFormat>(apiHost, "GET", `admin/projects/${projectId}`)

export const apiGetAdminProjectCampaignsPreInfo: (creativeId: string) => Promise<ResponseFormat> = (
  campaignId: string
) => api('GET', `admin/projects/campaigns/${campaignId}/pre-info`)

// http://rc-api01:9990/ad-admin/swagger-ui.html#/ad-app-push-controller/getAdAppPushCreativesDetailUsingGET
export const apiGetAdminAppPushProjectList: (params: string) => Promise<ResponseFormat> = (params) =>
  api('GET', `admin/creatives/apppush?${params}`)

export const apiGetAdminAppPushProjectItem: (creativeId: number) => Promise<ResponseFormat> = (creativeId) =>
  api('GET', `admin/creatives/apppush/${creativeId}`)

// http://rc-api01:9990/ad-center/swagger-ui.html#/ad-composite-flow-service-controller/createAndSubmitUsingPOST
//  /api/v1/ad/advertisements/creatives:submit - (EXP:테스트중) 광고 및 소재를 작성(생성)하고 (승인)요청합니다.
export type RequestCreativeParam = {
  projectNumber: number
  adName: string
  adCreatives: Array<{
    adGoodsId: number
    startAt: string
    endAt: string
    mainCopy: string
    subCopy: string
    landingUrl: string
  }>
  payInfoId: number
}
type RequestFormat = {
  adCreative: {
    id: number
    mainCopy: string
    subCopy: string
  }
  payInfoId: number
}
export const apiPostAdvertisementsCreativesSubmit: (params: string) => Promise<ResponseFormat> = (params) =>
  api('POST', 'advertisements/creatives:submit', { data: params })

export const apiPutAdvertisementsCreativeResumbit: (
  advertisementId: number,
  creativeId: number,
  request: RequestFormat
) => Promise<ResponseFormat> = (advertisementId, creativeId, request) =>
  api('PUT', `advertisements/${advertisementId}/creatives/${creativeId}:resubmit/`, {
    data: request,
  })
