// eslint-disable-next-line
import React from 'react'
import wadizLogo from './logo-150x150.png'
import styles from './AppPushPreview.module.scss'

export const AppPushPreview: React.FC<{
  mainCopy: string
  subCopy: string
}> = ({ mainCopy, subCopy }) => (
  <section className={styles.screen}>
    <img className={styles.wadizIcon} alt="와디즈 logo" data-type="image" src={wadizLogo} />
    <header className={styles.content}>
      <p className={styles.mainCopy}>{mainCopy}</p>
      <p className={styles.subCopy}>(광고) {subCopy}</p>
      <span className={styles.date}>30분전</span>
    </header>
  </section>
)
