// eslint-disable-next-line
import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import useAreaData, { tStore, tAccountItem, tGoodsItem } from './useAreaData'
import LoadingBar from '../../../../../components/LoadingBar'
import AreaItem, { tAreaInfo } from './AreaItem'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 5000, min: 750 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 749, min: 625 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 624, min: 400 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 399, min: 0 },
    items: 1,
  },
}

export type tFxChangeAreaInfo = (
  areaInfo: tAreaInfo,
  accountItem?: tAccountItem | null,
  goodsItem?: Array<tGoodsItem | null> | null,
  packagedItem?: {
    positionName: string
    manualUrl: string
    accountItem: tAccountItem | null
    goodsItem: Array<tGoodsItem | null> | null
  }
) => void

export type tCarouselContainerProps = { onChangeAreaInfo: tFxChangeAreaInfo }
const CarouselContainer = (props: tCarouselContainerProps) => {
  const store = useSelector((_store: tStore) => _store)
  const { isLoading, areaList, accountList, goodsList, isStoreProject, projectType, projectStartDate, comingsoon } = useAreaData(store)

  const [isActivated, setActivated] = useState([] as Array<boolean>)

  const { onChangeAreaInfo } = props
  const fireChangeEvent = useCallback(
    (activatedList) => {
      const isAllOff = activatedList.reduce((acc: boolean, item: number) => acc || item, false)
      if (!isAllOff) {
        onChangeAreaInfo({ manualUrl: '' } as tAreaInfo)
        return
      }
      activatedList.forEach((isActivatedIt: boolean, idx: number) => {
        if (!isActivatedIt) {
          return
        }

        if (!areaList[idx].isPackaged) {
          onChangeAreaInfo(areaList[idx], accountList[idx], goodsList[idx])
          return
        }

        const pId = areaList[idx].packagedId
        const isMaster = pId !== null
        if (!isMaster) {
          return
        }

        // 단일 패키지만 대응하고 있다. 다중 패키지일 경우는 이 부분에 수정이 필요하다.
        const pIndex = areaList.findIndex((area) => area.id === pId)
        if (pIndex < 0) {
          onChangeAreaInfo(areaList[idx], accountList[idx], goodsList[idx])
          return
        }

        onChangeAreaInfo(areaList[idx], accountList[idx], goodsList[idx], {
          positionName: areaList[pIndex].area,
          manualUrl: areaList[pIndex].manualUrl,
          accountItem: accountList[pIndex],
          goodsItem: goodsList[pIndex],
        })
      })
    },
    [onChangeAreaInfo, areaList, accountList, goodsList]
  )

  const [currentPackageName, setCurrentPackageName] = useState('')
  const [isLikeSuperAdmin, setSuper] = useState(false)

  const doSelect = useCallback(
    (idx) => {
      const currentArea = areaList[idx]
      const isSelectedPackage = currentArea.isPackaged
      const isSlave = !currentArea.packagedId
      const anotherPackageIndex = isSlave
        ? areaList.findIndex((item) => item.packagedId === currentArea.id)
        : areaList.findIndex((item) => item.id === currentArea.packagedId)

      const selectedAreas = isSelectedPackage ? [idx, anotherPackageIndex] : [idx]
      const activatedList = areaList.map((_, i) => (selectedAreas.indexOf(i) > -1 ? !isActivated[i] : false))

      setCurrentPackageName(activatedList[idx] ? currentArea.area.split('-')[0].trim() : '')

      fireChangeEvent(activatedList)
      setActivated(activatedList)
      setSuper(areaList.reduce((a, n) => a + (!n.packagedId ? 0 : 1), 0) < 1)
    },
    [isActivated, setActivated, areaList, fireChangeEvent, setCurrentPackageName, setSuper]
  )

  return isLoading ? (
    <LoadingBar />
  ) : (
    <div>
      <ul>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside
          renderDotsOutside={false}
          responsive={responsive}
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {areaList.map((area, idx) => (
            <AreaItem
              key={idx}
              area={area}
              selected={
                isActivated[idx] === true ||
                (!isLikeSuperAdmin && currentPackageName === area.area.split('-')[0].trim())
              }
              onSelectArea={() => doSelect(idx)}
              isStoreProject={isStoreProject}
              projectType={projectType}
              projectStartDate={projectStartDate}
              comingsoon={comingsoon}
            />
          ))}
        </Carousel>
      </ul>
    </div>
  )
}

export default CarouselContainer
