import React from 'react'

// Components
import Lnb from '../Lnb'
import CreateAdStep1 from './CreateAdStep/CreateAdStep1'
import CreateAdStep2 from './CreateAdStep/CreateAdStep2'
import CreateAdStep3 from './CreateAdStep/CreateAdStep3'
import LoadingBar from '../LoadingBar/LoadingBar'

// CSS module
import classNames from 'classnames/bind'
import style from './AdCreateContent.module.scss'
const cx = classNames.bind(style)

const AdCreateContent = ({
  state,
  isWadizAdmin,
  isSuperUser,
  handleClickHistoryBack,
  handleClickStep,
  handleClickSave,
  onClickRegister,
  handleChangeState,
  handleClickDatepicker,
  handleChangeGuide,
  handleOpenReservationPaymentDialog,
  imgFileSel,
  selectColor,
  inputTextChange,
  onSelectGoods,
  onSelectDiscountCoupon,
  onChangeDateRange,
  onFocusChange,
  imgDel,
  accountList,
  accountListDel,
  stepThirdAccountListDel,
  handleOpenGuideType,
  createStep,
  isComplete,
  openGuide,
  handleAdPreview,
  handleToAdList,
  handleToAdStep,
  onChangeLandingURL,
  setImage,
}) => {
  return (
    <main className={cx('main_container', 'ad')}>
      <Lnb type="adCreate" step={createStep} />
      <div className={cx('ad_create', 'content_wrap')}>
        {state.loadingBarFlag ? (
          <LoadingBar />
        ) : (
          <>
            {createStep === 0 && (
              <>
                {/* <NavBar list={navList1} /> */}
                <CreateAdStep1
                  {...state}
                  onClickHistoryBack={handleClickHistoryBack}
                  onClickStep={handleClickStep}
                  onChangeState={handleChangeState}
                  onChangeGuide={handleChangeGuide}
                />
              </>
            )}
            {createStep === 1 && (
              <>
                {/* <NavBar list={navList2} /> */}
                <CreateAdStep2
                  {...state}
                  isWadizAdmin={isWadizAdmin}
                  isSuperUser={isSuperUser}
                  onClickDatepicker={handleClickDatepicker}
                  onClickRegister={onClickRegister}
                  onClickSave={handleClickSave}
                  onClickStep={handleClickStep}
                  onChangeState={handleChangeState}
                  imgFileSel={imgFileSel}
                  selectColor={selectColor}
                  inputTextChange={inputTextChange}
                  onSelectGoods={onSelectGoods}
                  onChangeDateRange={onChangeDateRange}
                  onFocusChange={onFocusChange}
                  onSelectDiscountCoupon={onSelectDiscountCoupon}
                  imgDel={imgDel}
                  accountListDel={accountListDel}
                  onChangeOpenGuideType={handleOpenGuideType}
                  openGuide={openGuide}
                  handleAdPreview={handleAdPreview}
                  handleToAdStep={handleToAdStep}
                  onChangeLandingURL={onChangeLandingURL}
                  setImage={setImage}
                />
              </>
            )}
            {createStep === 2 && (
              <>
                {/* <NavBar list={navList3} /> */}
                <CreateAdStep3
                  {...state}
                  onClickRegister={onClickRegister}
                  onClickStep={handleClickStep}
                  onChangeState={handleChangeState}
                  onClickOpenReservationPaymentDialog={handleOpenReservationPaymentDialog}
                  isComplete={isWadizAdmin ? true : isComplete}
                  stepThirdAccountListDel={stepThirdAccountListDel}
                  onClickToAdList={handleToAdList}
                  handleToAdStep={handleToAdStep}
                />
              </>
            )}
          </>
        )}
      </div>
    </main>
  )
}

export default AdCreateContent
