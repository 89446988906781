// eslint-disable-next-line
import React, { FC } from 'react'
import { TypeState } from './AdState.interface'
import styles from './AdStateInformation.module.scss'

const AdStateInfoContainer: React.FC = ({ children }) => <div className={styles.container}>{children}</div>
// const acceptedInfo = `메이커 정보로 등록하신 메일로 광고 접수요청서가 발송되어 체결 대기 중입니다.
// 체결 완료 후 광고 승인됩니다.`
const feedbackInfo = ` 수정을 눌러 피드백을 확인해주세요.
  프로젝트를 수정한 후, 심사요청까지 완료해야 광고 심사가 다시 진행됩니다.`
const recreatingInfo = ` 수정을 눌러 피드백을 확인해주세요.
프로젝트를 수정한 후, 심사요청까지 완료해야 광고 심사가 다시 진행됩니다.`
const rejectedInfo = ` 광고 심사 반려 사유를 확인해주세요.
$반려 사유$
광고 진행을 원하실 경우, 새 광고 프로젝트를 만들어주세요.`
const paymentFailureInfo = ` 다음의 이유로 결제가 실패되었습니다.
$반려 사유$
수정요청을 눌러 결제 정보를 수정한 후 다시 심사요청해주세요.`

const creatingInfo = ` 메이커님의 광고가 작성 중입니다.
승인 요청 및 결제예약까지 완료 시, 광고 담당자가 검수를 시작합니다.`

const AdStateTable = {
  Submitted: '',
  Accepted: '',
  Scheduled: '',
  In_Progress: '',
  Creating: creatingInfo,

  Modified_in_Contr: '',
  Modified_in_Failed: '',
  Modified_in_Sched: '',
  Modified_in_Prog: '',

  Payment_Failed: paymentFailureInfo,
  Feedback: feedbackInfo,
  Feedback_in_Contr: feedbackInfo,
  Feedback_in_Sched: feedbackInfo,
  Feedback_in_Prog: feedbackInfo,
  Recreating: recreatingInfo,

  Rejected: rejectedInfo,
  Rejected_in_Contr: rejectedInfo,
  Canceled_in_Sched: '',
  Canceled_in_Prog: '',
  Completed: '',
}

type PropInfo = {
  adState: TypeState
  reason?: string
}
export const AdStateInformation: FC<PropInfo> = ({ adState, reason }) =>
  AdStateTable[adState] === '' ? null : (
    <AdStateInfoContainer>
      <pre style={{ whiteSpace: 'pre-wrap', fontWeight: 'normal' }}>
        <i className={'icon error-o'}></i>
        {(adState === 'Rejected' || adState === 'Rejected_in_Contr' || adState === 'Payment_Failed') && reason !== ''
          ? AdStateTable[adState].replace('$반려 사유$', reason)
          : AdStateTable[adState]}
      </pre>
    </AdStateInfoContainer>
  )
