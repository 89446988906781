import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons'

import { closePopup } from '../../reducers/dialogReducer'
import { openAlertPopup } from '../../reducers/alertReducer'

import className from 'classnames/bind'
import style from './Dialog.module.scss'
// import CommonDialog from './CommonDialog/CommonDialog';
import { network } from '../../network'
const cx = className.bind(style)

class SimplePaymentRegisterDialog extends PureComponent {
  state = {
    isShow: false,
    // cardNumberArray: [],
    cardNumber: '',
    paymentInfo: {
      adCenterAdvertisementId: 0,
      adCenterMakerId: 0,
      cardExpirationMonth: '01',
      cardExpirationYear: String(new Date().getFullYear()).substring(2, 4),
      cardIdNo: '',
      cardNumber: '',
      cardPasswd: '',
      type: 0,
      cardSaveYn: 0,
      payYn: 0,
      thirdYn: 0,
    },
    isPaymentHelper: false,
    isCardHelper: false,
    thirdHelper: false,
  }

  static getDerivedStateFromProps = (nextProps) => {
    if (nextProps.dialog.dialogType === 'simplePaymentRegisterDialog') {
      return { isShow: true }
    }

    return null
  }

  componentDidMount() {
    const paymentInfo = { ...this.state.paymentInfo }
    paymentInfo.adCenterMakerId = this.props.user.maker_id

    this.setState({ paymentInfo })
  }

  handleChangeCardIdNo = (event) => {
    // handle change expiration year
    const paymentInfo = { ...this.state.paymentInfo }
    paymentInfo.cardIdNo = String(event.target.value)

    if (event.target.value.length >= 10) {
      event.target.value = String(event.target.value).substring(0, 10)
    }
    this.setState({ paymentInfo })
  }

  handleChangeCardNumber = (event) => {
    const {
      value,
      validity: { valid },
    } = event.target
    let result = value
    // const cardNumberArray = [...this.state.cardNumberArray];

    let cardNumber = [...this.state.cardNumber]
    const paymentInfo = { ...this.state.paymentInfo }
    // handle change card number
    // if (value.length > 4) {
    //   // 다음 input tag로 넘어가게 추가 예정
    //   result = String(value).substring(0, 4);
    // } else if (/[^0-9]/g.test(value)) {
    //   result = String(value).slice(0, -1);
    // }
    if (!valid) {
      return
    }

    if (value.length > 16) {
      result = String(value).slice(0, 16)
    }

    cardNumber = result

    // if (cardNumberArray.indexOf(0) === -1) {
    if (cardNumber.length > 0) {
      paymentInfo.cardNumber = cardNumber
    }
    // }
    this.setState({ paymentInfo, cardNumber })
  }

  handleChangeExpirationMonth = (event) => {
    // handle change expiration month
    const paymentInfo = { ...this.state.paymentInfo }
    paymentInfo.cardExpirationMonth = event.target.value
    this.setState({ paymentInfo })
  }

  handleChangeExpirationYear = (event) => {
    // handle change expiration year
    const paymentInfo = { ...this.state.paymentInfo }
    paymentInfo.cardExpirationYear = String(event.target.value).substring(2, 4)
    this.setState({ paymentInfo })
  }

  handleChangePayAgreement = (event) => {
    // handle change pay agreement check
    const paymentInfo = { ...this.state.paymentInfo }
    if (event.target.checked) {
      paymentInfo.payYn = 1
    } else {
      paymentInfo.payYn = 0
    }
    this.setState({ paymentInfo })
  }

  handleChangeAgreement = (event) => {
    // handle change agreement check
    const paymentInfo = { ...this.state.paymentInfo }
    if (event.target.checked) {
      paymentInfo.cardSaveYn = 1
    } else {
      paymentInfo.cardSaveYn = 0
    }
    this.setState({ paymentInfo })
  }

  handleChangeThirdAgreement = (event) => {
    // handle change agreement check
    const paymentInfo = { ...this.state.paymentInfo }
    if (event.target.checked) {
      paymentInfo.thirdYn = 1
    } else {
      paymentInfo.thirdYn = 0
    }
    this.setState({ paymentInfo })
  }

  handleChangePassword = (event) => {
    // handle change expiration year
    const { value } = event.target
    let result = value
    // const paymentInfo = { ...this.state.paymentInfo };
    // paymentInfo.cardPasswd = String(event.target.value);

    if (value.length > 2) {
      // 다음 input tag로 넘어가게 추가 예정
      result = String(value).substring(0, 2)
    } else if (/[^0-9]/g.test(value)) {
      result = String(value).slice(0, -1)
    }

    this.setState((prevState) => ({ paymentInfo: { ...prevState.paymentInfo, cardPasswd: result } }))
  }

  handleClose = () => {
    this.props.closePopup()
    this.setState({
      isShow: false,
    })
  }

  handleConfirm = () => {
    // confirm function
    if (this.checkValidation()) {
      network.callApi({
        url: 'pay-info/save',
        method: 'post',
        body: this.state.paymentInfo,
        successFunc: (jsonData) => {
          if (jsonData.result === 'success') {
            this.handleClose()
            this.props.dialog.dialogData.confirmFunc()
          } else if (jsonData.result === 'exist') {
            this.props.openAlertPopup({
              alertData: {
                title: '간편 결제 카드 정보 중복',
                content: (
                  <p>
                    이미 간편 결제에 등록된 카드 정보 입니다.
                    <br />
                    <em className={cx('point_primary')}>다른 신용카드 정보를 입력해 주세요.</em>
                  </p>
                ),
                closeText: '닫기',
              },
            })
          } else {
            toast(jsonData.message)
          }
        },
        failFunc: () => {
          console.error(`fail- pay-info/save`)
        },
      })
    } else {
      toast('결제카드 번호와 비밀번호를 확인해주세요')
    }
  }

  checkValidation = () => {
    const paymentInfo = { ...this.state.paymentInfo }
    let validationResult = true
    const regex = /[^0-9]/g
    if (!this.state.isSimplePayment) {
      if (paymentInfo.adCenterMakerId === '') {
        validationResult = false
        return validationResult
      }
      if (paymentInfo.adCenterAdvertisementId === '') {
        validationResult = false
        return validationResult
      }
      if (!(paymentInfo.cardIdNo.length === 6 || paymentInfo.cardIdNo.length === 10)) {
        validationResult = false
        return validationResult
      }
      if (paymentInfo.cardNumber.length < 15) {
        validationResult = false
        return validationResult
      }
      if (regex.test(paymentInfo.cardPasswd) || paymentInfo.cardPasswd === '' || paymentInfo.cardPasswd.length !== 2) {
        validationResult = false
        return validationResult
      }
    }
    return validationResult
  }

  render() {
    const { handleClose, handleConfirm } = this

    const { dialogData } = this.props.dialog
    const { isShow, cardNumber, isPaymentHelper, isCardHelper, paymentInfo, thirdHelper } = this.state
    const {
      handleChangeCardIdNo,
      handleChangeCardNumber,
      handleChangeExpirationMonth,
      handleChangeExpirationYear,
      handleChangePassword,
      handleChangePayAgreement,
      handleChangeAgreement,
      handleChangeThirdAgreement,
    } = this

    return (
      isShow && (
        <div className={cx('dialog_shadow')}>
          <div className={cx('dialog_wrap', 'typePopup')}>
            <header>
              <div className={cx('dialog_close')}>
                {/* Close Button Area */}
                <button className={cx('wz button less')} onClick={handleClose}>
                  <i className={cx('icon close')}></i>
                </button>
              </div>
              <div className={cx('dialog_title')}>
                {/* Title Area */}
                <h3>{dialogData.title}</h3>
              </div>
            </header>
            {/* Content Area */}
            <main className={cx('dialog_content', 'creditCard')}>
              <ul className={cx('creditCardInfo')}>
                <li className={cx('creditCardIdNo')}>
                  <label>주민번호 앞 6자리 또는 사업자 번호
                    <Tooltip
                      title={
                        <>
                          - 일반 개인 카드일 경우, 주민번호 앞 6자리 기재<br />
                          - 법인 카드일 경우, 사업자 번호 기재<br />
                          - 단, 법인 카드 명의가 개인일 경우, 주민번호 앞 6자리 기재
                        </>
                      }
                      placement="bottom"
                      overlayStyle={{ width: 250 }}
                      trigger="hover">
                      <QuestionCircleOutlined
                        style={{ marginLeft: 8 }} />
                    </Tooltip>
                  </label>
                  <div className={cx('wz input')}>
                    <input type="number" maxLength="10" onChange={handleChangeCardIdNo} />
                  </div>
                </li>
                <li className={cx('creditCardNo')}>
                  <label>결제카드 번호</label>
                  <div className={cx('wz input')}>
                    <input
                      type="number"
                      pattern="^-?[0-9]\d*\.?\d*$"
                      maxLength="16"
                      name="cardNum4"
                      value={cardNumber}
                      onChange={handleChangeCardNumber}
                    />
                  </div>
                </li>
                <li className={cx('creditCardExp')}>
                  <label>유효기간</label>
                  <label className={cx('wz outline block dropdown')}>
                    <select onChange={handleChangeExpirationMonth}>
                      {new Array(12).fill(0).map((val, idx) => (
                        <option key={idx} value={idx + 1 < 10 ? `0${idx + 1}` : idx + 1}>
                          {(idx + 1) / 10 < 1 && '0'}
                          {idx + 1}
                        </option>
                      ))}
                    </select>
                  </label>
                  <span>월</span>
                  <label className={cx('wz outline block dropdown')}>
                    <select onChange={handleChangeExpirationYear}>
                      {new Array(41).fill(0).map(
                        (
                          val,
                          idx // 2060년 까지
                        ) => (
                          <option key={idx} value={new Date().getFullYear() + idx}>
                            {new Date().getFullYear() + idx}
                          </option>
                        )
                      )}
                    </select>
                  </label>
                  <span>년</span>
                </li>
                <li className={cx('creditCardPw')}>
                  <label>비밀번호</label>
                  <div className={cx('wz input')}>
                    <input
                      type="password"
                      maxLength="2"
                      value={this.state.paymentInfo.cardPasswd}
                      onChange={handleChangePassword}
                    />
                    <span>**</span>
                    <em>앞 두자리 입력</em>
                  </div>
                </li>
              </ul>
              <div>
                <div
                  className={cx('creditCard_checkbox_wrap')}
                  onClick={() => this.setState((prevState) => ({ isPaymentHelper: !prevState.isPaymentHelper }))}
                >
                  <label className={cx('wz checkbox circular large')}>
                    <input type="checkbox" name="pseudo2" onChange={handleChangePayAgreement} />
                    <span>결제 예약 및 개인정보 수집ᐧ이용 동의</span>
                  </label>
                  <span>개인정보 수집 및 이용 안내 보기</span>
                </div>
                <div className={cx('payAgreement', 'helper', { active: isPaymentHelper })}>
                  <span>
                    와디즈(주)는 광고서비스 제공을 위해 아래와 같이 개인정보를 수집 후, 별도 저장 없이 PG사에 안전하게
                    제공합니다.
                    <br />
                    1. 수집 항목 : 카드 번호, 카드 유효기간, 카드 비밀번호 앞2자리, 생년월일(또는 사업자등록번호)
                    <br />
                    2. 목적 : 광고서비스 제공을 위한 요금 결제
                    <br />
                    <strong>
                      3. 보유 기간 : PG사에 전달하기 위한 목적으로만 수집하며 와디즈(주)에는 별도 저장하지 않습니다.
                    </strong>
                    <br />
                    <br />
                    <span className="point_red">※ 동의 거부시 광고 서비스 이용이 불가합니다.</span>
                  </span>
                </div>
                <div
                  className={cx('creditCard_checkbox_wrap')}
                  onClick={() => this.setState((prevState) => ({ isCardHelper: !prevState.isCardHelper }))}
                >
                  <label className={cx('wz checkbox circular large')}>
                    <input type="checkbox" name="pseudo2" onChange={handleChangeAgreement} />
                    <span>광고 환불 규정 동의</span>
                  </label>
                  <span>광고서비스 환불 규정 확인하기</span>
                </div>
                <div className={cx('cardAgreement', 'helper', { active: isCardHelper })}>
                  <span>
                    와디즈 광고서비스의 환불 규정은 다음과 같습니다.
                    <br />
                    1. 환불 가능한 경우
                    <br />
                    - 와디즈의 귀책으로 광고를 취소하는 경우
                    <br />
                    - 취소 요청이 광고 시작일 기준 3 영업일(72시간) 이전, 영업시간 내인 경우
                    <br />
                    2. 환불 불가한 경우
                    <br />
                    - 메이커의 단순 변심 또는 메이커의 귀책으로 광고를 취소하는 경우
                    <br />
                    - 취소 요청이 광고 시작일 기준 3 영업일(72시간) 이내인 경우
                    <br />
                    ※ 와디즈 광고센터와 문의메일(ad@wadiz.kr)을 통해 광고 취소 요청이 가능하며 담당자 확인 후 환불
                    처리가 가능합니다.
                    <br />
                    ※ 영업시간 이후의 문의 건은 익일 영업시간 내에 확인, 처리됩니다.
                    <br />
                    ※ 카드 결제 취소를 통해 환불되며, 카드사에 따라 4~5영업일 소요될 수 있습니다.
                    <br />※ 광고서비스의 환불기준은 와디즈 내부 운영 정책에 따라 변경될 수 있습니다.
                  </span>
                </div>
                <div
                  className={cx('creditCard_checkbox_wrap')}
                  onClick={() => this.setState((prevState) => ({ thirdHelper: !prevState.thirdHelper }))}
                >
                  <label className={cx('wz checkbox circular large')}>
                    <input type="checkbox" name="pseudo2" onChange={handleChangeThirdAgreement} />
                    <span>간편 결제 등록 및 개인정보 수집ᐧ이용 동의</span>
                  </label>
                  <span>개인정보 수집 및 이용 안내 보기</span>
                </div>
                <div className={cx('cardAgreement', 'helper', { active: thirdHelper })}>
                  <span>
                    와디즈(주)는 간편 결제 등록을 위해 아래와 같이 개인정보를 수집 후, 별도 저장 없이 PG사에 안전하게
                    제공합니다.
                    <br />
                    1. 수집 항목 : 카드 번호, 카드 유효기간, 카드 비밀번호 앞2자리, 생년월일(또는 사업자등록번호)
                    <br />
                    2. 목적 : 간편 결제 서비스 제공, 유지, 관리
                    <br />
                    <strong>
                      3. 보유 기간 : PG사에 전달하기 위한 목적으로만 수집하며 와디즈(주)에는 별도 저장하지 않습니다.
                    </strong>
                    <br />
                    <br />
                    <span className="point_red">※ 동의 거부시 광고 서비스 이용이 불가합니다.</span>
                  </span>
                </div>
              </div>
            </main>
            <footer className={cx('dialog_button')}>
              <button type="button" className={cx('wz button')} onClick={handleClose}>
                {dialogData.closeText}
              </button>
              <button
                type="button"
                className={cx('wz button primary')}
                onClick={handleConfirm}
                disabled={paymentInfo.cardSaveYn !== 1 || paymentInfo.payYn !== 1 || paymentInfo.thirdYn !== 1}
              >
                {dialogData.confirmText}
              </button>
            </footer>
          </div>
        </div>
      )
    )
  }
}

export default connect(({ dialog, user }) => ({ dialog, user }), { closePopup, openAlertPopup })(
  SimplePaymentRegisterDialog
)
