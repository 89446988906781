import { api } from '../api'
// Captured - 2023-04-18
export type MakerInfoType = {
  advertiserType: number
  bankAccount: string
  bankCode: string
  bankCopy: number
  bankName: string
  bankbookUrl: string | null
  businessType: number
  ceoEmail: string | null
  ceoName: string | null
  ceoType: number
  coCeoEmail: string | null
  coCeoName: string | null
  createdAt: string
  id: number
  invoiceEmail: string | null
  invoiceNDate: string | null
  invoiceYDate: string | null
  invoiceYn: number
  licenseUrl: string | null
  makerEmail: string
  makerId: number
  makerName: string
  noMaskingMakerEmail: string
  noMaskingName: string
  phoneNumber: number | string | null
  registrationNumber: number | string | null
  updatedAt: string
  userType: number
}

type ResponseType = {
  result?: MakerInfoType
  message?: string
}

export const fetchMakerInfo: () => Promise<{ result: ResponseType }> = () => api('GET', 'maker/info')
