// eslint-disable-next-line
import React from 'react'
import { useLocation } from 'react-router-dom-v5'
import { ExpiredNotificationModal } from './ExpiredNotificationModal'

const enabledNotification = false
export const NotificationPanel = () => {
  const { pathname } = useLocation()

  return (
    enabledNotification &&
    pathname === '/main/ad/list' && (
      <ExpiredNotificationModal title={'현재 펀딩 광고만 신청 가능합니다'} handleKey={'adCenterNotification'}>
        스토어 광고는 준비 중으로 펀딩 광고(메인 패키지, 오픈예정 패키지, 펀딩 리스트)만 신청 가능합니다. 스토어 광고는
        조금만 기다려주세요!
      </ExpiredNotificationModal>
    )
  )
}
