// eslint-disable-next-line
import React from 'react'
import className from 'classnames/bind'
import { nanoid } from 'nanoid'
import style from '../../Dialog.module.scss'
const cx = className.bind(style)

type NotificationType = {
  title: string
  link: string
  items: Array<JSX.Element>
}
const notificationList: Array<NotificationType> = [
  {
    title: '결제 예약 및 개인정보 수집ᐧ이용 동의',
    link: '개인정보 수집 및 이용 안내 보기',
    items: [
      <>
        와디즈(주)는 광고서비스 제공을 위해 아래와 같이 개인정보를 수집 후, 별도 저장 없이 PG사에 안전하게 제공합니다.
      </>,
      <>1. 수집 항목 : 카드 번호, 카드 유효기간, 카드 비밀번호 앞2자리, 생년월일(또는 사업자등록번호)</>,
      <>2. 목적 : 광고서비스 제공을 위한 요금 결제</>,
      <strong>3. 보유 기간 : PG사에 전달하기 위한 목적으로만 수집하며 와디즈(주)에는 별도 저장하지 않습니다.</strong>,
      <> </>,
      <>※ 동의 거부시 광고 서비스 이용이 불가합니다.</>,
    ],
  },
  {
    title: '광고 환불 규정 동의',
    link: '광고서비스 환불 규정 확인하기',
    items: [
      <>와디즈 광고서비스의 환불 규정은 다음과 같습니다.</>,
      <>1. 환불 가능한 경우</>,
      <>- 와디즈의 귀책으로 광고를 취소하는 경우</>,
      <>- 취소 요청이 광고 시작일 기준 3 영업일(72시간) 이전, 영업시간 내인 경우</>,
      <>2. 환불 불가한 경우</>,
      <>- 메이커의 단순 변심 또는 메이커의 귀책으로 광고를 취소하는 경우</>,
      <>- 취소 요청이 광고 시작일 기준 3 영업일(72시간) 이내인 경우</>,
      <>
        ※ 와디즈 광고센터와 문의메일(ad@wadiz.kr)을 통해 광고 취소 요청이 가능하며 담당자 확인 후 환불 처리가
        가능합니다.
      </>,
      <>※ 영업시간 이후의 문의 건은 익일 영업시간 내에 확인, 처리됩니다.</>,
      <>※ 카드 결제 취소를 통해 환불되며, 카드사에 따라 4~5영업일 소요될 수 있습니다.</>,
      <>※ 광고서비스의 환불기준은 와디즈 내부 운영 정책에 따라 변경될 수 있습니다.</>,
    ],
  },
  {
    title: '간편 결제 등록 및 개인정보 수집ᐧ이용 동의(선택)',
    link: '개인정보 수집 및 이용 안내 보기',
    items: [
      <>
        와디즈(주)는 간편 결제 등록을 위해 아래와 같이 개인정보를 수집 후, 별도 저장 없이 PG사에 안전하게 제공합니다.
      </>,
      <>1. 수집 항목 : 카드 번호, 카드 유효기간, 카드 비밀번호 앞2자리, 생년월일(또는 사업자등록번호)</>,
      <>2. 목적 : 간편 결제 서비스 제공, 유지, 관리</>,
      <strong>3. 보유 기간 : PG사에 전달하기 위한 목적으로만 수집하며 와디즈(주)에는 별도 저장하지 않습니다.</strong>,
      <> </>,
      <>※ 동의 거부시 광고 서비스 이용이 불가합니다.</>,
    ],
  },
]

const Agreement: React.FC<{
  notificationInfo: NotificationType
  onCheck: (e: React.SyntheticEvent<EventTarget>) => void
  onShow: () => void
  isHelp: boolean
}> = ({ notificationInfo, onCheck, onShow, isHelp }) => (
  <>
    <div className={cx('creditCard_checkbox_wrap')} onClick={onShow}>
      <label className={cx('wz checkbox circular large')}>
        <input type="checkbox" name="pseudo2" onChange={onCheck} />
        <span>{notificationInfo.title}</span>
      </label>
      <span>{notificationInfo.link}</span>
    </div>
    <div className={cx('payAgreement', 'helper', { active: isHelp })}>
      <span>
        {notificationInfo.items.map((info) => (
          <React.Fragment key={nanoid()}>
            {info}
            <br />
          </React.Fragment>
        ))}
      </span>
    </div>
  </>
)

export const AgreementList: React.FC<{
  checkerList: Array<(e: React.SyntheticEvent<EventTarget>) => void>
}> = ({ checkerList }) => {
  const [isAgreements, setAgreements] = React.useState([false, false, false])
  const doShow = React.useCallback(
    (index) => () => {
      const updatedAgreements = [...isAgreements]
      updatedAgreements[index] = !isAgreements[index]
      setAgreements(updatedAgreements)
    },
    [isAgreements]
  )

  return (
    <div>
      {notificationList.map((agreement, index) => (
        <Agreement
          key={`agreement-${index}`}
          notificationInfo={agreement}
          onCheck={checkerList[index]}
          onShow={doShow(index)}
          isHelp={isAgreements[index]}
        />
      ))}
    </div>
  )
}
