import { useWatch, useController, type Control, FieldPath, FieldValues } from 'react-hook-form'
import { Select, Form } from 'antd'
import { OptionText } from './OptionText'
import { useGoodsListQuery } from '@center/query'

const { Option } = Select

type FormItemProps = React.ComponentProps<typeof Form.Item>
export type AdGoodsSelectorFunction = {
  name: FieldPath<FieldValues>
  control: Control<FieldValues>
}
export const AdGoodsFormField: React.FC<AdGoodsSelectorFunction> = ({ name, control }) => {
  const { areas, isPackage, projectType, campaignId } = useWatch({ control, name: 'selector' })
  const { data: goodsList } = useGoodsListQuery({
    areas,
    isPackage,
    projectType,
    campaignId,
  })
  const list = goodsList || []
  const { field, fieldState } = useController({ name, control })
  const handleNormalize: FormItemProps['normalize'] = (refGoodsId) => {
    field.onChange(list?.find((n) => n?.refGoodsId === refGoodsId))
    return refGoodsId
  }
  return (
    <Form.Item
      name={name}
      normalize={handleNormalize}
      initialValue={field?.value?.refGoodsId}
      validateStatus={fieldState.invalid ? 'error' : undefined}
      help={fieldState.error?.message}
      style={{ minWidth: '600px' }}
    >
      <Select placeholder="상품을 선택하세요." style={{ width: '100%', maxWidth: 500 }} defaultActiveFirstOption>
        {list &&
          Array.isArray(list) &&
          list.map((item, index) => {
            return (
              <Option key={index} value={item.refGoodsId}>
                <OptionText goods={item} />
              </Option>
            )
          })}
      </Select>
    </Form.Item>
  )
}
