import React from 'react'

// Components
import Tooltip from '../../Tooltip'

// CSS module
import className from 'classnames/bind'
import style from '../SettingContent.module.scss'
import Lnb from '../../Lnb'
import NavBar from '../../NavBar'
import LoadingBar from '../../LoadingBar/LoadingBar'
const cx = className.bind(style)

const navList = [
  { name: '설정', link: '/main/ad/settings/' },
  { name: '환불 계좌 관리', link: '/main/ad/settings/refundAccount' },
]

const RefundAccount = ({
  makerInfo,
  bankList,
  updateRefundAccount,
  openGuide,
  loadingBarFlag,
  onChangeOpenGuideType,
  inputChange,
  selectBank,
}) => {
  return (
    <main className={cx('main_container', 'settings')}>
      <Lnb type="settings" />
      <div className={cx('content_wrap')}>
        <NavBar list={navList} />
        {loadingBarFlag ? (
          <LoadingBar />
        ) : (
          <>
            {/* 컨텐츠 시작 */}
            <div className={cx('settings_refund')}>
              <hgroup>
                <h2>환불 계좌 관리</h2>
                <p>
                  회원님의 환불 계좌 정보입니다.<br></br>
                  사업자정보 관련 문의 사항은{' '}
                  <a
                    href={process.env.REACT_APP_WADIZ_HELP_CENTER}
                    target="_blank"
                    className={cx('point_link_blue')}
                    rel="noreferrer"
                  >
                    광고 문의하기
                  </a>{' '}
                  로 문의 부탁드립니다.
                </p>
              </hgroup>
              <section>
                <div
                  className={cx('guide_wrap', { active: openGuide.type === 'refundaccount' })}
                  onClick={() => onChangeOpenGuideType(0, 'refundaccount')}
                >
                  <h3>환불 계좌 정보</h3>
                  <Tooltip title="환불 계좌 정보" text={'취소된 광고의 환불금이 입금될 계좌정보를 입력해 주세요.'} />
                </div>
                <div className={cx('refund_input_wrap')}>
                  <label className={cx('wz outline block dropdown', 'rpDropdown')}>
                    <select onChange={(e) => selectBank(e)} value={makerInfo.bankCode}>
                      <option value="" hidden>
                        은행선택
                      </option>
                      {bankList &&
                        bankList.map((val, idx) => (
                          <option key={idx} value={val.bankCode}>
                            {val.bankName}
                          </option>
                        ))}
                    </select>
                  </label>
                  <div className={cx('wz input tiny')}>
                    <input
                      type="text"
                      name="bankAccount"
                      placeholder="계좌번호 (숫자)"
                      value={makerInfo.bankAccount}
                      maxLength="14"
                      onChange={(e) => {
                        const { value } = e.target
                        if (/[^0-9]/g.test(value)) return
                        inputChange(e)
                      }}
                    />
                  </div>
                </div>
              </section>{' '}
            </div>
            <div className={cx('buttonArea')}>
              <button type="button" className={cx('wz button primary', 'button_320')} onClick={updateRefundAccount}>
                저장
              </button>
            </div>
            {/* 컨텐츠 끝 */}
          </>
        )}
      </div>
    </main>
  )
}

export default RefundAccount
