import React, { useCallback, useState } from 'react'
import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { ConfirmModal } from '@wadiz-frontend/waffle-modified'
import { AreaType } from '@legacy/definitions'
import { AdStaticPreview } from '@common/ui'
import { KeywordAdField } from '@legacy/share/ui'
import { fetchGetKeyword, fetchGetKeywordValidation, fetchGetPopularKeyword } from '../../../../pages/_api'
import {
  ImageUploadButton,
  AdvertisementMatter,
  AdCopy,
  AdGoods,
  AdSelectDate,
  AdBgColorList,
  AdLandingUrl,
} from '@center/ui'
import { useBusinessDays, useBgColorListQuery } from '@center/query'
import { numberFormat } from '@wadiz-frontend/utils-modified'

import className from 'classnames/bind'
import style from './AccountCard.module.scss'

import PreviewContainer from '../../../../containers/PreviewContainer/PreviewContainer'

const cx = className.bind(style)

const dateFormat = 'YYYY-MM-DD'
// o: 영업일, h: 비영업일

const AccountCard = ({
  isWadizAdmin,
  imgFileSel,
  selectColor,
  data,
  inputTextChange,
  idxdata,
  onSelectGoods,
  onSelectDiscountCoupon,
  onChangeDateRange,
  onFocusChange,
  imgDel,
  // bgColorList,
  accountList,
  accountListDel,
  onChangeOpenGuideType,
  openGuide,
  lossText,
  projectType,
  makerInfo,
  onChangeLandingURL,
  landingUrl,
  setImage,
  isSuperUser,
}) => {
  const [visibleChangeGoodsModal, setVisibleChangeGoodsModal] = useState(false)
  const [selectedGoodsData, setSelectedGoodsData] = useState({})
  const { data: businessDays } = useBusinessDays({ startDate: dayjs().format(dateFormat) })
  const { data: bgColorQuery } = useBgColorListQuery(data.info.bgColorYn === 1 ? data.adCenterPubAreaId : null)

  const doSelectedGoodsChangeConfirm = useCallback(() => {
    const { selectedValue, idx } = selectedGoodsData
    imgDel(1)
    onSelectGoods(selectedValue, idx)
    setVisibleChangeGoodsModal(false)
  }, [imgDel, onSelectGoods, selectedGoodsData])

  const doSelectedGoodsChangeCancel = useCallback(() => {
    setVisibleChangeGoodsModal(false)
  }, [])

  const discountInfoFormat = (accountData) => {
    const { pricingType, impNum, discountPrice, impPeriod } = accountData

    if (pricingType === 'CPT') return `${impPeriod}일 ${numberFormat(discountPrice)}원`
    return `${impPeriod}일 ${numberFormat(discountPrice)}원 ${impNum}회 노출`
  }

  const handleDate = (date, normalDays, holidays) => {
    onChangeDateRange(date.startDate, date.endDate, idxdata, { normalDays, holidays })
  }

  const handleChangeGoods = (goods, idx) => {
    if (!accountList[1]?.imgUrl) {
      onSelectGoods(idx, idxdata)
      return
    }
    const value = data.goods.findIndex((ex) => ex.id === data.goodsid)

    if (idx !== `${value}`) {
      setVisibleChangeGoodsModal(true)
      setSelectedGoodsData({ selectedValue: idx, idx: idxdata })
    }
  }

  return (
    <div className={cx('account_card')}>
      <h4>
        {data.positionName}
        {data.packaged !== 'slave' && (
          <button
            type="button"
            className={cx('wz button circular mini')}
            onClick={(e) => {
              accountListDel(e, idxdata)
            }}
          >
            삭제
          </button>
        )}
      </h4>
      <div className={cx('account_cardForm')}>
        {data.packaged !== 'slave' && (
          <>
            {/* 광고상품 */}
            <section>
              <AdGoods
                defaultGoods={data.goodsList[data.goodsIdx]}
                goodsList={data.goodsList}
                handleGoods={handleChangeGoods}
                errorMsg={data.nameErrMsg}
              />
            </section>
            {/* 광고기간 */}
            <section>
              <AdSelectDate
                selectedGoods={data}
                selectedDate={{ startDate: data.startAt, endDate: data.endAt }}
                businessDays={businessDays?.result || []}
                handleDate={handleDate}
                price={data.price || 0}
                holidayPrice={data.holidayPrice || 0}
                impMinPeriod={data.impMinPeriod}
                errorMsg={data.dateErrMsg}
                isSuperUser={isSuperUser}
              />
            </section>
          </>
        )}
        {data.discountCoupon.length > 0 && (
          <>
            <section className={cx('wz form', 'ad_element', 'account_card_discount_coupon')}>
              <div
                htmlFor="id"
                className={cx('guide_wrap', {
                  active: openGuide.idx === idxdata && openGuide.type === 'discountCoupon',
                })}
                onClick={() => onChangeOpenGuideType(idxdata, 'date')}
              >
                <label>할인코드 선택</label>
              </div>
              <div className={cx('field')}>
                <label className={cx('wz outline block dropdown')}>
                  <select onChange={(e) => onSelectDiscountCoupon(e.target.value, idxdata)} value={data.discountIdx}>
                    {data.discountCoupon.map((val, idx) => (
                      <option key={idx} value={idx}>
                        {val.discountCode}
                      </option>
                    ))}
                    <option value="">할인코드를 선택하지 않습니다.</option>
                  </select>
                </label>
                <p className={cx('msgError', 'point_red')}>{data.nameErrMsg}</p>
              </div>
            </section>
            <section className={cx('wz form', 'ad_element', 'account_card_discount_coupon')}>
              <div
                htmlFor="id"
                className={cx('guide_wrap', {
                  active: openGuide.idx === idxdata && openGuide.type === 'discountCoupon',
                })}
                onClick={() => onChangeOpenGuideType(idxdata, 'date')}
              >
                <label>할인금액</label>
              </div>
              <div className={cx('field')}>
                <div className={cx('wz icon input disabled')}>
                  <input
                    type="text"
                    name="pseudo-text"
                    value={data.discountPrice || data.discountPrice === 0 ? discountInfoFormat(data) : ''}
                    autoComplete="off"
                    disabled
                  />
                </div>
                <em className={cx('helper')}>* 할인코드를 입력하시면 할인금액이 계산됩니다.</em>
              </div>
            </section>
          </>
        )}
        {/* XXX: 일단, 모든 데이터가 여기로 몰리므로 이 부분에서 데이터를 가져다가 그림을 그린다. 추후 모듈화 시기에는 아래 서술된 데이터들을 react query로 정리한다. */}
        {(data.adCenterPubAreaId === AreaType.mainHome ||
          data.adCenterPubAreaId === AreaType.fundingHome ||
          data.adCenterPubAreaId === AreaType.comingSoonHome ||
          data.adCenterPubAreaId === AreaType.preOrderBanner) && (
          <>
            <AdvertisementMatter
              areaType={data.adCenterPubAreaId}
              getManualUrl={() => data.manualUrl}
              haveImage={data.info.imageYn !== 0}
              imageUrl={data.imgUrl}
              imageSize={data?.info?.imageSize}
              imageWidth={data?.info?.imageWidth}
              imageHeight={data?.info?.imageHeight}
              imageErrMsg={data.imageErrMsg}
              isBadgeCombination={(() => {
                const { goodsIdx, goodsList, adCenterPubAreaId } = data
                const { badgeType } = goodsList[goodsIdx]
                return badgeType === 'Popularity' && adCenterPubAreaId === AreaType.comingSoonList
              })()}
              onImageUploadSuccess={(imgUrl) => setImage(imgUrl, idxdata)}
              onCloseImage={() => imgDel(idxdata)}
              haveColor={data.info.bgColorYn !== 0}
              haveMainCopy={data.info.mainCopyYn !== 0}
              mainCopy={data.mainCopy}
              haveSubCopy={data.info.subCopyYn !== 0}
              subCopy={data.subCopy}
              haveKeyword={data.info.adCenterPubAreaId === AreaType.searchKeyword}
              keyword={data.keyword}
            />

            {data.imgUrl && data.info.adCenterPubAreaId !== AreaType.deprecated2 && (
              <div className={cx('cautionMsg')} style={{ marginTop: 0 }}>
                ※ 광고센터 미리보기 화면에서 보이는 지면과 실제 게재지면과의 차이가 있을 수 있습니다. 소재의 위아래가
                잘려 보이지 않도록 제품 및 중요 이미지를 배너 중앙에 배치해주세요.
              </div>
            )}
          </>
        )}

        {data.adCenterPubAreaId !== AreaType.mainHome &&
          data.adCenterPubAreaId !== AreaType.fundingHome &&
          data.adCenterPubAreaId !== AreaType.comingSoonHome &&
          data.adCenterPubAreaId !== AreaType.preOrderBanner && (
            <section
              className={cx('wz form', 'ad_element', 'account_card_img')}
              style={{
                display: !Object.prototype.hasOwnProperty.call(data, 'info') || data.info.imageYn === 0 ? 'none' : '',
              }}
            >
              <div className={cx('guide_wrap')}>
                <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '2px dotted #dadce0' }}>
                  <div style={{ fontSize: '17px' }}>광고 소재</div>
                  <Button type="text" icon={<DownloadOutlined />} onClick={() => window.open(data.manualUrl)}>
                    {'영역별 광고 소재 제작 가이드 확인하기'}
                  </Button>
                </div>
              </div>
              <div className={cx('field')}>
                {!data.imgUrl && (
                  <ImageUploadButton
                    onChange={(e) => {
                      imgFileSel(e, idxdata, data.info.imageWidth, data.info.imageHeight, data.info.imageSize)
                    }}
                  />
                )}
                {!data.imgUrl && (
                  <>
                    <em className={cx('helper')}>
                      {Object.prototype.hasOwnProperty.call(data, 'info') && data.info.imageSize}MB 이하 / JPEG, JPG,
                      PNG 파일 소재 사이즈 {Object.prototype.hasOwnProperty.call(data, 'info') && data.info.imageWidth}*
                      {Object.prototype.hasOwnProperty.call(data, 'info') && data.info.imageHeight} 픽셀
                    </em>
                    <p className={cx('msgError', 'point_red')}>{data.imageErrMsg}</p>
                  </>
                )}
                {/* 이미지가 있을 경우 */}
                {data.imgUrl && data.info.adCenterPubAreaId && (
                  <PreviewContainer
                    mainCopy={data.mainCopy}
                    keyword={data.keyword}
                    subCopy={data.subCopy}
                    imgUrl={data.imgUrl}
                    bgColor={data.selColor}
                    area={data.positionName}
                    accountNum={data.info.accountNum}
                    adCenterPubAreaId={data.info.adCenterPubAreaId}
                    lossText={lossText}
                    imgDel={imgDel}
                    imgDelIdx={idxdata}
                    projectType={projectType}
                  />
                )}
              </div>
              {data.imgUrl && data.info.adCenterPubAreaId && (
                <div className={cx('cautionMsg')}>
                  ※ 광고센터 미리보기 화면에서 보이는 지면과 실제 게재지면과의 차이가 있을 수 있습니다. 소재의 위아래가
                  잘려 보이지 않도록 제품 및 중요 이미지를 배너 중앙에 배치해주세요.
                </div>
              )}
            </section>
          )}
        {/* 배경색 */}
        {data.info.bgColorYn === 1 && bgColorQuery && (
          <section>
            <AdBgColorList
              defaultColor={data.selColor}
              bgColorDataList={bgColorQuery.result}
              handleBgColor={(bgColorData) => selectColor(bgColorData, idxdata)}
              errorMsg={data.backgroundColorErrMsg}
            />
          </section>
        )}
        {/* 미리보기 - 고정 이미지 인 경우 */}
        <AdStaticPreview areaId={data.info.adCenterPubAreaId} />
        {/* 앱푸시 */}
        {data.info.adCenterPubAreaId === AreaType.messagePush && (
          <PreviewContainer
            mainCopy={data.mainCopy}
            keyword={data.keyword}
            subCopy={data.subCopy}
            imgUrl={data.imgUrl}
            bgColor={data.selColor}
            area={data.positionName}
            accountNum={data.info.accountNum}
            adCenterPubAreaId={data.info.adCenterPubAreaId}
            lossText={lossText}
            imgDel={imgDel}
            imgDelIdx={idxdata}
            projectType={projectType}
          />
        )}
        {/* 키워드 검색 */}
        {data.info.adCenterPubAreaId === AreaType.searchKeyword && (
          <section>
            <KeywordAdField
              keyword={data.keyword}
              {...{ fetchGetKeyword, fetchGetKeywordValidation, fetchGetPopularKeyword }}
              {...{ start_date: data.startAt, end_date: data.endAt }}
              onChange={(value) => inputTextChange('keyword', value, idxdata)}
            />
          </section>
        )}
        {data.info.mainCopyYn === 1 && (
          <section>
            <AdCopy
              title="메인카피"
              defaultValue={data.mainCopy || ''}
              rows={data.info.mainCopyLineNum}
              maxLength={data.info.mainCopyCharNum}
              handleCopy={(value) => inputTextChange('mainCopy', value, idxdata)}
              errorMsg={data.mainCopyErrMsg}
              areaId={data.info.adCenterPubAreaId}
            />
          </section>
        )}
        {data.info.subCopyYn === 1 && (
          <section>
            <AdCopy
              title="서브카피"
              defaultValue={data.subCopy || ''}
              rows={data.info.subCopyLineNum}
              maxLength={data.info.subCopyCharNum}
              handleCopy={(value) => inputTextChange('subCopy', value, idxdata)}
              errorMsg={data.subCopyErrMsg}
              areaId={data.info.adCenterPubAreaId}
            />
          </section>
        )}
        <section>
          <AdLandingUrl
            adCenterPubAreaId={data.adCenterPubAreaId}
            makerInfo={makerInfo}
            projectDetailURL={landingUrl}
            makerPageUrlSelected={data.makerPageUrlSelected}
            isWadizAdmin={isWadizAdmin}
            handleLandingURL={onChangeLandingURL}
            inputTextChange={inputTextChange}
          />
        </section>
      </div>
      {visibleChangeGoodsModal && (
        <ConfirmModal
          title={'광고 상품을 변경하시겠습니까?'}
          message={'현재 등록된 펀딩 오픈예정 리스트의 광고 소재는 삭제됩니다.'}
          onPositiveButtonClick={doSelectedGoodsChangeConfirm}
          onNegativeButtonClick={doSelectedGoodsChangeCancel}
          onClose={doSelectedGoodsChangeCancel}
          negativeButtonLabel={'취소'}
          positiveButtonLabel={'확인'}
          size={'sm'}
        />
      )}
    </div>
  )
}

export default AccountCard
