import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import style from './TitleSection.module.scss';
import download from '../../../assets/images/intro/icon_download.svg'
const cx = classNames.bind(style);

const TitleSection = ({
  section,
  className
}) => {
  let title;
  let description;
  switch (section) {
    case 'AdServiceSection':
      title = <>광고 서비스로 프로젝트를 알리고 <br />높은 성과를 달성하세요</>;
      description = (
        <p>
          광고를 한 번 이상 집행한 프로젝트의 성공률은 13% 더 높았으며, <br className={cx('pcBr')} />
          전체 평균 펀딩 금액보다 2배 높은 펀딩 금액을 달성하고 있습니다.
        </p>
      );
      break;
    case 'RecommandAdSection':
      title = <>메이커님의 예산에 꼭 맞는 <br />디스플레이 광고 상품을 추천드려요</>;
      description = (
        <p>
          프로젝트 제출 후 심사 승인이 완료되어야 <br />
          광고센터 로그인 후 광고 서비스 신청이 가능합니다.
        </p>
      );
      break;
    case 'DisplayAdSection':
      title = <>와디즈 디스플레이 광고를 통해 <br />서포터의 시선을 사로잡아 보세요</>;
      description = (
        <p>
          와디즈 디스플레이 광고를 통해 주목도 높은 지면에 광고를 노출할 수 있습니다. <br />
          월 평균 1,000만 명 이상의 유저가 와디즈를 탐색하는 주요 동선에서 <br className={cx('pcBr')} />효과적으로 광고를 노출해 보세요.
        </p>
      );
      break;
    case 'TargetPushAdSection':
      title = <>서포터를 더욱 끌어모으고 싶다면<br />타겟 광고 &#38; 푸시 광고도 함께 해보세요</>;
      break;
    case 'AdProcessSection':
      title = <>디스플레이 광고 진행 프로세스</>;
      description = (
        <p>
          와디즈 광고센터는 다음의 프로세스로 진행됩니다.
        </p>
      );
      break;
    case 'GuideSection':
      title = <>광고 서비스 이용 가이드 및<br />영역별 제작 가이드</>;
      description = (
        <p>
          상품 소개서에서 <br className={cx('moBr')} />와디즈의 다양한 광고 상품을 알아보세요.<br />
          <div>이용 가이드와 제작 가이드를 <strong>다운로드</strong><img src={download} alt="download" />하여</div>
          광고 진행 방법과 광고 소재 제작 방법을 <br className={cx('moBr')} />확인할 수 있습니다.
        </p>
      );
      break;
    case 'AskSection':
      title = <>문의사항이 있으신가요?</>;
      description = (
        <p>
          광고 서비스에 대해 궁금한 사항이 있다면 <br className={cx('moBr')} />아래의 <strong>광고 문의하기</strong>를 눌러<br />문의 사항을 보내주세요.
        </p>
      );
      break;
    default:
      title = '';
      description = '';
      break;
  }

  return (
    <div className={cx('container', className)}>
      {title && <h3 className={cx('title')}>{title}</h3>}
      {description && description}
    </div>
  )
};

TitleSection.propsTypes = {
  section: PropTypes.oneOf(['AdServiceSection', 'RecommandAdSection','DisplayAdSection', 'TargetPushAdSection', 'AdProcessSection', 'GuideSection','AskSection']).isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default TitleSection;
