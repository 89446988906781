import { memo } from 'react'
import { Typography, Button } from 'antd'

const { Title, Text } = Typography
const url = 'https://lookerstudio.google.com/u/0/reporting/eaba48ac-46c3-4339-8f03-7227e82483e1/page/tp6mD'

const HeadNotify: React.FC = () => (
  <>
    <Title level={5} style={{ marginTop: 16 }}>
      희망하는 광고 영역을 선택해주세요.
    </Title>
    <Text>
      ※ 사전 예약 시 프로젝트 진행 일정을 반드시 고려하여 광고를 등록하세요.
      <ul>
        <li style={{ listStyleType: 'disc' }}>
          오픈예정 패키지 : 오픈예정 기간 중에만 진행 가능, 펀딩/프리오더 프로젝트만 진행 가능
        </li>
        <li style={{ listStyleType: 'disc' }}>
          펀딩 패키지 : 본펀딩 기간 중에만 진행 가능, 펀딩/프리오더 프로젝트만 진행 가능
        </li>
      </ul>
    </Text>
    <Button type="primary" style={{ marginBottom: 16 }} onClick={() => window.open(url, '_blank')}>
      광고 구좌 현황 미리보기
    </Button>
  </>
)

export default memo(HeadNotify)
