import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeAlertPopup } from '../../reducers/alertReducer';
import CommonDialog from './CommonDialog/CommonDialog';
import className from 'classnames/bind';
import style from './Dialog.module.scss';
const cx = className.bind(style);

class AlertDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
    };
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.alert.alertFlag) this.setState({ isShow: true });
    else this.setState({ isShow: false });
  };

  handleConfirm = () => {
    const { alertData } = this.props.alert;
    if (Object.prototype.hasOwnProperty.call(alertData, 'confirmFunc')) alertData.confirmFunc();
    this.props.closeAlertPopup();
  };

  handleClose = () => {
    this.props.closeAlertPopup();
  };

  render() {
    const { isShow } = this.state;
    const { alertData } = this.props.alert;
    return isShow && (
      Object.prototype.hasOwnProperty.call(alertData, 'closeText') ? (
        <CommonDialog zIndex={'100'} type={'alert'} title={alertData.title} closeText={alertData.closeText} onClickClose={this.handleClose}>
          {/* Content Area */}
          <main className={cx('dialog_content') }>
            {alertData.content}
          </main>
        </CommonDialog>
      ) : (
        <CommonDialog zIndex={'100'} type={'alert'} title={alertData.title} confirmText={alertData.confirmText} onClickConfirm={this.handleConfirm} onClickClose={this.handleClose}>
          {/* Content Area */}
          <main className={cx('dialog_content') }>
            {alertData.content}
          </main>
        </CommonDialog>
      )
    );
  }
}

export default connect(({ alert }) => ({ alert }), ({ closeAlertPopup }))(AlertDialog);
