import React from 'react';
import classNames from 'classnames/bind';
import TitleSection from '../TitleSection';
import style from './AdServiceSection.module.scss';

const cx = classNames.bind(style);

const contentList = [
  {
    title: '프로젝트 평균 성공률',
    desc: <>*분석기간 : 22년 1월 ~ 23년 2월<br />*펀딩/프리오더 프로젝트의 달성금을 의미</>
  },
  {
    title: '프로젝트 평균 달성액',
    desc: '*단위: 만원'
  },
]

const AdServiceSection = () => {
  return (
    <section className={cx('container')}>
      <TitleSection section="AdServiceSection" />
      <div className={cx('section')}>
        {contentList.map((item, index) => (
          <div className={cx('graph')}>
            <h4 className={cx('title')}>{item.title}</h4>
            <div className={cx('image')} aria-label={item.title + ' 그래프'}></div>
            <div className={cx('desc')}>{item.desc}</div>
          </div>
        ))}
      </div>
    </section>
  )
};

export default AdServiceSection;
