import React, { FC } from 'react'
import PreviewImage from './keywordPreview.jpg'
import styles from './KeywordAdPreview.module.scss'

export const KeywordAdPreview: FC = () => {
  return (
    <div className={styles.container}>
      <img src={PreviewImage} alt="키워드 미리보기" />
    </div>
  )
}
