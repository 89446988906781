import { ImageUploader } from './ImageUploader'
import styles from './ImageMatterContainer.module.scss'

type ImageMatterContainerFunction = {
  imageUrl: string
  width: number
  height: number
  size: number
  isBadgeCombination: boolean
  errMessage: string
  onSuccess: (imgUrl: string) => void
}

// TODO: 아마도 이 모듈에서 react query로 이미지 관련 데이터를 캐싱할 듯하다.
// TODO: 따라서, 사용하는 속성을 모두 나열해두어 대비한다.
export const ImageMatterContainer: React.FC<ImageMatterContainerFunction> = ({
  imageUrl,
  width,
  height,
  size,
  errMessage,
  isBadgeCombination,
  onSuccess,
}) => {
  return (
    <div className={styles.container}>
      {!imageUrl && (
        <ImageUploader
          size={size}
          width={width}
          height={height}
          errMessage={errMessage}
          isBadgeCombination={isBadgeCombination}
          onSuccess={onSuccess}
        />
      )}
    </div>
  )
}
