import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom-v5';
import { openPopup, closePopup } from '../../reducers/dialogReducer';

import className from 'classnames/bind';
import style from './Dialog.module.scss';
const cx = className.bind(style);

class AdAccountConfirmDialog extends Component {
  initState = {
    isShow: false,
  };

  state = this.initState;

  static getDerivedStateFromProps = nextProps => {
    if (nextProps.dialog.dialogType === 'adAccountConfirmDialog') {
      return { isShow: true };
    }

    return { ...(new AdAccountConfirmDialog()).initState };
  };

  handleClose = () => {
    this.props.closePopup();
  };

  handleConfirm = (data) => {
    // confirm function
    const { dialogData } = this.props.dialog;
    if (dialogData.confirmFunc) {
      dialogData.confirmFunc(data);
    }
    // close when success
    this.handleClose();
  }

  render() {
    const { isShow } = this.state;
    const { dialogData } = this.props.dialog;
    const { handleClose, handleConfirm } = this;
    return isShow && (
      <div className={cx('dialog_shadow')} style={{ zIndex: 100 }}>
        <div className={cx('dialog_wrap', 'typeAlert')}>
          <header>
            <div className={cx('dialog_close')}>
              {/* Close Button Area */}
              <button className={cx('wz button less')} onClick={handleClose}>
                <i className={cx('icon close')}></i>
              </button>
            </div>
            <div className={cx('dialog_title')}>
              {/* Title Area */}
              <h3>{dialogData.title}</h3>
            </div>
          </header>
          {/* Content Area */}
          <main className={cx('dialog_content', 'scrollbar', 'creditCard')}>
            {dialogData.type === 'delete' && (
              <p>광고 상품을 삭제하면 <em className={cx('point_primary')}>광고 만들기가 더 이상 진행 되지 않습니다.</em><br />
              </p>
            )}
            {dialogData.type === 'cancelRequestForReview' && (
              <p>승인요청을 취소하면 광고 구좌는 <em className={cx('point_primary')}>작성중으로 변경</em>되며 결제 예약된 내용도 삭제됩니다.<br />
              </p>
            )}
            {dialogData.type === 'cancelScheduledAd' && (
              <p>광고를 취소하면 <em className={cx('point_primary')}>예정된 광고는 노출되지 않으며 </em><br />
                결제된 금액은 <em className={cx('point_primary')}>환불 규정</em>에 따라 진행됩니다.<br />
                <br />
                <a href='https://help.wadiz.kr/ko/articles/4027439' target='_blank' rel="noopener noreferrer"><em className={cx('point_primary')}>환불 정책 확인하기 &gt; </em></a>
              </p>
            )}
            {dialogData.type === 'SubmittedAfterCreating:Cancel' && (
              <p>
                요청을 취소하면 심사가 중단되며, 프로젝트를 다시 작성하여 심사요청을 해야 심사가 재개됩니다.
              </p>
            )}
            {dialogData.type === 'Submitted:Cancel' && (
              <p>
                요청을 취소하면 심사가 중단되며, 프로젝트를 다시 수정할 수 있습니다.
              </p>
            )}
            {dialogData.type === 'Accepted:Cancel' && (
              <p>
                요청을 취소하면 계약 중으로 상태가 변경됩니다.
              </p>
            )}
            {dialogData.type === 'cancelReason' && (
              <p>{dialogData.cancelReason}</p>
            )}
            {dialogData.type === 'onlyText' && (
              <p>{dialogData.deleteText1}<br />
                {dialogData.deleteText2}
              </p>
            )}
            {dialogData.type === 'deleteAdAlert' && (
              <p>
                광고 구좌 중에 광고 <em className={cx('point_primary')}>승인요청, 진행중, 취소, 완료</em>가 있을 경우에는<br />
                광고를 삭제할 수 없습니다.
              </p>
            )}
          </main>
          <footer className={cx('dialog_button')}>
            {/* Button Area */}
            {dialogData.cancelText && (
              <button type="button" className={cx('wz button primary-outline')} onClick={() => { handleClose(); }}>{dialogData.cancelText}</button>
            )}
            {dialogData.confirmText && (
              <button type="button" className={cx('wz button primary')} onClick={() => { handleConfirm(dialogData.confirmData); }}>{dialogData.confirmText}</button>
            )}
          </footer>
        </div>
      </div>
    );
  }
}

export default connect(({ dialog }) => ({ dialog }), ({ openPopup, closePopup }))(withRouter(AdAccountConfirmDialog));
