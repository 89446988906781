import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom-v5'
import { connect } from 'react-redux'

// Components
import { openPopup } from '../../reducers/dialogReducer'
import AdCostReportContent from '../../components/AdCostReportContent/AdCostReportContent'

import { dateToString, dateToStringAdPeriod } from '../../datetostring'
import { network } from '../../network'

class AdResultReportPage extends PureComponent {
  constructor(props) {
    super(props)
    const { history } = props
    let prevState = {}
    prevState = JSON.parse(sessionStorage.getItem('report_cost'))

    this.state = {
      tableLevel: 0,
      projectNameOption: [],
      adNameOption: [],
      itemList: [],
      allItemTotalCount: -1,
      totalCount: 1,
      selectedOption: {
        projectId: '',
        projectSort: 0,
        adId: '',
        adSort: 0,
        start: 1,
        size: 10,
      },
      needInit: undefined,
      tableData: [],
      isDownload: false,
      fileName: 'all_report_cost',
      isInit: false,
      loadingBarFlag: false,
    }

    if (history.action === 'POP' && prevState) {
      sessionStorage.removeItem('report_cost')
      const { state } = this
      this.state = { ...state, ...prevState }
    }
  }

  navList = [
    { name: '보고서', link: '/main/ad/report/' },
    { name: '광고비 보고서', link: '/main/ad/report/cost' },
  ]

  tableInfo = {
    // tableLabel: ['No.', '프로젝트', '광고명', '광고 영역', '구좌', '기간', '광고비', '승인단계', '운영단계', '광고비잔금', '남은 광고일', '결제상태'],
    // tableValue: ['idx', 'projectName', 'adName', 'area', 'accountNum', 'period', 'price', 'status', 'adProcessStatus', 'adRemainPrice', 'adRemainDay', 'adPayStatus'],
    tableLabel: [
      'No.',
      '프로젝트',
      '광고명',
      '광고 영역',
      '구좌',
      '기간',
      '광고비',
      '승인단계',
      '운영단계',
      '광고비잔금',
      '남은 광고시간',
      '결제상태',
    ],
    tableValue: [
      'idx',
      'projectName',
      'adName',
      'area',
      'accountNum',
      'period',
      'price',
      'status',
      'adProcessStatus',
      'adRemainPrice',
      'adRemainHour',
      'adPayStatus',
    ],
  }

  projectAndAdOptions = {
    projectId: '',
    adId: '',
  }

  statusType = {
    status: ['', '작성중', '승인요청', '승인완료', '반려'],
    adProcessStatus: ['', '예정', '진행중', '취소', '완료', '삭제'],
    adPayStatus: ['', '결제 예약취소', '결제 예약완료', '결제완료', '결제실패', '결제완료', '결제완료', '정산완료'],
    cancelStatus: [
      '',
      '관리자 광고 진행중 취소',
      '관리자 광고 예정중 취소',
      '메이커의 심사중 광고취소',
      '메이커의 예정중 광고 취소',
      '시스템 취소',
      '반려-심사중 관리자가 광고 반려',
      '결제실패',
    ],
  }

  componentDidMount() {
    this.setState({ loadingBarFlag: true }, () => {
      Promise.all([this.getAllItem(true), this.getProjectSelectOption()]).then(() => {
        this.setState({ loadingBarFlag: false })
      })
    })
  }

  checkMakerHaveProject = (allItemTotalCount) => {
    if (this.state.allItemTotalCount === -1) {
      this.setState({ allItemTotalCount })
    }
  }

  // For Test Data
  getRandomArbitrary = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min)
  }

  getAllItem = (isInit) => {
    return new Promise((resolve, reject) => {
      const makerId = this.props.user.maker_id
      const { projectId, adId, start, size, projectSort, adSort } = this.state.selectedOption
      const items = []
      const noAdData = [
        {
          area: '-',
          accountNum: '-',
          startAt: '',
          endAt: '',
          price: '-',
          status: '-',
          adProcessStatus: '-',
          adRemainPrice: '-',
          adRemainDay: '-',
          adRemainHour: '-',
          adPayStatus: '-',
        },
      ]
      const url = `advertisement-pay-report/list?maker_id=${makerId}&project_id=${projectId}&advertisement_id=${adId}&start=${start}&size=${size}&project_sort=${projectSort}&advertisement_sort=${adSort}`
      network.callApi({
        url,
        method: 'get',
        header: { 'Content-Type': 'application/json;charset=UTF-8' },
        successFunc: (jsonData) => {
          const { result, totalCount } = jsonData
          // 맨 처음에 Maker가 광고를 가지고 있는지
          this.checkMakerHaveProject(totalCount)
          if (totalCount === 0) {
            // (선택적으로 뿌렸는데 해당 프로젝트에 광고가 없는 경우)
            this.setState({ itemList: [], totalCount, loadingBarFlag: false })
            return
          }
          for (let i = 0; i < result.length; i += 1) {
            const val = result[i]
            const idx = totalCount - ((start - 1) * size + i)
            if (val.creativeList.length === 0) {
              // 프로젝트는 있는데 광고가 없는 경우
              items.push({
                idx,
                projectName: val.projectName,
                adName: val.adName,
                // adCenterAdvertisementId: val.id,
                // 게제위치 x -> 링크 연결 X
                adCenterAdvertisementId: undefined,
                creativeList: noAdData,
              })
            } else if (val.creativeList.length > 0) {
              items.push({
                idx,
                projectName: val.projectName,
                adName: val.adName,
                adCenterAdvertisementId: val.id,
                creativeList: val.creativeList,
              })
            }
          }
          if (isInit) {
            this.setState({ itemList: items, totalCount, isInit })
            resolve()
          } else {
            this.setState({ itemList: items, totalCount })
            reject()
          }
        },
        failFunc: () => {
          console.error(`fail- ${url}`)
        },
      })
    })
  }

  getProjectSelectOption = () => {
    return new Promise((resolve, reject) => {
      // 선택 옵션에 프로젝트 리스트 불러오기
      const makerId = this.props.user.maker_id

      network.callApi({
        url: `projects/list?maker_id=${makerId}`,
        method: 'get',
        header: { 'Content-Type': 'application/json;charset=UTF-8' },
        successFunc: (jsonData) => {
          const { result } = jsonData
          const projects = []
          result.map((val) => projects.push([val.id, val.projectName]))
          this.setState({ projectNameOption: projects })
          resolve()
        },
        failFunc: (error) => {
          reject(error)
        },
      })
    })
  }

  getAdNameSelectOption = (e) => {
    // 선택 옵션에 광고명 리스트 불러오기
    const { selectedOption } = this.state
    const projectId = e
    if (projectId === '') {
      this.projectAndAdOptions = { projectId: '', adId: '' }
      this.setState({ adNameOption: [], selectedOption: { ...selectedOption, projectId: '', adId: '' } })
      return
    }

    const url = `advertisement/list?project_id=${projectId}`

    network.callApi({
      url,
      method: 'get',
      header: { 'Content-Type': 'application/json;charset=UTF-8' },
      successFunc: (jsonData) => {
        const { result } = jsonData
        const adNames = []
        result.map((val) => adNames.push([val.id, val.adName]))
        this.setState({ adNameOption: adNames })
      },
      failFunc: () => {
        console.error(`fail- ${url}`)
      },
    })
  }

  handleProjectNameSelect = (e) => {
    // 프로젝트 이름 선택  -> Ad List 가져오기
    this.projectAndAdOptions.adId = '' // default를 전체 선택으로 설정
    const projectId = e.target.value
    this.projectAndAdOptions.projectId = projectId
    this.getAdNameSelectOption(projectId)
  }

  handleAdNameSelect = (e) => {
    // AD 이름 선택
    const adId = e.target.value
    this.projectAndAdOptions.adId = adId
  }

  handleOptionSelectAndGetResult = () => {
    // 선택된 프로젝트, AD ID 에 맞는 정보 가져오기
    const { selectedOption } = this.state
    const { projectId, adId } = this.projectAndAdOptions
    this.setState(
      {
        selectedOption: {
          ...selectedOption,
          start: 1,
          projectId,
          adId,
        },
      },
      () => {
        this.getAllItem()
      }
    )
  }

  handlePageNumber = (start) => {
    // 선택한 page에 대한 정보 가져오기
    const { selectedOption } = this.state
    this.setState({ selectedOption: { ...selectedOption, start } }, () => {
      this.getAllItem()
    })
  }

  handleProjectNameSort = () => {
    // 프로젝트 이름 순 정렬
    const projectSort = this.state.selectedOption.projectSort === 0 ? 1 : 0
    const { selectedOption } = this.state
    this.setState(
      {
        selectedOption: {
          ...selectedOption,
          projectSort,
          adSort: 0,
          start: 1,
        },
      },
      () => {
        this.getAllItem()
      }
    )
  }

  handleAdNameSort = () => {
    // 광고 이름 순 정렬
    const adSort = this.state.selectedOption.adSort === 0 ? 1 : 0
    const { selectedOption } = this.state
    this.setState(
      {
        selectedOption: {
          ...selectedOption,
          adSort,
          projectSort: 0,
          start: 1,
        },
      },
      () => {
        this.getAllItem()
      }
    )
  }

  handleViewSizeSelect = (e) => {
    // 리스트에 뿌려줄 아이템 개수 선택
    const size = e.target.value
    const { selectedOption } = this.state
    this.setState({ selectedOption: { ...selectedOption, size, start: 1 } }, () => {
      this.getAllItem()
    })
  }

  handleGoToADDetail = (id) => {
    if (id) {
      const { state } = this
      sessionStorage.setItem(
        'report_cost',
        JSON.stringify({
          ...state,
        })
      )
      this.props.history.push(`/main/ad/report/cost/detail/${id}`)
    }
  }

  handleClickDetailPopUp = (id) => {
    const { statusType } = this
    this.props.openPopup({
      dialogType: 'reportCostDetail',
      dialogData: {
        id,
        statusType,
      },
    })
  }

  localeOrNull = (value) => {
    if (value || value === 0) {
      return value.toLocaleString()
    }
    return ''
  }

  handleExportExcel = () => {
    this.genExcelData()
  }

  genRow = (tableInfo, item) => {
    const { tableLabel, tableValue } = tableInfo
    const d = {}
    for (let i = 0; i < tableLabel.length; i += 1) {
      d[tableLabel[i]] = item[tableValue[i]]
    }

    return d
  }

  genExcelData = () => {
    const makerId = this.props.user.maker_id
    const { projectId, adId, size, projectSort, adSort } = this.state.selectedOption
    const items = []
    const noAdData = {
      area: '-',
      accountNum: '-',
      startAt: '',
      endAt: '',
      price: '-',
      status: '-',
      adProcessStatus: '-',
      adRemainPrice: '-',
      adRemainDay: '-',
      adPayStatus: '-',
    }

    const url = `advertisement-pay-report/list?maker_id=${makerId}&project_id=${projectId}&advertisement_id=${adId}&start=0&size=${this.state.allItemTotalCount}&project_sort=${projectSort}&advertisement_sort=${adSort}`
    network.callApi({
      url,
      method: 'get',
      header: { 'Content-Type': 'application/json;charset=UTF-8' },
      successFunc: (jsonData) => {
        const { result, totalCount } = jsonData
        for (let i = 0; i < result.length; i += 1) {
          const val = result[i]
          const { projectName, adName } = val
          const idx = totalCount - ((1 - 1) * size + i)
          if (val.creativeList.length === 0) {
            // 프로젝트는 있는데 광고가 없는 경우
            items.push(
              this.genRow(this.tableInfo, {
                ...noAdData,
                idx,
                adName,
                projectName,
              })
            )
          } else if (val.creativeList.length > 0) {
            for (let j = 0; j < val.creativeList.length; j += 1) {
              const {
                area,
                accountNum,
                price,
                startAt,
                endAt,
                status,
                adProcessStatus,
                adRemainPrice,
                adRemainDay,
                adRemainHour,
                adPayStatus,
              } = val.creativeList[j]
              const period = `${dateToStringAdPeriod(startAt)}~${dateToStringAdPeriod(endAt)}`
              if (j === 0) {
                const row = {
                  idx,
                  projectName: val.projectName,
                  adName: val.adName,
                  area,
                  accountNum,
                  period,
                  price,
                  status: this.statusType.status[status],
                  adProcessStatus: this.statusType.adProcessStatus[adProcessStatus],
                  adRemainPrice,
                  adRemainDay,
                  adRemainHour,
                  adPayStatus: this.statusType.adPayStatus[adPayStatus],
                }
                items.push(this.genRow(this.tableInfo, row))
              } else {
                const row = {
                  area,
                  accountNum,
                  period,
                  price,
                  status: this.statusType.status[status],
                  adProcessStatus: this.statusType.adProcessStatus[adProcessStatus],
                  adRemainPrice,
                  adRemainDay,
                  adRemainHour,
                  adPayStatus: this.statusType.adPayStatus[adPayStatus],
                }
                items.push(this.genRow(this.tableInfo, row))
              }
            }
          }
        }
        this.setState({ tableData: [items], isDownload: true, fileName: this.state.fileName }, () => {
          this.handleDownloaded()
        })
      },
      failFunc: () => {
        console.error(`fail- ${url}`)
      },
    })
  }

  handleDownloaded = () => {
    const isDownload = this.state.isDownload !== true
    this.setState({ isDownload })
  }

  handleToAdCreate = () => {
    // 광고 생성 페이지로 이동
    this.props.history.push('/main/ad/create/step1')
  }

  render() {
    const {
      state,
      handleProjectNameSelect,
      handleAdNameSelect,
      handleOptionSelectAndGetResult,
      handlePageNumber,
      handleProjectNameSort,
      handleAdNameSort,
      handleViewSizeSelect,
      handleGoToADDetail,
      handleClickDetailPopUp,
      handleExportExcel,
      handleToAdCreate,
      localeOrNull,
      statusType,
      navList,
    } = this

    const { fileName, tableData, isDownload, isInit, selectedOption, totalCount } = this.state

    return (
      <AdCostReportContent
        state={state}
        makerName={this.props.user.userData.noMaskingName}
        handleProjectNameSelect={handleProjectNameSelect}
        handleAdNameSelect={handleAdNameSelect}
        handleOptionSelectAndGetResult={handleOptionSelectAndGetResult}
        handlePageNumber={handlePageNumber}
        handleProjectNameSort={handleProjectNameSort}
        handleAdNameSort={handleAdNameSort}
        handleViewSizeSelect={handleViewSizeSelect}
        handleGoToADDetail={handleGoToADDetail}
        handleClickDetailPopUp={handleClickDetailPopUp}
        handleExportExcel={handleExportExcel}
        dateToString={dateToString}
        dateToStringAdPeriod={dateToStringAdPeriod}
        localeOrNull={localeOrNull}
        statusType={statusType}
        selectedOption={selectedOption}
        totalCount={totalCount}
        fileName={fileName}
        tableData={tableData}
        isDownload={isDownload}
        navList={navList}
        isInit={isInit}
        handleToAdCreate={handleToAdCreate}
      />
    )
  }
}

export default connect(({ user }) => ({ user }), { openPopup })(withRouter(AdResultReportPage))
