import withIcon, { Props } from './utils/withIcon'

function PersonIcon(props: Props) {
  return (
    <svg viewBox="0 0 40 40" {...props}>
      <path d="M20.27 3.46a9.42 9.42 0 1 0 0 18.84 9.42 9.42 0 1 0 0-18.84zm0 22.13c-6.52 0-15.19 1.09-15.19 10.57h30.38c0-9.48-8.67-10.57-15.19-10.57z" />
      <path fill="none" d="M0 0h40v40H0z" />
    </svg>
  )
}

export default withIcon('PersonIcon')(PersonIcon)
