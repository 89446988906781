import { createAction, handleActions } from 'redux-actions';

const SET_ERROR_PAGE = 'SET_ERROR_PAGE';

export const setError = createAction(SET_ERROR_PAGE);

const initState = {
  isError: false,
};

export default handleActions({
  [SET_ERROR_PAGE]: (state, action) => ({
    ...state,
    isError: action.payload,
  }),
}, initState);
