import React from 'react'

// Components
import Lnb from '../Lnb'
import NavBar from '../NavBar'

// import ExcelExportContainer from '../../containers/ExcelExportContainer/ExcelExportContainer'

// CSS module
import style from './AdCostReportContent.module.scss'
import className from 'classnames/bind'
const cx = className.bind(style)

const AdCostDetailContent = ({
  adName,
  projectName,
  allItemList,
  tableData,
  fileName,
  isDownload,
  navList,
  statusType,
  handleClickDetailPopUp,
  handleClickHistoryBack,
  handleClickFavorit,
  handleExportExcel,
  dateToStringCreatedDate,
  localeOrNull,
}) => {
  const isNull = (value) => {
    if (value === '' || value === null || value === undefined) {
      return true
    }
    return false
  }
  const isNullText = (value) => {
    if (value === '' || value === null || value === undefined) {
      return '-'
    }
    return value
  }

  return (
    <main className={cx('main_container', 'report')}>
      <Lnb type="report" />
      <div className={cx('adList_detail', 'content_wrap')}>
        <NavBar list={navList} />
        <hgroup>
          <h2>{adName}</h2>
          <p>{projectName}</p>
        </hgroup>
        <div className={cx('wz container table', 'cancellation')}>
          <div className={cx('wz table track')}>
            <table className={cx('ad_table', 'ad_table_cost_detail')}>
              <thead>
                <tr>
                  <th style={{ width: '50px' }}>No.</th>
                  <th style={{ width: '124px' }}>광고 영역</th>
                  <th style={{ width: '50px' }}>
                    광고<br></br>구좌
                  </th>
                  <th style={{ width: '90px' }}>광고기간</th>
                  <th style={{ width: '90px' }}>광고비</th>
                  <th style={{ width: '70px' }}>
                    광고<br></br>단계
                  </th>
                  <th style={{ width: '65px' }}>
                    결제<br></br>예약일
                  </th>
                  <th style={{ width: '65px' }}>
                    결제 예약<br></br>취소일
                  </th>
                  <th style={{ width: '65px' }}>결제일</th>
                  {/* <th style={{ width: '65px' }}>환불일</th> */}
                  {/* <th style={{ width: '90px' }}>환불액</th> */}
                  <th style={{ width: '65px' }}>반려일</th>
                  <th style={{ width: '65px' }}>
                    취소일<br></br>
                  </th>
                  {/* <th style={{ width: '90px' }}>광고<br></br>잔금</th> */}
                  {/* <th style={{ width: '50px' }}>남은<br></br>광고일</th> */}
                  <th style={{ width: '70px' }}>
                    남은<br></br>광고시간
                  </th>
                  <th style={{ width: '60px' }}>결제상태</th>
                </tr>
              </thead>
              <tbody>
                {allItemList.length > 0 &&
                  allItemList.map((val, idx) => (
                    <tr key={idx}>
                      <td>{allItemList.length - idx}</td>
                      <td>{val.area}</td>
                      <td>{val.accountNum}</td>
                      <td>
                        {val.startAt.slice(2, 10)}~<br></br>
                        {val.endAt.slice(2, 10)}
                      </td>
                      <td>{localeOrNull(val.price) !== '-' ? `${localeOrNull(val.price)}원` : '-'}</td>
                      <td>
                        {isNullText(
                          val.adProcessStatus !== 0
                            ? statusType.adProcessStatus[val.adProcessStatus]
                            : statusType.status[val.status]
                        )}
                      </td>
                      <td>
                        {isNullText(dateToStringCreatedDate(val.reservedPayDate)[0])}
                        <span className={cx('table_time')}>{dateToStringCreatedDate(val.reservedPayDate)[1]}</span>
                      </td>
                      <td>
                        {isNullText(dateToStringCreatedDate(val.reservedPayCancelDate)[0])}
                        <span className={cx('table_time')}>
                          {dateToStringCreatedDate(val.reservedPayCancelDate)[1]}
                        </span>
                      </td>
                      <td>
                        {isNullText(dateToStringCreatedDate(val.payDate)[0])}
                        <span className={cx('table_time')}>{dateToStringCreatedDate(val.payDate)[1]}</span>
                      </td>
                      {/* <td>
                        {isNullText(dateToStringCreatedDate(val.refundDate)[0])}
                        <span className={cx('table_time')}>{dateToStringCreatedDate(val.refundDate)[1]}</span>
                      </td> */}
                      {/* <td>{localeOrNull(val.refundPrice) !== '-' && val.refundDate !== undefined ? `${localeOrNull(val.refundPrice)}원` : '-'}</td> */}
                      <td>
                        {isNullText(dateToStringCreatedDate(val.rejectDate)[0])}
                        <span className={cx('table_time')}>{dateToStringCreatedDate(val.rejectDate)[1]}</span>
                      </td>
                      <td>
                        {isNullText(dateToStringCreatedDate(val.cancelDate)[0])}
                        <span className={cx('table_time')}>{dateToStringCreatedDate(val.cancelDate)[1]}</span>
                      </td>
                      {/* <td>{val.status === 1 ? '-' : localeOrNull(val.adRemainPrice) !== '-' ? `${localeOrNull(val.adRemainPrice)}원` : '-'}</td> */}
                      {/* <td>{val.status === 1 ? '-' : val.adRemainDay}</td> */}
                      <td>{val.status === 1 ? '-' : val.adRemainHour}</td>
                      <td
                        className={
                          !isNull(statusType.adPayStatus[val.adPayStatus]) && cx('point_primary', 'not_pointer')
                        }
                        // onClick={() => !isNull(statusType.adPayStatus[val.adPayStatus]) && handleClickDetailPopUp(val.id)}
                      >
                        {isNullText(statusType.adPayStatus[val.adPayStatus])}
                      </td>
                    </tr>
                  ))}
                {/* 데이터가 없는 경우 */}
                {allItemList.length === 0 && (
                  <tr>
                    <td colSpan="17">-</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* {allItemList.length !== 0 && (
            <div className={cx('ad_table_btnArea')}>
              <button onClick={handleExportExcel} className={cx('wz button')}>
                <i className={cx('icon download')}></i>엑셀 파일로 정보 다운로드
                <ExcelExportContainer fileName={fileName} tableData={tableData} isDownload={isDownload} />
              </button>
            </div>
          )} */}
        </div>
        <div className={cx('buttonArea')}>
          <button type="button" onClick={handleClickHistoryBack} className={cx('wz button less', 'button_historyBack')}>
            <i className={cx('icon chevron-left')} />
            이전
          </button>
          <button type="button" className={cx('wz button primary', 'button_320')} onClick={handleClickFavorit}>
            보고서 즐겨찾기
          </button>
        </div>
      </div>
    </main>
  )
}

export default AdCostDetailContent
