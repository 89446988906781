import semverSatisfies from 'semver/functions/satisfies';
import { getGlobalObject } from './misc';

const { navigator } = getGlobalObject<Window>();
const ua = navigator ? navigator.userAgent : '';

type BrowserInfo = {
  name: string | null;
  version: string | null;
};

// 브라우저 버전 정규식
const parseBrowserInfo = (browserName: string): BrowserInfo => {
  const exp = new RegExp(`(${browserName})([\\W\\w]+?)([0-9]+(\\.[0-9]+){1,2})`, 'i');
  const matched = exp.exec(ua);
  const name = (matched && matched[1]) || null;
  const version = (matched && matched[3]) || null;
  return {
    name,
    version,
  };
};

/**
 * iOS 환경
 */
export const isIos: boolean = /iP(hone|ad|od)\sOS/.test(ua);

/**
 * Android 환경
 */
export const isAndroid: boolean = /;\sAndroid/.test(ua);

/**
 * (iOS || Android) === 모바일
 */
export const isMobile: boolean = isIos || isAndroid;

// In-App 이름
const INAPP_NAME = {
  WADIZ: 'Wadiz',
  NAVER: 'NAVER',
  KAKAOTALK: 'KAKAOTALK',
  FACEBOOK: 'Facebook',
  WHALE: 'Whale',
  SAMSUNG: 'SamsungBrowser',
  LINE: 'LINE',
  SYRUPWALLET: 'SyrupWallet',
};

// In-App Browser
const inAppWadiz = 'ioswadiz|androidwadiz';
const inAppList = [
  inAppWadiz,
  INAPP_NAME.NAVER,
  'WorksMobile',
  INAPP_NAME.KAKAOTALK,
  'Twitter|Instagram|FBAN', // SNS
  ' Line', // LINE App
  INAPP_NAME.WHALE,
  INAPP_NAME.SAMSUNG,
  INAPP_NAME.SYRUPWALLET,
].join('|');
const inAppParsed = parseBrowserInfo(inAppList);

/**
 * 모바일 In-App 웹브라우저 이름
 */
export const inAppName = (isMobile && `${(inAppParsed.name) || ''}`
  .replace(new RegExp(inAppWadiz), INAPP_NAME.WADIZ) // Wadiz App
  .replace(/FBAN/i, INAPP_NAME.FACEBOOK) // Facebook
  .replace(/\sLine/i, INAPP_NAME.LINE) // LINE App
) || null;

/**
 * 모바일 In-App 웹브라우저 버전
 */
export const inAppVersion = (isMobile && inAppParsed.version) || null;

/**
 * 모바일 In-App 웹브라우저 이름과 버전
 */
export const inApp = [inAppName, inAppVersion].join(' ').replace(/^\s|\s$/g, '') || null;

/**
 * iOS 와디즈 앱 환경
 */
export const isWadizIosApp: boolean = isIos && /ioswadiz(\/([0-9.]+))?$/i.test(ua);

/**
 * Android 와디즈 앱 환경
 */
export const isWadizAndroidApp: boolean = isAndroid && /androidwadiz(\/([0-9.]+))?$/i.test(ua);

/**
 * 와디즈 모바일 앱 환경
 */
export const isWadizApp: boolean = isWadizIosApp || isWadizAndroidApp;

/**
 * 삼성인터넷(삼성브라우저) 환경
 */
export const isSamsungInternet = inAppName === INAPP_NAME.SAMSUNG;

/**
 * 네이버 모바일 앱 환경
 */
export const isNaverApp = inAppName === INAPP_NAME.NAVER;

/**
 * 모바일 카카오톡 앱 In-App 환경
 */
export const isKakaoTalkApp = inAppName === INAPP_NAME.KAKAOTALK;

// 네이버 웨일브라우저
const whaleBrowserParsed = parseBrowserInfo('Whale');

/**
 * 네이버 웨일 웹브라우저
 */
export const isWhale = !!whaleBrowserParsed.name;

/**
 * 모바일 웨일 브라우저
 */
export const isWhaleMobile: boolean = isWhale && inAppName === INAPP_NAME.WHALE;

// User-Agent에서 MSIE 버전을 가져온다.
const parseMSIE = /(MSIE)\s(\d+)/.exec(ua);

/**
 * Internet Exploter 10
 */
export const isMSIE10: boolean = (parseMSIE && parseInt(parseMSIE[2], 10) === 10 && ua.includes('Trident/6')) || false;

/**
 * Internet Exploter 11
 */
export const isMSIE11: boolean = ua.includes('Trident/7');

/**
 * Internet Explorer
 */
export const isMSIE = (!isMobile && (isMSIE10 || isMSIE11 || (parseMSIE && parseMSIE[1] === 'MSIE'))) || false;

/**
 * Edge browser
 */
const edgeBrowserParsed = parseBrowserInfo('Edge');
export const isEdge = !!edgeBrowserParsed.name;

/**
 * In-App의 버전을 비교
 * @param version Semantic Version
 */
export const isInAppVersion = (version: string) => {
  return semverSatisfies(inAppParsed.version!, version);
};

/**
 * 와디즈 앱의 버전을 비교
 * @param version Semantic Version
 */
export const isWadizAppVersion = (version: string) => {
  return isWadizApp && isInAppVersion(version);
};
