import { handleActions, createAction } from 'redux-actions';

const OPEN_SECOND_POPUP = 'secondDialog/OPEN_SECOND_POPUP';
const CLOSE_SECOND_POPUP = 'secondDialog/CLOSE_SECOND_POPUP';

export const openSecondPopup = createAction(OPEN_SECOND_POPUP);
export const closeSecondPopup = createAction(CLOSE_SECOND_POPUP);

const initState = {
  dialogType: '',
  dialogData: {},
};

export default handleActions({
  [OPEN_SECOND_POPUP]: (state, action) => {
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
    return { ...state, dialogType: action.payload.dialogType, dialogData: action.payload.dialogData };
  },
  [CLOSE_SECOND_POPUP]: () => {
    document.body.style.overflow = 'unset';
    return { initState };
  },
}, initState);
