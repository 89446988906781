import { createAction, handleActions } from 'redux-actions';

const OPEN_ALERT_POPUP = 'dialog/OPEN_ALERT_POPUP';
const CLOSE_ALERT_POPUP = 'dialog/CLOSE_ALERT_POPUP';

export const openAlertPopup = createAction(OPEN_ALERT_POPUP);
export const closeAlertPopup = createAction(CLOSE_ALERT_POPUP);

const initState = {
  alertFlag: false,
  alertData: {},
};

export default handleActions({
  [OPEN_ALERT_POPUP]: (state, action) => {
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
    return { ...state, alertFlag: true, alertData: action.payload.alertData };
  },
  [CLOSE_ALERT_POPUP]: () => {
    document.body.style.overflow = 'unset';
    return { initState };
  },
}, initState);
