import { network } from '../../../network';

const factoryPromise = (fx) => (coupon, isUsed) =>
  new Promise((resolve, reject) => {
    return network.callApi({
      url: `discount/${fx}`,
      method: 'POST',
      body: { id: coupon, isUsed },
      successFunc: (jsonData) => {
        resolve(jsonData);
      },
      failFunc: (error) => {
        reject(error);
      },
    });
  });
export const promiseDiscountUpdate = factoryPromise('update');
