import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { openAlertPopup, closeAlertPopup } from '../../reducers/alertReducer'
import { closePopup } from '../../reducers/dialogReducer'
import TimelineContainer from '../TimelineContainer/TimelineContainer'

import className from 'classnames/bind'
import style from './Dialog.module.scss'
import { toast } from 'react-toastify'
import { network } from '../../network'

const cx = className.bind(style)

let timer
class AdDate extends PureComponent {
  initState = {
    listData: [],
    startDate: {
      year: 0,
      month: 0,
      day: 0,
    },
    endDate: {
      year: 0,
      month: 0,
      day: 0,
    },
    startTime: '', // 초기 세팅 시간
    isShow: false,
    impPeriod: 0,

    alertIsShow: 0,
    isWadizAdmin: false,

    startTimelineDate: moment().subtract('month', 6),
    endTimelineDate: moment().add('month', 6),
    startVisibleTimelineDate: moment().startOf('day').add(-2, 'day'),
    endVisibleTimelineDate:
      document.body.offsetWidth <= 768
        ? moment().startOf('day').add(8, 'day').valueOf()
        : moment().startOf('day').add(18, 'day').valueOf(),

    items: [],
    groups: [],
    advertiserDivision: '',
  }

  state = this.initState

  static getDerivedStateFromProps = (nextProps, nextState) => {
    if (nextProps.dialog.dialogType === 'adDate') {
      if (nextState.isShow === true) {
        return { ...nextState }
      }

      const { data, openDate } = nextProps.dialog.dialogData

      const currentDate = new Date(openDate.replace(/\s/, 'T'))

      const endDate = new Date(currentDate)
      endDate.setDate(endDate.getDate() + data.impPeriod)

      return {
        ...nextState,
        isShow: true,
        impPeriod: data.impPeriod,
        startDate: {
          year: currentDate.getFullYear(),
          month: (currentDate.getMonth() + 1) / 10 >= 1 ? currentDate.getMonth() + 1 : `0${currentDate.getMonth() + 1}`,
          day: currentDate.getDate(),
        },
        endDate: {
          year: endDate.getFullYear(),
          month: (endDate.getMonth() + 1) / 10 >= 1 ? endDate.getMonth() + 1 : `0${endDate.getMonth() + 1}`,
          day: endDate.getDate(),
        },
      }
    }

    return { ...new AdDate().initState }
  }

  componentDidMount() {
    this.getAdTimelineData()
  }

  handleClose = () => {
    this.props.closePopup()
    this.setState({ ...this.initState })
  }

  handleConfirm = () => {
    const { startDate, endDate, startTime } = this.state
    const validation = this.handleCheckIsOverlapDate(startDate, endDate)
    validation.then((v) => {
      if (v === 1) {
        this.props.openAlertPopup({
          alertData: {
            title: '광고일 확인 안내',
            content: (
              <p>
                선택된 광고 기간 중에는
                <br />
                이미 예정 또는 진행 중인 광고가 있습니다.
                <br />
                <em className={cx('point_primary')}>광고 시작일을 변경해 주세요.</em>
              </p>
            ),
            closeText: '닫기',
          },
        })
      } else {
        // confirm function
        this.props.dialog.dialogData.confirmFunc(
          `${startDate.year}-${startDate.month}-${startDate.day}`,
          `${endDate.year}-${endDate.month}-${endDate.day}`,
          startTime
        )
        // close when success
        this.handleClose()
      }
    })
  }

  // 광고시작일 체크 함수
  handleChangeStartDate = (startDate) => {
    const { impPeriod } = this.state

    // 영업일 기준 2일 이후 체크
    const tempDate = new Date()
    tempDate.setDate(tempDate.getDate() + 1)

    let diff = new Date(startDate.year, startDate.month - 1, startDate.day).getTime() - tempDate.getTime()
    diff = Math.ceil(diff / (1000 * 3600 * 24))

    if (diff < -1) {
      toast('오늘 이후 일자를 선택해 주세요.')
      return
    }

    let endDate = new Date(startDate.year, startDate.month - 1, startDate.day)
    endDate.setDate(endDate.getDate() + impPeriod)
    endDate = {
      year: endDate.getFullYear(),
      month: (endDate.getMonth() + 1) / 10 >= 1 ? endDate.getMonth() + 1 : `0${endDate.getMonth() + 1}`,
      day: endDate.getDate(),
    }

    const validation = this.handleCheckIsOverlapDate(startDate, endDate)
    validation.then((v) => {
      if (v === 1) {
        this.props.openAlertPopup({
          alertData: {
            title: '광고 시작 안내',
            content: (
              <p>
                선택한 광고기간 중에는
                <br />
                이미 확정된 광고가 있습니다.
                <br />
                <em className={cx('point_primary')}>광고 시작일을 변경해 주세요.</em>
              </p>
            ),
            closeText: '닫기',
          },
        })
      } else {
        // if (diff >= -1 && diff <= 1) {
        //   this.props.openAlertPopup({
        //     alertData: {
        //       title: '설정한 광고 시작일이 매우 촉박합니다.',
        //       content: (
        //         <p><em className={cx('point_primary')}>{startDate.year}년 {startDate.month}월 {startDate.day}일</em>은 광고 시작일로 설정 가능하지만, 심사 일정을 고려하여 안정적인 광고 진행을 위해 <em className={cx('point_primary')}>광고 시작일을 오늘부터 3 영업일 이후로</em> 설정하기를 권장합니다.</p>
        //       ),
        //       confirmText: '예, 알겠습니다.',
        //       confirmFunc: () => {},
        //     },
        //   });
        // }
        this.setState({ startDate, endDate })
      }
    })
  }

  // 확정된 광고와 겹쳐지지 않는지 체크
  handleCheckIsOverlapDate = (startDate, endDate) => {
    const { data } = this.props.dialog.dialogData

    return new Promise((resolve, reject) => {
      const url = `creative/status/validation?advertiser_type=${this.props.user.isWadizAdmin ? 1 : 0}&start_date=${
        startDate.year
      }-${startDate.month}-${startDate.day} 00:00:00&end_date=${endDate.year}-${endDate.month}-${
        endDate.day
      } 00:00:00&pub_account_id=${data.adCenterPubAccountId}`
      network.callApi({
        url,
        method: 'GET',
        successFunc: ({ result }) => {
          if (result) {
            resolve(result.validation)
            return result.validation
          }
          return 0
        },
        failFunc: (error) => {
          reject(error)
        },
      })
    })
  }

  /* calendar functions start */
  getAdTimelineData = () => {
    // const url = 'adCondition/getDateFilter';
    const { data } = this.props.dialog.dialogData
    const startDate = moment().subtract('month', 6).format('YYYY-MM-DD')
    const endDate = moment().add('month', 6).format('YYYY-MM-DD')

    const url = `creative/status/list?advertiser_type=${
      this.props.user.isWadizAdmin ? 1 : 0
    }&start_date=${startDate}&end_date=${endDate}&pub_account_id=${data.adCenterPubAccountId}&pub_area_id=${
      data.adCenterPubAreaId
    }`

    network.callApi({
      url,
      method: 'get',
      successFunc: (jsonData) => {
        const { groups, items } = this.parseTimelineData(jsonData.result.creativeList)
        const { startTime } = jsonData.result
        this.setState({ groups, items, startTime })
      },
      failFunc: () => {
        console.error(`fail- ${url}`)
      },
    })
  }

  // 타임라인 기간 변경 이벤트
  onTimeChange = (start, end) => {
    if (!timer) {
      timer = setTimeout(() => {
        timer = null
        const { startTimelineDate, endTimelineDate } = this.state
        if (start < startTimelineDate || end < startTimelineDate) {
          this.setState(
            {
              startTimelineDate: startTimelineDate.subtract('month', 6),
            },
            () => {
              this.getAdTimelineData()
            }
          )
        } else if (start > endTimelineDate || end > endTimelineDate) {
          this.setState(
            {
              endTimelineDate: endTimelineDate.add('month', 6),
            },
            () => {
              this.getAdTimelineData()
            }
          )
        }
      }, 400)
    }
  }

  // 타임라인 데이터 parse
  parseTimelineData = (timelineData) => {
    const { adCenterPubAreaId, accountNum } = this.props.dialog.dialogData.data
    const groups = []
    const items = []
    const group = `${adCenterPubAreaId}_${accountNum}`

    groups.push({
      id: group,
      title: `구좌 ${accountNum}`,
    })

    groups.push({ id: 'ws1', title: 'white-space-one' })
    groups.push({ id: 'ws2', title: 'white-space-two' })
    groups.push({ id: 'ws3', title: 'white-space-three' })

    timelineData.forEach((account) => {
      const { id, adName, status, adProcessStatus, startAt, endAt, advertiserDivision } = account
      items.push({
        id,
        group,
        title: adName,
        start: moment(startAt).valueOf(),
        end: moment(endAt).valueOf(),
        className: 'ad-date',
        itemProps: {
          'data-tip': 'We need to override the cross-platform PCI alarm!',
        },
        adProcessStatus,
        status,
        advertiserDivision,
      })
    })

    if (groups.length !== 0) {
      groups[groups.length - 1].lastRow = 'ad-date-last-row'
    }

    return {
      groups,
      items,
    }
  }

  displayEndDate = () => {
    const { endDate } = this.state
    const displayEndDate = new Date(endDate.year, endDate.month - 1, endDate.day - 1)

    return `${String(displayEndDate.getFullYear()).slice(2)}-${
      (displayEndDate.getMonth() + 1) / 10 >= 1 ? displayEndDate.getMonth() + 1 : `0${displayEndDate.getMonth() + 1}`
    }-${displayEndDate.getDate() / 10 >= 1 ? displayEndDate.getDate() : `0${displayEndDate.getDate()}`}`
  }

  comma = (val) => {
    if (typeof val === 'string') return val.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if (typeof val === 'number') return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return val
  }

  /* calendar functions end */

  render() {
    this.displayEndDate()
    const { handleClose, handleChangeStartDate, handleConfirm, comma, onTimeChange } = this
    const { isShow, startDate, impPeriod, groups, items, startVisibleTimelineDate, endVisibleTimelineDate } = this.state
    const { dialogData } = this.props.dialog
    const { isWadizAdmin } = this.props.user

    return (
      isShow && (
        <>
          <div className={cx('dialog_shadow')} onClick={handleClose}></div>
          <div id={cx('adDate')} className={cx('dialog_wrap', 'typePopup')}>
            <header>
              <div className={cx('dialog_close')}>
                {/* Close Button Area */}
                <button className={cx('wz button less')} onClick={handleClose}>
                  <i className={cx('icon close')}></i>
                </button>
              </div>
              <div className={cx('dialog_title')}>
                {/* Title Area */}
                <h3>광고일자 선택</h3>
                <p>
                  <em className={cx('point_primary')}>
                    광고 시작일은 프로젝트 오픈 예정일의 1일 이후로 설정되어 있으며 변경할 수 있습니다.
                    <br />
                    안정적인 광고 서비스를 위해서는 오늘로부터 2 영업일 이후 광고 시작일을 설정
                  </em>
                  해 주세요.
                  <br></br>빠른 광고를 원하실 경우는{' '}
                  <a href="mailto:ad@wadiz.kr" className={cx('point_link_blue')}>
                    이 곳
                  </a>
                  으로 메일 주시면 답변 드리겠습니다.
                </p>
              </div>
            </header>
            <main className={cx('dialog_content', 'dialog_priod')}>
              <h4>
                {dialogData.data.positionName} - 구좌{dialogData.data.info.accountNum}{' '}
                <em>
                  광고상품 {impPeriod}일 <span className={cx('point_red')}>{comma(dialogData.data.price)}원</span>
                </em>
              </h4>
              <div className={cx('dialog_priod_select')}>
                <div>
                  <strong>광고시작일</strong>
                  <label className={cx('wz outline dropdown', 'dialog_priod_year', 'rpDropdown')}>
                    <select
                      value={startDate.year}
                      onChange={(e) => {
                        handleChangeStartDate({ ...this.state.startDate, year: Number(e.target.value) })
                      }}
                    >
                      {new Array(4).fill(0).map((val, idx) => (
                        <option key={idx} value={new Date().getFullYear() + idx}>
                          {new Date().getFullYear() + idx}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className={cx('wz outline dropdown', 'rpDropdown')}>
                    <select
                      value={startDate.month}
                      onChange={(e) => {
                        const { openDate } = this.props.dialog.dialogData
                        const minimumDate = new Date(openDate.replace(/\s/, 'T'))
                        const changeDate = new Date(this.state.startDate.year, e.target.value - 1, 1)
                        if (changeDate < minimumDate) {
                          handleChangeStartDate({
                            ...this.state.startDate,
                            month:
                              (minimumDate.getMonth() + 1) / 10 >= 1
                                ? `${minimumDate.getMonth() + 1}`
                                : `0${minimumDate.getMonth() + 1}`,
                            day: minimumDate.getDate(),
                          })
                        } else {
                          handleChangeStartDate({ ...this.state.startDate, month: e.target.value, day: 1 })
                        }
                      }}
                    >
                      {new Array(12).fill(0).map((val, idx) => (
                        <option key={idx} value={(idx + 1) / 10 >= 1 ? idx + 1 : `0${idx + 1}`}>
                          {(idx + 1) / 10 >= 1 ? idx + 1 : `0${idx + 1}`}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className={cx('wz outline dropdown', 'rpDropdown')}>
                    <select
                      value={startDate.day}
                      onChange={(e) => {
                        handleChangeStartDate({ ...this.state.startDate, day: Number(e.target.value) })
                      }}
                    >
                      {new Array(new Date(startDate.year, startDate.month, 0).getDate()).fill(0).map((val, idx) => (
                        <option key={idx} value={idx + 1}>
                          {(idx + 1) / 10 >= 1 ? idx + 1 : `0${idx + 1}`}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div>
                  <strong>광고기간</strong>
                  <div className={cx('wz input', 'dateInput')}>
                    <input
                      type="text"
                      value={`${String(startDate.year).slice(2)}-${startDate.month}-${
                        startDate.day / 10 >= 1 ? startDate.day : `0${startDate.day}`
                      } ~ ${this.displayEndDate()}`}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <h5>
                {dialogData.data.positionName} - 구좌{dialogData.data.info.accountNum} &gt; 광고 예약 현황{' '}
              </h5>
              {/* Content Area */}
              <TimelineContainer
                groups={groups}
                items={items}
                sidebarContent={null}
                sidebarWidth={0}
                startVisibleTimelineDate={startVisibleTimelineDate}
                endVisibleTimelineDate={endVisibleTimelineDate}
                keys={{
                  groupIdKey: 'id',
                  groupTitleKey: 'title',
                  groupRightTitleKey: 'title',
                  itemIdKey: 'id',
                  itemTitleKey: 'title',
                  itemDivTitleKey: 'title',
                  itemGroupKey: 'group',
                  itemTimeStartKey: 'start',
                  itemTimeEndKey: 'end',
                }}
                legend={
                  isWadizAdmin
                    ? [
                        { name: '승인요청', color: '#00b2b2' },
                        { name: '예정', color: '#f2ae00' },
                        { name: '진행중', color: '#4167d9' },
                        { name: '와디즈', color: '#60656a' },
                      ]
                    : [
                        { name: '승인요청', color: '#00b2b2' },
                        { name: '예정', color: '#f2ae00' },
                        { name: '진행중', color: '#4167d9' },
                      ]
                }
                targetPage={'DAILY_OVERVIEW_PAGE'}
                dataUpdate={onTimeChange}
                onClickAdBar={() => {
                  console.info('onClickAdBar')
                }}
              />
            </main>
            <footer className={cx('dialog_button')}>
              {/* Button Area */}
              <button className={cx('wz button')} onClick={handleClose}>
                취소
              </button>
              <button className={cx('wz button primary')} onClick={() => handleConfirm()}>
                등록
              </button>
            </footer>
          </div>
        </>
      )
    )
  }
}

export default connect(({ dialog, user, alert }) => ({ dialog, user, alert }), {
  closePopup,
  openAlertPopup,
  closeAlertPopup,
})(AdDate)
