import React from 'react';
import { useIsSmallScreen } from '../../../../containers';
import classNames from 'classnames/bind';
import style from './FootVisual.module.scss';
import { connect } from 'react-redux';
const cx = classNames.bind(style);

const FooterFloating = ({ children }) => {
  const [checkFooterFloating, setCheckFooterFloating] = React.useState(false); // 스크롤이 최상단 또는 최하단일 경우 플로팅 none
  React.useEffect(() => {
    const handleScroll = (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.srcElement.scrollingElement;
      const footerHeight = 157; // document.getElementById('footer').offsetHeight;

      setCheckFooterFloating(!(scrollTop + clientHeight + footerHeight > scrollHeight || scrollTop < 624));
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={cx('floatFooter', 'isDeskTop', { active: checkFooterFloating })}>
      { children}
    </div>
  );
};

function encodePrivateURL(url) {
  return url
    .replace(/[:]/ig, '%3A')
    .replace(/[/]/ig, '%2F')
    .replace(/[?]/ig, '%3F')
    .replace(/[=]/ig, '%3D');
}

const makeReturnURL = () => {
  const sessionCode = `${process.env.REACT_APP_WADIZ_APP_ROOT}web/session2token/session?returnURL=${window.location.origin}`;
  const enCode = encodePrivateURL(sessionCode);
  return `${process.env.REACT_APP_WADIZ_APP_ROOT}web/waccount/wAccountLogin?returnURL=${enCode}`;
};

const descTable = {
  no_authed: {
    mention: <>로그인하고<br className={cx('moBr')} /> 광고에 대해 더 알아보세요.</>,
    btnContent: '로그인 하기',
    btnLink: makeReturnURL(),
    message: '메이커님, 지금 로그인하고 광고를 자세히 알아보세요.'
  },
  no_project: {
    mention: <>프로젝트를 완성하고<br className={cx('moBr')} /> 어울리는 광고를 알아보세요</>,
    btnContent: '프로젝트 완성하기',
    btnLink: `${process.env.REACT_APP_WADIZ_PROJECT_OPEN}`,
    message: '메이커님, 프로젝트를 완성하고 광고를 알아보세요'
  },
  no_ad: {
    mention: <>광고를 통해<br className={cx('moBr')} /> 더 많은 서포터를 만나보세요.</>,
    btnContent: '광고 만들기 시작',
    btnLink: '/main/ad/list',
    message: '메이커님, 지금 가능한 광고 상품을 확인해보세요.'
  },
  ad: {
    mention: <>다양한 광고 상품이<br className={cx('moBr')} /> 기다리고 있어요.</>,
    btnContent: '광고 관리하기',
    btnLink: '/main/ad/list',
    message: '메이커님, 계속해서 광고를 진행해보세요.'
  },
};

const FootVisual = ({ isAuthed, userData, adStatus }) => {
  const { mention, btnContent, btnLink, message } = !isAuthed
    ? descTable.no_authed
    : adStatus === 'no_ad' || adStatus === 'ad'
      ? descTable[adStatus]
      : descTable.no_project;

  const floatMessage = !isAuthed ? message : `${userData.noMaskingName} ${message}`;
  const isSmall = useIsSmallScreen();
  return (
    <section className={cx('container')}>
      <p> 메이커님, <br className={cx('pcBr')} />{mention} </p>
      { !isSmall && (
        <button
          className={cx('btn')}
          onClick={() => { window.location.href = btnLink; }}
        > {btnContent} </button>)}

      <FooterFloating>
        <div className={cx('isDeskTop')}>
          <strong>{floatMessage}</strong>
          {!isSmall && (
            <button
              className={cx('btn', !isAuthed && 'login')}
              onClick={() => { window.location.href = btnLink; }}
            > {btnContent} </button>)}
        </div>
      </FooterFloating>
    </section>
  );
};

const mapStateToProps = state => {
  return {
    isAuthed: state.user.isAuthed,
    userData: state.user.userData,
    adStatus: state.adStatus.adStatus,
  };
};

export default connect(mapStateToProps)(FootVisual);
