import React, { Component } from 'react';

// css module
import styles from './Tooltip.module.scss';
import classNames from 'classnames/bind';

// Components
import Ballon from './Balloon';

const cx = classNames.bind(styles);

class Tooltip extends Component {
    state={
      isShow: false,
    }

    hideDropdown = () => {
      this.setState({ isShow: !this.state.isShow });
    }

    render() {
      const { state, props, hideDropdown } = this;
      const { children, title, text } = props;
      const { isShow } = state;
      return (
        <div className={cx('tooltip_box')}>
          <div className={cx('tooltip_btn')} onClick={hideDropdown}>
            { !children ? (
              <i className={cx('icon help-o')}></i>
            ) : (children) }
          </div>
          {
            isShow ? (
              <Ballon
                {...state}
                hideDropdown={hideDropdown}
                title={title}
                text={text}
              />
            ) : (null)
          }
        </div>
      );
    }
}
export default Tooltip;
