import React, { forwardRef } from 'react';
import cx from 'classnames';

import styles from './Input.module.scss';

export interface InputProps<T = HTMLInputElement> extends Omit<React.InputHTMLAttributes<T>, 'size' | 'status'> {

  /** Input 크기 */
  size?: 'sm' | 'md' | 'lg' | 'xl';

  /** Input 상태 */
  status?: 'error';
  
  className?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(({
  type = 'text',
  size = 'lg',
  status,
  className,
  ...props
}, ref) => {
  const classes = cx(
    styles.input,
    size && {
      [styles.sm]: size === 'sm',
      [styles.md]: size === 'md',
      [styles.lg]: size === 'lg',
      [styles.xl]: size === 'xl',
    },
    {
      [styles.error]: status === 'error',
    },
    className
  );
  return (
    <input
      {...props}
      type={type}
      className={classes}
      ref={ref}
      aria-invalid={status === 'error' ? "true" : "false"}
    />
  );
});

export default React.memo(Input);
