import React from 'react'
import { withRouter, useLocation } from 'react-router-dom-v5'
import { connect } from 'react-redux'
import { useCookies } from 'react-cookie'
import queryString from 'query-string'
import { fetchLoginApi } from '../reducers/userReducer'
import LoadingBar from '../components/LoadingBar/LoadingBar'

function encodePrivateURL(url) {
  return url.replace(/[:]/gi, '%3A').replace(/[/]/gi, '%2F').replace(/[?]/gi, '%3F').replace(/[=]/gi, '%3D')
}

const makeReturnURL = () => {
  const sessionCode = `${process.env.REACT_APP_WADIZ_APP_ROOT}web/session2token/session?returnURL=${window.location.origin}`
  const enCode = encodePrivateURL(sessionCode)
  return `${process.env.REACT_APP_WADIZ_APP_ROOT}web/waccount/wAccountLogin?returnURL=${enCode}`
}

export const TokenCheck = withRouter(({ history, setLoading, fetchLogin }) => {
  const [{ token: cookieToken }, setToken] = useCookies(['token'])
  const location = useLocation()

  React.useEffect(() => {
    const { search, pathname } = history.location
    const queryParam = queryString.parse(search)
    const { token: queryToken } = queryParam
    let token = queryToken
    // 쿼리에서 토큰 찾고 있으면 토큰넣기 (로그인된거)
    if (token) {
      setToken('token', token, { path: '/' })
    } else {
      token = cookieToken
    }
    // 토큰있으면 makerInfo API 호출, 토큰 없으면 login페이지로 이동
    if (token) {
      fetchLogin({ token }).then(
        () => {
          if (pathname === '/') {
            history.push('/main')
          }
          setLoading(false)
        },
        () => {
          history.push('/login')
          setLoading(false)
        }
      )
    } else {
      // /main 으로 온 경우 로그인된 사용자가 외부에서 온 것으로 회원팀과 합의
      if (location.pathname.indexOf('/main') === 0) {
        window.location.href = makeReturnURL()
      } else {
        history.push('/login')
        setLoading(false)
      }
    }
  }, [cookieToken, fetchLogin, history, setLoading, setToken, location])
  return <LoadingBar />
})

export default connect(({ user }) => ({ user }), { fetchLogin: fetchLoginApi })(TokenCheck)
