import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ConfigProvider } from 'antd'
import { BrowserRouter } from 'react-router-dom-v5'
// import * as Sentry from '@sentry/browser'
import { ToastWrapper, wadizLocale, antdTheme } from '@common/ui'
import RouteCenter from './router/RouteCenter'
import { HeadNotify, RootDialog, SecondDialog, NotificationPanel } from './containers'

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // onError: (error) => {
        //   Sentry.captureException(error)
        // },
      },
    },
  })

  return (
    <ConfigProvider locale={wadizLocale} theme={antdTheme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <HeadNotify />
          <ToastWrapper />
          <RouteCenter />
          <RootDialog />
          <SecondDialog />
          <NotificationPanel />
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={process.env.NODE_ENV === 'production'} />
      </QueryClientProvider>
    </ConfigProvider>
  )
}

export default App
