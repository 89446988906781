import { createAction, handleActions } from 'redux-actions';

const OPEN_POPUP = 'dialog/OPEN_POPUP';
const CLOSE_POPUP = 'dialog/CLOSE_POPUP';

export const openPopup = createAction(OPEN_POPUP);
export const closePopup = createAction(CLOSE_POPUP);

const initState = {
  dialogType: '',
  dialogData: {},
};

export default handleActions({
  [OPEN_POPUP]: (state, action) => {
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
    return { ...state, dialogType: action.payload.dialogType, dialogData: action.payload.dialogData };
  },
  [CLOSE_POPUP]: () => {
    document.body.style.overflow = 'unset';
    return { initState };
  },
}, initState);
