import React, { PureComponent } from 'react'

// Components
import Timeline from '../../components/Timeline'
import AdBar from '../../components/Timeline/AdBar'

// Plugin
import moment from 'moment'

class TimelineContainer extends PureComponent {
  constructor(props) {
    super(props)

    let { startVisibleTimelineDate, endVisibleTimelineDate } = props

    if (!startVisibleTimelineDate || !endVisibleTimelineDate) {
      startVisibleTimelineDate = moment().startOf('day').add(-2, 'day').valueOf()
      endVisibleTimelineDate =
        document.body.offsetWidth <= 768
          ? moment().startOf('day').add(8, 'day').valueOf()
          : moment().startOf('day').add(18, 'day').valueOf()
    } else {
      startVisibleTimelineDate = startVisibleTimelineDate.valueOf()
      endVisibleTimelineDate = endVisibleTimelineDate.valueOf()
    }

    this.state = {
      visibleTimeStart: startVisibleTimelineDate,
      visibleTimeEnd: endVisibleTimelineDate,
      scrolling: true,
    }
  }

  // 타임라인 캘린더 한달 전으로 이동
  onPrevClick = () => {
    const { visibleTimeStart } = this.state
    const newVisibleStartTime = moment(visibleTimeStart).subtract(1, 'months').set('date', 1)
    const newVisibleEndTime =
      document.body.offsetWidth <= 768
        ? moment(newVisibleStartTime).startOf('day').add(10, 'day')
        : moment(newVisibleStartTime).startOf('day').add(20, 'day')

    this.setState(
      {
        visibleTimeStart: newVisibleStartTime.valueOf(),
        visibleTimeEnd: newVisibleEndTime.valueOf(),
        scrolling: false,
      },
      () => {
        const { dataUpdate } = this.props
        if (dataUpdate) {
          dataUpdate(newVisibleStartTime, newVisibleEndTime)
        }
      }
    )
  }

  // 타임라인 캘린더 한달 뒤로 이동
  onNextClick = () => {
    const { visibleTimeStart } = this.state
    const newVisibleStartTime = moment(visibleTimeStart).add(1, 'months').set('date', 1)
    const newVisibleEndTime =
      document.body.offsetWidth <= 768
        ? moment(newVisibleStartTime).startOf('day').add(10, 'day')
        : moment(newVisibleStartTime).startOf('day').add(20, 'day')

    this.setState(
      {
        visibleTimeStart: newVisibleStartTime.valueOf(),
        visibleTimeEnd: newVisibleEndTime.valueOf(),
        scrolling: false,
      },
      () => {
        const { dataUpdate } = this.props
        if (dataUpdate) {
          dataUpdate(newVisibleStartTime, newVisibleEndTime)
        }
      }
    )
  }

  // 타임라인 날짜 변경 이벤트
  onTimeChange = (visibleTimeStart, visibleTimeEnd) => {
    this.setState(
      {
        visibleTimeStart,
        visibleTimeEnd,
        scrolling: true,
      },
      () => {
        const { dataUpdate } = this.props
        if (dataUpdate) {
          this.props.dataUpdate(moment(visibleTimeStart), moment(visibleTimeEnd))
        }
      }
    )
  }

  // 타임라인 Item 클릭 이벤트
  barClick = (item) => {
    const { visibleTimeStart, visibleTimeEnd } = this.state
    this.props.onClickAdBar(item, moment(visibleTimeStart), moment(visibleTimeEnd))
  }

  // Item 렌더 함수
  itemRenderer = (props) => <AdBar {...props} targetPage={this.props.targetPage} onClickAdBar={this.barClick} />

  // Group 렌더 함수
  groupRenderer = ({ group }) => {
    return <div className={`inner-row ${group.lastRow ? group.lastRow : ''}`}>{group.title}</div>
  }

  // Todo 오늘 날짜 표시를 위한 함수
  verticalLineClassNamesForTime = (timeStart) => {
    const classArr = []
    const start = moment(timeStart).format('YYYY-MM-DD')
    const today = moment().format('YYYY-MM-DD')

    if (start === today) {
      classArr.push('today')
    }
    return classArr
  }

  render() {
    const { visibleTimeStart, visibleTimeEnd, scrolling } = this.state
    const { itemRenderer, groupRenderer, onPrevClick, onNextClick, onTimeChange, verticalLineClassNamesForTime } = this

    const { sidebarWidth, keys, legend = [] } = this.props

    const { items, groups } = this.props

    // items 또는 groups 배열 갯수가 0개 일 경우 타임라인 레이아웃 깨짐 방지를 위한 코드
    if (items.length === 0) {
      let targetId
      if (groups.length === 0) {
        groups.push({
          id: 1,
          title: 'no data',
        })
        targetId = 1
      } else {
        targetId = groups[0].id
      }
      items.push({
        id: 1,
        group: targetId,
        title: 'no data',
        start: moment().valueOf(),
        end: moment().add(10, 'day').valueOf(),
        className: 'hide',
        itemProps: {
          'data-tip': 'We need to override the cross-platform PCI alarm!',
        },
      })
    }

    const defaultTimeRange = visibleTimeEnd - visibleTimeStart
    const currentMonth = moment(visibleTimeStart).format('YYYY.MM.DD').split('.')
    const currentMonthText = `${currentMonth[0]}년 ${currentMonth[1]}월`

    return (
      <Timeline
        scrollRef={(el) => {
          this.scrollRef = el
        }}
        groups={groups}
        items={items}
        keys={keys}
        sidebarWidth={sidebarWidth}
        canMove
        canResize="right"
        canSelect={false}
        itemsSorted
        itemTouchSendsClick={false}
        stackItems
        itemHeightRatio={0.8}
        showCursorLine
        visibleTimeStart={visibleTimeStart}
        visibleTimeEnd={visibleTimeEnd}
        itemRenderer={itemRenderer}
        groupRenderer={groupRenderer}
        horizontalLineClassNamesForGroup={(group) => {
          return group.lastRow ? [group.lastRow] : ''
        }}
        verticalLineClassNamesForTime={verticalLineClassNamesForTime}
        timeSteps={{
          day: 1,
          month: 1,
          year: 1,
        }}
        minZoom={defaultTimeRange}
        maxZoom={defaultTimeRange}
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        onTimeChange={onTimeChange}
        scrolling={scrolling}
        currentMonthText={currentMonthText}
        legend={legend}
      />
    )
  }
}

export default TimelineContainer
