import { PLUGINS, POPUP_TEMPLATES, RegisterCommand } from 'froala-editor'
import type FroalaEditor from 'froala-editor'
import type { WYSIWYGEditor } from 'froala-editor'
import { invert } from 'lodash-es'

const COMMAND = {
  quoteDefault: 'type-default',
  quoteLine: 'type-line',
}

RegisterCommand('quote', {
  title: '인용',
  icon: 'quote',
  plugin: 'quote',
  refreshAfterCallback: false,
  callback: function () {
    const { quote } = this as unknown as WYSIWYGEditor
    quote.show()
  },
  refresh: function () {
    const { quote } = this as unknown as WYSIWYGEditor
    quote.refresh()
  },
})

RegisterCommand('quoteDefault', {
  title: '기본형',
  icon: 'quoteDefault',
  callback() {
    const { quote } = this as unknown as WYSIWYGEditor

    quote.insert(COMMAND.quoteDefault)
    quote.hide()
  },
})

RegisterCommand('quoteLine', {
  title: '라인형',
  icon: 'quoteLine',
  callback() {
    const { quote } = this as unknown as WYSIWYGEditor

    quote.insert(COMMAND.quoteLine)
    quote.hide()
  },
})

RegisterCommand('unquote', {
  title: '초기화',
  icon: 'unquote',
  callback() {
    const { quote } = this as unknown as WYSIWYGEditor

    quote.unquote()
    quote.hide()
  },
})

Object.assign(POPUP_TEMPLATES, { 'quote.popup': '[_BUTTONS_]' })

PLUGINS.quote = function (editor: FroalaEditor) {
  return {
    _init: () => {
      const { button, opts: options, popups } = editor as unknown as WYSIWYGEditor

      const templateProperties = {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        buttons: `<div class="fr-buttons">${button.buildList(options.quoteButtons as never)}</div>`,
      }

      return popups.create('quote.popup', templateProperties)
    },

    show: () => {
      const { $tb, popups } = editor

      if ($tb.find('[data-cmd="quote"]').length === 0) {
        return
      }

      const { scrollTop } = document.documentElement
      const { left: leftOffset, top, height } = $tb.find('[data-cmd="quote"]')[0].getBoundingClientRect()
      const topOffset = scrollTop + top + height - 10

      popups.setContainer('quote.popup', $tb)
      popups.show('quote.popup', leftOffset, topOffset, height, false)
    },

    hide: () => {
      const { popups } = editor
      popups.hide('quote.popup')
    },

    insert: (className: string) => {
      const { $, selection } = editor as unknown as WYSIWYGEditor
      const blockquoteElement = document.createElement('blockquote')

      selection.blocks([]).forEach((element: Element, index: number) => {
        let blockquote: HTMLQuoteElement | null = null

        if (element.tagName.toLowerCase() === 'blockquote') {
          blockquote = element as HTMLQuoteElement
        } else if (element.firstElementChild?.tagName.toLowerCase() === 'blockquote') {
          blockquote = element.firstElementChild as HTMLQuoteElement
        } else if (element.closest('blockquote')) {
          blockquote = element.closest('blockquote')
        }

        if (blockquote) {
          blockquote.className = className
        } else if (element.tagName.toLowerCase() === 'td') {
          const blockquote = document.createElement('blockquote')
          blockquote.className = className
          blockquote.innerText = selection.text() || '내용을 입력해 주세요.'

          element.append(blockquote)
        } else if (['h1', 'h2', 'h3', 'h4', 'h5', 'p', 'li'].includes(element.tagName.toLowerCase())) {
          const paragraphFormatElement = document.createElement(
            element.tagName.toLowerCase() === 'li' ? 'p' : element.tagName.toLowerCase()
          )

          if (index === selection.blocks([]).length - 1 && blockquoteElement.childElementCount === 0) {
            paragraphFormatElement.innerText = selection.text() || (element as HTMLElement).innerText
          } else {
            paragraphFormatElement.innerText = (element as HTMLElement).innerText
          }

          blockquoteElement.className = className
          blockquoteElement.append(paragraphFormatElement)

          if (element.parentElement === null) {
            return
          }

          if (element.tagName.toLowerCase() === 'li' || element.closest('li')) {
            if ($(element).parents('ol').length === 0) {
              $(blockquoteElement).insertAfter($(element).parents('ul').last())
            } else if ($(element).parents('ul').length === 0) {
              $(blockquoteElement).insertAfter($(element).parents('ol').last())
            } else {
              if ($(element).parents('ol').last().parents('ul').length === 0) {
                $(blockquoteElement).insertAfter($(element).parents('ol').last())
              } else {
                $(blockquoteElement).insertAfter($(element).parents('ul').last())
              }
            }
          } else {
            if (element.nextSibling) {
              element.parentElement.insertBefore(blockquoteElement, element.nextSibling)
            } else {
              element.parentElement.append(blockquoteElement)
            }
          }

          if (index === selection.blocks([]).length - 1) {
            if (selection.text()) {
              selection.remove()
            } else {
              element.remove()
            }
          }
        }
      })
    },

    unquote: () => {
      const { selection } = editor

      selection.blocks([]).forEach((element: Element) => {
        let blockquote: HTMLQuoteElement | null = null

        if (element.tagName.toLowerCase() === 'blockquote') {
          blockquote = element as HTMLQuoteElement
        } else if (element.firstElementChild?.tagName.toLowerCase() === 'blockquote') {
          blockquote = element.firstElementChild as HTMLQuoteElement
        } else if (element.closest('blockquote')) {
          blockquote = element.closest('blockquote')
        }

        if (blockquote) {
          blockquote.outerHTML = blockquote.innerHTML
        }
      })
    },

    refresh: () => {
      const { $tb, selection } = editor

      $tb.find('[data-cmd*="quote"]').removeClass('fr-active').attr('aria-selected', 'false')

      selection.blocks([]).forEach((element: Element) => {
        let blockquote: HTMLQuoteElement | null = null

        if (element.tagName.toLowerCase() === 'blockquote') {
          blockquote = element as HTMLQuoteElement
        } else if (element.firstElementChild?.tagName.toLowerCase() === 'blockquote') {
          blockquote = element.firstElementChild as HTMLQuoteElement
        } else if (element.closest('blockquote')) {
          blockquote = element.closest('blockquote')
        }

        if (blockquote) {
          $tb
            .find('[data-cmd="quote"]')
            .add($tb.find(`[data-cmd="${invert(COMMAND)[blockquote.className]}"]`))
            .addClass('fr-active')
            .attr('aria-selected', 'true')
        }
      })
    },
  }
}
