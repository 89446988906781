import { createAction, handleActions } from 'redux-actions';

const SET_MENU_STATUS = 'menu/SET_MENU_STATUS';

export const setMenuStatus = createAction(SET_MENU_STATUS);


const initState = {
  status: false,
};

export default handleActions({
  [SET_MENU_STATUS]: (state, action) => {
    if (action.payload) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    } else {
      document.body.style.overflow = 'unset';
    }

    return { ...state, status: action.payload };
  },
}, initState);
