import { createAction, handleActions } from 'redux-actions'

const RESET_ACTIVATED_AD_INFO = 'adlist/GET_ACTIVATED_AD_INFO'
const SET_ACTIVATED_AD_INFO = 'adlist/SET_ACTIVATED_AD_INFO'

export const resetActivatedAdInfo = createAction(RESET_ACTIVATED_AD_INFO)
export const setActivatedAdInfo = createAction(SET_ACTIVATED_AD_INFO)

const initState = {
  activatedProjectId: 0,
  activatedAdId: 0,
  activatedCampaignId: 0,
}

export default handleActions(
  {
    [SET_ACTIVATED_AD_INFO]: (state, action) => {
      return {
        ...state,
        activatedProjectId: action.payload.projectId,
        activatedAdId: action.payload.adId,
        activatedCampaignId: action.payload.campaignId,
      }
    },
    [RESET_ACTIVATED_AD_INFO]: (state) => {
      return {
        ...state,
        activatedProjectId: 0,
        activatedAdId: 0,
        activatedCampaignId: 0,
      }
    },
  },
  initState
)
