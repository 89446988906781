const superUserList = [
  'ad@wadiz.kr',
  'wadizcontents@wadiz.kr',
  /* 카테고리그룹1 */ 'team.category-group1@wadiz.kr',
  /* 카테고리그룹2 */ 'team.category-group2@wadiz.kr',
  /* 성공지원     */ 'team.mss@wadiz.kr',
  /* 예이커PM    */ 'team.makerpm@wadiz.kr',
  /* 파드너관리   */ 'team.partner-manage@wadiz.kr',
  /* 파트너스     */ 'team.wadiz-partners@wadiz.kr',
  /* ^5048      */ 'team.store@wadiz.kr',
  /* 메이커관리    */ 'team.maker_manage@wadiz.kr',
  /* 마케딩       */ 'team.marketing@wadiz.kr',
  /* 와디즈엑스    */ 'wadiz.bic@wadiz.kr',
  /* 카테고리 PD3팀*/ 'team.pd3@wadiz.kr',
]
export const isSuperUser = (email: string) => superUserList.includes(email)
