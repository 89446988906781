import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons'

import { network } from '../../network';

import { closePopup } from '../../reducers/dialogReducer';
import { openAlertPopup } from '../../reducers/alertReducer';

import { PayConfirm, SimplePaySelector, AgreementList } from './components';

import className from 'classnames/bind';
import style from './Dialog.module.scss';
const cx = className.bind(style);
class CreditCardDetailDialog extends PureComponent {
  state = {
    isLoading: true,
    isShow: false,
    isSimplePayment: true,
    isPaying: false,
    price: 0,
    // cardNumberArray: [
    // ],
    cardNumber: '',
    paymentInfo: {
      adCenterAdvertisementId: 0,
      adCenterMakerId: 0,
      cardExpirationMonth: '01',
      cardExpirationYear: String(new Date().getFullYear()).slice(-2),
      cardIdNo: '',
      cardNumber: '',
      cardPasswd: '',
      cardSaveYn: 0,
      payYn: 0,
      thirdYn: 0,
      type: 0,
      creativeIdList: [],
    },
    simplePaymentCardArray: [],
    accountList: [],
    inChecking: true,
  };

  static getDerivedStateFromProps = (nextProps) => {
    if (nextProps.dialog.dialogType === 'creditCardDetailDialog2') {
      return { isShow: true };
    }

    return null;
  };

  componentDidMount() {
    const paymentInfo = { ...this.state.paymentInfo };
    let simplePaymentCardArray = [...this.state.simplePaymentCardArray];

    network.callApi({
      url: `pay-info/list?maker_id=${this.props.user.maker_id}`,
      method: 'get',
      successFunc: (jsonData) => {
        let isSimplePayment = false;
        if (jsonData.result.length === 0) {
          isSimplePayment = false;
        } else {
          isSimplePayment = true;
          simplePaymentCardArray = jsonData.result;
        }
        paymentInfo.adCenterMakerId = this.props.user.maker_id;
        paymentInfo.adCenterAdvertisementId = this.props.dialog.dialogData.advertisementId;

        const accountList = [...this.props.dialog.dialogData.accountList];

        this.setState({
          isSimplePayment,
          paymentInfo,
          simplePaymentCardArray,
          price: this.props.dialog.dialogData.price,
          accountList,
          isLoading: false,
        });
      },
      failFunc: () => {
        this.setState({ isLoading: false });
      },
    });
  }

  handleClose = () => {
    this.props.closePopup();
    this.setState({
      isShow: false,
    });
  };

  handleConfirm = () => {
    this.setState({ isPaying: true }, () => {
      this.doConfirm(() => {
        this.setState({ isPaying: false });
      });
    });
  };

  doConfirm = (waitClose) => {
    const paymentInfo = {
      ...this.state.paymentInfo,
      creativeIdList: this.state.accountList.map((account) => account.id),
    };

    if (this.checkValidation()) {
      network.callApi({
        url: 'reserved-payment/save',
        method: 'post',
        body: paymentInfo,
        successFunc: (jsonData) => {
          if (jsonData.result !== 'success' && jsonData.result !== 'exist') {
            toast(jsonData.message);
          }
          this.props.dialog.dialogData.confirmFunc(jsonData.result);
          this.handleClose();
          waitClose();
        },
        failFunc: () => {
          waitClose();
        },
      });
    } else {
      waitClose();
      toast('결제카드 번호와 비밀번호를 확인해주세요');
    }
  };

  handleChangeCardIdNo = (event) => {
    // handle change expiration year
    const paymentInfo = { ...this.state.paymentInfo };
    paymentInfo.cardIdNo = String(event.target.value);

    let { value } = event.target;

    if (value.length >= 10) {
      value = String(value).substring(0, 10);
    }
    this.setState({ paymentInfo });
  };

  handleChangeCardNumber = (event) => {
    // const cardNumberArray = [...this.state.cardNumberArray];
    let cardNumber = [...this.state.cardNumber];
    const paymentInfo = { ...this.state.paymentInfo };
    // handle change card number
    let { value } = event.target;
    const {
      validity: { valid },
    } = event.target;

    if (!valid) {
      return;
    }

    if (value.length > 16) {
      value = String(value).slice(0, 16);
    }
    cardNumber = String(value);
    if (cardNumber.length > 0) {
      paymentInfo.cardNumber = cardNumber;
    }
    // }
    this.setState({ paymentInfo, cardNumber });
  };

  handleChangeExpirationMonth = (event) => {
    // handle change expiration month
    const paymentInfo = { ...this.state.paymentInfo };
    paymentInfo.cardExpirationMonth = event.target.value;
    this.setState({ paymentInfo });
  };

  handleChangeExpirationYear = (event) => {
    // handle change expiration year
    const paymentInfo = { ...this.state.paymentInfo };
    paymentInfo.cardExpirationYear = String(event.target.value).substring(2, 4);
    this.setState({ paymentInfo });
  };

  handleChangePayAgreement = (event) => {
    // handle change pay agreement check
    const paymentInfo = { ...this.state.paymentInfo };
    if (event.target.checked) {
      paymentInfo.payYn = 1;
    } else {
      paymentInfo.payYn = 0;
    }
    this.setState({ paymentInfo });
  };

  handleChangeAgreement = (event) => {
    // handle change agreement check
    const paymentInfo = { ...this.state.paymentInfo };
    if (event.target.checked) {
      paymentInfo.cardSaveYn = 1;
    } else {
      paymentInfo.cardSaveYn = 0;
    }
    this.setState({ paymentInfo });
  };

  handleChangeThirdAgreement = (event) => {
    // handle change agreement check
    const paymentInfo = { ...this.state.paymentInfo };
    if (event.target.checked) {
      paymentInfo.thirdYn = 1;
    } else {
      paymentInfo.thirdYn = 0;
    }
    this.setState({ paymentInfo });
  };

  handleChangePassword = (event) => {
    // handle change expiration year
    const paymentInfo = { ...this.state.paymentInfo };
    paymentInfo.cardPasswd = String(event.target.value);

    let { value } = event.target;

    if (value.length >= 2) {
      value = String(value).substring(0, 2);
      paymentInfo.cardPasswd = value;
    }
    this.setState({ paymentInfo });
  };

  handleChangeSimplePaymentInfo = (event) => {
    const simplePaymentCardArray = [...this.state.simplePaymentCardArray];
    let paymentInfo = { ...this.state.paymentInfo };
    const { value } = event.target;
    // handle change simple paymentInfo
    if (value === '-1') {
      const paymentInfo = {
        adCenterAdvertisementId: 0,
        adCenterMakerId: 0,
        cardExpirationMonth: '01',
        cardExpirationYear: String(new Date().getFullYear()).slice(-2),
        cardIdNo: '',
        cardNumber: '',
        cardPasswd: '',
        cardSaveYn: 0,
        payYn: 0,
        type: 0,
        creativeIdList: [],
      };
      this.setState({ paymentInfo });
      return;
    }
    for (let i = 0; i < simplePaymentCardArray.length; i += 1) {
      if (simplePaymentCardArray[i].id === parseInt(value, 10)) {
        paymentInfo = simplePaymentCardArray[i];
        // 임시 광고ID 추가
        paymentInfo.adCenterAdvertisementId = this.props.dialog.dialogData.advertisementId;
        paymentInfo.type = 1;
        paymentInfo.payYn = 1;
        paymentInfo.thirdYn = 1;
        break;
      }
    }
    this.setState({ paymentInfo });
  };

  addNewCardInfo = () => {
    let paymentInfo = { ...this.state.paymentInfo };
    paymentInfo = {
      ...paymentInfo,
      cardExpirationMonth: '01',
      cardExpirationYear: String(new Date().getFullYear()).slice(-2),
      cardIdNo: '',
      cardName: '',
      cardNumber: '',
      cardPasswd: '',
      cardSaveYn: 0,
      payYn: 0,
      type: 0,
    };
    this.setState({
      isSimplePayment: false,
      paymentInfo,
    });
  };

  comma = (val) => {
    if (typeof val === 'string') return val.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (typeof val === 'number') return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return val;
  };

  checkValidation = () => {
    const paymentInfo = { ...this.state.paymentInfo };
    let validationResult = true;
    const regex = /[^0-9]/g;
    if (!this.state.isSimplePayment) {
      if (paymentInfo.adCenterMakerId === '') {
        validationResult = false;
        return validationResult;
      }
      if (paymentInfo.adCenterAdvertisementId === '') {
        validationResult = false;
        return validationResult;
      }
      if (!(paymentInfo.cardIdNo.length === 6 || paymentInfo.cardIdNo.length === 10)) {
        validationResult = false;
        return validationResult;
      }
      if (paymentInfo.cardNumber.length < 15) {
        validationResult = false;
        return validationResult;
      }
      if (regex.test(paymentInfo.cardPasswd) || paymentInfo.cardPasswd === '' || paymentInfo.cardPasswd.length !== 2) {
        validationResult = false;
        return validationResult;
      }
    }
    return validationResult;
  };

  render() {
    const {
      handleClose,
      handleConfirm,
      handleChangeCardNumber,
      handleChangeExpirationMonth,
      handleChangeExpirationYear,
      handleChangePayAgreement,
      handleChangeAgreement,
      handleChangeThirdAgreement,
      handleChangeSimplePaymentInfo,
      comma,
      addNewCardInfo,
    } = this;

    const { dialogData } = this.props.dialog;
    const { isShow, isSimplePayment, price, simplePaymentCardArray, paymentInfo, isLoading, isPaying } = this.state;

    return (
      !isLoading &&
      isShow && (
        <div className={cx('dialog_shadow')}>
          <div className={cx('dialog_wrap', 'typePopup')}>
            <header>
              <div className={cx('dialog_close')}>
                {/* Close Button Area */}
                <button className={cx('wz button less')} onClick={handleClose}>
                  <i className={cx('icon close')}></i>
                </button>
              </div>
              <div className={cx('dialog_title')}>
                {/* Title Area */}
                <h3>{dialogData.title}</h3>
              </div>
            </header>
            {/* Content Area */}
            <main className={cx('dialog_content', 'creditCard')}>
              <div className={cx('viewPay_total')}>
                <h3>
                  <span>결제 예약 금액</span> <strong className={cx('point_red')}>{comma(price)}원</strong>
                </h3>
              </div>
              {!isSimplePayment ? (
                <>
                  <ul className={cx('creditCardInfo')}>
                    <li className={cx('creditCardIdNo')}>
                      <label>주민번호 앞 6자리 또는 사업자 번호
                        <Tooltip
                          title={
                            <>
                              - 일반 개인 카드일 경우, 주민번호 앞 6자리 기재<br />
                              - 법인 카드일 경우, 사업자 번호 기재<br />
                              - 단, 법인 카드 명의가 개인일 경우, 주민번호 앞 6자리 기재
                            </>
                          }
                          placement="bottom"
                          overlayStyle={{ width: 250 }}
                          trigger="hover">
                          <QuestionCircleOutlined
                            style={{ marginLeft: 8 }} />
                        </Tooltip>
                      </label>
                      <div className={cx('wz input')}>
                        <input type="number" maxLength="10" onChange={this.handleChangeCardIdNo} />
                      </div>
                    </li>
                    <li className={cx('creditCardNo')}>
                      <label>결제카드 번호</label>
                      <div className={cx('wz input')}>
                        <input
                          type="number"
                          step="any"
                          pattern="^-?[0-9]\d*\.?\d*$"
                          maxLength="16"
                          name="cardNum"
                          onChange={handleChangeCardNumber}
                        />
                      </div>
                      {/* <p className={cx('msgError', 'point_red')}>
                          신용카드 번호를 입력하세요
                          </p> */}
                    </li>
                    <li className={cx('creditCardExp')}>
                      <label>유효기간</label>
                      <label className={cx('wz outline block dropdown')}>
                        <select onChange={handleChangeExpirationMonth}>
                          {new Array(12).fill(0).map((val, idx) => (
                            <option key={idx} value={idx + 1 < 10 ? `0${idx + 1}` : idx + 1}>
                              {(idx + 1) / 10 < 1 && '0'}
                              {idx + 1}
                            </option>
                          ))}
                        </select>
                      </label>
                      <span>월</span>
                      <label className={cx('wz outline block dropdown')}>
                        <select onChange={handleChangeExpirationYear}>
                          {new Array(41).fill(0).map((
                            val,
                            idx // 2060년 까지
                          ) => (
                            <option key={idx} value={new Date().getFullYear() + idx}>
                              {new Date().getFullYear() + idx}
                            </option>
                          ))}
                        </select>
                      </label>
                      <span>년</span>
                    </li>
                    <li className={cx('creditCardPw')}>
                      <label>비밀번호</label>
                      <div className={cx('wz input')}>
                        <input type="password" maxLength="2" onChange={this.handleChangePassword} />
                        <span>**</span>
                        <em>앞 두자리 입력</em>
                      </div>
                    </li>
                  </ul>
                  <AgreementList
                    checkerList={[handleChangePayAgreement, handleChangeThirdAgreement, handleChangeAgreement]}
                  />
                </>
              ) : (
                <div className={cx('creditCard_select')}>
                  <SimplePaySelector cardList={simplePaymentCardArray} onChange={handleChangeSimplePaymentInfo} />
                  <button type="button" className={cx('wz button gray')} onClick={addNewCardInfo}>
                    신규 결제 카드 등록하기
                  </button>
                </div>
              )}
            </main>
            <PayConfirm
              onClose={handleClose}
              onPay={handleConfirm}
              closeText={dialogData.closeText}
              loading={isPaying}
              disabled={paymentInfo.payYn !== 1 || paymentInfo.thirdYn !== 1 || isPaying}
              resState={dialogData.areaAvailable}
            />
          </div>
        </div>
      )
    );
  }
}

export default connect(({ user, dialog }) => ({ user, dialog }), { openAlertPopup, closePopup })(
  CreditCardDetailDialog
);
