import React, { FC, useState } from 'react'
import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { Preview } from './Preview'
import { PreviewModal } from './PreviewModal'
import styles from './MainBoardPreview.module.scss'
type Props = {
  mainCopy: string
  imageUrl: string
  accountNum: number
  onDelete?: () => void
}
export const MainBoardPreview: FC<Props> = ({ mainCopy, imageUrl, accountNum, onDelete }) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <div className={styles.container}>
      <Preview mainCopy={mainCopy} imageUrl={imageUrl} />
      {onDelete && <Button type="text" className={styles.antCloseButton} icon={<CloseOutlined />} onClick={onDelete} />}
      <Button size="middle" onClick={() => setOpen(true)} block>
        광고 미리보기
      </Button>
      <PreviewModal isOpen={isOpen} onClose={() => setOpen(false)}>
        <h3 className={styles.previewTitle}>{`메인보드 - 구좌 ${accountNum}`}</h3>
        <Preview mainCopy={mainCopy} imageUrl={imageUrl} />
      </PreviewModal>
    </div>
  )
}
