// eslint-disable-next-line
import React, { FC } from 'react'

type PropsType = {
  isBandTypePopularity: boolean
  bandBanner: string
}
export const BandLabelDescriptor: FC<PropsType> = ({ isBandTypePopularity, bandBanner }) =>
  !isBandTypePopularity ? null : (
    <div>
      <div style={{ width: '100%', position: 'relative' }}>
        <img
          src={bandBanner}
          alt={"오픈예정 리스트 '인기'라벨 적용 예시"}
          style={{
            width: '300px',
            height: '168px',
            position: 'relative',
            display: 'block',
            margin: '0 auto',
          }}
        />
        <div
          style={{
            color: '#868e96',
            fontSize: '12px',
            lineHeight: '18px',
            margin: '8px auto 24px',
            width: 'fit-content',
          }}
        >
          오픈예정 리스트 '인기' 라벨 적용 예시
        </div>
      </div>
      <div
        style={{
          color: '#868e96',
          fontSize: '12px',
          lineHeight: '18px',
          width: 'fit-content',
        }}
      >
        * 광고 신청일 1일 전 기준 알림 신청자 수가 200명 이상이거나 앵콜펀딩인 경우에는 오픈예정 리스트에 '인기' 라벨을
        활용할 수 있습니다. '인기' 문구는 리스트 이미지의 좌상단에 자동으로 연동됩니다.
      </div>
    </div>
  )
