import { useQuery } from '@tanstack/react-query'
import { fetchGoodsList, GoodsParams } from '@center/api'
import keys from './keys'

const useAdGoodsList = (params: GoodsParams) => {
  return useQuery(keys.goodsList(params.id), () => fetchGoodsList(params), {
    enabled: Boolean(params.id),
    keepPreviousData: true,
    staleTime: Infinity,
  })
}

export { useAdGoodsList }
