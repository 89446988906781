import { type AreaIDType, AreaType } from './area'
type AreaStyleType = {
  adClass: string
  selClass: string
}
const adAreaIdTable: { [key: number]: AreaStyleType } = {
  [AreaType.mainHome]: { adClass: 'Key', selClass: 'isMain' }, // 메인 패키지 - 메인 홈 배너
  [AreaType.unassigned1]: { adClass: 'project', selClass: 'isMain' }, // 메인 프로젝트 보러가기
  [AreaType.deprecated1]: { adClass: 'line', selClass: 'isMain' }, // 메인 띠배너
  [AreaType.messagePush]: { adClass: 'Key', selClass: 'isInvestHome' }, // 메시지 앱 푸쉬
  [AreaType.unassigned2]: { adClass: 'project', selClass: 'isComing' }, // 투자 오픈예정
  [AreaType.fundingHome]: { adClass: 'Key', selClass: 'isRwHome' }, // 펀딩 홈 배너
  [AreaType.fundingList]: { adClass: 'project', selClass: 'isRwHome' }, // 펀딩 리스트
  [AreaType.comingSoonHome]: { adClass: 'Key', selClass: 'isComing' }, // 오픈 예정 패키지 - 오픈 예정 홈 배너
  [AreaType.comingSoonList]: { adClass: 'project', selClass: 'isComing' }, // 오픈 예정 패키지 - 오픈 예정 리스트
  [AreaType.mainBanner]: { adClass: 'line', selClass: 'isMy' }, // 메인 패키지 - MY띠 배너
  [AreaType.storeHome]: { adClass: 'Key', selClass: 'isMain' }, // 스토어 홈 배너
  [AreaType.storeList]: { adClass: 'store', selClass: 'isList' }, // 스토어 리스트
  [AreaType.mainBoardDeprecated]: { adClass: 'Main', selClass: 'board' }, // 메인 보드
  [AreaType.preOrderBanner]: { adClass: 'Key', selClass: 'isPoHome' }, // 프리오더 패키지 - 프리오더 홈 배너
  [AreaType.preOrderList]: { adClass: 'project', selClass: 'isPoHome' }, // 프리오더 패키지 - 프리오더 리스트
  [AreaType.mainBoard]: { adClass: 'Key', selClass: 'isMain' }, // 메인 보드,
  [AreaType.searchBoard]: { adClass: 'line', selClass: 'isMain' },
  [AreaType.multiMainBoardContents]: { adClass: 'project', selClass: 'isRwHome' },
}
type FunctionType = (areaId: AreaIDType) => AreaStyleType
export const getAreaStyle: FunctionType = (areaId) => adAreaIdTable[areaId]
