import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Preview from '../../components/Preview'
import { openSecondPopup } from '../../reducers/secondDialogReducer'
class PreviewContainer extends PureComponent {
  handlePreviewModalOpen = (
    mainCopy,
    subCopy,
    imgUrl,
    bgColor,
    area,
    accountNum,
    adCenterPubAreaId,
    lossText,
    projectType,
    keyword
  ) => {
    this.props.openSecondPopup({
      dialogType: 'previewDialog',
      dialogData: {
        title: `${area} - 구좌${accountNum}`,
        confirmText: '닫기',
        imgUrl,
        mainCopy,
        keyword,
        subCopy,
        bgColor,
        pubAreaId: adCenterPubAreaId,
        lossText,
        projectType,
      },
    })
  }

  render() {
    const { handlePreviewModalOpen } = this
    const { imgDel } = this.props
    return <Preview {...this.props} imgDel={imgDel} handlePreviewModalOpen={handlePreviewModalOpen} />
  }
}

export default connect(({ user, secondDialog }) => ({ user, secondDialog }), ({ openSecondPopup }))(PreviewContainer);
