import { api } from '@wad/api'

type ReturnType = {
  data: unknown
}

type ParameterType = {
  adMakerId: number
}

type ProjectListFunctionType = (param: ParameterType) => Promise<boolean>
export const getTermsAgreements: ProjectListFunctionType = ({ adMakerId }) =>
  api('GET', `/makers/${adMakerId}/terms/agreements`).then(
    (res) => ((res as ReturnType)?.data?.length > 0) as boolean
  )
