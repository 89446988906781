import { useState, useCallback } from 'react'
import { Modal, Button } from 'antd'
import styles from './PreviewModal.module.scss'

type PreviewModalFunction = {
  children: React.ReactNode
  width: number | undefined
  onLoading?: () => Promise<boolean> | undefined
}

export const PreviewModal: React.FC<PreviewModalFunction> = ({ children, width, onLoading }) => {
  const [loading, setLoading] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const doClick = useCallback(() => {
    setLoading(true)
    const promise = onLoading?.() || Promise.resolve(true)
    promise.then(() => {
      setLoading(false)
      setOpen(true)
    })
  }, [onLoading])
  return (
    <>
      <Button size="middle" onClick={doClick} block loading={loading}>
        광고 미리보기
      </Button>
      <Modal
        className={styles.container}
        open={isOpen}
        onCancel={() => setOpen(false)}
        footer={null}
        width={width || 600}
        title={
          <>
            <h5 className={styles.title}>광고 미리보기</h5>
            <div className={styles.cautionMessage}>
              ※ 광고센터 미리보기 화면에서 보이는 지면과 실제 게재지면과의 차이가 있을 수 있습니다. 소재의 위아래가 잘려
              보이지 않도록 제품 및 중요 이미지를 배너 중앙에 배치해주세요.
            </div>
          </>
        }
        maskClosable={false}
      >
        {children}
      </Modal>
    </>
  )
}
