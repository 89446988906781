
export default function addPackagedTag(accountList) {
  const packageIdList = accountList.map(account => account.packagedId).filter(account => !(account === undefined || account === null));
  const subAccountList = packageIdList.map(id => accountList.find(account => account.adCenterPubAreaId === id)).filter(account => account !== undefined);
  const mainPackageList = subAccountList.map(sub => accountList.find(account => sub.adCenterPubAreaId === account.packagedId));
  const decisionTable = [
    ...mainPackageList.map(account => account.adCenterPubAreaId),
    ...subAccountList.map(account => account.adCenterPubAreaId)
  ].reduce((decisions, id) => ({ ...decisions, [id]: true }), {})

  return accountList.map(account => ({
    ...account,
    isPackagedTag: (decisionTable[account.adCenterPubAreaId] !== undefined)
  }));
}
