import withIcon, { Props } from './utils/withIcon'

function CheckIcon(props: Props) {
  return (
    <svg viewBox="0 0 48 48" {...props}>
      <path d="M18 39.6L4.8 26.4l3.36-3.36L18 32.76l21.84-21.72 3.36 3.36z" />
    </svg>
  )
}

export default withIcon('CheckIcon')(CheckIcon)
