import { network } from '../../../network';

export const promiseAdvertisementUpdate = (state) =>
  new Promise((resolve, reject) => {
    return network.callApi({
      url: 'advertisement/update',
      method: 'POST',
      body: {
        adCenterProjectId: state.projectId,
        adName: state.adName,
        adPrice: state.totalPrice,
        id: state.adId,
      },
      successFunc: (jsonData) => {
        resolve(jsonData);
      },
      failFunc: (error) => {
        reject(error);
      },
    });
  });
