function pad(n, width) {
  // eslint-disable-next-line no-param-reassign
  n += '';
  return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
}

// ex) date = 2019-11-16 17:39:31
export function dateToString(date) {
  if (date && date !== '-') {
    const newDate = new Date(date.replace(/\s/, 'T'));
    return `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`;
  }
  return '';
}

export function dateToStringAdPeriod(date) {
  if (date && date !== '-') {
    const newDate = new Date(date.replace(/\s/, 'T'));
    return `${String(newDate.getFullYear()).substring(2)}-${pad(newDate.getMonth() + 1, 2)}-${pad(newDate.getDate(), 2)}`;
  }
  return '';
}


export function dateToStringCreatedDate(date) {
  if (date && date !== '-') {
    const newDate = new Date(date.replace(/\s/, 'T'));
    return [`${String(newDate.getFullYear()).substring(2)}-${pad(newDate.getMonth() + 1, 2)}-${pad(newDate.getDate(), 2)}`, `${pad(newDate.getHours(), 2)}:${pad(newDate.getMinutes(), 2)}:${pad(newDate.getSeconds(), 2)}`];
  }
  return ['', ''];
}

// [YY.MM.DD, HH.MM.SS]
export const stringDateToyymmddhhmmss = (date, split_text = '-') => {
  // 설정 - 변경일시
  if (date && date !== '-') {
    const newDate = new Date(date.replace(/\s/, 'T'));

    return [
      [String(newDate.getFullYear()).substring(2), pad(newDate.getMonth() + 1, 2), pad(newDate.getDate(), 2)].join(split_text),
      [pad(newDate.getHours(), 2), pad(newDate.getMinutes(), 2), pad(newDate.getSeconds(), 2)].join(':'),
    ];
  }
  return ['', ''];
};

export const stringDateToyymm = (d, split_text = '') => {
  if (d === '') return d;
  const date = new Date(d.replace(/\s/, 'T'));
  const mm = date.getMonth() + 1; // getMonth() is zero-based

  return [String(date.getFullYear()).substring(2),
    (mm > 9 ? '' : '0') + mm,
  ].join(split_text);
};
