import dayjs from 'dayjs'

export const makeRequestBody = (isWadizAdmin, adAccountList, adId, startTime) =>
  adAccountList.reduce(
    (result, adAccount) => {
      if (!adAccount.callApiType) {
        return result
      }
      const requestBody = {}
      requestBody.id = adAccount.id
      requestBody.adCenterAdvertisementId = adId
      requestBody.adCenterBgColorId = adAccount.adCenterBgColorId
      requestBody.adCenterPubAccountId = adAccount.adCenterPubAccountId
      requestBody.adCenterPubAreaId = adAccount.adCenterPubAreaId
      requestBody.adCenterPubGoodsId = adAccount.selectedGoods.id
      requestBody.adCenterStatusId = 1 // 추후 수정될 가능성 있음
      requestBody.startAt = `${adAccount.startAt}${adAccount.startAt.indexOf(':') < 0 ? ` ${startTime}:00` : ''}`
      requestBody.endAt = `${dayjs(adAccount.endAt).add(1, 'day').format('YYYY-MM-DD')} ${startTime}:00`
      requestBody.status = isWadizAdmin ? 3 : 1
      requestBody.shared = adAccount.shared
      if (isWadizAdmin) {
        requestBody.adProcessStatus = 1
      }
      requestBody.advertiserDivision = isWadizAdmin ? 1 : 0
      requestBody.bgColor = adAccount.bgColor
      // tempList.imgFile = val.imgFile;
      if (Object.prototype.hasOwnProperty.call(adAccount, 'imgUrl')) {
        requestBody.imgUrl = adAccount.imgUrl
      }
      requestBody.mainCopy = adAccount.mainCopy
      requestBody.keyword = adAccount.keyword
      requestBody.subCopy = adAccount.subCopy
      requestBody.landingUrl = adAccount.landingUrl

      console.log('making', adAccount)
      requestBody.price =
        adAccount.selectedGoods.price * (adAccount.normalDays || 0) +
        adAccount.selectedGoods.holidayPrice * (adAccount.holidays || 0)

      let coupon = null
      if (adAccount.adCenterDiscountId && adAccount.adCenterDiscountId !== 0 && adAccount.adCenterDiscountId !== 1) {
        // tmpCouponList.push(adAccount.adCenterDiscountId);
        coupon = adAccount.adCenterDiscountId
        requestBody.adCenterDiscountId = adAccount.adCenterDiscountId
        requestBody.usedDiscount = 2
      } else {
        requestBody.adCenterDiscountId = 1
        requestBody.usedDiscount = 1
      }
      requestBody.costPrice = requestBody.price
      requestBody.vat = Math.floor(requestBody.price * 0.1)

      if (adAccount.adState === 'Accepted') {
        // goto Modified in Contract
        requestBody.status = 2
        requestBody.adProcessStatus = 6
        requestBody.cancelStatus = 5
      } else if (adAccount.adState === 'Feedback' || adAccount.adState === 'Recreating') {
        requestBody.status = 6
        requestBody.adProcessStatus = 0
        requestBody.cancelStatus = 0
      }

      const listName = adAccount.callApiType === 'create' ? 'createList' : 'updateList'
      return {
        ...result,
        [listName]: [...result[listName], requestBody],
        modifiedList: [...result.modifiedList, adAccount],
        couponList: coupon !== null ? [...result.couponList, coupon] : result.couponList,
      }
    },
    {
      createList: [],
      updateList: [],
      modifiedList: [],
      couponList: [],
    }
  )
