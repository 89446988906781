import React, { useState, useMemo } from 'react'
import { Input, Typography } from 'antd'
import { AreaType, AreaIDType } from '@legacy/definitions'

import styles from './TextCopy.module.scss'

interface TextCopyProps {
  defaultValue: string
  rows: number
  maxLength: number
  onChange: (text: string) => void
  areaId: AreaIDType
}

const { TextArea } = Input
const { Text } = Typography

// 특수문자 허용 지면
const passSpecialString: AreaIDType[] = [AreaType.messagePush, AreaType.message150KPush]

export const TextCopy: React.FC<TextCopyProps> = ({ defaultValue = '', rows, maxLength, onChange, areaId }) => {
  const [value, setValue] = useState<string>(defaultValue)
  const isPassSpecialString = useMemo(() => passSpecialString.includes(areaId), [areaId])

  const handleTextarea = (text: string) => {
    // 특수 문자 중 ,.% 는 허용
    const regExp = /[\{\}\[\]\/?;:|\)*~`!^\-_+<>@\#$&\\\=\(\'\"]/g
    if (regExp.test(text) && !isPassSpecialString) {
      return
    }
    setValue(text)
    onChange(text)
  }

  const onPressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // TextArea의 줄 수는 rows를 넘길 수 없다.
    if (value.split('\n').length >= rows) {
      e.preventDefault()
    }
  }

  return (
    <div>
      <TextArea
        value={value}
        onChange={(e) => handleTextarea(e.target.value)}
        onPressEnter={(e) => onPressEnter(e)}
        maxLength={maxLength}
        rows={rows || 1}
        showCount
        className={styles.textCopy}
      />
      {!isPassSpecialString && (
        <Text type="secondary" style={{ fontSize: 12, paddingLeft: 40 }}>
          (최대 {maxLength}자, {rows || 1}줄 가능) ,.% (쉼표, 마침표, 퍼센트 기호)를 제외한 특수기호 사용 불가{' '}
        </Text>
      )}
    </div>
  )
}
