import React from 'react';

import className from 'classnames/bind';
import style from './ModifyAdStep2.module.scss';
const cx = className.bind(style);

const ModifyAdStep2 = ({ handleClickGoToAdList }) => (
  <div className={cx('create_ad', 'create_ad_container_step3')}>
    <hgroup>
      <h2>광고 수정요청하기</h2>
    </hgroup>
    <section className={cx('ad_paid')}>
      <p className={cx('modify_cont')}>
        <strong>수정요청이 정상적으로 접수되었습니다.</strong>
        1. 수정요청 시, 최대 2일이 소요되며, 수정요청이 승인 혹은 반려될 수 있습니다.<br></br>
        2. 수정요청은 와디즈 내부 광고 심의 기준 및 배너 제작 가이드에 적합하지 않은 경우, 반려될 수 있습니다.<br></br>
        3. 수정요청 승인 여부는 메이커님이 프로젝트 등록 시 기재하신 이메일을 통해 안내드립니다.<br></br>
        4. 2개 이상의 광고를 한 번에 결제 예약할 경우, 심사 승인 여부에 따라 결제가 일부만 진행될 수 있습니다.<br></br>
      </p>
      <small>
        <div className={cx('process_title')}>광고 심사 프로세스 알아보기</div>
        수정요청을 접수한 경우, [수정요청]으로 표시됩니다.<br></br>
        수정요청 결과 안내 및 접수요청서의 서명이 완료되면 [계약 중] 으로 표시됩니다.<br></br>
        광고가 최종 승인된 경우, [예정] 으로 표시됩니다.<br></br>
        광고가 최종 승인을 반려하면 [반려]로 표시됩니다.<br></br>
      </small>
      <p className={cx('point_red', 'point_red_bottom')}>접수요청서 서명이 완료된 이후 광고비 결제가 진행됩니다.</p>
      <button type="button" className={cx('wz button primary', 'button_320')} onClick={handleClickGoToAdList}>
        MY 광고관리
      </button>
    </section>
  </div>
)


export default ModifyAdStep2;
