import qs from 'qs';
import { WadizGlobal } from './wadiz';

import {
  isIos,
  isAndroid,
  isWadizApp,
  isWadizIosApp,
  isWadizAndroidApp,
} from './browser';
import { getGlobalObject } from './misc';

const origin = process.env['NODE_ENV'] !== 'production' ? 'https://dev.wadiz.kr' : '';
const PDFViewerPath = `${origin}/resources/static/pdf/viewer.html`;

const APP_DOWNLOAD_LINK_IOS = 'https://itunes.apple.com/kr/app/wadijeu/id1107828621?l=enmt=8';
const appLinkAndroid = (href: string): string => `intent://${href}#Intent;scheme=https;package=com.markmount.wadiz;end;`;

/**
 * 파일 다운로드 링크 생성
 * @param fileId 파일ID
 * @param fileType 파일 종류 (PHOTO, ATTACH)
 */
export function fileDownloadLink(fileId: string, fileType: string): string {
  const encodeFileId = encodeURIComponent(fileId);
  if (fileType === 'PHOTO') {
    return `${origin}/web/ftcampaign/download/photo?encPhotoId=${encodeFileId}`;
  }
  return `${origin}/web/ftcampaign/download/attach?encFileId=${encodeFileId}`;
}

/**
 * 파일 다운로드
 * - 와디즈 앱 환경에서는 내부 API를 호출
 * @param fileId 파일ID
 * @param fileType 파일 종류
 * @param fileName 파일 이름
 */
export function fileDownload(fileId: string, fileType: string, fileName: string): void {
  const encodeFileId = encodeURIComponent(fileId);
  const { Invest } = getGlobalObject<WadizGlobal>();

  // Android Wadiz App
  if (isWadizAndroidApp) {
    setTimeout(() => {
      if (fileType === 'PHOTO') {
        Invest.photoFileDownload(encodeFileId, fileName);
      } else {
        Invest.attachFileDownload(encodeFileId, fileName);
      }
    }, 100);
    return;
  }

  // other
  window.location.href = fileDownloadLink(fileId, fileType);
}

/**
 * PDF파일 뷰어 경로
 * @param fileId 파일ID
 * @param fileType 파일 종류
 * @param fileName 파일 이름
 */
export function getPdfViewerPath(fileId: string, fileType: string, fileName: string): string {
  const parameter = qs.stringify({
    fileName,
    file: fileDownloadLink(fileId, fileType),
  });
  return `${PDFViewerPath}?${parameter}`;
}

/**
 * 1:1 문의하기 실행 (Intercom)
 */
export function openHelpMessage(): void {
  if (isWadizIosApp) {
    window.location.href = 'wadiz://webcommand/webInterface.intercom';
    return;
  }

  if (isWadizAndroidApp) {
    const { WebInterface } = getGlobalObject();
    WebInterface.intercom();
    return;
  }

  // 웹브라우저
  const { Intercom } = getGlobalObject();
  if (Intercom) {
    Intercom('show');
  }
}

/**
 * iOS Schema를 호출하기 위해 iframe 내부에서 실행
 * @param src
 */
function moveiOSAppLink(src: string): HTMLIFrameElement {
  const iframe = document.createElement('iframe');
  iframe.src = src;
  iframe.style.display = 'none';
  document.body.appendChild(iframe);
  return iframe;
}

/**
 * 와디즈 앱에서 현재URL, 특정URL을 연다
 * @param url URL
 * @param callback Callback
 */
export function appLink(url: string, callback?: unknown): void {
  const { location } = getGlobalObject<Window>();
  const currentLocation = (url || location.href || '').replace(/^https?:\/\//i, '');
  // https://developers.naver.com/docs/utils/mobileapp/
  if (isIos) {
    const clickedAt = Date.now();
    setTimeout(() => {
      if (Date.now() - clickedAt < 2000) {
        window.location.href = APP_DOWNLOAD_LINK_IOS;
      }
    }, 1500);
    moveiOSAppLink(`wadiz://${currentLocation}`);
    return;
  }

  if (isAndroid) {
    window.location.href = appLinkAndroid(currentLocation);
    return;
  }

  if (callback && typeof callback === 'function') {
    callback();
  }
}

/**
 * 와디즈 앱으로 이벤트 메시지 전송
 * @param eventName 이벤트 이름
 * @param options 이벤트 옵션
 */
export function sendAppMessage(eventName: string, options?: unknown): void {
  const IOS_SEND_MESSAGE_FRAME_ID = 'IOS_SEND_MESSAGE_FRAME';
  const message = Object.assign({
    eventName,
  }, options && { options });
  try {
    const messageJson = JSON.stringify(message);
    if (isWadizIosApp) {
      let frame = document.getElementById(IOS_SEND_MESSAGE_FRAME_ID) as HTMLIFrameElement;
      if (!frame) {
        frame = document.createElement('iframe');
        frame.id = IOS_SEND_MESSAGE_FRAME_ID;
        frame.style.display = 'none';
        document.body.appendChild(frame);
      }
      if (frame.contentDocument) {
        frame.contentDocument.location.href = `wadiz://webcommand/webInterface.receiveWebMessage?message=${messageJson}`;
      }
    } else if (isWadizAndroidApp) {
      const { WebInterface } = getGlobalObject<WadizGlobal>();
      WebInterface.receiveWebMessage(messageJson);
    }
  } catch (err) {
    const { Sentry } = getGlobalObject();
    if (Sentry) {
      Sentry.withScope((scope: any) => {
        scope.setExtras(message);
        Sentry.captureException(err);
      });
    }
  }
}

/**
 * 와디즈 앱으로 이벤트 메시지 전송
 * sendAppMessage 함수와 다른 점: 앱 인터페이스가 WebInterface.receiveWebMessage로 통일됨.
 * @param {String} eventName 이벤트 이름
 * @param {*} [options] 이벤트 옵션
 * @return {void}
 */
export function sendAppMessage2(eventName: string, options?: unknown) {
  const { WebInterface } = getGlobalObject<WadizGlobal>();
  const message = Object.assign(
    { eventName },
    options && { options },
  );

  try {
    if (isWadizApp) {
      const messageJson = JSON.stringify(message);
      // eslint-disable-next-line no-undef
      WebInterface.receiveWebMessage(messageJson);
    }
  } catch (error) {
    const { Sentry } = getGlobalObject();

    if (Sentry) {
      Sentry.withScope((scope: any) => {
        scope.setExtras(message);
        Sentry.captureException(error);
      });
    }
  }
}
