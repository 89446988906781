import React, { PureComponent } from 'react';

// Plugin
import { connect } from 'react-redux';

// Actions
import { setError } from '../../reducers/errorReducer';

// Components
import ErrorContent from '../../components/ErrorContent';

class ErrorPage extends PureComponent {
  componentDidMount() {
    this.props.setError(false);
  }

  handleClickGoToHome = () => {
    const { history } = this.props;
    history.push('/');
  }

  render() {
    const { handleClickGoToHome } = this;

    return (
      <ErrorContent
        handleClickGoToHome={handleClickGoToHome}
      />
    );
  }
}

export default connect(null, { setError })(ErrorPage);
