import { network } from '../../../network'

const factoryPromise = (fx) => (requestList) =>
  new Promise((resolve, reject) => {
    return network.callApi({
      url: `creative/${fx}`,
      method: 'POST',
      body: requestList,
      successFunc: (jsonData) => {
        resolve(jsonData)
      },
      failFunc: (error) => {
        reject(error)
      },
    })
  })
export const promiseCreativeCreate = factoryPromise('create')
export const promiseCreativeUpdate = factoryPromise('update')

export const promiseCreativeSyncCreate = factoryPromise('create-sync')
export const promiseCreativeSyncUpdate = factoryPromise('update-sync')
