//http://rc-api01:9990/ad-admin/swagger-ui.html#/
import { api } from '../api/api'

type ResponseFormat = {
  result?: string
  response?: any
}

export const centerGetCreativesStatus: (creativeId: number) => Promise<ResponseFormat> = (creativeId) =>
  api('GET', `/creatives/${creativeId}/status`)
// 광고(소재)의 진행상태를 조회합니다: 전체 진행상태

export const centerGetCreativesStatusWithCount: (creativeId: number, rowCount: number) => Promise<ResponseFormat> = (
  creativeId,
  rowCount
) => api('GET', `/creatives/${creativeId}/status/${rowCount}`)
// 광고(소재)의 진행상태를 조회합니다.

export const centerPostCreativesCancel: (creativeId: number) => Promise<ResponseFormat> = (creativeId: number) =>
  api('POST', `/creatives/${creativeId}:cancel`)
// (EXP:테스트중) (승인)요청된 광고(소재)를 취소합니다.

type ParameterType = {
  startAt: string
  endAt: string
  pubAreaId: number
  projectType: number
  campaignId: number
}
export const centerGetPubAreaAvailability: (params: ParameterType) => Promise<ResponseFormat> = ({
  pubAreaId,
  startAt,
  endAt,
  projectType,
  campaignId,
}) => {
  return api(
    'GET',
    `/pub-areas/${pubAreaId}/availability?startAt=${startAt}&endAt=${endAt}&${
      projectType === 3 ? 'wdz_store_project_id' : 'wdz_campaign_id'
    }=${campaignId}`
  )
}
