import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { openPopup } from '../../reducers/dialogReducer'

import AdCostDetailContent from '../../components/AdCostReportContent/AdCostDetailContent'
import { dateToString, dateToStringAdPeriod, dateToStringCreatedDate } from '../../datetostring'
import { network } from '../../network'

class ReportCostDetail extends PureComponent {
  state = {
    adName: '',
    projectName: '',
    allItemList: [],
    isDownload: false,
    fileName: 'report_cost_detail',
  }

  navList = [
    { name: '보고서', link: '/main/ad/report/result' },
    { name: '광고비 보고서', link: '/main/ad/report/cost' },
    { name: '상세보기', link: window.location.pathname },
  ]

  tableInfo = {
    tableLabel: [
      'No.',
      '광고 영역',
      '구좌',
      '기간',
      '광고비',
      '승인단계',
      '운영단계',
      '결제 예약일',
      '결제 예약취소일',
      '결제일',
      '환불일',
      '환불액',
      '반려일',
      '취소일',
      '광고비잔금',
      '남은광고시간',
      '결제상태',
    ],
    tableValue: [
      'idx',
      'area',
      'accountNum',
      'period',
      'price',
      'status',
      'adProcessStatus',
      'reservedPayDate',
      'reservedPayCancelDate',
      'payDate',
      'refundDate',
      'refundPrice',
      'rejectDate',
      'cancelDate',
      'adRemainPrice',
      'adRemainHour',
      'adPayStatus',
    ],
  }

  statusType = {
    status: ['', '작성중', '승인요청', '승인완료', '반려'],
    adProcessStatus: ['', '예정', '진행중', '취소', '완료', '삭제'],
    adPayStatus: ['', '결제 예약취소', '결제 예약완료', '결제완료', '결제실패', '결제완료', '결제완료', '결제완료'],
    cancelStatus: [
      '',
      '관리자 광고 진행중 취소',
      '관리자 광고 예정중 취소',
      '메이커의 심사중 광고취소',
      '메이커의 예정중 광고 취소',
      '시스템 취소',
      '반려-심사중 관리자가 광고 반려',
      '결제실패',
    ],
  }

  getItem = () => {
    const adId = this.props.match.params.id
    const makerId = this.props.user.maker_id

    const url = `advertisement-pay-report/list?maker_id=${makerId}&advertisement_id=${adId}`
    network.callApi({
      url,
      method: 'get',
      header: { 'Content-Type': 'application/json;charset=UTF-8' },
      successFunc: (jsonData) => {
        const { result } = jsonData
        if (result.length === 0) {
          // No Data Case
          this.setState({
            adName: '광고비 보고서가 없거나 삭제되었습니다.',
            projectName: '광고비 보고서가 없거나 삭제되었습니다.',
          })
          return
        }
        const { adName, projectName, creativeList } = result[0]
        this.setState({ adName, projectName, allItemList: creativeList })
      },
      failFunc: () => {
        console.error(`fail- ${url}`)
      },
    })
  }

  localeOrNull = (value) => {
    if (value || value === 0) {
      return value.toLocaleString()
    }
    return ''
  }

  handleClickHistoryBack = () => this.props.history.goBack()

  handleClickDetailPopUp = (id) => {
    const { statusType } = this
    this.props.openPopup({
      dialogType: 'reportCostDetail',
      dialogData: {
        id,
        statusType,
      },
    })
  }

  handleClickFavorit = () => {
    this.props.openPopup({
      dialogType: 'reportFavorit',
      dialogData: {
        reportType: '광고비',
        reportName: this.state.adName,
        adCenterMakerId: this.props.user.maker_id,
        adCenterAdvertisementId: this.props.match.params.id,
      },
    })
  }

  handleExportExcel = () => {
    this.genExcelData()
  }

  genRow = (tableInfo, item) => {
    const { tableLabel, tableValue } = tableInfo
    const d = {}
    for (let i = 0; i < tableLabel.length; i += 1) {
      d[tableLabel[i]] = item[tableValue[i]]
    }

    return d
  }

  genExcelData = () => {
    const { allItemList } = this.state
    const items = []

    allItemList.forEach((val, idx) => {
      const {
        area,
        accountNum,
        price,
        status,
        adProcessStatus,
        reservedPayDate,
        reservedPayCancelDate,
        payDate,
        refundDate,
        refundPrice,
        rejectDate,
        cancelDate,
        adRemainPrice,
        adRemainDay,
        adRemainHour,
        adPayStatus,
        startAt,
        endAt,
      } = val
      const period = `${dateToStringAdPeriod(startAt)}~${dateToStringAdPeriod(endAt)}`
      const row = {
        idx: idx + 1,
        area,
        accountNum,
        period,
        price,
        status: this.statusType.status[status],
        adProcessStatus: this.statusType.adProcessStatus[adProcessStatus],
        reservedPayDate,
        reservedPayCancelDate,
        payDate,
        refundDate,
        refundPrice,
        rejectDate,
        cancelDate,
        adRemainPrice,
        adRemainDay,
        adRemainHour,
        adPayStatus: this.statusType.adPayStatus[adPayStatus],
      }
      items.push(this.genRow(this.tableInfo, row))
    })

    this.setState({ tableData: [items], isDownload: true, fileName: this.state.fileName }, () => {
      this.handleDownloaded()
    })
  }

  handleDownloaded = () => {
    const isDownload = this.state.isDownload !== true
    this.setState({ isDownload })
  }

  componentDidMount() {
    this.getItem()
  }

  render() {
    const { state, navList, statusType } = this
    const { localeOrNull, handleClickHistoryBack, handleClickDetailPopUp, handleClickFavorit, handleExportExcel } = this

    const { adName, projectName, allItemList, tableData, fileName, isDownload } = state

    return (
      <AdCostDetailContent
        adName={adName}
        projectName={projectName}
        allItemList={allItemList}
        tableData={tableData}
        fileName={fileName}
        isDownload={isDownload}
        navList={navList}
        statusType={statusType}
        handleClickDetailPopUp={handleClickDetailPopUp}
        handleClickHistoryBack={handleClickHistoryBack}
        handleClickFavorit={handleClickFavorit}
        handleExportExcel={handleExportExcel}
        dateToString={dateToString}
        dateToStringAdPeriod={dateToStringAdPeriod}
        dateToStringCreatedDate={dateToStringCreatedDate}
        localeOrNull={localeOrNull}
      />
    )
  }
}

export default connect(({ user }) => ({ user }), { openPopup })(ReportCostDetail)
