// eslint-disable-next-line
import React, { useState, useCallback, CSSProperties } from 'react'
import CarouselContainer, { tFxChangeAreaInfo } from './CarouselContainer'

export type tFxChange = tFxChangeAreaInfo
const HeadNotify = () => (
  <>
    <h4>희망하는 광고 영역을 선택해주세요.</h4>
    <p>
      ※ 사전 예약 시 프로젝트 진행 일정을 반드시 고려하여 광고를 등록하세요. <br />
      · 오픈예정 패키지 : 오픈예정 기간 중에만 진행 가능, 펀딩/프리오더 프로젝트만 진행 가능
      <br />· 펀딩 리스트 / 펀딩 홈 배너 : 본펀딩 기간 중에만 진행 가능, 펀딩/프리오더 프로젝트만 진행 가능
    </p>
  </>
)

const notifyStyle: CSSProperties = {
  marginTop: '20px',
  fontSize: '12px',
  lineHeight: '0',
  color: '#90949c',
}
const MiddleNotify = () => (
  <>
    <span style={notifyStyle}>
      ※ 앱 푸시는 상시 운영 상품이 아니며 와디즈 담당자를 통해 제안드리는 프리미엄 상품입니다.
    </span>
    <br />
    <span style={notifyStyle}>※ 메인 홈 배너 광고는 PC에서만 노출되며, 모바일에서는 노출되지 않습니다.</span>
    <br />
    <span style={notifyStyle}>※ 메인콘텐츠 광고는 모바일에서만 노출되며, PC에서는 노출되지 않습니다.</span>
    <br />
    <span style={notifyStyle}>※ 광고 집행일 기준 3영업일 이내 광고를 취소하는 경우 위약금이 발생할 수 있습니다.</span>
  </>
)

const buttonStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
}

type tGuideProps = { url: string }
const AdGuide = (props: tGuideProps) => {
  const { url } = props
  const doClick = useCallback(() => {
    window.open(url)
  }, [url])
  return (
    <div style={buttonStyle}>
      <button type="button" className={'wz button'} onClick={doClick} disabled={!url}>
        <i className={'icon download'}></i>
        영역별 광고 소재 제작 가이드 확인하기
      </button>
    </div>
  )
}

type tAdAreaProps = { onChangeAreaInfo: tFxChangeAreaInfo }
const AdAreaSection = (props: tAdAreaProps) => {
  const { onChangeAreaInfo } = props
  const [guideURL, setGuideURL] = useState('')
  const doChangeInfo: tFxChangeAreaInfo = useCallback(
    (areaInfo, accountItem, goodsItems, packagedInfo) => {
      setGuideURL(areaInfo.manualUrl)
      onChangeAreaInfo(areaInfo, accountItem, goodsItems, packagedInfo)
    },
    [setGuideURL, onChangeAreaInfo]
  )
  return (
    <section>
      <HeadNotify />
      <CarouselContainer onChangeAreaInfo={doChangeInfo} />
      <MiddleNotify />
      <AdGuide url={guideURL} />
    </section>
  )
}
export default AdAreaSection
