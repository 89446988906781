import {v4 as uuidv4} from 'uuid';
import { WadizGlobal } from './wadiz';

const fallbackGlobalObject = {};

/**
 * Node.js 환경 여부를 반환
 */
export function isNodeEnv(): boolean {
  return Object.prototype.toString.call(typeof process !== 'undefined' ? process : 0) === '[object process]';
}

/* eslint-disable no-restricted-globals */
/**
 * Node.js/Browser 환경 여부에 따른 Global Object를 반환
 */
export function getGlobalObject<T>(): T & WadizGlobal {
  return (isNodeEnv()
    ? global
    : typeof window !== 'undefined'
      ? window
      : typeof self !== 'undefined'
        ? self
        : fallbackGlobalObject) as T & WadizGlobal;
}
/* eslint-enable no-restricted-globals */


/**
 * 현재 페이지에서 리소스 경로와 일치하는 Script 태그를 찾는다.
 * @param src 원격 리소스 경로
 */
function findScript(src: string): HTMLScriptElement | null {
  return document.querySelector(`script[src="${src}"]`);
}

/**
 * 현재 페이지에서 리소스 경로가 일치하는 태그를 찾고 일치하지 않으면
 * `<head>`태그에 해당 리소스 경로를 참조하는 `<script>`태그를 생성한 후 삽입한다.
 * 삽입한 이후 로드가 완료되면 `Promise`를 반환한다.
 * @param url 원격 리소스 경로
 */
export function loadScript(url: string): Promise<HTMLScriptElement> {
  return new Promise((resolve, reject) => {
    const domScript = findScript(url) as HTMLScriptElement & { isComplete : boolean};
    if (domScript) {
      if (domScript.isComplete === false) {
        domScript.addEventListener('load', () => resolve(domScript));
        domScript.addEventListener('error', () => reject(domScript));
        return;
      }

      resolve(domScript);
      return;
    }

    const script = document.createElement('script') as HTMLScriptElement & { isComplete : boolean};
    script.type = 'text/javascript';
    script.charset = 'utf-8';
    script.async = true;
    script.src = url;
    script.isComplete = false;
    script.addEventListener('load', () => {
      script.isComplete = true;
      resolve(script);
    });
    script.addEventListener('error', () => {
      script.isComplete = true;
      reject(new Error(`Loading Script failed. (${url})`));
    });
    document.head.appendChild(script);
  });
}

/**
 * 현재 페이지에서 리소스 경로와 일치하는 link 태그를 찾는다.
 * @param src 원격 리소스 경로
 */
function findStylesheetLink(src: string): HTMLLinkElement | null {
  return document.querySelector(`link[rel="stylesheet"][href="${src}"]`);
}

/**
 * 현재 페이지에서 리소스 경로가 일치하는 태그를 찾고 일치하지 않으면
 * `<head>`태그에 해당 리소스 경로를 참조하는 `<link>`태그를 생성한 후 삽입한다.
 * 삽입한 이후 로드가 완료되면 `Promise`를 반환한다.
 * @param url 원격 리소스 경로
 */
export function loadStylesheet(url: string): Promise<HTMLLinkElement> {
  return new Promise((resolve, reject) => {
    const domLink = findStylesheetLink(url) as HTMLLinkElement & { isComplete : boolean};
    if (domLink) {
      if (domLink.isComplete === false) {
        domLink.addEventListener('load', () => resolve(domLink));
        domLink.addEventListener('error', () => reject(domLink));
        return;
      }

      resolve(domLink);
      return;
    }
    const style = document.createElement('link') as HTMLLinkElement & { isComplete : boolean}
    style.rel = 'stylesheet';
    style.charset = 'utf-8';
    style.href = url;
    style.isComplete = false;
    style.addEventListener('load', () => {
      style.isComplete = true;
      resolve(style);
    });
    style.addEventListener('error', () => {
      style.isComplete = true;
      reject(new Error(`Loading Stylesheet failed. (${url})`));
    });
    document.head.appendChild(style);
  });
}


/**
 * `input[type="radio"]`와 같은 곳에서 `id`와 `for`를 연결하지 위한 용도로 사용한다.
 * `uuidv4` 함수를 사용한다.
 */
export function uuid(): string {
  // 테스트 시에는 uuid를 생성하지 않는다.
  return global ? 'test-mode-uuid' : uuidv4();
}

/**
 * 웹브라우저의 세션 스토리지, 로컬 스토리지 사용 가능 여부 확인
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage|Feature-detecting localStorage}
 */
export function storageAvailable(type: 'localStorage' | 'sessionStorage'): boolean | undefined {
  let storage;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return e instanceof DOMException && (
      // everything except Firefox
      e.code === 22
        // Firefox
        || e.code === 1014
        // test name field too, because code might not be present
        // everything except Firefox
        || e.name === 'QuotaExceededError'
        // Firefox
        || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
        // acknowledge QuotaExceededError only if there's something already stored
        && (storage && storage.length !== 0);
  }
}
