import { useState, useEffect, useCallback } from 'react'
import { useForm } from 'react-hook-form-v6'
import { toast } from 'react-toastify'
import { Button } from '@wadiz-frontend/waffle-modified'
import { CheckIcon } from '@wadiz-frontend/waffle-icons-modified'
import Lnb from '../Lnb'
import NavBar from '../NavBar'
import LoadingBar from '../LoadingBar/LoadingBar'
import { TermsModal } from '../TermsModal'
import { useSelector } from 'react-redux'
import { centerGetTermsAgreements, centerPostTermsAgreements } from '@wad/api'

import styles from './TermsContent.module.scss'

const formId = 'request-term-agreement-id'
const RequireAgreementButton = () => {
  const [isModalOpen, setModalOpen] = useState(false)
  return (
    <div className={styles.buttonContainer}>
      <Button
        color={'secondary'}
        variant={'contained'}
        size={'xl'}
        startIcon={<CheckIcon />}
        onClick={() => {
          setModalOpen(!isModalOpen)
        }}
        fullWidth={false}
      >
        약관 동의하기
      </Button>
      <TermsModal
        isOpen={isModalOpen}
        onCancel={() => {
          setModalOpen(false)
        }}
        formid={formId}
      />
    </div>
  )
}
const AgreeStatement = ({ date, isAgreement }) =>
  !isAgreement ? (
    <div className={styles.agreeContainer}>
      <div className={styles.agreeBox}> 동의완료</div>
      <div className={styles.agreeDate}>{date}</div>
      <div>
        <a
          href={`${process.env.REACT_APP_WADIZ_APP_ROOT}web/wterms/ad_service`}
          target="_blank"
          rel="noopener noreferrer"
        >
          약관보기
        </a>
      </div>
    </div>
  ) : (
    <div className={styles.nonAgreeContainer}>
      <div className={styles.notice}>광고 상품 등록 이용을 위해 약관 내용을 확인하고 동의해 주세요. </div>
      <RequireAgreementButton />
    </div>
  )

const navList = [
  { name: '설정', link: '/main/ad/settings/' },
  { name: '약관 및 계약 관리', link: '/main/ad/settings/terms' },
]

export const TermsContent = () => {
  const user = useSelector((store) => store.user)
  const [agreeDate, setAgreeDate] = useState({ date: null, isAgreement: false })
  const [isLoading, setLoading] = useState(true)

  const { handleSubmit } = useForm()
  const [isSubmitted, setSubmit] = useState(false)
  const doSubmit = useCallback(() => {
    setSubmit(true)
  }, [])

  const adMakerId = user?.maker_id
  useEffect(() => {
    if (isSubmitted && adMakerId) {
      const fn = async () => {
        setLoading(true)
        try {
          const { data: agreementInfo } = await centerPostTermsAgreements({ adMakerId })
          setAgreeDate({
            date: agreementInfo?.agreementDate || '',
            isAgreement: agreementInfo?.adMakerId === adMakerId,
          })
          if (agreementInfo) {
            toast('동의 완료 되었습니다.')
          }
        } catch (e) {
          console.warn(e)
        } finally {
          setLoading(false)
          setSubmit(false)
        }
      }
      fn()
    }
  }, [adMakerId, isSubmitted, user])

  useEffect(() => {
    if (adMakerId) {
      const fn = async () => {
        try {
          const { data: agreementList } = await centerGetTermsAgreements({ adMakerId })
          setAgreeDate({ date: agreementList[0]?.agreementDate || '', isAgreement: agreementList?.length > 0 })
        } catch (e) {
          console.warn(e)
        } finally {
          setLoading(false)
        }
      }
      fn()
    }
  }, [user, setLoading, adMakerId])

  return (
    <main className={styles.main_container}>
      <Lnb type="settings" />
      <div className={styles.content_wrap}>
        <NavBar list={navList} />
        <hgroup>
          <h2>약관 및 계약 관리</h2>
          <p>광고 서비스 이용 약관</p>
          <form id={formId} onSubmit={handleSubmit(doSubmit)}>
            {isLoading ? <LoadingBar /> : <AgreeStatement {...agreeDate} />}
          </form>
        </hgroup>
      </div>
    </main>
  )
}
