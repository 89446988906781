import React, { ReactChildren, Component } from 'react';
import cx from 'classnames';

import styles from './Toast.module.scss';

export const ToastDelay = {
  short: 'short',
  long: 'long',
} as const;

export const ToastType = {
  basic: 'basic',
  warning: 'warning',
} as const;

// waffle 기준 breakpoint-desktop
const breakpoint = 1096;

const durationMap = {
  'short': 2500,
  'long': 3500,
};
export interface ToastProps {
  /** toast를 구분하는 id 제거시 필요 */
  id: number,

  /** destroy 요청 여부, true로 설정되는 시점에 hide animation이 시작됨 */
  requestDestroy: boolean,

  /** toast 유지 시간 (short, long) */
  delay: keyof typeof ToastDelay | number,

  /** toast type (basic, warning) */
  type: keyof typeof ToastType,

  /** toast 제거 함수 */
  onClose: Function,

  children: ReactChildren,
}

export interface ToastState {
  rootClass: string,
}

class Toast extends Component<ToastProps, ToastState> {
  timerId: any;
  showClassName: string | undefined;
  hideClassName: string | undefined;
  isDestroy: boolean;

  static defaultProps = {
    delay: ToastDelay.short,
    type: ToastType.basic,
  }

  constructor(props: ToastProps) {
    super(props);
    this.state = {
      rootClass: styles.container,
    };
    this.isDestroy = false;
  }

  /** setTimeout clear */
  clearTimer = () => {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  }

  /** animation이 끝나면 toast를 제거 */
  handleAnimationEnd = () => {
    if (this.isDestroy) {
      this.props.onClose(this.props.id);
    }
  }

  componentDidMount() {
    this.showClassName = styles.showMobile;
    this.hideClassName = styles.hideMobile;
    if (window.matchMedia(`(min-width: ${breakpoint}px)`).matches) {
      this.showClassName = styles.show;
      this.hideClassName = styles.hide;
    }
    this.show();
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  componentDidUpdate(prevProps: any) {
    /** toast가 추가된 경우 기존 toast는 기다리지 않고 사라짐 */
    if (!prevProps.requestDestroy && this.props.requestDestroy) {
      this.hide();
    }
  }

  show() {
    this.setState({ rootClass: cx(styles.container, this.showClassName) });
    this.timerId = setTimeout(() => {
      this.hide();
    }, typeof this.props.delay === 'number' ? this.props.delay : durationMap[this.props.delay]); // TODO: test code
  }

  hide() {
    this.clearTimer();
    this.setState({ rootClass: cx(styles.container, this.hideClassName) });
    this.isDestroy = true;
  }

  render() {
    const {
      children,
    } = this.props;
    return (
      <div className={cx(this.state.rootClass, {[styles.warning]: this.props.type === 'warning'})} onAnimationEnd={this.handleAnimationEnd}>
        <div className={cx(styles.content, {[styles.warning]: this.props.type === 'warning'})}>
          {children as any}
        </div>
      </div>
    )
  }
}

export default Toast
