import { useQuery, UseQueryResult } from '@tanstack/react-query'
import type { FunctionKeyword, KeywordListType, KeywordReturnType } from '@wad/api'

type FunctionType = (param: {
  fetchGetKeyword: FunctionKeyword
  keyword: string
  enabled: boolean
}) => UseQueryResult<KeywordListType>
export const useSearchKeyword: FunctionType = ({ fetchGetKeyword, keyword, enabled }) =>
  useQuery({
    queryKey: ['searchKeyword', keyword],
    queryFn: () => fetchGetKeyword({ keyword }).then((res: KeywordReturnType) => res.data),
    enabled,
  })
