import withIcon, { Props } from './utils/withIcon'

function CalendarOIcon(props: Props) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M24 4V1.6h-1.6V4H9.6V1.6H8V4H3.2v25.6h25.6V4zm3.2 24H4.8V5.6h22.4z" />
      <path d="M8 8.8h3.2V12H8zm6.4 3.2h3.2V8.8h-3.2zm6.4 0H24V8.8h-3.2zM8 18.4h3.2v-3.2H8zm6.4 0h3.2v-3.2h-3.2zm6.4 0H24v-3.2h-3.2zM8 24.8h3.2v-3.2H8zm6.4 0h3.2v-3.2h-3.2zm6.4 0H24v-3.2h-3.2z" />
    </svg>
  )
}

export default withIcon('CalendarOIcon')(CalendarOIcon)
