// eslint-disable-next-line
import React from 'react';
import { TypeState } from './AdState.interface';
import styles from './AdStateBadge.module.scss';

const AdStateTable = {
    Submitted: { name: '요청', bgColor: '#00C4C4' },
    Accepted: { name: '계약 중', bgColor: '#00C4C4' },
    Scheduled: { name: '예정', bgColor: '#00C4C4' },
    In_Progress: { name: '진행 중', bgColor: '#00C4C4' },
    Creating: { name: '작성 중', bgColor: '#00C4C4' },

    Modified_in_Contr: { name: '수정요청', bgColor: '#00C4C4' },
    Modified_in_Failed: { name: '수정요청', bgColor: '#00C4C4' },
    Modified_in_Sched: { name: '수정요청', bgColor: '#00C4C4' },
    Modified_in_Prog: { name: '수정요청', bgColor: '#00C4C4' },

    Payment_Failed: { name: '결제실패', bgColor: '#FF6666' },
    Feedback: { name: '피드백', bgColor: '#FF6666' },
    Feedback_in_Contr: { name: '피드백', bgColor: '#FF6666' },
    Feedback_in_Sched: { name: '피드백', bgColor: '#FF6666' },
    Feedback_in_Prog: { name: '피드백', bgColor: '#FF6666' },
    Recreating: { name: '수정 중', bgColor: '#FF6666' },

    Rejected: { name: '반려', bgColor: '#868E96' },
    Rejected_in_Contr: { name: '반려', bgColor: '#868E96' },
    Canceled_in_Sched: { name: '진행 취소', bgColor: '#868E96' },
    Canceled_in_Prog: { name: '진행 취소', bgColor: '#868E96' },
    Completed: { name: '완료', bgColor: '#868E96' },
};

const StateBadge: React.FC<{ bgColor: string }> = ({ children, bgColor }) => (
    <span className={styles.stateBadge} style={{ backgroundColor: bgColor }}>
        {children}
    </span>
);

type PropAdStateBadge = {
    adState: TypeState;
};
export const AdStateBadge: React.FC<PropAdStateBadge> = ({ adState }) => (
    <StateBadge bgColor={AdStateTable[adState]?.bgColor || '#868E96'}>{AdStateTable[adState]?.name || ''}</StateBadge>
);
