import { api, ResultType } from '../api'

export interface AccountDetailParams {
  pubAreaId: number
  isWadizAdmin: boolean
}
export interface AccountDetail {
  accountNum: number
  advertiserDivision: number
  available: number
  id: number
  impNum: number
  impPeriod: number
  price: number
  pricingType: string
}

export const fetchAccountDetailList = (params: AccountDetailParams): Promise<ResultType<AccountDetail[]>> => {
  const advertiserType = `advertiser_type=${params.isWadizAdmin ? 1 : 0}`
  const pubAreaUrl = `pub-account/list?pub_area_id=${params.pubAreaId}&${advertiserType}`
  return api('GET', pubAreaUrl)
}
