import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom-v5'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { network } from '../../network'
import { getProjectList } from '../_api'

import dayjs from 'dayjs'
import { AreaType, checkMakerLandingURL } from '@legacy/definitions'

import AdCreateContent from '../../components/AdCreateContent'
import { setMenuStatus, openPopup, openAlertPopup, openWaffleModal, setActivatedAdInfo } from '../../reducers'

import { TermsModalContainer } from '../../components/TermsModalContainer'
import { centerGetPubAreaAvailability } from '@wad/api'
import { getTermsAgreements, getWadizOnlyCondition } from '../_api'

class AdCreatePage extends PureComponent {
  state = {
    createStep: 0,
    projectList: [],
    campaignId: '0',
    projectName: '',
    accountList: [],
    thAccountList: [],

    adId: 0,
    isComplete: false,

    guide1: false,
    guide2: false,
    msg1: '',
    msg2: '',

    openGuide: { idx: -1, type: '' }, // 광고등록 스텝2 가이드

    adsPrice: 0, // 전체 광고 금액
    adsVat: 0, // 전체 부가세
    totalPrice: 0, // 총 금액

    projectId: '',
    projectTitle: '',
    projectType: 0,
    comingsoon: 0,
    landingUrl: '',
    openDate: '',

    loadingBarFlag: false,

    // 쿠폰 리스트
    usingCouponList: [], // 사용중인 할인쿠폰 리스트

    // 약관 동의 모달
    isOpenAgreementModal: false,

    shared: null, // 다중지면 플래그
  }

  getListWithMakerId = () => {
    toast('진행할 수 없는 프로젝트 입니다. 광고만들기로 새로 진행해주세요.')
    this.setState({ loadingBarFlag: false })
    this.props.setMenuStatus(true)
    this.props.history.push('/main/ad/list')
  }

  componentDidMount = () => {
    network.callApi({
      url: `maker/isBlocked?maker_id=${this.props.user.userData.makerId}`,
      method: 'get',
      successFunc: (jsonData) => {
        if (jsonData) {
          if (!jsonData.result) {
            this.props.openAlertPopup({
              alertData: {
                content: (
                  <p>
                    메이커님은 관리자에 의해 차단되었습니다. 관리자에게 문의 바랍니다.
                    <br />
                    ad@wadiz.kr로 메일 주시기 바랍니다.
                  </p>
                ),
                closeText: '닫기',
              },
            })
            this.props.setMenuStatus(false)
            this.props.history.push('/main/ad/list')
          }
        }
      },
    })

    this.setState({ loadingBarFlag: true }, () => {
      console.log('location.state', this.props.history.location.state)
      if (this.props.history.location.state) {
        const {
          adId,
          adName,
          campaignId,
          landingUrl,
          // comingsoon,
        } = this.props.history.location.state
        network.callApi({
          url: `project/list?maker_id=${this.props.user.maker_id}`,
          method: 'get',
          successFunc: (jsonData) => {
            if (jsonData.result && jsonData.result.length > 0) {
              let projectList = [...this.state.projectList]
              projectList = jsonData.result
              const project = projectList.find((ex) => ex.campaignId === Number(campaignId))
              network.callApi({
                url: `project/openDate?project_id=${campaignId}&project_type=${project.projectType}`,
                method: 'get',
                successFunc: (openDateJsonData) => {
                  this.setState({
                    ...this.props.history.location.state,
                    projectList,
                    campaignId,
                    projectName: adName,
                    projectType: project.projectType,
                    adId,
                    openDate: openDateJsonData.result.openDate,
                    projectStartDate: project.openDate,
                    loadingBarFlag: false,
                    landingUrl,
                    comingsoon: project.comingsoon,
                  })
                },
                failFunc: () => {
                  this.getListWithMakerId()
                },
              })
            }
          },
          failFunc: () => {
            this.getListWithMakerId()
          },
        })
      } else {
        network.callApi({
          url: `project/list?maker_id=${this.props.user.maker_id}`,
          method: 'get',
          successFunc: (jsonData) => {
            if (jsonData.result && jsonData.result.length > 0) {
              const { campaignId, projectTitle, projectType, landingUrl, comingsoon, projectStartDate } =
                jsonData.result[0]
              const projectName = `광고-${jsonData.result[0].projectTitle}`
              network.callApi({
                url: `project/openDate?project_id=${campaignId}&project_type=${projectType}`,
                method: 'get',
                successFunc: (openDateJsonData) => {
                  this.setState({
                    projectList: jsonData.result,
                    campaignId,
                    projectName,
                    projectTitle,
                    projectType,
                    comingsoon,
                    landingUrl,
                    openDate: openDateJsonData.result.openDate,
                    projectStartDate: projectStartDate,
                    loadingBarFlag: false,
                  })
                },
                failFunc: () => {
                  this.getListWithMakerId()
                },
              })
            } else {
              this.setState({
                loadingBarFlag: false,
              })
              toast('광고를 만들 수 있는 프로젝트가 없습니다. ')
              this.handleToAdList()
            }
          },
          failFunc: () => {
            this.getListWithMakerId()
          },
        })
      }
    })
  }

  static getDerivedStateFromProps = (nextProps, nextState) => {
    const { pathname } = window.location
    const step = pathname.split('/')[4]
    switch (step) {
      case 'step1':
        return { ...nextState, createStep: 0 }
      case 'step2':
        return { ...nextState, createStep: 1 }
      case 'step3':
        return { ...nextState, createStep: 2 }
      default:
        return { ...nextState, createStep: 0 }
    }
  }

  handleClickHistoryBack = () => this.props.history.goBack()

  doValidationCheckForEndDate = () => {
    const { accountList, projectType, campaignId } = this.state
    return Promise.all(
      accountList.map((account) =>
        centerGetPubAreaAvailability({
          startAt: dayjs(account.startAt).format('YYYY-MM-DD'),
          endAt: dayjs(account.endAt).format('YYYY-MM-DD'),
          pubAreaId: account.adCenterPubAreaId,
          projectType,
          campaignId,
        })
      )
    ).then((res) => {
      const newAccountList = accountList.map((account, index) => ({
        ...account,
        dateErrMsg:
          res[index]?.data?.availability !== 'Closed'
            ? ''
            : '설정하신 기간의 예약이 마감되었습니다. 다른 기간을 선택해주세요.',
      }))
      const availability = this.estimationPubAreaAvailability(res)
      return new Promise((resolve, reject) => {
        this.setState({ accountList: newAccountList }, () => {
          // Available:가능, Imminent:마감 임박, Closed:마감, Unavailable: 이전 광고와 연계하여 광고 할 수 없음.
          if (availability === 'Closed') {
            toast('설정하신 기간의 예약이 마감되었습니다. 다른 기간을 선택해주세요')
          }
          if (availability === 'Unavailable') {
            toast('다른 기간을 선택해 주세요. 같은 기간에 이미 진행중인 광고가 있습니다')
            return
          }
          resolve(availability)
        })
      })
    })
  }

  handleClickStep = (createStep) => {
    const { campaignId, projectName, projectTitle, adId, projectType, landingUrl, openDate, comingsoon } = this.state
    if (createStep === 1 && !adId) {
      if (!campaignId) {
        this.setState({ msg1: '프로젝트를 선택하세요' })
        return
      }
      if (!projectName || projectName.trim().length === 0) {
        this.setState({ msg2: '광고명을 입력하세요' })
        return
      }
      this.setState({ loadingBarFlag: true }, () => {
        network.callApi({
          url: 'project/check',
          method: 'post',
          body: {
            projectModel: {
              adCenterMakerId: this.props.user.userData.id,
              campaignId,
              projectName: projectTitle,
              projectType,
              landingUrl,
              comingsoon,
              openDate,
            },
            advertisementModel: {
              adName: projectName,
            },
          },
          successFunc: (jsonData) => {
            getProjectList({ makerId: this.props.user.maker_id }).then((res) => {
              const project = res.find((i) => i.campaignId === Number(campaignId))
              this.setState({
                LnbData: res,
                projectId: project?.id,
              })
            })
            this.setState({ adId: jsonData.result.id, loadingBarFlag: false }, () =>
              this.props.history.push({
                pathname: '/main/ad/create/step2',
                state: { ...this.state, adName: projectName },
              })
            )
          },
          failFunc: () => {
            console.error(`fail- project/check`)
          },
        })
      })
    } else if (createStep === 1 && this.state.adId) {
      if (!this.state.campaignId) {
        this.setState({ msg1: '광고할 프로젝트를 선택하세요' })
        return
      }
      if (!this.state.projectName || this.state.projectName.trim().length === 0) {
        this.setState({ msg2: '프로젝트 광고명을 입력하세요' })
        return
      }

      this.setState({ loadingBarFlag: true }, () => {
        const url = 'advertisement/update'
        network.callApi({
          url,
          method: 'POST',
          body: {
            id: this.state.adId,
            adName: this.state.projectName,
          },
          successFunc: (jsonData) => {
            if (jsonData.result === 'success') {
              const tmpAccountList = this.state.accountList.map((val, idx) => {
                const tempVal = val
                tempVal.callApiType = 'update'
                tempVal.id = this.state.accountList[idx].id || ''
                return tempVal
              })
              this.setState({ accountList: tmpAccountList, loadingBarFlag: false }, () =>
                this.props.history.push({
                  pathname: '/main/ad/create/step2',
                  state: { ...this.state, adName: this.state.projectName },
                })
              )
            }
          },
          failFunc: () => {
            console.error(`fail- ${url}`)
          },
        })
      })
    } else if (createStep === 2) {
      console.log('createStep 2')
      // handleCheckValidation에서 sync로 유효성 체크를 하게 되어 있다.
      if (!this.handleCheckValidation()) {
        // async로 유효성 체크를 하는 걸 handleCheckValidation 함수가 지원하게 하기는 코드 수정이 불가피 하니 이를 우회해서
        // async로 유효성 체크하는 함수를 따로 만든다.
        this.doValidationCheckForEndDate()
          .then((availability) => {
            // Available:가능, Imminent:마감 임박, Closed:마감, Unavailable: 이전 광고와 연계하여 광고 할 수 없음.
            if (availability === 'Closed') {
              return
            }
            this.setState({ loadingBarFlag: true }, () => {
              this.submit(this.state.thAccountList.length === 0 ? 'create' : 'update')
              this.props.history.push({
                pathname: '/main/ad/create/step3',
                state: { ...this.state, adName: this.state.projectName },
              })
            })
          })
          .catch((e) => {
            console.log('err', e)
          })
      }
    } else {
      this.props.history.push({ pathname: '/main/ad/create/step1', state: { ...this.state } })
    }
    window.scrollTo(0, 0)
  }

  handleClickSave = () => {
    const { accountList, adId, landingUrl } = this.state
    const { user } = this.props
    const promiseList = []
    const createRequestBodyList = []
    const updateRequestBodyList = []
    let flag = true
    console.log('save', accountList)
    accountList.forEach((adAccount) => {
      const requestBody = {}
      if (adAccount.callApiType === 'update') {
        requestBody.id = adAccount.id
        requestBody.adCenterAdvertisementId = adId
        requestBody.adCenterBgColorId = adAccount.adCenterBgColorId
        requestBody.adCenterPubAccountId = adAccount.adCenterPubAccountId
        requestBody.adCenterPubAreaId = adAccount.adCenterPubAreaId
        requestBody.adCenterPubGoodsId = adAccount.goodsid
        requestBody.adCenterStatusId = 1 // 추후 수정될 가능성 있음
        if (adAccount.startAt && adAccount.startAt !== '') requestBody.startAt = `${adAccount.startAt} 00:00:00`
        if (adAccount.endAt && adAccount.endAt !== '') requestBody.endAt = `${adAccount.endAt} 00:00:00`
        requestBody.status = 1
        requestBody.advertiserDivision = user.isWadizAdmin ? 1 : 0
        requestBody.bgColor = adAccount.bgColor
        // tempList.imgFile = val.imgFile;
        if (Object.prototype.hasOwnProperty.call(adAccount, 'imgUrl')) {
          requestBody.imgUrl = adAccount.imgUrl
        }
        requestBody.mainCopy = adAccount.mainCopy
        requestBody.keyword = adAccount.keyword
        requestBody.subCopy = adAccount.subCopy
        requestBody.landingUrl = adAccount.landingUrl === '' ? landingUrl : adAccount.landingUrl
        const price = adAccount.price * adAccount.normalPeriod + adAccount.holidayPrice * adAccount.holidayPeriod
        requestBody.price = price
        requestBody.vat = Math.floor(price * 0.1)

        updateRequestBodyList.push(requestBody)
      } else {
        const tempList = {}
        tempList.adCenterAdvertisementId = adId
        tempList.adCenterBgColorId = adAccount.adCenterBgColorId
        tempList.adCenterPubAccountId = adAccount.id
        tempList.adCenterPubAreaId = adAccount.adCenterPubAreaId
        tempList.adCenterPubGoodsId = adAccount.goodsid
        tempList.adCenterStatusId = 1 // 추후 수정될 가능성 있음
        if (adAccount.startAt && adAccount.startAt !== '') tempList.startAt = `${adAccount.startAt} 00:00:00`
        if (adAccount.endAt && adAccount.endAt !== '') tempList.endAt = `${adAccount.endAt} 00:00:00`
        tempList.status = user.isWadizAdmin ? 3 : 1
        tempList.adProcessStatus = user.isWadizAdmin ? 2 : 0
        tempList.advertiserDivision = user.isWadizAdmin ? 1 : 0
        tempList.bgColor = adAccount.selColor
        // tempList.imgFile = val.imgFile;
        if (Object.prototype.hasOwnProperty.call(adAccount, 'imgUrl')) tempList.imgUrl = adAccount.imgUrl
        tempList.mainCopy = adAccount.mainCopy
        tempList.keyword = adAccount.keyword
        tempList.subCopy = adAccount.subCopy
        tempList.landingUrl = landingUrl
        const price = adAccount.price * adAccount.normalPeriod + adAccount.holidayPrice * adAccount.holidayPeriod
        tempList.price = price
        tempList.vat = Math.floor(price * 0.1)

        createRequestBodyList.push(tempList)
      }
    })

    const createAdAccount = new Promise((resolve, reject) => {
      const url = this.state.shared === 1 ? 'creative/create-sync' : 'creative/create'
      return network.callApi({
        url: url,
        method: 'post',
        body: this.state.shared === 1 ? createRequestBodyList[0] : createRequestBodyList,
        successFunc: (jsonData) => {
          resolve(jsonData)
          if (jsonData.result === 'success') {
            network.callApi({
              url: `creative/list?advertisement_id=${this.state.adId}`,
              method: 'get',
              successFunc: ({ result }) => {
                if (result) {
                  const resultList = result.sort((a, b) => {
                    return Number(b.main) - Number(a.main) // 메인 영역인 값을 index 0으로 만들기
                  })
                  const tmpAccountList = this.state.shared === 1 ? this.state.accountList[0] : this.state.accountList
                  resultList.forEach((val, idx) => {
                    tmpAccountList[idx].id = val.id
                    tmpAccountList[idx].callApiType = 'update'
                  })
                  this.setState({ accountList: tmpAccountList })
                }
              },
              failFunc: () => {
                console.error(`fail -  creative/list?advertisement_id=${this.state.adId}`)
              },
            })
          } else if (jsonData.result === 'duplicated') {
            toast('이미 생성된 광고 입니다. 관리자에게 문의해 주세요.')
          }
        },
        failFunc: (error) => {
          flag = false
          reject(error)
        },
      })
    })
    promiseList.push(createAdAccount)

    const updateAdAccount = new Promise((resolve, reject) => {
      const url = this.state.shared === 1 ? 'creative/update-sync' : 'creative/update'
      return network.callApi({
        url: url,
        method: 'POST',
        body: this.state.shared === 1 ? updateRequestBodyList[0] : updateRequestBodyList,
        successFunc: (jsonData) => {
          resolve(jsonData)
        },
        failFunc: (error) => {
          flag = false
          reject(error)
        },
      })
    })

    promiseList.push(updateAdAccount)

    if (flag) toast('저장되었습니다.')
    else toast('저장에 실패했습니다.')
  }

  handleChangeState = (action) => this.setState({ ...action })

  handleClickDatepicker = (data, idx) => {
    this.props.openPopup({
      dialogType: 'adDate',
      dialogData: {
        data,
        openDate: data.openDate,
        confirmFunc: (startDate, endDate, startTime) => this.handleSetAdDate(idx, startDate, endDate, startTime),
      },
    })
  }

  handleSetAdDate = (idx, startDate, endDate, startTime) => {
    const { accountList } = this.state
    const newAccountList = [...accountList]

    const splitedStartAt = startDate.split('-')
    const splitedEndAt = endDate.split('-')

    newAccountList[idx].startAt = `${splitedStartAt[0]}-${
      splitedStartAt[1][1] ? splitedStartAt[1] : `0${splitedStartAt[1]}`
    }-${splitedStartAt[2][1] ? splitedStartAt[2] : `0${splitedStartAt[2]}`}`
    newAccountList[idx].endAt = `${splitedEndAt[0]}-${splitedEndAt[1][1] ? splitedEndAt[1] : `0${splitedEndAt[1]}`}-${
      splitedEndAt[2][1] ? splitedEndAt[2] : `0${splitedEndAt[2]}`
    }`
    newAccountList[idx].startTime = startTime
    newAccountList[idx].dateErrMsg = ''
    this.setState({ accountList: newAccountList })
  }

  handleClickRegister = (adData) => {
    const { campaignId, projectType, projectId, landingUrl } = this.state
    const getGoodListData = (id, isWadizAdmin, isPackaged) => {
      let selectType = isPackaged ? 2 : 0

      // 슈퍼유저일 경우 다중지면도 패키지가 아닌 단일로 해야함 type: 0 - 일반 / 2 - 패키지
      // if (this.props.user.isSuperUser) selectType = 0
      return new Promise((resolve, reject) =>
        network.callApi({
          url: `goods/list?pub_account_id=${id}&advertiser_type=${isWadizAdmin ? '1' : '0'}&type=${selectType}&${
            projectType === 3 ? 'wdz_store_project_id' : 'wdz_campaign_id'
          }=${campaignId}`,
          method: 'get',
          successFunc: (jsonData) => resolve(jsonData.result),
          failFunc: (error) => reject(error),
        })
      )
    }
    const getMakerInfo = () => {
      new Promise((resolve, reject) =>
        network.callApi({
          url: `maker-page/info?project_id=${projectId}`,
          method: 'get',
          successFunc: (jsonData) => {
            this.setState({
              makerInfo: jsonData.result,
            })
            resolve(jsonData.result)
          },
          failFunc: (error) => reject(error),
        })
      )
    }

    // 메이커 정보 가져오기 - LandingURL
    if (checkMakerLandingURL(adData.areaInfo.areas[0].id)) {
      getMakerInfo()
    }

    const getPubAccountInfoData = (id) =>
      new Promise((resolve, reject) =>
        network.callApi({
          url: `pub-account/info?pub_account_id=${id}`,
          method: 'get',
          successFunc: (jsonData) => {
            resolve(jsonData.result)
          },
          failFunc: (error) => {
            reject(error)
          },
        })
      )

    const getDiscountCouponCode = (id) =>
      new Promise((resolve, reject) =>
        network.callApi({
          url: `discount/list?campaign_id=${campaignId}&project_type=${projectType}&pub_account_id=${id}&is_used=1`,
          method: 'get',
          successFunc: (jsonData) => {
            resolve(jsonData.result.list || [])
          },
          failFunc: (error) => {
            reject(error)
          },
        })
      )

    const confirmFunc = (data) => {
      console.log('comfi', data)
      const aList = data.returnState.accountList.reduce((acc, item) => {
        console.log(item)
        // shared - null-패키지아님, 0-일반패키지, 1-다중 지면
        if (!item.packagedInfo || data.returnState.areaInfo.shared === 1) {
          return [...acc, item]
        }
        const {
          discount,
          endAt,
          goodsIdx,
          goodsList,
          goodsNum,
          isAdded,
          startAt,
          packagedInfo: { accountItem, goodsItem: selectedGoods, positionName, manualUrl },
        } = item
        console.log('packaged?', data, selectedGoods, goodsIdx, goodsList, item)

        return [
          ...acc,
          {
            ...item,
            price: goodsList[goodsIdx].price,
            packaged: 'master',
          },
          {
            ...accountItem,
            price: 0, // 패키지 이기 때문에
            discount,
            endAt,
            goodsIdx,
            goodsList,
            goodsNum,
            goodsid: selectedGoods[goodsIdx].id, //selectedGoods.goods[goodsIdx].id, //
            isAdded,
            manualUrl,
            positionName,
            selectedGoods,
            startAt,
            packaged: 'slave',
          },
        ]
      }, [])

      const makeAccountItem = (base, goods, info, discountCoupon) => {
        console.log('makleAccountItem', base, goods, info, discountCoupon)

        const newAccountItem = {
          ...base,
          goods,
          openDate: dayjs(data.returnState.startDate).format('YYYY-MM-DD'),
          mainCopy: '',
          subCopy: '',
          landingUrl,
          adCenterPubAccountId: base.id,
          vat: Math.floor(base.price * 0.1),
          info: { ...info, landingUrl },
          discountCoupon,
          // adStartDateId: `adStartDate_${base.id}`,
          // adEndDateId: `adEndDate_${base.id}`,
          adCenterPubAreaId: info.adCenterPubAreaId,
          goodsid: goods[base.goodsIdx].id,
        }
        console.log('new', newAccountItem)
        if (discountCoupon.length > 0) {
          newAccountItem.discountIdx = 0
          newAccountItem.discountId = newAccountItem.discountCoupon[newAccountItem.discountIdx].id
          newAccountItem.discountPrice =
            newAccountItem.price - (newAccountItem.price * newAccountItem.discountCoupon[0].discountRate) / 100
        } else {
          newAccountItem.discountPrice = null
        }

        return newAccountItem
      }

      Promise.all(
        aList.reduce(
          (acc, item) => [
            ...acc,
            getGoodListData(item.id, this.props.user.isWadizAdmin, item.packaged || item.isPackaged),
            getPubAccountInfoData(item.id),
            getDiscountCouponCode(item.id),
          ],
          []
        )
      ).then((response) => {
        const len = response.length / 3
        const tmpList = []
        for (let i = 0; i < len; i += 1) {
          const baseIdx = i * 3
          tmpList.push(makeAccountItem(aList[i], response[baseIdx], response[baseIdx + 1], response[baseIdx + 2]))
        }
        const accountList = [...this.state.accountList, ...tmpList]
        console.log('temaccountListpList', accountList)
        const prices = accountList.reduce(
          (a, adAccount) => {
            const price =
              adAccount.price * (adAccount.normalPeriod || 0) + adAccount.holidayPrice * (adAccount.holidayPeriod || 0)
            return {
              adsPrice: a.adsPrice + price,
              adsVat: a.adsVat + Math.floor(price * 0.1),
            }
          },
          { adsPrice: 0, adsVat: 0 }
        )

        this.setState(
          {
            accountList,
            shared: adData.areaInfo.shared,
            totalPrice: prices.adsPrice + prices.adsVat,
            ...prices,
          },
          () => {
            accountList.forEach((item) => {
              const startDate = document.getElementById(item.adStartDateId)
              if (startDate) {
                startDate.setAttribute('readonly', 'readonly')
              }

              const endDate = document.getElementById(item.adEndDateId)
              if (endDate) {
                endDate.setAttribute('readonly', 'readonly')
              }
            })
          }
        )
      })
    }
    confirmFunc({ returnState: adData })
    // this.props.openPopup({
    //   dialogType: 'adAccount',
    //   dialogData: {
    //     isStoreProject: this.isStoreProject(),
    //     campaignId,
    //     openDate,
    //     projectType,
    //     projectStartDate,
    //     comingsoon,
    //     accountList,
    //     confirmFunc,
    //   },
    // })
  }

  isWadizOnlyGoodsSelected = () => {
    const { accountList } = this.state
    // accountList 는 상품 정보를 가지고 있는 배열이다.
    // FE레거시 코드가 이부분을 굉장히 복잡하게 관리하고 있는데,
    // 어쨌든 store에 경우 상품을 하나씩만 선택가능하므로 wadiz Only를 확인하는 대상은 0번 index일 수 밖에없다.
    if (!accountList[0]) {
      return false
    }
    const { selectedGoods } = accountList[0]
    return selectedGoods?.badgeType === 'WadizOnly'
  }

  isStoreProject = () => {
    const { projectList, campaignId } = this.state
    return projectList?.find((p) => `${p.campaignId}` === `${campaignId}`)?.projectType === 3
  }

  checkIsRefundAccount = () => {
    return new Promise((resolve, reject) => {
      network.callApi({
        url: `maker/info?maker_id=${this.props.user.maker_id}`,
        method: 'get',
        successFunc: ({ result }) => {
          if (result) {
            resolve(result.bankCode)
            return result.bankCode
          }
          return null
        },
        failFunc: (error) => {
          reject(error)
        },
      })
    })
  }

  validateAccountListOfPackage = (aList) => {
    const packageIdList = aList.reduce((idList, n) => (!n.packagedId ? idList : [...idList, n.packagedId]), [])
    const subPackageList = packageIdList
      .map((n) => aList.find((acc) => acc.adCenterPubAreaId === n))
      .filter((item) => item !== undefined)
    const isNotValidate =
      subPackageList.reduce((failureList, sub) => {
        if (sub.costPrice !== 0) {
          return [...failureList, sub]
        }
        return failureList
      }, []).length > 0

    return !isNotValidate
  }

  estimationPubAreaAvailability = (list) => {
    // Available:가능, Imminent:마감 임박, Closed:마감, Unavailable: 이전 광고와 연계하여 광고 할 수 없음.
    if (list.findIndex((r) => r?.data?.availability === 'Closed') > -1) {
      return 'Closed'
    }
    if (list.findIndex((r) => r?.data?.availability === 'Imminent') > -1) {
      return 'Imminent'
    }
    if (list.findIndex((r) => r?.data?.availability === 'Unavailable') > -1) {
      return 'Unavailable'
    }

    return 'Available'
  }

  doAgreementConfirm = (isConfirmed) => {
    this.setState({ isOpenAgreementModal: false }, () => {
      if (isConfirmed) {
        this.handleOpenReservationPaymentDialogImp()
      }
    })
  }

  isFakeWadizOnlyCondition = async () => {
    if (!this.isWadizOnlyGoodsSelected() || !this.isStoreProject() || this.props.user.isSuperUser) {
      return false
    }
    const wadizOnlyCondition = await getWadizOnlyCondition({
      adMakerId: this.props.user?.maker_id,
      wdzStoreProjectId: this.state.campaignId,
    })
    return !wadizOnlyCondition
  }

  handleOpenReservationPaymentDialog = async () => {
    const isFakeWadizOnly = await this.isFakeWadizOnlyCondition()

    if (isFakeWadizOnly) {
      this.props.openWaffleModal({
        title: '광고 상품을 다시 선택해 주세요',
        message: '해당 프로젝트는 와디즈 Only 프로젝트가 아니므로 할인 적용 대상이 아닙니다.',
        buttonColor: 'secondary',
      })
      return
    }

    const isAgreements = await getTermsAgreements({ adMakerId: this.props.user?.maker_id })
    if (isAgreements) {
      this.handleOpenReservationPaymentDialogImp()
    } else {
      this.setState({ isOpenAgreementModal: true })
    }
  }

  handleOpenReservationPaymentDialogImp = () => {
    const { totalPrice, adId, thAccountList, projectType, campaignId } = this.state
    if (!this.validateAccountListOfPackage(thAccountList)) {
      toast('패키지 설정을 확인해 주세요. ')
      return
    }

    const accountList = [...thAccountList]
    Promise.all(
      accountList.map((account) =>
        centerGetPubAreaAvailability({
          startAt: dayjs(account.startAt).format('YYYY-MM-DD'),
          endAt: dayjs(account.endAt).format('YYYY-MM-DD'),
          pubAreaId: account.adCenterPubAreaId,
          projectType,
          campaignId,
        })
      )
    ).then((res) => {
      // Available:가능, Imminent:마감 임박, Closed:마감, Unavailable: 이전 광고와 연계하여 광고 할 수 없음.
      const availability = this.estimationPubAreaAvailability(res)
      if (availability === 'Unavailable') {
        // this.props.openWaffleModal({
        //   title: '다른 기간을 선택해 주세요.',
        //   message: '같은 기간에 이미 진행중인 광고가 있습니다',
        // })

        toast('다른 기간을 선택해 주세요. 같은 기간에 이미 진행중인 광고가 있습니다')
        return
      }
      this.props.openPopup({
        dialogType: 'creditCardDetailDialog',
        dialogData: {
          title: '카드 결제 예약하기',
          price: totalPrice,
          advertisementId: adId,
          accountList,
          confirmFunc: (isComplete) => this.handleClickReservationPayment(isComplete),
          closeText: '닫기',
          areaAvailable: availability,
        },
      })
    })
  }

  handleClickReservationPayment = (isComplete) => {
    this.setState({ isComplete }, () => window.scrollTo(0, 0))
  }

  handleChangeGuide = (num) => {
    if (num === 1) {
      this.setState((prevState) => ({ guide1: !prevState.guide1, guide2: false }))
    } else if (num === 2) {
      this.setState((prevState) => ({ guide2: !prevState.guide2, guide1: false }))
    }
  }

  imgFileSel = (e, idx, iwidth, iheight, isize) => {
    const { target } = e
    const tempfile = target.files[0]
    const fr = new FileReader()
    const image = new Image()
    if (!tempfile) return
    fr.readAsDataURL(tempfile)
    fr.onload = (file) => {
      image.src = file.target.result
      image.onload = () => {
        const { height, width } = image
        if (width !== iwidth || height !== iheight) {
          toast('이미지 사이즈를 확인하세요.')
          target.value = null
          return
        }
        if (tempfile.size > isize * 1024 * 1024) {
          toast('이미지 용량을 확인하세요.')
          target.value = null
          return
        }

        const data = new FormData()
        data.append('img_file', tempfile)

        const { accountList } = this.state
        const { goodsIdx, goodsList, adCenterPubAreaId } = accountList[idx]
        const { badgeType } = goodsList[goodsIdx]
        data.append(
          'img_type',
          badgeType === 'Popularity' && adCenterPubAreaId === AreaType.comingSoonList ? 'COMBINATION' : 'NORMAL'
        )

        const url = 'creative/image/upload'
        network.callApi({
          url,
          method: 'post',
          body: data,
          isFormData: true,
          successFunc: ({ result, response }) => {
            if (!result) {
              toast('이미지 업로드 실패')
              return
            }
            this.setImage(response.imgUrl, idx)
          },
          failFunc: () => {
            console.error(`fail- ${url}`)
          },
        })
      }
    }
  }
  setImage = (imgUrl, idx) => {
    const { accountList } = this.state
    const newAccountList = [...accountList]
    newAccountList[idx].imgUrl = imgUrl
    newAccountList[idx].imageErrMsg = ''
    this.setState({ accountList: newAccountList }, () => {
      const elm = document.getElementById(`fileImg${idx}`)
      if (elm) {
        elm.src = imgUrl
      }
    })
  }

  selectColor = (selColor, idx) => {
    const { accountList } = this.state
    const newAccountList = [...accountList]
    console.log('setColor', selColor)
    newAccountList[idx].selColor = selColor.bgColor
    newAccountList[idx].adCenterBgColorId = selColor.id
    newAccountList[idx].backgroundColorErrMsg = ''
    this.setState({ accountList: newAccountList })
  }

  inputTextChange = (name, value, idx) => {
    const { accountList } = this.state
    const newAccountList = [...accountList]
    newAccountList[idx][name] = value
    newAccountList[idx][`${name}ErrMsg`] = ''

    this.setState({ accountList: newAccountList })
  }

  onSelectGoods = (val, idx) => {
    const { accountList } = this.state
    const newAccountList = [...accountList]
    const { impNum, adCenterPubAccountId, impPeriod, price, holidayPrice } = accountList[idx].goods[val]
    newAccountList[idx].impNum = impNum
    // newAccountList[idx].goodsid = id
    newAccountList.forEach((v, index) => {
      v.goodsid = accountList[index].goods[val].id
      v.goodsIdx = Number(val)
    })
    newAccountList[idx].adCenterPubAccountId = adCenterPubAccountId
    newAccountList[idx].impPeriod = impPeriod
    newAccountList[idx].price = price
    newAccountList[idx].holidayPrice = holidayPrice
    newAccountList[idx].endAt = dayjs(newAccountList[idx].startAt)
      .add(impPeriod - 1, 'day')
      .format('YYYY-MM-DD')
    newAccountList[idx].nameErrMsg = ''
    newAccountList[idx].selectedGoods = newAccountList[idx].goods[val]

    if (newAccountList[idx].discountCoupon.length > 0) {
      newAccountList[idx].discountIdx = 0
      newAccountList[idx].discountId = newAccountList[idx].discountCoupon[newAccountList[idx].discountIdx].id
      newAccountList[idx].discountPrice =
        newAccountList[idx].price -
        (newAccountList[idx].price * newAccountList[idx].discountCoupon[0].discountRate) / 100
    } else {
      newAccountList[idx].discountPrice = null
    }

    // newAccountList[idx].vat = Math.floor(
    //   newAccountList[idx].discountPrice || newAccountList[idx].discountPrice === 0
    //     ? newAccountList[idx].discountPrice * 0.1
    //     : price * 0.1
    // )
    let resultPrice =
      newAccountList[idx].price * newAccountList[idx].normalPeriod +
      newAccountList[idx].holidayPeriod * newAccountList[idx].holidayPrice
    let resultVat = Math.floor(resultPrice * 0.1)

    // newAccountList.forEach((v) => {
    //   if (v.price) {
    //     if (v.discountPrice || v.discountPrice === 0) {
    //       resultPrice += v.discountPrice
    //     } else {
    //       resultPrice += v.price
    //     }
    //   }

    //   if (v.vat) {
    //     resultVat += v.vat
    //   }
    // })

    this.setState({
      accountList: newAccountList,
      adsPrice: resultPrice,
      adsVat: resultVat,
      totalPrice: resultPrice + resultVat,
    })
  }

  doCheckEndDate = (startAt, endAt, id) => {
    const { projectType, campaignId } = this.state
    return centerGetPubAreaAvailability({ startAt, endAt, pubAreaId: id, projectType, campaignId }).then((res) => {
      // Available:가능, Imminent:마감 임박, Closed:마감, Unavailable: 이전 광고와 연계하여 광고 할 수 없음.
      const {
        data: { availability },
      } = res
      if (availability === 'Closed') {
        toast('설정하신 기간의 예약이 마감되었습니다. 다른 기간을 선택해주세요.')
      }
      return availability
    })
  }

  onChangeDateRange = (startAt, endAt, idx, checkHolidays) => {
    const accountList = [...this.state.accountList]
    const newAccountList = [...accountList]

    const areaId = newAccountList[idx].adCenterPubAreaId

    this.doCheckEndDate(startAt, endAt, areaId).then((availability) => {
      // Available:가능, Imminent:마감 임박, Closed:마감, Unavailable: 이전 광고와 연계하여 광고 할 수 없음.
      if (availability === 'Closed') {
        return
      }
      const currentAccount = newAccountList[idx]
      currentAccount.startAt = startAt
      currentAccount.endAt = endAt
      currentAccount.adStartDateFocus = false
      currentAccount.adEndDateFocus = false
      currentAccount.holidayPeriod = checkHolidays.holidays
      currentAccount.normalPeriod = checkHolidays.normalDays

      const price =
        currentAccount.price * checkHolidays.normalDays + currentAccount.holidayPrice * checkHolidays.holidays
      currentAccount.vat = Math.floor(price * 0.1)

      const adsPrice = price
      const adsVat = Math.floor(price * 0.1)
      const totalPrice = price + currentAccount.vat

      if (currentAccount.packagedInfo) {
        const pIdx = newAccountList.findIndex((acc) => acc.id === currentAccount.packagedInfo.accountItem.id)
        if (pIdx > -1) {
          const slaveAccount = newAccountList[pIdx]
          slaveAccount.startAt = currentAccount.startAt
          slaveAccount.endAt = currentAccount.endAt
        }
      }
      this.setState({
        accountList: newAccountList,
        totalPrice,
        adsPrice,
        adsVat,
      })
    })
  }

  onFocusChange = (name, value, idx) => {
    const accountList = [...this.state.accountList]
    const newAccountList = [...accountList]

    if (name.indexOf('adStartDateFocus_') >= 0) {
      newAccountList[idx].adStartDateFocus = value
    } else {
      newAccountList[idx].adEndDateFocus = value
    }

    this.setState({
      accountList: newAccountList,
    })
  }

  onSelectDiscountCoupon = (val, idx) => {
    const { accountList } = this.state
    const newAccountList = [...accountList]

    const account = newAccountList[idx]
    if (val === '') {
      account.discountId = ''
      account.discountIdx = ''
      account.discountPrice = account.price
    } else {
      account.discountId = account.discountCoupon[val].id
      account.discountIdx = val
      account.discountPrice = account.price - account.price * (account.discountCoupon[val].discountRate / 100)
    }

    let adsPrice = 0
    let adsVat = 0
    let totalPrice = 0

    newAccountList.forEach((adAccount) => {
      if (adAccount.discountPrice || adAccount.discountPrice === 0) {
        adsPrice += adAccount.discountPrice
        adsVat += Math.floor(adAccount.discountPrice * 0.1)
      } else {
        adsPrice += adAccount.price
        adsVat += Math.floor(adAccount.price * 0.1)
      }
    })

    totalPrice = adsPrice + adsVat

    this.setState({
      accountList: newAccountList,
      adsPrice,
      adsVat,
      totalPrice,
    })
  }

  imgDel = (idx) => {
    const { accountList } = this.state
    const newAccountList = [...accountList]
    delete newAccountList[idx].imgUrl

    this.setState({ accountList: newAccountList })
  }

  accountListDel = (e, idx) => {
    this.props.openPopup({
      dialogType: 'adAccountConfirmDialog',
      dialogData: {
        type: 'delete',
        title: '만들고 있던 광고 상품을 삭제 하시겠습니까?',
        confirmText: '삭제',
        confirmFunc: () => {
          this.handleThirdAccountListDel(e, idx)
        },
      },
    })
  }

  stepThirdAccountListDel = (e, idx) => {
    this.props.openPopup({
      dialogType: 'adAccountConfirmDialog',
      dialogData: {
        type: 'delete',
        title: '만들고 있던 광고 상품을 삭제 하시겠습니까?',
        confirmText: '삭제',
        confirmFunc: () => {
          this.handleThirdAccountListDel(e, idx)
        },
      },
    })
  }

  handleThirdAccountListDel = (e, idx) => {
    const { accountList, thAccountList } = this.state

    const newThAccountList = [...thAccountList]
    const newAccountList = [...accountList]
    const deleteAccount = this.state.accountList[idx]

    const url = 'creative/delete'
    network.callApi({
      url,
      method: 'POST',
      body: {
        // id: this.state.thAccountList[idx].id,
        id: deleteAccount.id,
      },
      successFunc: (jsonData) => {
        if (jsonData.result) {
          if (deleteAccount.discountId) {
            network.callApi({
              url: 'discount/update',
              method: 'POST',
              body: { id: deleteAccount.discountId, isUsed: 1 },
              successFunc: () => {
                console.info(`success- ${url}`)
              },
              failFunc: () => {
                console.error(`fail- ${url}`)
              },
            })
          }

          const { packagedInfo, packaged } = newAccountList[idx]
          const delList = [idx]

          if (packagedInfo) {
            const {
              accountItem: { id },
            } = packagedInfo
            delList.push(newAccountList.findIndex((ac) => ac.adCenterPubAccountId === id))
          } else if (packaged === 'slave') {
            delList.push(
              newAccountList.findIndex(
                (ac) => ac.packagedInfo && ac.packagedInfo.accountItem.id === newAccountList[idx].adCenterPubAccountId
              )
            )
          }

          delList
            .sort()
            .reverse()
            .forEach((i) => {
              newThAccountList.splice(i, 1)
              newAccountList.splice(i, 1)
            })

          let resultPrice = 0
          let resultVat = 0

          newAccountList.forEach((v) => {
            if (v.price) {
              if (v.discountPrice || v.discountPrice === 0) {
                resultPrice += v.discountPrice
              } else {
                resultPrice += v.price
              }
            }
            if (v.vat) resultVat += v.vat
          })

          this.setState(
            {
              thAccountList: newThAccountList,
              adsPrice: resultPrice,
              adsVat: resultVat,
              totalPrice: resultPrice + resultVat,
              accountList: newAccountList,
            },
            () => this.props.history.push({ state: { ...this.state } })
          )
        }
      },
      failFunc: () => {
        console.error(`fail- ${url}`)
      },
    })
  }

  stepThirdAccountListGet = () => {
    const url = `creative/reserved/list?advertisement_id=${this.state.adId}`
    network.callApi({
      url,
      method: 'get',
      successFunc: (jsonData) => {
        if (jsonData.result) {
          if (jsonData.result.length <= 0) this.handleToAdList()
          else {
            const result = jsonData.result.sort((a, b) => {
              return Number(b.main) - Number(a.main) // 메인 영역인 값을 index 0으로 만들기
            })
            console.log('res', this.state.accountList, this.state.shared, result)
            // 다중 지면인 경우 main이 1이 메인
            const resultList = this.state.shared === 1 ? result.filter((i) => i.main === 1) : result
            const tmpAccountList = this.state.accountList
            tmpAccountList.forEach((val, idx) => {
              tmpAccountList[idx].id = resultList[idx].id
              tmpAccountList[idx].callApiType = 'update'
            })

            this.setState(
              {
                thAccountList: resultList,
                accountList: tmpAccountList,
                loadingBarFlag: false,
              },
              () =>
                this.props.history.push({
                  pathname: '/main/ad/create/step3',
                  state: { ...this.state, adName: this.state.projectName },
                })
            )
          }
        }
      },
      failFunc: (err) => {
        console.error(`fail- ${url}`)
      },
    })
  }

  handleOpenGuideType = (idx, type) => {
    this.setState((prevState) => ({ openGuide: { idx, type: prevState.openGuide.type === type ? '' : type } }))
  }

  handleCheckValidation = () => {
    let flag = false
    let toastMsg = ''
    const { accountList } = this.state
    const newAccountList = [...accountList]
    newAccountList.forEach((val, idx) => {
      if (!Object.prototype.hasOwnProperty.call(val, 'goodsid') || val.goodsid === '') {
        newAccountList[idx].nameErrMsg = '광고 상품을 선택하세요.'
        flag = true
      } else newAccountList[idx].nameErrMsg = ''
      if (!Object.prototype.hasOwnProperty.call(val, 'startAt') || val.startAt === '') {
        newAccountList[idx].dateErrMsg = '광고 기간을 선택하세요.'
        flag = true
      } else newAccountList[idx].dateErrMsg = ''
      if ((!Object.prototype.hasOwnProperty.call(val, 'imgUrl') || val.imgUrl === '') && !(val.info.imageYn === 0)) {
        newAccountList[idx].imageErrMsg = '광고 소재를 등록해주세요.'
        flag = true
      } else newAccountList[idx].imageErrMsg = ''
      if (
        (!Object.prototype.hasOwnProperty.call(val, 'selColor') || val.selColor === '') &&
        !(val.info.bgColorYn === 0)
      ) {
        newAccountList[idx].backgroundColorErrMsg = '색상을 선택하세요.'
        flag = true
      } else newAccountList[idx].backgroundColorErrMsg = ''
      if (
        (!Object.prototype.hasOwnProperty.call(val, 'mainCopy') || val.mainCopy === '') &&
        !(val.info.mainCopyYn === 0)
      ) {
        newAccountList[idx].mainCopyErrMsg = '메인 카피를 등록하세요.'
        flag = true
      } else newAccountList[idx].mainCopyErrMsg = ''
      if (
        (!Object.prototype.hasOwnProperty.call(val, 'subCopy') || val.subCopy === '') &&
        !(val.info.subCopyYn === 0)
      ) {
        newAccountList[idx].subCopyErrMsg = '서브 카피를 등록하세요.'
        flag = true
      } else newAccountList[idx].subCopyErrMsg = ''

      if (
        (val.info.mainCopyLineNum <= 1 && val.mainCopy.indexOf('\n') >= 0) ||
        (val.info.subCopyLineNum <= 1 && val.subCopy.indexOf('\n') >= 0)
      ) {
        toastMsg = '줄바꿈 여부를 확인하세요.'
      }
      if (val.info.adCenterPubAreaId === AreaType.searchKeyword && !val.keyword) {
        flag = true
      }
    })
    if (flag || toastMsg !== '') {
      this.setState({ newAccountList })
      if (toastMsg !== '') {
        toast(toastMsg)
      } else {
        toast('입력 정보를 확인하세요.')
      }
    }
    console.log('flag', flag)
    return flag
  }

  submit = (whatWork) => {
    const { accountList, adId, usingCouponList } = this.state
    const { user } = this.props
    let apiPostacList = []
    const tmpCouponList = []
    const promiseList = []
    const createRequestBodyList = []
    const updateRequestBodyList = []
    const modifiedAdAccountList = []
    // let result = true;
    if (whatWork === 'create') {
      accountList.forEach((val) => {
        const tempList = {}
        tempList.adCenterAdvertisementId = adId
        tempList.adCenterBgColorId = val.adCenterBgColorId
        tempList.adCenterPubAccountId = val.id
        tempList.adCenterPubAreaId = val.adCenterPubAreaId
        tempList.adCenterPubGoodsId = val.goodsid
        tempList.adCenterStatusId = 1 // 추후 수정될 가능성 있음
        if (val.startAt && val.startAt !== '') tempList.startAt = `${val.startAt} 00:00:00`
        if (val.endAt && val.endAt !== '') {
          const endDate = dayjs(val.endAt).add(1, 'day').format('YYYY-MM-DD')
          tempList.endAt = `${endDate} 00:00:00`
        }
        tempList.status = user.isWadizAdmin ? 3 : 1
        tempList.adProcessStatus = user.isWadizAdmin ? 1 : 0
        tempList.advertiserDivision = user.isWadizAdmin ? 1 : 0
        tempList.bgColor = val.selColor
        // tempList.imgFile = val.imgFile;
        if (Object.prototype.hasOwnProperty.call(val, 'imgUrl')) tempList.imgUrl = val.imgUrl
        tempList.mainCopy = val.mainCopy
        tempList.subCopy = val.subCopy
        tempList.keyword = val.keyword
        tempList.landingUrl = val.landingUrl
        // if (val.discountId) {
        //   tmpCouponList.push(val.discountId)
        //   tempList.price = val.discountPrice
        //   tempList.costPrice = val.price
        //   tempList.adCenterDiscountId = val.discountId
        //   tempList.usedDiscount = 2
        // } else {
        //   tempList.price = val.price
        //   tempList.adCenterDiscountId = 1
        //   tempList.usedDiscount = 1
        // }
        console.log('val', val)
        const price = val.price * (val.normalPeriod || 0) + val.holidayPrice * (val.holidayPeriod || 0)
        tempList.price = price
        tempList.vat = Math.floor(price * 0.1)

        // tempList.vat = Math.floor(tempList.price * 0.1)
        apiPostacList.push(tempList)
        console.log('ff', apiPostacList)
      })

      if (apiPostacList.length > 0) {
        const newCreateAdAccount = new Promise((resolve, reject) => {
          console.log('newCreateAdAccount', apiPostacList, this.state.shared)
          const url = this.state.shared === 1 ? 'creative/create-sync' : 'creative/create'
          return network.callApi({
            url: url,
            method: 'post',
            body: this.state.shared === 1 ? apiPostacList[0] : apiPostacList,
            successFunc: (jsonData) => {
              if (jsonData.result === 'fail') {
                toast(jsonData.message)
              }
              if (jsonData.result === 'duplicated') {
                toast('이미 생성된 광고 입니다. 관리자에게 문의해 주세요.')
              }
              resolve(jsonData)
            },
            failFunc: (error) => {
              reject(error)
            },
          })
        })
        promiseList.push(newCreateAdAccount)
        apiPostacList = []
      }

      if (usingCouponList.length > 0) {
        usingCouponList.forEach((coupon) => {
          promiseList.push(
            new Promise((resolve, reject) => {
              return network.callApi({
                url: 'discount/update',
                method: 'POST',
                body: { id: coupon, isUsed: 1 },
                successFunc: (discountCouponResult) => {
                  resolve(discountCouponResult)
                },
                failFunc: (error) => {
                  reject(error)
                },
              })
            })
          )
        })
      }

      if (tmpCouponList.length > 0) {
        tmpCouponList.forEach((coupon) => {
          promiseList.push(
            new Promise((resolve, reject) => {
              return network.callApi({
                url: 'discount/update',
                method: 'POST',
                body: { id: coupon, isUsed: 2 },
                successFunc: (discountCouponResult) => {
                  resolve(discountCouponResult)
                },
                failFunc: (error) => {
                  reject(error)
                },
              })
            })
          )
        })
      }

      Promise.all(promiseList).then(() => {
        this.setState({ usingCouponList: tmpCouponList }, () => this.stepThirdAccountListGet())
      })
    } else if (whatWork === 'update') {
      accountList.forEach((adAccount) => {
        const requestBody = {}
        requestBody.id = adAccount.id
        requestBody.adCenterAdvertisementId = adId
        requestBody.adCenterBgColorId = adAccount.adCenterBgColorId
        requestBody.adCenterPubAccountId = adAccount.adCenterPubAccountId
        requestBody.adCenterPubAreaId = adAccount.adCenterPubAreaId
        requestBody.adCenterPubGoodsId = adAccount.goodsid
        requestBody.adCenterStatusId = 1 // 추후 수정될 가능성 있음
        if (adAccount.startAt && adAccount.startAt !== '') requestBody.startAt = `${adAccount.startAt} 00:00:00`
        if (adAccount.endAt && adAccount.endAt !== '') {
          const endDate = dayjs(adAccount.endAt).add(1, 'day').format('YYYY-MM-DD')
          requestBody.endAt = `${endDate} 00:00:00`
        }
        requestBody.status = user.isWadizAdmin ? 3 : 1
        requestBody.advertiserDivision = user.isWadizAdmin ? 1 : 0
        requestBody.bgColor = adAccount.bgColor
        // tempList.imgFile = val.imgFile;
        if (Object.prototype.hasOwnProperty.call(adAccount, 'imgUrl')) {
          requestBody.imgUrl = adAccount.imgUrl
        }
        requestBody.mainCopy = adAccount.mainCopy
        requestBody.keyword = adAccount.keyword
        requestBody.subCopy = adAccount.subCopy
        requestBody.landingUrl = adAccount.landingUrl
        // if (adAccount.discountId) {
        //   tmpCouponList.push(adAccount.discountId)
        //   requestBody.price = adAccount.discountPrice
        //   requestBody.costPrice = adAccount.price
        //   requestBody.adCenterDiscountId = adAccount.discountId
        //   requestBody.usedDiscount = 2
        // } else {
        //   requestBody.price = adAccount.price
        //   requestBody.adCenterDiscountId = 1
        //   requestBody.usedDiscount = 1
        // }
        console.log('update val', adAccount)
        const price = adAccount.price * adAccount.normalPeriod + adAccount.holidayPrice * adAccount.holidayPeriod
        requestBody.price = price
        requestBody.vat = Math.floor(price * 0.1)
        if (adAccount.callApiType === 'update') {
          updateRequestBodyList.push(requestBody)
          modifiedAdAccountList.push(adAccount)
        } else {
          createRequestBodyList.push(requestBody)
          modifiedAdAccountList.push(adAccount)
        }
      })

      if (updateRequestBodyList.length > 0) {
        const updateAdAccount = new Promise((resolve, reject) => {
          const url = this.state.shared === 1 ? 'creative/update-sync' : 'creative/update'
          return network.callApi({
            url: url,
            method: 'POST',
            body: this.state.shared === 1 ? updateRequestBodyList[0] : updateRequestBodyList,
            successFunc: (jsonData) => {
              if (jsonData.result === 'duplicated') {
                // result = false;
              }
              resolve(jsonData)
            },
            failFunc: (error) => {
              reject(error)
            },
          })
        })
        promiseList.push(updateAdAccount)
      }

      if (createRequestBodyList.length > 0) {
        const createAdAccount = new Promise((resolve, reject) => {
          // TODO 여기서 keyword 필드가 채워져 올라오는 것을 확인해야 한다.
          console.log('createAdAccount keyword', createRequestBodyList)
          const url = this.state.shared === 1 ? 'creative/create-sync' : 'creative/create'
          return network.callApi({
            url: url,
            method: 'post',
            body: this.state.shared === 1 ? createRequestBodyList[0] : createRequestBodyList,
            successFunc: (jsonData) => {
              if (jsonData.result === 'duplicated') {
                toast('이미 생성된 광고 입니다. 관리자에게 문의해 주세요.')
              }
              resolve(jsonData)
            },
            failFunc: (error) => {
              reject(error)
            },
          })
        })
        promiseList.push(createAdAccount)
      }

      if (usingCouponList.length > 0) {
        usingCouponList.forEach((coupon) => {
          promiseList.push(
            new Promise((resolve, reject) => {
              return network.callApi({
                url: 'discount/update',
                method: 'POST',
                body: { id: coupon, isUsed: 1 },
                successFunc: (discountCouponResult) => {
                  resolve(discountCouponResult)
                },
                failFunc: (error) => {
                  reject(error)
                },
              })
            })
          )
        })
      }

      if (tmpCouponList.length > 0) {
        tmpCouponList.forEach((coupon) => {
          promiseList.push(
            new Promise((resolve, reject) => {
              return network.callApi({
                url: 'discount/update',
                method: 'POST',
                body: { id: coupon, isUsed: 2 },
                successFunc: (discountCouponResult) => {
                  resolve(discountCouponResult)
                },
                failFunc: (error) => {
                  reject(error)
                },
              })
            })
          )
        })
      }

      Promise.all(promiseList).then(() => {
        this.setState({ usingCouponList: tmpCouponList }, () => this.stepThirdAccountListGet())
      })
    }
  }

  handleAdPreview = (e, idx) => {
    const { accountList } = this.state
    const currentAccount = accountList[idx]
    this.props.openPopup({
      dialogType: 'previewDialog',
      dialogData: {
        title: `${currentAccount.positionName} - 구좌${currentAccount.accountNum}`,
        mainCopy: currentAccount.mainCopy,
        keyword: currentAccount.keyword,
        subCopy: currentAccount.subCopy,
        imgUrl: currentAccount.imgUrl,
        bgColor: currentAccount.bgColor,
        pubAreaId: currentAccount.info.adCenterPubAreaId,
        confirmText: '닫기',
      },
    })
  }

  handleToAdList = () => {
    // 광고 목록 페이지로 이동
    this.props.setMenuStatus(false)
    this.props.history.push('/main/ad/list')
  }

  handleToAdStep = (step) => {
    // 광고 목록 페이지로 이동
    this.props.history.push({
      pathname: `/main/ad/create/step${step}`,
      state: { ...this.state, adName: this.state.projectName },
    })
  }

  onChangeLandingURL = (isProjectURL) => {
    const { accountList, makerInfo } = this.state
    const newAccountList = accountList.map((account) => ({
      ...account,
      landingUrl: isProjectURL ? account.landingUrl : makerInfo.makerPageLandingUrl,
      makerPageUrlSelected: !isProjectURL,
    }))
    this.setState({
      accountList: newAccountList,
    })
  }

  render() {
    const {
      state,
      handleClickHistoryBack,
      handleClickStep,
      handleClickSave,
      handleClickRegister,
      handleChangeState,
      handleClickDatepicker,
      handleChangeGuide,
      handleOpenReservationPaymentDialog,
      imgFileSel,
      selectColor,
      inputTextChange,
      onSelectGoods,
      onSelectDiscountCoupon,
      onChangeDateRange,
      onFocusChange,
      imgDel,
      accountListDel,
      stepThirdAccountListDel,
      handleOpenGuideType,
      handleAdPreview,
      handleToAdList,
      handleToAdStep,
      doAgreementConfirm,
      onChangeLandingURL,
      setImage,
    } = this
    const { createStep, isComplete, openGuide, isOpenAgreementModal } = this.state
    return (
      <>
        <AdCreateContent
          state={state}
          isSuperUser={this.props.user.isSuperUser}
          isWadizAdmin={this.props.user.isWadizAdmin}
          handleClickHistoryBack={handleClickHistoryBack}
          handleClickStep={handleClickStep}
          handleClickSave={handleClickSave}
          onClickRegister={handleClickRegister}
          handleChangeState={handleChangeState}
          handleClickDatepicker={handleClickDatepicker}
          handleChangeGuide={handleChangeGuide}
          handleOpenReservationPaymentDialog={handleOpenReservationPaymentDialog}
          handleToAdStep={handleToAdStep}
          imgFileSel={imgFileSel}
          selectColor={selectColor}
          inputTextChange={inputTextChange}
          onSelectGoods={onSelectGoods}
          onSelectDiscountCoupon={onSelectDiscountCoupon}
          onChangeDateRange={onChangeDateRange}
          onFocusChange={onFocusChange}
          imgDel={imgDel}
          accountListDel={accountListDel}
          stepThirdAccountListDel={stepThirdAccountListDel}
          handleOpenGuideType={handleOpenGuideType}
          createStep={createStep}
          isComplete={isComplete}
          openGuide={openGuide}
          handleAdPreview={handleAdPreview}
          handleToAdList={handleToAdList}
          onChangeLandingURL={onChangeLandingURL}
          setImage={setImage}
        />
        <TermsModalContainer isOpen={isOpenAgreementModal} onAgreementConfirm={doAgreementConfirm} />
      </>
    )
  }
}

export default connect(({ user, loadingBar, waffleModal }) => ({ user, loadingBar, waffleModal }), {
  openPopup,
  openAlertPopup,
  setMenuStatus,
  openWaffleModal,
  setActivatedAdInfo,
})(withRouter(AdCreatePage))
