import React from 'react';
import className from 'classnames/bind';
import style from './Common.module.scss';
const cx = className.bind(style);

const CommonDialog = ({
  zIndex,
  title,
  confirmText,
  closeText,
  children,
  onClickConfirm,
  onClickClose,
  type,
}) => {
  return (
    <div className={cx('dialog_shadow')} style={{ zIndex: `${zIndex}` }}>
      <div className={cx('dialog_wrap', type === 'alert' ? 'typeAlert' : 'typePopup')}>
        <header>
          <div className={cx('dialog_close')}>
            {/* Close Button Area */}
            <button className={cx('wz button less')} onClick={onClickClose}>
              <i className={cx('icon close')}></i>
            </button>
          </div>
          <div className={cx('dialog_title')}>
            {/* Title Area */}
            <h3>{title}</h3>
          </div>
        </header>
        {/* Content Area */}
        {children}
        <footer className={cx('dialog_button')}>
          {/* Button Area */}
          {
            confirmText ? (
              closeText ? (
                <>
                  <button type="button" className={cx('wz button')} onClick={onClickClose}>{closeText}</button>
                  <button type="button" className={cx('wz button primary')} onClick={onClickConfirm}>{confirmText}</button>
                </>
              ) : (
                <button type="button" className={cx('wz button primary')} onClick={onClickConfirm}>{confirmText}</button>
              )
            ) : (
              closeText && (
                <button type="button" className={cx('wz button primary')} onClick={onClickClose}>{closeText}</button>
              )
            )
          }
        </footer>
      </div>
    </div>
  );
};

export default CommonDialog;
