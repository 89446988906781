import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { closePopup } from '../../reducers/dialogReducer'

import PreviewContainer from '../PreviewContainer/PreviewContainer'

import className from 'classnames/bind'
import style from './Dialog.module.scss'

import { dateToStringAdPeriod } from '../../datetostring'

import { network } from '../../network'

const cx = className.bind(style)

class ReportCostDetail extends PureComponent {
  initState = {
    listData: [],
    reservYear: new Date().getFullYear(),
    reservMonth: new Date().getMonth() + 1,
    isShow: false,
    statusType: {},
  }

  state = this.initState

  static getDerivedStateFromProps = (nextProps) => {
    if (nextProps.dialog.dialogType === 'reportCostDetail') {
      const { id, statusType } = nextProps.dialog.dialogData
      return { isShow: true, id, statusType }
    }

    return { ...new ReportCostDetail().initState }
  }

  handleClose = () => {
    this.props.closePopup()
    this.setState({ ...this.initState })
  }

  handleConfirm = () => {
    // confirm function

    // close when success
    this.handleClose()
  }

  getDetailInfo() {
    const { id } = this.state
    const url = `advertisement-pay-report/info?creative_id=${id}`
    network.callApi({
      url,
      method: 'get',
      header: { 'Content-Type': 'application/json;charset=UTF-8' },
      successFunc: (jsonData) => {
        const { result } = jsonData
        if (result) {
          this.setState({ listData: result })
        }
      },
      failFunc: () => {
        console.error(`fail- ${url}`)
      },
    })
  }

  componentDidMount() {
    this.getDetailInfo()
  }

  render() {
    const { handleClose } = this
    const { isShow, listData, statusType } = this.state

    // 환불내역
    let refundInfoFormat = '-'
    if (listData.refundInfo) {
      // 문자값으로 넘어오는 '<br>'값을 태그로 처리도되록 한다.
      let refundInfoArr = []
      refundInfoArr = listData.refundInfo.split('<br>')
      refundInfoFormat = (
        <div className={cx('bold')}>
          {refundInfoArr.map((value) => (
            <sapn>
              {value}
              <br />
            </sapn>
          ))}
        </div>
      )
    }

    return (
      isShow && (
        <>
          <div className={cx('dialog_shadow')} onClick={handleClose}></div>
          <div id={cx('reportFavorit')} className={cx('dialog_wrap', 'typePopup')}>
            <header>
              <div className={cx('dialog_close')}>
                {/* Close Button Area */}
                <button className={cx('wz button less')} onClick={handleClose}>
                  <i className={cx('icon close')} />
                </button>
              </div>
              <div className={cx('dialog_title')}>
                {/* Title Area */}
                <h3>광고 결제 상태</h3>
              </div>
            </header>
            <main className={cx('dialog_content')}>
              {/* Content Area */}
              <table className={cx('wz table', 'theadLeft')}>
                <caption>광고 결제 상태</caption>
                <tbody>
                  <tr>
                    <th>광고명</th>
                    <td>{listData.adName}</td>
                  </tr>
                  <tr>
                    <th>광고 영역/구좌</th>
                    <td>
                      <strong className={cx('point_primary')}>
                        {listData.area} - 구좌{listData.accountNum}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <th>광고기간</th>
                    <td>
                      {dateToStringAdPeriod(listData.startAt)} ~ {dateToStringAdPeriod(listData.endAt)}
                    </td>
                  </tr>
                  <tr>
                    <th>광고비</th>
                    <td>
                      <strong>
                        {listData.price ? `${listData.price.toLocaleString()}원` : '-'} (VAT 포함 :{' '}
                        {listData.totalPrice ? `${listData.totalPrice.toLocaleString()}원` : ' - '})
                      </strong>
                    </td>
                  </tr>
                  {listData.usedDiscount === 2 && (
                    <tr>
                      <th>할인코드</th>
                      <td>
                        <strong>
                          {listData.discountCode} (할인 전 : {listData.costPrice.toLocaleString()}원, 할인 후 :{' '}
                          {listData.price.toLocaleString()}원)
                        </strong>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th>광고이미지</th>
                    <td>
                      <PreviewContainer
                        mainCopy={listData.mainCopy}
                        keyword={listData.keyword}
                        subCopy={listData.subCopy}
                        imgUrl={listData.imgUrl}
                        bgColor={listData.bgColor}
                        area={listData.area}
                        accountNum={listData.accountNum}
                        adCenterPubAreaId={listData.adCenterPubAreaId}
                      />
                    </td>
                  </tr>
                  {listData.mainCopy && (
                    <tr>
                      <th>메인카피</th>
                      <td>{listData.mainCopy}</td>
                    </tr>
                  )}
                  {listData.subCopy && (
                    <tr>
                      <th>서브카피</th>
                      <td>{listData.subCopy}</td>
                    </tr>
                  )}
                  {listData.bgColor && (
                    <tr>
                      <th>백그라운드 컬러</th>
                      <td>
                        <div
                          className={cx('bg_color_box')}
                          style={{
                            background: listData.bgColor === '' || !listData.bgColor ? '#ECECEC' : listData.bgColor,
                          }}
                        >
                          {listData.bgColor}
                        </div>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th>결제상태</th>
                    <td>
                      <strong
                        className={cx(listData.adPayStatus === 5 || listData.adPayStatus === 6 ? 'point_red' : '')}
                      >
                        {statusType.adPayStatus[listData.adPayStatus]}
                      </strong>
                    </td>
                  </tr>
                  {/* {환불 완료(6) 일때만} */}
                  {(listData.adPayStatus === 5 || listData.adPayStatus === 6) && (
                    <>
                      <tr>
                        <th>환불금액</th>
                        <td>
                          <em className={cx('point_red')}>{listData.refundTotalPrice.toLocaleString()}원</em>
                          <strong> (VAT 포함)</strong>
                        </td>
                      </tr>
                      <tr>
                        <th>환불내역</th>
                        <td>
                          <ul>
                            <li>
                              <strong>{refundInfoFormat}</strong>
                            </li>
                            <li className={cx('helper')}>
                              ＊ 환불 내역은 광고 취소 후 최대 1시간이 경과하면 확인 가능합니다.
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </>
                  )}
                  {listData.adRemainPrice && (
                    <tr>
                      <th>광고잔금</th>
                      <td>
                        <strong>{listData.adRemainPrice.toLocaleString()}원</strong>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th>남은 광고 시간</th>
                    <td>
                      <strong>{listData.adRemainHour}시간</strong>
                    </td>
                  </tr>
                  {listData.cancelStatus !== 0 && listData.cancelStatus !== 8 && (
                    <>
                      <tr>
                        <th>광고 취소/반려 상태</th>
                        <td>
                          <em className={cx('point_red')}>{statusType.cancelStatus[listData.cancelStatus]}</em>
                        </td>
                        {/* 심사중 관리자가 광고 반려
                          관리자의 광고 예정중 취소
                          신용카드 결제 오류
                          광고주의 심사중 광고 취소
                          광고주의 예정중 광고 취소
                          시스템 취소 */}
                      </tr>
                      <tr>
                        <th>광고 취소/반려 사유</th>
                        <td>
                          <strong>{listData.cancelReason}</strong>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </main>
            <footer className={cx('dialog_button')}>
              {/* Button Area */}
              <button className={cx('wz button')} onClick={handleClose}>
                닫기
              </button>
              {/* <button className={cx('wz button primary')} onClick={handleConfirm}>저장</button> */}
            </footer>
          </div>
        </>
      )
    )
  }
}

export default connect(({ dialog }) => ({ dialog }), { closePopup })(ReportCostDetail)
