import React, { useState, FC } from 'react'
import { type PaginationProps, ConfigProvider, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { nanoid } from 'nanoid'
import { Button, Loader } from '@wadiz-frontend/waffle-modified'
import type { KeywordListType, KeywordType } from '@wad/api'
import styles from './KeywordTable.module.scss'

export const KeywordTable: FC<{
  data: KeywordListType
  isLoading: boolean
  isHold: boolean
  onSelectKeyword: (keyword: string) => void
  unavailableList: Array<string>
}> = ({ data, isLoading, isHold, onSelectKeyword, unavailableList }) => {
  const [selectedKeyword, setSelectedKeyword] = useState('')
  const isUnavailable = (keyword: string) => unavailableList?.findIndex((elem) => elem === keyword) > -1 || false
  const onSelect = (keyword: string) => {
    setSelectedKeyword(keyword || '')
    onSelectKeyword(keyword)
  }
  const columns: ColumnsType<KeywordType> = [
    {
      title: () => <div className={styles.keywordHeader}>키워드</div>,
      dataIndex: 'searchKeyword',
      key: ' ',
      width: 260,
      render: (text, record, index) => (
        <div
          className={isUnavailable(record?.searchKeyword) ? styles.keywordContentUnavailable : styles.keywordContent}
        >
          {text}
        </div>
      ),
    },
    {
      title: () => <div className={styles.countHeader}>쿼리수</div>,
      dataIndex: 'sumOfCount',
      key: 'sumOfCount',
      render: (text, record, index) => (
        <div className={isUnavailable(record?.searchKeyword) ? styles.countContentUnavailable : styles.countContent}>
          {text + 0 <= 30 ? '30 이하' : text}
        </div>
      ),
    },
    {
      title: () => <div className={styles.selectHeader}>선택</div>,
      key: 'select',
      width: 106,
      render: (text, record, index) => (
        <Button
          color={!isUnavailable(record?.searchKeyword) ? 'secondary' : 'tertiaryGrey'}
          size="sm"
          fullWidth
          onClick={() => onSelect(record?.searchKeyword)}
          disabled={isHold || isUnavailable(record?.searchKeyword)}
          loading={isHold && selectedKeyword !== '' && selectedKeyword === record?.searchKeyword}
        >
          선택
        </Button>
      ),
    },
  ]

  type ItemRenderFunction = PaginationProps['itemRender']
  const pageNationRender: ItemRenderFunction = (current, type, originalElement) =>
    type === 'prev' || type === 'next' ? null : originalElement
  return (
    <ConfigProvider renderEmpty={() => <div className={styles.empty}></div>}>
      <Table
        columns={columns}
        rowKey={() => nanoid()}
        dataSource={data?.list}
        pagination={{
          className: styles.pagination,
          itemRender: pageNationRender,
          showSizeChanger: false,
          pageSize: 8,
          total: data?.count || 0,
        }}
        loading={{
          spinning: isLoading,
          indicator: <Loader color="primary" />,
        }}
        size="small"
        rowClassName={styles.row}
      />
    </ConfigProvider>
  )
}
