import { api, ResultType } from './api'

export interface BgColorResult {
  id: number
  adCenterPubAreaId: number
  bgColor: string
  description: string
  createdAt: string
  updatedAt: string
}

export const fetchBgColorList = (pubAreaId: string): Promise<ResultType<BgColorResult[]>> => {
  return api('GET', `bg-color/list?pub_area_id=${pubAreaId}`)
}
