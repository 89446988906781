import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'

import { closePopup } from '../../reducers/dialogReducer'

import { network } from '../../network'
import { dateToStringCreatedDate } from '../../datetostring'

import className from 'classnames/bind'
import style from './Dialog.module.scss'
const cx = className.bind(style)

const pad = (n, width) => {
  let tmp = n
  tmp += ''
  return tmp.length >= width ? tmp : new Array(width - tmp.length + 1).join('0') + tmp
}

const dateToStringAdPeriod = (format, date) => {
  const newDate = new Date(date)
  switch (format) {
    case 'date':
      break
    case 'month':
      return `${String(newDate.getFullYear()).substring(2)}-${newDate.getMonth() + 1}`
    case 'term':
      return `${String(newDate.getFullYear()).substring(2)}-${newDate.getMonth() + 1}-${newDate.getDate()}, ${pad(
        newDate.getHours(),
        2
      )}시`
    default:
      return date
  }
  return date
}

class TaxBillDetailDialog extends PureComponent {
  initState = {
    isShow: false,
    data: {},
    detailData: {},
  }

  state = this.initState

  static getDerivedStateFromProps = (nextProps) => {
    const { dialogType, dialogData } = nextProps.dialog
    if (dialogType === 'taxBillDetailDialog') {
      return { isShow: true, data: dialogData.data }
    }

    return null
  }

  componentDidMount() {
    const { data } = this.state
    const url = `invoice/info?invoice_id=${data.id}`
    network.callApi({
      url,
      method: 'get',
      header: { 'Content-Type': 'application/json;charset=UTF-8' },
      successFunc: (jsonData) => {
        const { result } = jsonData
        this.setState({ detailData: result })
      },
      failFunc: () => {
        console.error(`fail- ${url}`)
      },
    })
  }

  handleClose = () => {
    this.props.closePopup()
    this.setState({ ...this.initState })
  }

  handleConfirm = () => {
    // confirm function

    // close when success
    this.handleClose()
  }

  render() {
    const { handleClose } = this

    const { dialogData } = this.props.dialog
    const { isShow, data, detailData } = this.state
    return (
      isShow && (
        <>
          <div className={cx('dialog_shadow')} onClick={handleClose}></div>
          <div id={cx('reportFavorit')} className={cx('dialog_wrap', 'typePopup')}>
            <header>
              <div className={cx('dialog_close')}>
                {/* Close Button Area */}
                <button className={cx('wz button less')} onClick={handleClose}>
                  <i className={cx('icon close')} />
                </button>
              </div>
              <div className={cx('dialog_title')}>
                {/* Title Area */}
                <h3>{dialogData.title}</h3>
              </div>
            </header>
            <main className={cx('dialog_content')}>
              <table className={cx('wz table', 'theadLeft')}>
                <caption>광고 결제 상태 / 내용 보기</caption>
                <tbody>
                  <tr>
                    <th scope="row">공급월</th>
                    <td>{dateToStringAdPeriod('month', data.supMonth)}</td>
                  </tr>
                  <tr>
                    {detailData.length > 0 && (
                      <Fragment>
                        <th scope="row" rowSpan={detailData.length}>
                          공급내용
                        </th>
                        <td className={cx('taxbill_sup_detail')}>
                          <div>
                            <span className={cx('sub_title')}>결제일:</span>{' '}
                            <span className={cx('pay_date')}>
                              {dateToStringCreatedDate(detailData[0].payDate)[0]}{' '}
                              <span className={cx('table_time')}>
                                {dateToStringCreatedDate(detailData[0].payDate)[1]}
                              </span>
                            </span>
                          </div>
                          <div>
                            <span className={cx('sub_title')}>광고 영역/구좌:</span>{' '}
                            <span>
                              {detailData[0].area} - 구좌{detailData[0].accountNum}
                            </span>
                          </div>
                          <div>
                            <span className={cx('sub_title')}>광고기간:</span>{' '}
                            <span>
                              {dateToStringCreatedDate(detailData[0].startAt)[0]} ~{' '}
                              {dateToStringCreatedDate(detailData[0].endAt)[0]}
                            </span>
                          </div>
                          <div>
                            <span className={cx('sub_title')}>결제금액:</span>{' '}
                            <span>{detailData[0].payPrice.toLocaleString()}원</span>
                          </div>
                        </td>
                      </Fragment>
                    )}
                  </tr>
                  {detailData.length > 1 &&
                    detailData.slice(1).map((val, idx) => (
                      <tr key={idx}>
                        <td className={cx('taxbill_sup_detail')}>
                          <div>
                            <span className={cx('sub_title')}>결제일:</span>{' '}
                            <span className={cx('pay_date')}>
                              {dateToStringCreatedDate(val.payDate)[0]}{' '}
                              <span className={cx('table_time')}>{dateToStringCreatedDate(val.payDate)[1]}</span>
                            </span>
                          </div>
                          <div>
                            <span className={cx('sub_title')}>광고 영역/구좌:</span>{' '}
                            <span>
                              {val.area} - 구좌{val.accountNum}
                            </span>
                          </div>
                          <div>
                            <span className={cx('sub_title')}>광고기간:</span>{' '}
                            <span>
                              {dateToStringCreatedDate(val.startAt)[0]} ~ {dateToStringCreatedDate(val.endAt)[0]}
                            </span>
                          </div>
                          <div>
                            <span className={cx('sub_title')}>결제금액:</span>{' '}
                            <span>{val.payPrice.toLocaleString()}원</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <th scope="row">공급가액</th>
                    <td>{data.supPrice.toLocaleString()}원</td>
                  </tr>
                  <tr>
                    <th scope="row">부가세</th>
                    <td>{data.vat.toLocaleString()}원</td>
                  </tr>
                  <tr>
                    <th scope="row">총 금액</th>
                    <td>{data.totalPrice.toLocaleString()}원</td>
                  </tr>
                  {data.invoiceNum && (
                    <tr>
                      <th scope="row">계산서 번호</th>
                      <td>{data.invoiceNum}</td>
                    </tr>
                  )}
                  <tr>
                    <th scope="row">발행여부</th>
                    <td>{data.status === 1 ? '미발행' : '발행완료'}</td>
                  </tr>
                </tbody>
              </table>
            </main>
            <footer className={cx('dialog_button')}>
              {/* Button Area */}
              <button className={cx('wz button primary')} onClick={handleClose}>
                {dialogData.closeText}
              </button>
            </footer>
          </div>
        </>
      )
    )
  }
}

export default connect(({ dialog }) => ({ dialog }), { closePopup })(TaxBillDetailDialog)
