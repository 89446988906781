import {
  createStore,
  compose,
  applyMiddleware
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';

const composeEnhancer = (
  process.env.REACT_APP_ENVIRONMENT !== 'live' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) || compose;

const store = createStore(
  rootReducer,
  composeEnhancer(
    applyMiddleware(
      thunkMiddleware,
    ),
  ),
);

export default {
  store
};
