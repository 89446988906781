import React from 'react'

// Components
import Lnb from '../Lnb'
import LoadingBar from '../LoadingBar/LoadingBar'

// CSS module
import classNames from 'classnames/bind'
import style from './AdModifyContent.module.scss'
import ModifyAdStep1 from './ModifyAdStep/ModifyAdStep1'
import ModifyAdStep2 from './ModifyAdStep/ModifyAdStep2'

const cx = classNames.bind(style)

const AdModifyContent = ({
  state,
  isWadizAdmin,
  handleOpenGuideType,
  handleChangeProject,
  handleChangeAdName,
  handleAdAccountModalOpen,
  adAccountListDel,
  handleAdAccountListDelModalOpen,
  onSelectGoods,
  onSelectDiscountCoupon,
  handleClickDatepicker,
  imgFileSel,
  imgDel,
  selectColor,
  handleChangeMainCopy,
  handleChangeKeyword,
  handleChangeSubCopy,
  handleChangeLandingUrl,
  handleClickPrevButton,
  submit,
  handleOpenReservationPaymentDialog,
  handleClickGoToAdList,
  comma,
  onChangeDateRange,
  onFocusChange,
  selectedAdAccountCardRef,
  adAccountList,
  onChangeLandingURL,
  setImage,
  isSuperUser,
}) => {
  const { loadingBarFlag, LnbData, projectId, adId, isModify, modifyStep } = state

  return (
    <main className={cx('main_container', 'ad')}>
      <Lnb
        type="adlist"
        LnbData={LnbData}
        projectId={projectId}
        adId={adId}
        isModify={isModify}
        mobileMenuIsOpen={false}
      />
      <div className={cx('ad_list', 'content_wrap')}>
        {/* 여기에 상단 네비게이션 추가해주세요 <NavBar list={navList} /> */}
        {loadingBarFlag ? (
          <LoadingBar />
        ) : (
          <>
            {modifyStep === 1 && (
              <ModifyAdStep1
                accountList={adAccountList}
                isWadizAdmin={isWadizAdmin}
                handleChangeProject={handleChangeProject}
                handleOpenGuideType={handleOpenGuideType}
                handleChangeAdName={handleChangeAdName}
                adAccountListDel={adAccountListDel}
                onSelectGoods={onSelectGoods}
                onSelectDiscountCoupon={onSelectDiscountCoupon}
                comma={comma}
                imgDel={imgDel}
                handleClickDatepicker={handleClickDatepicker}
                imgFileSel={imgFileSel}
                handleChangeMainCopy={handleChangeMainCopy}
                handleChangeKeyword={handleChangeKeyword}
                handleChangeSubCopy={handleChangeSubCopy}
                handleChangeLandingUrl={handleChangeLandingUrl}
                selectColor={selectColor}
                handleAdAccountModalOpen={handleAdAccountModalOpen}
                handleClickPrevButton={handleClickPrevButton}
                submit={submit}
                onChangeDateRange={onChangeDateRange}
                onFocusChange={onFocusChange}
                selectedAdAccountCardRef={selectedAdAccountCardRef}
                onChangeLandingURL={onChangeLandingURL}
                setImage={setImage}
                isSuperUser={isSuperUser}
                {...state}
              />
            )}
            {modifyStep === 2 && (
              <ModifyAdStep2
                handleAdAccountListDelModalOpen={handleAdAccountListDelModalOpen}
                handleOpenReservationPaymentDialog={handleOpenReservationPaymentDialog}
                handleClickPrevButton={handleClickPrevButton}
                handleClickGoToAdList={handleClickGoToAdList}
                {...state}
              />
            )}
          </>
        )}
      </div>
    </main>
  )
}

export default AdModifyContent
