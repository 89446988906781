import { DatePicker, ConfigProvider, Typography } from 'antd'
import locale from 'antd/lib/locale/ko_KR'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/ko'
import { BusinessDayItem, Goods } from '@center/api'
dayjs.locale('ko')

interface SelectDateProps {
  selectedGoods: Goods
  selecedDate: SelectDate
  businessDays: BusinessDayItem[]
  onSelect: (date: SelectDate) => void
  disabled?: boolean
  isSuperUser?: boolean
}

interface SelectDate {
  startDate: string // dateFormat
  endDate: string // dateFormat
}

export const LIMITE_DAY = 60
const dateFormat = 'YYYY-MM-DD'
// o: 영업일, h: 비영업일

const SelectDate: React.FC<SelectDateProps> = ({
  selectedGoods,
  selecedDate,
  businessDays,
  onSelect,
  disabled = false,
  isSuperUser = false,
}) => {
  const disabledDate = (current: Dayjs) => {
    // 레거시 광고 센터에서 최대 선택 가능한 기한은 6월 13일까지 가능하다.
    // 슈퍼 어드민의 경우 날짜 제한 없이 선택 가능
    return (
      current < dayjs().subtract(1, 'day').endOf('day') ||
      (!isSuperUser ? current > dayjs('2024-06-13').endOf('day') : false)
    )
  }
  const checkHolidays = (current: Dayjs) => {
    if (current < dayjs()) return false
    // 일반 = o, 공휴일 = h, 대체공휴일 = i, 임시공휴일 = t
    const holidays = businessDays.filter((item) => item.type !== 'o')
    return holidays?.some((i) => current.format(dateFormat) === i.eventDate)
  }

  const handlePicker = (_: unknown, dateString: string) => {
    if (!dateString) {
      return
    }
    const date = {
      startDate: dateString,
      endDate:
        selectedGoods.impMinPeriod > 1
          ? dayjs(dateString)
              .add(selectedGoods.impMinPeriod - 1, 'day')
              .format(dateFormat)
          : dateString,
    }
    onSelect(date)
  }

  return (
    <div>
      <p>선택하신 광고 상품의 진행 기간을 설정해 주세요.</p>
      <ConfigProvider locale={locale}>
        <DatePicker
          style={{ marginTop: 8 }}
          disabled={!selectedGoods.id || disabled}
          onChange={handlePicker}
          value={selectedGoods?.id && selecedDate.startDate ? dayjs(selecedDate.startDate) : undefined}
          disabledDate={disabledDate}
          cellRender={(current) => {
            const style: React.CSSProperties = {}
            if (checkHolidays(current as Dayjs)) {
              style.color = 'red'
            }
            return (
              <div className="ant-picker-cell-inner" style={style}>
                {(current as Dayjs).date()}
              </div>
            )
          }}
        />
        <span style={{ margin: '0 16px' }}>~</span>
        <DatePicker
          disabled
          value={selectedGoods?.id && selecedDate.endDate ? dayjs(selecedDate.endDate) : undefined}
        />
      </ConfigProvider>
    </div>
  )
}

export default SelectDate
