import BigNumber from 'bignumber.js';

import { padStart, padEnd } from './string';
import { isNumber } from './is';

/**
 * 숫자를 세자리 수 마다 ,(콤마)를 삽입 한다.
 * @param value
 */
export function numberFormat(value: number | string): string {
  const string = /^([0-9]+)\.([0-9]+)$/.exec(String(value));
  if (string && string.length === 3) {
    // 소수점이 존재할 경우 소수점은 콤마를 제외시킨다.
    return `${numberFormat(string[1])}.${string[2]}`;
  }
  const number = new BigNumber(value);
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * 숫자를 시간으로 계산하여 표시한다.
 */
export function timeFormat(value: number): string {
  const pad = '0';
  const hours = Math.floor(value / 60 / 60);
  const minutes = Math.floor((value - (hours * 60 * 60)) / 60);
  const seconds = Math.round(value - (hours * 60 * 60) - (minutes * 60));
  if (hours) {
    return `${hours}:${padStart(minutes, 2, pad)}:${padStart(seconds, 2, pad)}`;
  }
  return `${minutes}:${padStart(seconds, 2, pad)}`;
}

type shortNumberOptions = {
  /** 대략.. (약 00만원) */
  about?: number | true;
  /** 자리 수 사이에 공백 제거 */
  disableSpaceBeforeNumber?: boolean;
};
/*
Formats the given number using `Number#toLocaleString`.
- If locale is a string, the value is expected to be a locale-key (for example: `de`).
- If locale is true, the system default locale is used for translation.
- If no value for locale is specified, the number is returned unmodified.
*/
function toLocaleString(number: number | string, locale: boolean | string) {
  let result = number;
  if (typeof locale === 'string') {
    result = number.toLocaleString(locale);
  } else if (locale === true) {
    result = number.toLocaleString();
  }

  return result;
}

/**
 * 숫자를 Byte로 계산하여 나타낸다.
 * [공개된 소스](https://github.com/sindresorhus/pretty-bytes/blob/master/index.js)를
 * 일부 변경하였다.
 */
export function prettyBytes(number: number, options: any) {
  if (!Number.isFinite(number)) {
    throw new TypeError(`Expected a finite number, got ${typeof number}: ${number}`);
  }

  /* eslint-disable no-param-reassign, prefer-template, no-restricted-properties */

  options = Object.assign({}, options);

  const isNegative = number < 0;
  const UNITS = [
    'B',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB',
    'EB',
    'ZB',
    'YB',
  ];

  if (isNegative) {
    number = -number;
  }

  if (number < 1) {
    const numberString = toLocaleString(number, options.locale);
    return (isNegative ? '-' : '') + numberString + 'B';
  }

  const exponent = Math.min(Math.floor(Math.log10(number) / 3), UNITS.length - 1);
  number = Number((number / Math.pow(1000, exponent)).toPrecision(3));
  const numberString = toLocaleString(number, options.locale);

  const unit = UNITS[exponent];

  return (isNegative ? '-' : '') + numberString + '' + unit;
}
