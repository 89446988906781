import { AreaType, AreaIDType } from './area'
const areaList: AreaIDType[] = [
  AreaType.mainHome,
  AreaType.mainBanner,
  AreaType.fundingHome,
  AreaType.comingSoonHome,
  AreaType.comingSoonList,
  AreaType.fundingList,
  AreaType.unassigned1, // TODO 미지정인데 사용하고 있네...
  AreaType.deprecated1, // TODO 확인 필요
  AreaType.messagePush,
  AreaType.storeHome,
  AreaType.storeList,
  AreaType.unassigned2, // TODO 투자 오픈예정 프로젝트
  AreaType.deprecated2, // TODO 확인 필요
  AreaType.mainContentA,
  AreaType.mainBoardDeprecated,
  AreaType.mainContentB,
  AreaType.mainContentC,
  AreaType.preOrderBanner,
  AreaType.preOrderList,
  AreaType.searchKeyword,
  AreaType.mainBoard,
  AreaType.recommendedContents,
  AreaType.searchBoard,
  AreaType.mainPlusContents, // 다중 지면 - 메인콘텐츠B+
  AreaType.multiMainBoardContents, // 다중 지면 - 멀티콘텐츠 B+
]

// 다중지면이고, 슈퍼유저일 경우 1개씩 볼 수 있게 처리
const areaListForSuperUser = [AreaType.mainPlusBest, AreaType.mainPlusFriend, AreaType.mainPlusWish]

export const sortByArea = (adAccountList: Array<{ adCenterPubAreaId: number }>, isSuperUser = false) => {
  let newAreaList = areaList

  if (isSuperUser) newAreaList = newAreaList.concat(areaListForSuperUser)
  const list = newAreaList
    .map((id) => adAccountList.filter((ac) => ac.adCenterPubAreaId === id))
    .reduce((a, n) => [...a, ...n]) // flat;

  return list
}
