import React, { useState, useCallback, FC } from 'react'
import { Modal, Radio, RadioChangeEvent } from 'antd'
import type {
  FunctionKeyword,
  FunctionPopularKeyword,
  FunctionKeywordValidation,
  KeywordValidationParam,
} from '@wad/api'
import { InputDirectKeyword } from './InputDirectKeyword'
import { PopularSelectKeyword } from './PopularSelectKeyword'
import styles from './KeywordSelectPopup.module.scss'

type KeywordOption = 'direct' | 'popular'
type KeywordSelectPopupType = {
  fetchGetKeyword: FunctionKeyword
  fetchGetKeywordValidation: FunctionKeywordValidation
  fetchGetPopularKeyword: FunctionPopularKeyword
  creative_id: number | undefined
  start_date: string
  end_date: string
  isOpen: boolean
  onCancel: () => void
  onSelectKeyword: (keyword: string) => void
}

export const KeywordSelectPopup: FC<KeywordSelectPopupType> = ({
  fetchGetKeyword,
  fetchGetKeywordValidation,
  fetchGetPopularKeyword,
  creative_id,
  start_date,
  end_date,
  isOpen,
  onCancel,
  onSelectKeyword,
}) => {
  const [option, setOption] = useState<KeywordOption>('direct')
  const doChange = useCallback((e: RadioChangeEvent) => {
    setOption(e.target.value)
  }, [])
  const validationData: KeywordValidationParam = {
    creative_id,
    start_date,
    end_date,
    keyword: undefined,
    validateType: undefined,
  }
  return (
    <Modal onCancel={onCancel} open={isOpen} width={737} footer={false} maskClosable={false}>
      <section className={styles.container}>
        <h3 className={styles.title}>키워드 선택하기</h3>
        <Radio.Group onChange={doChange} value={option}>
          <Radio value="direct">직접 입력</Radio>
          <Radio value="popular">인기 키워드</Radio>
        </Radio.Group>
        {option === 'direct' && (
          <InputDirectKeyword {...{ fetchGetKeyword, fetchGetKeywordValidation, validationData, onSelectKeyword }} />
        )}
        {option === 'popular' && (
          <PopularSelectKeyword
            {...{ fetchGetKeywordValidation, fetchGetPopularKeyword, validationData, onSelectKeyword }}
          />
        )}
      </section>
    </Modal>
  )
}
