import React from 'react'

import className from 'classnames/bind'
import style from './ErrorContent.module.scss'
const cx = className.bind(style)

const ErrorContent = ({ handleClickGoToHome }) => {
  return (
    <>
      <main className={cx('main_container', 'not_found')}>
        <div className={cx('content_wrap')}>
          <i className={cx('icon error')}></i>
          <h2>죄송합니다</h2>
          <p>
            요청하신 서비스가 정상 처리되지 않았습니다.<br></br>
            페이지를 찾을 수 없거나 예기치 못한 오류가 발생하였습니다.<br></br>
            보다 정확하고 편리한 서비스를 제공할 수 있도록 최선을 다하겠습니다.<br></br>
            감사합니다.
          </p>
          {handleClickGoToHome && <button type="button" className={cx('wz button primary')} onClick={handleClickGoToHome}>
            홈으로
          </button>}
        </div>
      </main>
      <footer className={cx('footer')}>© WADIZ Co., Ltd.</footer>
    </>
  )
}

export default ErrorContent
