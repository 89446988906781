import { api, ResultType } from '../api'

export interface PubAreaParams {
  isWadizAdmin: boolean
  projectType: number
  comingsoon: number
  isSuperUser: boolean
}

export interface PubAreaItemArea {
  id: number
  area: string
  imgUrl: string
  manualUrl: string
  main: null | 1 | 0 // null-패키지 아님, 1-주영역, 0-보조영역
  available: 0 | 1 // 0 이면 사용 가능 1이면 비활성화
  createdAt: string | null | undefined
  updatedAt: string | null | undefined
}

export interface PubAreaItem {
  name: string // 상품명(패키지일때 패키지명, 단독지면은 지면명)
  subName?: string // 프론트에서 가공한 패키지들 이름
  isPackage: boolean // 패키지 여부
  shared: null | 0 | 1 // null-패키지아님, 0-일반패키지, 1-다중 지면
  areas: PubAreaItemArea[]
}
// {
//       "name": "메인 콘텐츠A",
//       "isPackage": true,    // 패키지 여부
//       "shared": null,       // null-패키지아님, 0-일반패키지, 1-동기화 필요 패키지
//       "areas": [
//         {
//           "id": 10000014,
//           "area": "메인 콘텐츠A",
//           "manualUrl": "https://rc-ad.wadiz.kr/images/manual/guideline.pdf",
//           "imgUrl": "https://rc-ad.wadiz.kr/images/20221025/1666662173485.png",
//           "main": null,
//           "available": 0,
//           "createdAt": null,
//           "updatedAt": "2023-06-16 09:35:21"
//         }
//       ]
//     },
const fetchPubAreaList = ({
  isWadizAdmin,
  projectType,
  comingsoon,
}: PubAreaParams): Promise<ResultType<PubAreaItem[]>> => {
  const advertiserType = `advertiser_type=${isWadizAdmin ? 1 : 0}`
  const areaUrl = `pub-area?${advertiserType}&project_type=${projectType}&comingsoon=${comingsoon}`

  return api('GET', areaUrl)
}

export { fetchPubAreaList }
