import React, { PureComponent } from 'react';

// Components
import Paginator from '../../components/Paginator/Paginator';

class PaginatorContainer extends PureComponent {
  onChangePage = type => {
    const { totalArr, activePageIdx } = this.data2page();
    const { onChange } = this.props;
    if (type === 'prev') {
      onChange(totalArr[activePageIdx - 1][0]);
    } else if (type === 'next') {
      onChange(totalArr[activePageIdx + 1][0]);
    }
  };

  onChangeCurrPage = val => {
    const { totalCount, onChange } = this.props;
    if (val <= totalCount && val > 0) {
      onChange(val);
    }
  };

  data2page = () => {
    const { totalCount, activePage, viewCount } = this.props;
    if (totalCount < 1) {
      return {
        totalArr: [[]],
        activePage: 0,
        activeIdx: 0,
        activePageIdx: 0,
        items: [],
      };
    }

    const paginatorCount = 5; // 페이지네이터에 띄울 번호 개수 default: 5개

    const totalArr = [];

    for (let i = 0; i < totalCount / viewCount; i += paginatorCount) {
      totalArr.push(new Array(paginatorCount).fill(0).map((val, idx) => i + idx + 1));

      const tmpIdx = totalArr[totalArr.length - 1].indexOf(parseInt((totalCount - 1) / viewCount, 0) + 1);
      if (tmpIdx > -1) totalArr[totalArr.length - 1].splice(tmpIdx + 1);
    }

    const activeIdx = (activePage - 1) % paginatorCount;
    const activePageIdx = parseInt((activePage - 1) / paginatorCount, 0);

    return {
      totalArr,
      activePage,
      activeIdx,
      activePageIdx,
      items: totalArr[activePageIdx],
    };
  };

  render() {
    const {
      onChangePage, onChangeCurrPage, data2page,
    } = this;

    return (
      <Paginator
        data={data2page()}
        onChangePage={onChangePage}
        onChangeCurrPage={onChangeCurrPage}
      />
    );
  }
}

export default PaginatorContainer;
