// eslint-disable-next-line
import React from 'react';
import style from '../../Dialog.module.scss';

// pay-info/list?maker_id API 결과 필드
type SimplePayCard = {
  // adCenterAdvertisementId: number;
  // adCenterCreativeId: number;
  // adCenterMakerId: number;
  // billKey: string;
  // cardCl: string;
  // cardCode: string;
  cardName: string;
  cardNumber: string;
  // createdAt: string;
  id: number;
  // status: number;
  // updatedAt: string;
};
const notiList = [
  '결제 실패한 경우, 간편결제 카드를 변경해주시거나 신규 결제 카드를 등록해주세요.',
  '메이커님은 이미 간편결제가 등록되어 있습니다.',
  '간편결제 해지를 원하시면 설정>간편결제 관리에서 해지가 가능합니다.',
];
export const SimplePaySelector: React.FC<{
  cardList: Array<SimplePayCard>;
  onChange: (e: React.SyntheticEvent<EventTarget>) => void;
}> = ({ cardList, onChange }) => (
  <>
    <label className={'wz outline block dropdown'}>
      <select onChange={onChange}>
        <option className={style.selectDefault} value="-1">
          신용카드를 선택하세요
        </option>
        {cardList.map((val, idx) => (
          <option key={`cards-${idx}`} value={val.id}>
            {' '}
            {val.cardName} | **** - **** - **** - {val.cardNumber}{' '}
          </option>
        ))}
      </select>
    </label>
    <ul style={{ marginBottom: '30px' }}>
      {notiList.map((n) => (
        <li key={n}>{n}</li>
      ))}
    </ul>
  </>
);
