import React, { Fragment } from 'react'

// Components
import Lnb from '../Lnb'
import NavBar from '../NavBar'
import PaginatorContainer from '../../containers/PaginatorContainer/PaginatorContainer'
// import ExcelExportContainer from '../../containers/ExcelExportContainer/ExcelExportContainer'

// CSS module
import style from './AdResultReportContent.module.scss'
import className from 'classnames/bind'
import LoadingBar from '../LoadingBar/LoadingBar'
const cx = className.bind(style)

const navList = [
  { name: '보고서', link: '/main/ad/report/' },
  { name: '성과 보고서', link: '/main/ad/report/result' },
]

const viewSizeList = [5, 10, 15, 20, 25, 30]

const isNullText = (value) => {
  if (value === '' || value === null || value === undefined) {
    return '-'
  }
  return value
}

const AdResultReportContent = ({
  state,
  makerName,
  handleProjectNameSelect,
  handleAdNameSelect,
  handleOptionSelectAndGetResult,
  handlePageNumber,
  handleProjectNameSort,
  handleAdNameSort,
  handleViewSizeSelect,
  handleGoToADDetail,
  handleExportExcel,
  localeOrNull,
  handleToAdCreate,
  selectedOption,
  totalCount,
  fileName,
  tableData,
  isDownload,
  statusType,
}) => {
  return (
    <>
      <main className={cx('main_container', 'report')}>
        <Lnb type="report" />
        <div className={cx('report_result', 'content_wrap')}>
          <NavBar list={navList} />
          {state.loadingBarFlag ? (
            <LoadingBar />
          ) : state.allItemTotalCount === 0 ? (
            <div className={cx('report_empty')}>
              <h2>안녕하세요. {makerName} 메이커님.</h2>
              <p>
                등록된 광고가 없어 성과 보고서를 확인할 수 없습니다.<br></br>광고를 만들면 성과 보고서를 확인할 수
                있습니다.
              </p>
              <button
                type="button"
                className={cx('wz button primary', 'button_320')}
                onClick={() => handleToAdCreate()}
              >
                <i className={cx('icon add')} />
                광고 만들기
              </button>
            </div>
          ) : (
            <>
              <h2>성과 보고서</h2>
              <div className={cx('result_select')}>
                <label className={cx('wz outline dropdown', 'rpDropdown')}>
                  <select defaultValue={state.selectedOption.projectId} onChange={handleProjectNameSelect}>
                    <option value="">전체 프로젝트</option>
                    {state.projectNameOption.map((val, idx) => (
                      <option key={idx} value={val[0]}>
                        {val[1]}
                      </option>
                    ))}
                  </select>
                </label>
                <label className={cx('wz outline dropdown', 'rpDropdown')}>
                  <select defaultValue={state.selectedOption.adId} onChange={handleAdNameSelect}>
                    <option value="">전체 광고명</option>
                    {state.adNameOption.map((val, idx) => (
                      <option key={idx} value={val[0]}>
                        {val[1]}
                      </option>
                    ))}
                  </select>
                </label>
                <button className={cx('wz button')} onClick={handleOptionSelectAndGetResult}>
                  이동
                </button>
                <label className={cx('wz dropdown outline dense', 'rpDropdown', 'tablePageLength')}>
                  <select defaultValue={state.selectedOption.size} onChange={handleViewSizeSelect}>
                    {viewSizeList.map((val, idx) => (
                      <option key={idx} value={val}>
                        {val}개씩 보기
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className={cx('wz container table', 'cancellation')}>
                <div className={cx('wz table track', 'tableWrap')}>
                  <table className={cx('ad_table', 'ad_tableResult')}>
                    <thead>
                      <tr>
                        <th style={{ width: '50px' }}>No.</th>
                        <th style={{ width: '152px' }}>
                          <button className={cx('wz button less mini')} onClick={handleProjectNameSort}>
                            프로젝트
                            <i
                              className={cx(
                                state.selectedOption.projectSort === 0 ? 'icon angle-down' : 'icon angle-up'
                              )}
                            ></i>
                            <span>정렬하기</span>
                          </button>
                        </th>
                        <th style={{ width: '152px' }}>
                          <button className={cx('wz button less mini')} onClick={handleAdNameSort}>
                            광고명
                            <i
                              className={cx(state.selectedOption.adSort === 0 ? 'icon angle-down' : 'icon angle-up')}
                            ></i>
                            <span>정렬하기</span>
                          </button>
                        </th>
                        <th style={{ width: '190px' }}>광고 영역</th>
                        <th style={{ width: '50px' }}>광고구좌</th>
                        <th style={{ width: '120px' }}>광고기간</th>
                        <th style={{ width: '90px' }}>광고비</th>
                        <th style={{ width: '70px' }}>
                          광고<br></br>단계
                        </th>
                        <th style={{ width: '90px' }}>노출수</th>
                        <th style={{ width: '90px' }}>클릭수</th>
                        <th style={{ width: '90px' }}>클릭률</th>
                      </tr>
                    </thead>
                    {/* 병합된 부분은 tbody안에 묶어주세요 */}
                    {state.itemList.length > 0 &&
                      state.itemList.map((val, idx) => (
                        <Fragment key={idx}>
                          <tbody className={cx('ad_table_group')}>
                            <tr key={idx}>
                              <td rowSpan={val.creativeList.length}>{val.idx}</td>
                              <td rowSpan={val.creativeList.length} className={cx('aligned left')}>
                                {val.projectName}
                              </td>
                              <td
                                rowSpan={val.creativeList.length}
                                className={val.adCenterAdvertisementId && cx('point_primary')}
                                onClick={() => handleGoToADDetail(val.adCenterAdvertisementId)}
                              >
                                {val.adName}
                              </td>
                              <td>{val.creativeList[0].area}</td>
                              <td>{val.creativeList[0].accountNum}</td>
                              {val.creativeList[0].startAt === '' ? (
                                <td>-</td>
                              ) : (
                                // : <td>{dateToStringAdPeriod(val.creativeList[0].startAt)} ~<br></br>{dateToStringAdPeriod(val.creativeList[0].endAt)}</td>
                                <td>
                                  {val.creativeList[0].startAt.slice(2, 10)} ~<br></br>
                                  {val.creativeList[0].endAt.slice(2, 10)}
                                </td>
                              )}
                              <td>
                                {localeOrNull(val.creativeList[0].price) !== '-'
                                  ? `${localeOrNull(val.creativeList[0].price)}원`
                                  : '-'}
                              </td>
                              <td>
                                {isNullText(
                                  val.creativeList[0].adProcessStatus !== 0
                                    ? statusType.adProcessStatus[val.creativeList[0].adProcessStatus]
                                    : statusType.status[val.creativeList[0].status]
                                )}
                              </td>
                              <td>{localeOrNull(val.creativeList[0].numImp)}</td>
                              <td>{localeOrNull(val.creativeList[0].numClick)}</td>
                              <td>{val.creativeList[0].ctr}</td>
                              {/* <td>{val.creativeList[0].price === '-' ? val.creativeList[0].price : val.creativeList[0].price.toLocaleString()}</td>
                                  <td>{val.creativeList[0].numClick === '-' ? val.creativeList[0].numClick : val.creativeList[0].numClick.toLocaleString()}</td>
                                  <td>{val.creativeList[0].numImp === '-' ? val.creativeList[0].numImp : val.creativeList[0].numImp.toLocaleString()}</td>
                                  <td>{val.creativeList[0].ctr === '-' ? val.creativeList[0].ctr : val.creativeList[0].ctr}</td> */}
                            </tr>
                            {val.creativeList.slice(1).map((val2, idx2) => (
                              <tr key={idx2}>
                                <td>{val2.area}</td>
                                <td>{val2.accountNum}</td>
                                {/* <td>{dateToStringAdPeriod(val2.startAt)} ~<br></br>{dateToStringAdPeriod(val2.endAt)}</td> */}
                                <td>
                                  {val2.startAt.slice(2, 10)} ~<br></br>
                                  {val2.endAt.slice(2, 10)}
                                </td>
                                <td>{localeOrNull(val2.price) !== '-' ? `${localeOrNull(val2.price)}원` : '-'}</td>
                                <td>
                                  {isNullText(
                                    val2.adProcessStatus !== 0
                                      ? statusType.adProcessStatus[val2.adProcessStatus]
                                      : statusType.status[val2.status]
                                  )}
                                </td>
                                <td>{localeOrNull(val2.numImp)}</td>
                                <td>{localeOrNull(val2.numClick)}</td>
                                <td>{localeOrNull(val2.ctr)}</td>
                                {/* <td>{val2.price === '-' ? val2.price : val2.price.toLocaleString()}</td>
                                    <td>{val2.numClick === '-' ? val2.numClick : val2.numClick.toLocaleString()}</td>
                                    <td>{val2.numImp === '-' ? val2.numImp : val2.numImp.toLocaleString()}</td>
                                    <td>{val2.ctr === '-' ? val2.ctr : val2.ctr}</td> */}
                              </tr>
                            ))}
                          </tbody>
                        </Fragment>
                      ))}
                    {state.itemList.length === 0 && (
                      <tbody className={cx('ad_table_group')}>
                        <tr>
                          <td className={cx('error_table')} colSpan="10">
                            <div>
                              <i className={cx('icon error-o')}></i>성과 보고서가 없습니다.
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
                {state.itemList.length !== 0 && (
                  <>
                    <PaginatorContainer
                      onChange={handlePageNumber}
                      activePage={selectedOption.start}
                      totalCount={totalCount}
                      viewCount={selectedOption.size}
                    />
                    {/* <div className={cx('ad_table_btnArea')}>
                      <button onClick={handleExportExcel} className={cx('wz button')}>
                        <i className={cx('icon download')}></i>
                        엑셀 파일로 전체 정보 다운로드
                        <ExcelExportContainer fileName={fileName} tableData={tableData} isDownload={isDownload} />
                      </button>
                    </div> */}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </main>
      {/* 성과보고서 개수 확인 전 기본 화면 */}
      {/* {state.allItemTotalCount === -1 && (
        <>
          <Lnb type="report" />
          <div className={cx('report_empty', 'content_wrap')}>
            <NavBar list={navList} />
            <div className={cx('empty_container')}>
            </div>
          </div>
        </>
      )} */}
    </>
  )
}

export default AdResultReportContent
